// TODO: Remove
// @ts-nocheck
import { useMediaQuery } from 'react-responsive'

import SlideOver, { SlideOverTypeT } from '@/components/SlideOver'

import DefaultFilterPanel, {
  FilterPanelPropsI as DefaultFilterPanelPropsI,
} from './FilterPanel'
import InstantSearchFilterPanel, {
  InstantSearchFilterPanelPropsI,
} from './InstantSearchFilterPanel'

interface DefaultResponsiveFilterPanelPropsI extends DefaultFilterPanelPropsI {
  type?: 'default'
}

interface InstantSearchResponsiveFilterPanelPropsI
  extends InstantSearchFilterPanelPropsI {
  type?: 'instantSearch'
}

type ResponsiveFilterPanelPropsI = (
  | DefaultResponsiveFilterPanelPropsI
  | InstantSearchResponsiveFilterPanelPropsI
) & {
  slideOverType?: SlideOverTypeT
  open: boolean
  setOpen: (args: any) => any
}

const ResponsiveFilterPanel = ({
  slideOverType,
  open,
  setOpen,
  forceSlideover,
  type = 'default',
  ...props
}: ResponsiveFilterPanelPropsI) => {
  const isBelow2XL = useMediaQuery({
    query: '(max-width: 1535px)',
  })
  const FilterPanel =
    type === 'instantSearch' ? InstantSearchFilterPanel : DefaultFilterPanel

  if (isBelow2XL || forceSlideover) {
    return (
      <SlideOver
        type={slideOverType}
        title='Filters'
        open={open}
        onClose={() => setOpen(false)}
      >
        <FilterPanel {...props} forceSlideover={forceSlideover} />
      </SlideOver>
    )
  }

  return <FilterPanel {...props} forceSlideover={forceSlideover} />
}

export default ResponsiveFilterPanel
