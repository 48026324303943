import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Toaster, toast } from 'sonner'

import useGlobalAlerts from '../../hooks/useGlobalAlerts'
// Hooks
import useSettings from '../../hooks/useSettings'
// Components
import Banner from '../Banner'

const GlobalAlerts = ({
  restrictedRoutes = [],
}: {
  restrictedRoutes?: string[]
}) => {
  const params = useLocation()
  const isRestrictedOnCurrentPath =
    restrictedRoutes.filter((restrictedRoute) =>
      params.pathname.includes(restrictedRoute),
    ).length > 0
  const { appSettings } = useSettings()
  const key = appSettings?.key
  const { alerts, dismissAlert } = useGlobalAlerts({
    route: params.pathname,
    key,
  })

  useEffect(() => {
    alerts.map(({ active, id, message, type, dismissable }) => {
      if (active && dismissable) {
        toast.custom(
          (t) => (
            <Banner
              dismissable
              message={message}
              appearance={type}
              showIcon={true}
              onClick={() => {
                toast.dismiss(t)
                dismissAlert(id)
              }}
            />
          ),
          {
            id,
            dismissible: true,
            duration: 1000 * 60 * 10, //10 minutes
          },
        )
      }
    })
  }, [alerts])

  if (isRestrictedOnCurrentPath) return <></>

  return (
    <>
      <Toaster
        position='top-center'
        richColors={true}
        visibleToasts={3}
        expand
      />
      {alerts
        .filter(({ dismissable }) => !dismissable)
        .map(({ id, message, type }, index) => (
          <motion.div
            key={id}
            initial={{ y: -24, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: index * 0.1 }}
          >
            <Banner
              dismissable={false}
              message={message}
              appearance={type}
              showIcon
            />
          </motion.div>
        ))}
    </>
  )
}

export default GlobalAlerts

