function formatDataSize({
  value,
  unit,
  decimals = 2,
  perSecond = false,
}: {
  value: number
  unit: 'bit' | 'byte'
  decimals?: number
  perSecond?: boolean
}): string {
  const speedUnit = perSecond ? '/s' : ''
  if (value === 0) return `0 ${unit === 'bit' ? 'Bits' : 'Bytes'}${speedUnit}`

  const base = unit === 'bit' ? 1000 : 1024 // Use 1000 for bits and 1024 for bytes
  const dm = decimals < 0 ? 0 : decimals
  const sizeUnits = {
    bit: ['Bits', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
    byte: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  }

  const i = Math.floor(Math.log(value) / Math.log(base))
  const formattedNumber = parseFloat((value / Math.pow(base, i)).toFixed(dm))
  const sizeUnit = sizeUnits[unit][i]

  return `${formattedNumber} ${sizeUnit}${speedUnit}`
}

export default formatDataSize
