import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest } from '../authConfig'
import { API_ACCESS_TOKEN_SCOPE } from '../config/constants'
import { msalInstance } from '../index'

export default async function getAccessToken(forceRefresh = false) {
  const account = msalInstance.getActiveAccount()

  if (!account) return null

  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: [API_ACCESS_TOKEN_SCOPE],
      account,
      forceRefresh,
    })

    return response.accessToken
  } catch (error) {
    console.error('Error acquiring token silently:', error)

    // Handle InteractionRequiredAuthError or other errors that indicate the need for user interaction
    if (error instanceof InteractionRequiredAuthError) {
      try {
        // Prompt user for interaction
        const response = await msalInstance.acquireTokenPopup(loginRequest) // or acquireTokenRedirect
        return response.accessToken
      } catch (popupError) {
        console.error('Error acquiring token via popup:', popupError)
        // Handle further errors, possibly show a user-facing notification
      }
    } else {
      // Handle other errors or perhaps retry logic
    }
    msalInstance.logoutRedirect()
    return null
  }
}
