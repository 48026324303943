import moment from 'moment-timezone'

export function createUserProperties(userData: ADUserI) {
  const {
    id,
    userPrincipalName = 'Unknown UPN',
    mail,
    givenName,
    surname,
    preferredLanguage,
    jobTitle,
    officeLocation,
    displayName,
  } = userData

  const userName = displayName || `${givenName} ${surname}`

  return {
    id,
    userPrincipalName,
    mail,
    givenName,
    surname,
    userName,
    preferredLanguage,
    jobTitle,
    officeLocation,
    timezone: moment.tz.guess(true),
  }
}
