import clsx from 'clsx'
import React from 'react'

import { Switch } from '@headlessui/react'

export default function SwitchComponent({
  label,
  checked = false,
  onChange,
  labelHidden = false,
  labelPosition = 'right',
}) {
  // Add tooltip
  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch
        checked={checked}
        onChange={onChange}
        className={clsx(
          checked ? 'bg-elements-primary-main' : 'bg-neutral-shadow-heavy',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main focus-visible:ring-offset-neutral',
          { 'order-2': labelPosition === 'left' },
        )}
      >
        <span className='sr-only'>{label}</span>
        <span
          aria-hidden='true'
          className={clsx(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>
      {label && (
        <Switch.Label
          as='span'
          className={clsx('text-sm text-text-primary', {
            hidden: labelHidden,
            'ml-3': labelPosition === 'right',
            'mr-3': labelPosition === 'left',
          })}
        >
          {label}
        </Switch.Label>
      )}
    </Switch.Group>
  )
}
