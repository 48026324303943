interface VideoFormatIconsProps {
  videoFormatIcons?: string[]
  type?: string
  className?: string
  imageClassName?: string
}

const VideoFormatIcons: React.FC<VideoFormatIconsProps> = ({
  videoFormatIcons,
  type = 'dark',
  className,
  imageClassName,
}) => {
  return (
    <div className={`${className}`}>
      {videoFormatIcons?.map((key, index) => {
        const imageColorClass = type === 'light' ? 'invert' : 'invert-0'

        return (
          <img
            key={`${key}-${index}`}
            src={`/images/videoFormat/${key}.png`}
            className={`${imageColorClass} ${imageClassName}`}
            alt={key}
          />
        )
      })}
    </div>
  )
}

export default VideoFormatIcons
