import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPrometheusCoreIngressConfig from './common/prometheusCoreIngress'

const requestRate: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreIngressConfig = commonPrometheusCoreIngressConfig()

  return {
    ...prometheusCoreIngressConfig,
    value: 'request-rate',
    label: 'Request Rate (rps)',
    shortLabel: 'Req.Rate (rps)',
    dataKey: 'requestRate',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number): string => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format(value)}`
    },
    valueFormat: (value: number): string => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format(value)}`
    },
    info: 'Request Rate refers to the rate at which incoming requests are being made to a system through an ingress controller. Request Rate provides information about the frequency at which external clients or users are making requests to services that are exposed through the ingress controller. This metric is crucial for monitoring and understanding the load or demand placed on the system by incoming requests.',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusCoreIngressConfig.defaultConfig,
      metric: 'request-rate',
    },
  }
}

export default requestRate
