import { ClientSettingsI, ClientT } from '../types'
import nbcuDtcClientSettings from './nbcu-dtc'
import nowClientSettings from './now'
import nowGoldClientSettings from './now-gold'
import peacockClientSettings from './peacock'
import showmaxClientSettings from './showmax'
import skyEuropeClientSettings from './sky-europe'
import skyEuropeAndPeacockSettings from './sky-europe-and-peacock'
import skyshowtimeClientSettings from './skyshowtime'

const clients: Record<ClientT, ClientSettingsI> = {
  'nbcu-dtc': nbcuDtcClientSettings,
  peacock: peacockClientSettings,
  skyshowtime: skyshowtimeClientSettings,
  now: nowClientSettings,
  'sky-europe-and-peacock': skyEuropeAndPeacockSettings,
  'sky-europe': skyEuropeClientSettings,
  showmax: showmaxClientSettings,
  'now-gold': nowGoldClientSettings,
}

export default clients
