import { HighchartsReact } from 'highcharts-react-official'
import Highcharts, {
  XAxisPlotLinesOptions,
  XAxisTitleOptions,
  YAxisOptions,
} from 'highcharts/highstock'
import HighchartsBoost from 'highcharts/modules/boost'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsExporting from 'highcharts/modules/exporting'
import { debounce, max, mean, merge, sum } from 'lodash'
import React from 'react'
import Measure from 'react-measure'
import tinycolor from 'tinycolor2'

import { ChartTypes } from '@/config/types'
import useChartColors from '@/hooks/useChartColors'
import useTheme from '@/hooks/useTheme'
import useWindowSize from '@/hooks/useWindowSize'
import { useTimeStore } from '@/stores/time.store'

import SharedHighchartsReact from './SharedHighchartsReact'

HighchartsBoost(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)

interface PropsI {
  data: MetricDatumI[] | any[] | undefined
  aggregationType: 'peak' | 'average' | string
  yAxisFormat?: (v: any) => any
  yAxisType?: ChartAxisType
  yAxisFormatSecondary?: (v: any) => any
  valueFormat?: (v: any) => any
  valueFormatSecondary?: (v: any) => any
  chartType?: ChartTypes
  isMobile?: boolean
  immutable?: boolean
  forceRefresh?: boolean
  exportingEnabled?: boolean
  legendEnabled?: boolean
  yTitle?: string
  yTitleSecondary?: string
  xTitle?: string
  xTitleProps?: XAxisTitleOptions
  spacingLeft?: number
  spacingRight?: number
  spacingBottom?: number
  spacingTop?: number
  width?: any
  connectNulls?: boolean
  plotLines?: XAxisPlotLinesOptions[]
  clickablePoints?: boolean
  onPointClick?: (v: any) => any
  useSharedChart?: boolean
  insideLabels?: boolean
  comparisonOffset?: [number, any] | null
  tooltipHeaderFormat?: string
}

interface ChartOptionsI extends Highcharts.Options {
  yAxis: YAxisOptions[]
}

Highcharts.SVGRenderer.prototype.symbols.exporting = function (
  x: number,
  y: number,
  w: number,
  h: number,
) {
  const path = [
    // Arrow stem
    'M',
    x + w * 0.5,
    y,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    // Arrow head
    'M',
    x + w * 0.3,
    y + h * 0.5,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    'L',
    x + w * 0.7,
    y + h * 0.5,
    // Box
    'M',
    x,
    y + h * 0.9,
    'L',
    x,
    y + h,
    'L',
    x + w,
    y + h,
    'L',
    x + w,
    y + h * 0.9,
  ]
  return path
}

const LineChart = ({
  data = [],
  aggregationType,
  yAxisFormat = (v) => v,
  yAxisType = 'linear',
  yAxisFormatSecondary = (v) => v,
  valueFormat = (v) => v,
  valueFormatSecondary = (v) => v,
  chartType = 'spline',
  isMobile = false,
  immutable = true,
  forceRefresh = false,
  exportingEnabled = true,
  legendEnabled = true,
  yTitle,
  yTitleSecondary,
  xTitle,
  xTitleProps,
  spacingLeft = 1.5,
  spacingRight = 0,
  spacingBottom = 0,
  spacingTop = 0,
  connectNulls = true,
  plotLines,
  clickablePoints,
  onPointClick = (v) => v,
  useSharedChart = false,
  insideLabels = false,
  comparisonOffset,
  tooltipHeaderFormat,
}: PropsI) => {
  const { theme } = useTheme()!
  const timezone = useTimeStore((state) => state.timezone)
  const chartColors = useChartColors()
  const windowSize = useWindowSize()
  const [dimensions, setDimensions] = React.useState(0)
  const chartComponent = React.useRef<any>(null)
  const [_immutable, setImmutable] = React.useState(immutable)
  const chartOptions = React.useMemo(() => {
    const defaultChart: ChartOptionsI = {
      title: undefined,
      chart: {
        type: chartType,
        animation: false,
        reflow: true,
        spacingLeft: spacingLeft,
        spacingRight: spacingRight,
        spacingBottom: spacingBottom,
        spacingTop: spacingTop,
        zooming: { type: 'x', mouseWheel: false },
        events: {
          redraw: () => {
            setImmutable(immutable) // Fires after selection, legend click events to ensure the initial immutable state is used to avoid rerendering when new points are added
          },
        },
      },
      colors: getChartColors(chartColors, chartType),
      legend: {
        enabled: !isMobile && legendEnabled,
        useHTML: true,
        symbolHeight: 8,
      },
      exporting: {
        enabled: exportingEnabled,
        filename: `Insights - ${new Date().getTime()}`,
        buttons: {
          contextButton: {
            symbol: 'exporting',
            theme: {},
            menuItems: [
              'viewFullscreen',
              // "separator",
              // "downloadPNG",
              // "downloadSVG",
              'downloadCSV',
              'downloadXLS',
            ],
            x: -6,
            y: -12,
          },
        },
      },
      xAxis: [
        {
          plotLines: plotLines,
          title: {
            text: xTitle,
            ...xTitleProps,
          },
          type: 'datetime',
          labels: {
            style: {
              whiteSpace: 'nowrap',
            },
          },
          startOnTick: false,
          endOnTick: false,
        },
      ],

      series: supportSeriesComparison(
        cleanseData(data, chartType, chartColors, theme?.colors),
        comparisonOffset,
      ),
      credits: {
        enabled: false,
      },
      time: {
        timezone,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
        series: {
          type: 'column',
          opacity: 0.8,
        },
      },
      scrollbar: {
        enabled: false,
      },
      tooltip: {
        shared: yTitleSecondary && yTitleSecondary.length > 0 ? true : false,
        split: yTitleSecondary && yTitleSecondary.length > 0 ? false : true,
        useHTML: true,
        pointFormatter: function () {
          const that = { ...this } as any

          return generateTooltip({
            point: that,
            data,
            valueFormat,
            valueFormatSecondary,
          })
        },

        headerFormat:
          typeof tooltipHeaderFormat === 'string'
            ? tooltipHeaderFormat
            : '<div class="px-3 py-2 shadow-md bg-neutral-dimmed-heavy rounded-md border border-border-main">' +
              '<p class="font-sans text-xs font-medium text-text-tertiary">{point.key}' +
              '</p>' +
              '</div>',
      },
      yAxis: [
        {
          title: {
            text: yTitle,
          },
          opposite: false,
          type: yAxisType,
          labels: {
            formatter: function () {
              return yAxisFormat((this as any).value)
            },
            align: insideLabels || isMobile ? 'left' : 'right',
            x: insideLabels || isMobile ? 0 : -12,
          },
          endOnTick: false,
          // min: 0,
        },
      ],
      plotOptions: {
        flags: {
          useHTML: true,
          allowOverlapX: false,
          // stacking: "normal",
          grouping: true,
          showInNavigator: false,
          label: {
            connectorAllowed: true,
          },
          tooltip: {
            pointFormat: undefined,
          },
          groupPadding: 0,
          clip: false,
        },
        series: {
          gapSize: 5,
          connectNulls: connectNulls,
          animation: false,
          dataGrouping: {
            enabled: chartType === 'column',
            approximation: (groupData: any) => {
              if (aggregationType === 'peak') {
                return max(groupData)
              } else if (aggregationType === 'average') {
                return mean(groupData)
              } else {
                return sum(groupData)
              }
            },
          },
          cursor: clickablePoints ? 'pointer' : 'default',
          point: {
            events: {
              click: clickablePoints
                ? (event: any) => onPointClick(event)
                : undefined,
            },
          },
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
        },
        column: {
          stacking: 'normal',
          opacity: 0.9,
        },
      },
    }

    if (data?.find((d) => d.yAxis === 1)) {
      defaultChart.yAxis.push({
        title: {
          text: yTitleSecondary,
        },
        gridLineWidth: 0,
        opposite: true,
        labels: {
          formatter: function () {
            return yAxisFormatSecondary((this as any).value)
          },
          align: isMobile || insideLabels ? 'left' : 'right',
          x: isMobile || insideLabels ? 0 : 12,
        },
      })
    }

    return styledChart(defaultChart, theme?.colors)
  }, [
    data,
    chartType,
    yAxisType,
    yTitle,
    yTitleSecondary,
    theme,
    aggregationType,
    plotLines,
    isMobile,
    insideLabels,
    timezone,
  ])

  React.useEffect(() => {
    setImmutable(true)
  }, [forceRefresh])

  React.useEffect(() => {
    setImmutable(true)
    const reflowChart = debounce(() => {
      chartComponent?.current?.chart?.reflow()
    }, 100) // Adjust the debounce delay as needed
    reflowChart()
    return () => {
      reflowChart.cancel() // Cancel the debounce on component unmount
    }
  }, [dimensions, windowSize])

  const HighchartsComponent = React.useMemo(
    () => (useSharedChart ? SharedHighchartsReact : HighchartsReact),
    [useSharedChart],
  )

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        if (contentRect.bounds) {
          setDimensions(contentRect.bounds.width)
        }
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className='w-full h-full pb-2 overflow-hidden'>
          <HighchartsComponent
            comparisonOffset={comparisonOffset}
            key={JSON.stringify(chartOptions)}
            immutable={_immutable}
            constructorType='stockChart'
            ref={chartComponent}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{
              className: 'highcharts-container',
              style: { height: '100%', width: '100%' },
            }}
            updateArgs={[true, true, true]} // redraw, oneToOne, animation
          />
        </div>
      )}
    </Measure>
  )
}

// export default withSize()(memo(LineChart)) <- with size bugs
export default LineChart

// Helpers
function generateTooltip({
  point,
  data,
  valueFormat,
  valueFormatSecondary,
}: {
  point: any
  data?: MetricDatumI[] | any[]
  valueFormat: (value: any) => string
  valueFormatSecondary: (value: any) => string
}) {
  if (!data) return ''
  if (point.series.type === 'flags') return ''

  if (point.series.chart.yAxis.length === 1) {
    //  Usual tooltip for single Y Axis
    return (
      '<div class="py-1 shadow-md bg-neutral-dimmed-heavy rounded-md border border-border-main">' +
      '<div class="flex items-center truncate w-full px-3">' +
      `<div class="w-2 h-2 mr-2 rounded-full flex-shrink-0" style="background-color: ${point.series.color}"></div>` +
      `<p class="font-sans text-sm font-medium text-text-tertiary mr-2">${point.series.name}</p>` +
      `<p class="font-sans text-sm font-bold text-text-primary tabular-nums tracking-tight">${valueFormat(
        point.y,
      )}</p>` +
      '</div>' +
      '</div>'
    )
  } else if (point.series.chart.yAxis.length === 2) {
    // Grouped tooltip per yaxis for 2 Y Axes without split panes
    return (
      `<div class="py-1 bg-neutral-dimmed-heavy  border-border-main ${
        point.series.index === 0 ||
        point.series.index === data.findIndex((d) => d.yAxis === 1)
          ? 'border border-b-0 mt-1 rounded-t-md'
          : point.series.index === data.length - 1 ||
            point.series.index === data.filter((d) => d.yAxis === 0).length - 1
          ? 'border border-t-0 rounded-b-md'
          : 'border-t-0 border-b-0 border'
      }">` +
      (point.series.index === 0 ||
      point.series.index === data.findIndex((d) => d.yAxis === 1)
        ? `<div class="font-sans text-sm font-bold text-text-tertiary px-3  truncate w-full my-2">${point.series.yAxis.userOptions.title.text} </div>`
        : `<p></p>`) +
      '<div class="flex items-center truncate w-full px-3">' +
      (point.series.yAxis.userOptions.index === 0
        ? `<div class="w-2 h-2 mr-2 rounded-full flex-shrink-0" style="background-color: ${point.series.color}"></div>`
        : `<div class="w-3 h-0.5 -ml-0.5 mr-1.5 my-auto flex-shrink-0" style="background-color: ${point.series.color}"></div>`) +
      `<p class="font-sans text-sm font-medium text-text-tertiary mr-2">${point.series.name}</p>` +
      `<p class="font-sans text-sm font-bold text-text-primary tabular-nums tracking-tight">${
        point.series.yAxis.userOptions.index === 0
          ? valueFormat(point.y)
          : valueFormatSecondary(point.y)
      }</p>` +
      '</div>' +
      '</div>'
    )
  }

  return ''
}

function getChartColors(colors: string[] = [], chartType: ChartTypes) {
  const _colors = [...colors]
  return chartType === 'column'
    ? [..._colors].splice(1, colors.length)
    : _colors
}

function addGradient(data: any, chartColors: string[], themeColors: any) {
  const _data = [...data]
  return _data.map((datum, i) => {
    if (datum?.type === 'flags') {
      return datum
    } else {
      const color = datum?.color || chartColors[i]
      return {
        ...datum,
        color:
          datum?.slug === 'total'
            ? themeColors['elements-primary-main']
            : color,
        lineWidth: datum?.slug === 'total' ? 2.5 : 1.5,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.66 },
          stops: [
            [
              0,
              tinycolor(color)
                .setAlpha(datum?.slug === 'total' ? 0 : 0.2)
                .toRgbString(),
            ],
            [1, tinycolor(color).setAlpha(0).toRgbString()],
          ],
        },
      }
    }
  })
}

function supportSeriesComparison(data: any[], comparisonOffset?: any) {
  if (!comparisonOffset) {
    return data
  } else {
    return data.map((series) => ({
      ...series,
      dashStyle: 'ShortDot',
    }))
  }
}

function cleanseData(
  data: MetricDatumI[],
  chartType: ChartTypes,
  chartColors: any,
  themeColors: any,
) {
  if (chartType === 'column') {
    return addGradient(
      data.filter((datum) => datum.slug !== 'total'),
      chartColors,
      themeColors,
    )
  } else {
    return addGradient(data, chartColors, themeColors)
  }
}

function styledChart(chartOptions: ChartOptionsI, colors: any) {
  const chartStyles: ChartOptionsI | any = {
    chart: {
      selectionMarkerFill: tinycolor(colors['elements-primary-main'])
        .setAlpha(0.1)
        .toString(),
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'inherit',
      },
    },
    xAxis: [
      {
        gridLineColor: colors['border-main'],
        lineColor: colors['border-main'],
        tickColor: colors['divider-shadow'],
        crosshair: {
          color: colors['divider-shadow'],
        },
        title: {
          style: {
            color: colors['text-dimmed'],
          },
        },
        labels: {
          style: {
            color: colors['text-dimmed'],
            fontSize: '0.75rem',
          },
        },
      },
    ],
    yAxis: [
      {
        gridLineColor: colors['border-main'],
        lineColor: colors['border-main'],
        tickColor: colors['divider-shadow'],
        title: {
          style: {
            color: colors['text-dimmed'],
          },
        },
        labels: {
          style: {
            color: colors['text-dimmed'],
            fontSize: '0.75rem',
          },
        },
      },
    ],

    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      padding: 0,
      shadow: false,
    },

    legend: {
      itemHiddenStyle: {
        color: colors['text-disabled'],
      },
      itemHoverStyle: {
        color: colors['text-primary'],
      },
      itemStyle: {
        color: colors['text-tertiary'],
        fontWeight: '500',
        fontSize: '0.75rem',
      },
    },
    plotOptions: {
      flags: {
        fillColor: colors['neutral-dimmed'],
        lineColor: colors['border-main'],
        lineWidth: 1,
        borderRadius: 8,
        states: {
          hover: {
            fillColor: colors['neutral'],
            lineColor: colors['elements-primary-main'],
            lineWidth: 2,
          },
        },
      },
    },
    navigation: {
      menuStyle: {
        border: `1px solid ${colors['border-main']}`,
        background: colors['neutral-dimmed-heavy'],
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        borderRadius: '0.375rem',
      },
      menuItemStyle: {
        color: colors['text-secondary'],
        padding: '0.75em 1.25em',
      },
      menuItemHoverStyle: {
        background: colors['neutral'],
        color: colors['text-primary'],
      },
      buttonOptions: {
        symbolStroke: colors['text-tertiary'],
        theme: {
          fill: colors['neutral'],
          // states: {
          //   hover: {
          //     fill: colors['neutral-shadow'],
          //     color: colors['text-secondary'],
          //   },
          //   select: {
          //     fill: colors['neutral-shadow'],
          //     color: colors['text-secondary'],
          //   },
          // },
        },
      },
    },
    navigator: {
      maskFill: tinycolor(colors['elements-primary-main'])
        .setAlpha(0.1)
        .toString(),
      outlineColor: colors['divider-shadow'],
      xAxis: {
        gridLineColor: colors['border-main'],
        lineColor: colors['border-main'],
        tickColor: colors['divider-shadow'],
        labels: {
          style: {
            color: colors['text-primary'],
            fontSize: '0.75rem',
          },
        },
      },
      handles: {
        backgroundColor: colors['neutral-shadow'],
        borderColor: colors['text-dimmed'],
        height: 20,
        width: 8,
      },
    },
  }

  const styledChartOptions = merge(
    {},
    chartOptions,
    chartStyles,
  ) as ChartOptionsI

  styledChartOptions.yAxis?.forEach((axis) => {
    axis.title = {
      ...axis.title,
      style: {
        ...axis.title?.style,
        color: colors['text-dimmed'],
      },
    }
    axis.labels = {
      ...axis.labels,
      style: {
        ...axis.labels?.style,
        color: colors['text-dimmed'],
        fontSize: '0.75rem',
      },
    }
  })

  return styledChartOptions
}
