import React from "react";
import PageLoading from "../PageLoading";

const Loadable = (Component) => (props) => (
  <React.Suspense fallback={<PageLoading />}>
    <Component {...props} />
  </React.Suspense>
);

export default Loadable;
