import axios from 'axios'
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query'

import getAccessToken from '../utils/getAccessToken'

const queryfn = async (url: string, customErrorMessage?: string) => {
  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }

    return response.data
  } catch (error) {
    console.error(error)

    if (customErrorMessage) {
      throw new Error(customErrorMessage)
    } else {
      throw error
    }
  }
}

const useAuthenticatedQuery = <T>(
  queryKey: QueryKey,
  url: string,
  options?: UseQueryOptions,
  customErrorMessage?: string,
): UseQueryResult<T, Error> => {
  return useQuery<any, any, any, QueryKey>(
    queryKey,
    () => queryfn(url, customErrorMessage),
    options,
  )
}

export default useAuthenticatedQuery
