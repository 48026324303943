import React from 'react'

import AuthPageLayout from '@/components/AuthPageLayout'
import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import useSettings from '@/hooks/useSettings'
import { useClientStore } from '@/stores/client.store'
import { useTimeStore } from '@/stores/time.store'
import { cn } from '@/utils/cn'

const Login = ({ onLogin }) => {
  const setClient = useClientStore((state) => state.setClient)
  const setTimezone = useTimeStore((state) => state.setTimezone)
  
  const { appSettings } = useSettings()

  const { name = '', description = '', login } = appSettings

  React.useEffect(() => {
    setClient(appSettings.defaultClient)
    setTimezone(CLIENT_DEFAULT_TIMEZONES[appSettings.defaultClient])
  }, [])

  return (
    <AuthPageLayout showAppLogo>
      <div>
        <h2 className='mt-8 text-3xl font-extrabold text-center text-text-primary'>
          Welcome to {name}
        </h2>
        <p className='mt-4 text-sm text-center text-text-tertiary max-w'>
          {description}
        </p>
        <div className='mt-8'>
          <button
            type='button'
            onClick={onLogin}
            className={cn(
              'flex justify-center w-full px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm',
              login.loginButtonClassName,
            )}
          >
            Sign in with Your Corporate Login
          </button>
        </div>
      </div>
    </AuthPageLayout>
  )
}

export default Login
