import { MetricSettingsFn } from '../../types'
import activations from './bigvivaSdp/activations'
import cancellations from './bigvivaSdp/cancellations'
import averageBitrate from './conviva/averageBitrate'
import averageVideoStartupTime from './conviva/averageVideoStartupTime'
import concurrentPlays from './conviva/concurrentPlays'
import connectionInducedRebufferingRatio from './conviva/connectionInducedRebufferingRatio'
import exitsBeforeVideoStart from './conviva/exitsBeforeVideoStart'
import exitsBeforeVideoStartPercentage from './conviva/exitsBeforeVideoStartPercentage'
import plays from './conviva/plays'
import rebufferingRatio from './conviva/rebufferingRatio'
import totalAttempts from './conviva/totalAttempts'
import videoPlaybackFailures from './conviva/videoPlaybackFailures'
import videoPlaybackFailuresBusinessPercentage from './conviva/videoPlaybackFailuresBusinessPercentage'
import videoPlaybackFailuresPercentage from './conviva/videoPlaybackFailuresPercentage'
import videoPlaybackFailuresTechnicalPercentage from './conviva/videoPlaybackFailuresTechnicalPercentage'
import videoStartFailures from './conviva/videoStartFailures'
import videoStartFailuresBusinessPercentage from './conviva/videoStartFailuresBusinessPercentage'
import videoStartFailuresPercentage from './conviva/videoStartFailuresPercentage'
import videoStartFailuresTechnicalPercentage from './conviva/videoStartFailuresTechnicalPercentage'
import signIns from './identity/signIns'
import prometheusResponseDuration from './prometheusApplication/responseDuration'
import prometheusTotalResponses from './prometheusApplication/totalResponses'
import prometheusTps from './prometheusApplication/tps'
import prometheusPacketDrops from './prometheusCloudEngGateway/packetDrops'
import prometheusThroughputCloudEngGW from './prometheusCloudEngGateway/throughputCloudEngGW'
import prometheusCPUUsage from './prometheusCore/cpuUsage'
import prometheusMemoryUsage from './prometheusCore/memoryUsage'
import prometheusTotalAvailableNodeCores from './prometheusCore/totalAvailableNodeCores'
import prometheusTotalNodeBufferCores from './prometheusCore/totalNodeBufferCores'
import prometheusTotalNodeRequestedCores from './prometheusCore/totalNodeRequestedCores'
import prometheusTotalNodes from './prometheusCore/totalNodes'
import prometheusTotalReadyNodes from './prometheusCore/totalReadyNodes'
import prometheusUtilisedNodes from './prometheusCore/utilisedNodes'
import prometheusUtilisedPods from './prometheusCore/utilisedPods'
import prometheusRequestRate from './prometheusCoreIngress/requestRate'
import prometheusWaitingConnections from './prometheusCoreIngress/waitingConnections'
import prometheusWritingConnections from './prometheusCoreIngress/writingConnections'
import prometheusMaxConnections from './prometheusCoreLoadBalancer/maxConnections'
import prometheusOpenConnections from './prometheusCoreLoadBalancer/openConnections'
import prometheusTrafficIn from './prometheusCoreNetwork/trafficIn'
import prometheusTrafficOut from './prometheusCoreNetwork/trafficOut'
import prometheusLatency from './prometheusPersistence/latency'
import prometheusThroughputPersistence from './prometheusPersistence/throughputPersistence'
import signUps from './sdp/signUps'

const metrics: { [key: string]: MetricSettingsFn } = {
  // Conviva
  'concurrent-plays': concurrentPlays,
  plays: plays,
  'total-attempts': totalAttempts,
  'average-bitrate': averageBitrate,
  'connection-induced-rebuffering-ratio': connectionInducedRebufferingRatio,
  'rebuffering-ratio': rebufferingRatio,
  'exits-before-video-start-percentage': exitsBeforeVideoStartPercentage,
  'exits-before-video-start': exitsBeforeVideoStart,
  'video-playback-failures-percentage': videoPlaybackFailuresPercentage,
  'video-playback-failures': videoPlaybackFailures,
  'video-playback-failures-business-percentage':
    videoPlaybackFailuresBusinessPercentage,
  'video-playback-failures-technical-percentage':
    videoPlaybackFailuresTechnicalPercentage,
  'video-start-failures-percentage': videoStartFailuresPercentage,
  'video-start-failures': videoStartFailures,
  'video-start-failures-business-percentage':
    videoStartFailuresBusinessPercentage,
  'video-start-failures-technical-percentage':
    videoStartFailuresTechnicalPercentage,
  'video-startup-time': averageVideoStartupTime,

  // Identity
  'sign-ins': signIns,

  // SDP
  'sign-ups': signUps,

  // Bigviva SDP
  activations: activations,
  cancellations: cancellations,

  // Prometheus Application
  'response-duration': prometheusResponseDuration,
  'total-responses': prometheusTotalResponses,
  tps: prometheusTps,

  // Prometheus Cloud Eng Gateway
  'throughput-cloud-eng-gw': prometheusThroughputCloudEngGW,
  'packet-drops': prometheusPacketDrops,

  // Prometheus Core
  'cpu-usage': prometheusCPUUsage,
  'memory-usage': prometheusMemoryUsage,
  'total-nodes': prometheusTotalNodes,
  'total-ready-nodes': prometheusTotalReadyNodes,
  'total-node-buffer-cores': prometheusTotalNodeBufferCores,
  'total-node-requested-cores': prometheusTotalNodeRequestedCores,
  'total-available-node-cores': prometheusTotalAvailableNodeCores,
  'utilised-nodes': prometheusUtilisedNodes,
  'utilised-pods': prometheusUtilisedPods,

  // Prometheus Core Network
  'traffic-in': prometheusTrafficIn,
  'traffic-out': prometheusTrafficOut,

  // Prometheus Core Ingress
  'waiting-connections': prometheusWaitingConnections,
  'writing-connections': prometheusWritingConnections,
  'request-rate': prometheusRequestRate,

  // Prometheus Core Load Balancer
  'open-connections': prometheusOpenConnections,
  'max-connections': prometheusMaxConnections,

  // Prometheus Persistence
  'throughput-persistence': prometheusThroughputPersistence,
  latency: prometheusLatency,
}

export default metrics
