import { BsDatabaseDown, BsDatabaseFillDown } from 'react-icons/bs'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreNetworkConfig from './common/prometheusCoreNetwork'

const trafficOut: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreNetworkConfig = commonPrometheusCoreNetworkConfig()

  return {
    ...prometheusCoreNetworkConfig,
    value: 'traffic-out',
    label: 'Traffic Out',
    shortLabel: 'Traffic Out',
    dataKey: 'trafficOut',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: BsDatabaseFillDown,
    solidIcon: BsDatabaseDown,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Traffic Out refers to the volume or amount of data that is transmitted from a particular point within the network to an external destination. Traffic Out is often used to analyse network usage, assess network performance, and manage network resources effectively.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreNetworkConfig.defaultConfig,
      metric: 'traffic-out',
    },
  }
}

export default trafficOut
