import {
  PROPOSITION,
  SPLIT_BY,
  TERRITORY,
  nowTerritoryOptions,
} from '@/config/formOptions'
import { MinutesUniquesSettingsI } from '@/config/types'

import { CLIENT_DEFAULT_TIMEZONES } from '../constants'
import commonBigvivaRealtimeConfig from './common/bigvivaRealtimeConfig'

const minutesAndUniquesRealtimeSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaRealtimeConfig,
  timezone: CLIENT_DEFAULT_TIMEZONES['now'],
  form: [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [SPLIT_BY['territory']],
    },
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['now-tv']],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...nowTerritoryOptions],
    },
  ],
}

export default Object.freeze(minutesAndUniquesRealtimeSettings)
