import React from 'react'

import { CONTROL_CENTRE_V2_API_DOMAIN } from '@/config/constants'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'

import { FeatureFlagsT } from '@/config/types'

type FeatureFlagsResponse = {
  data: FeatureFlagsT
  count: number
}

type GetFeatureFlag = (featureFlagKey: string) => boolean

type UseFeatureFlagsReturn = {
  loading: boolean
  error: Error | null
  getFeatureFlag: GetFeatureFlag
  featureFlags: FeatureFlagsT
}

const useFeatureFlags = (): UseFeatureFlagsReturn => {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagsT>({})
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | null>(null)

  const featureFlagsQuery = useAuthenticatedQuery<FeatureFlagsResponse>(
    'feature-flags',
    // TODO: Add client param
    `${CONTROL_CENTRE_V2_API_DOMAIN}/user_feature_flags`,
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  React.useEffect(() => {
    if (featureFlagsQuery?.status === 'success' && featureFlagsQuery?.data) {
      setFeatureFlags(featureFlagsQuery?.data?.data || {})
      setLoading(false)
      setError(null)
    } else if (featureFlagsQuery?.status === 'error') {
      setError(featureFlagsQuery.error)
      setLoading(false)
    }
  }, [featureFlagsQuery])

  const getFeatureFlag = React.useCallback<GetFeatureFlag>(
    (featureFlagKey: string) => {
      if (!featureFlags) return false
      return featureFlags[featureFlagKey] || false
    },
    [featureFlags],
  )

  return {
    loading,
    error,
    getFeatureFlag,
    featureFlags,
  }
}

export default useFeatureFlags
