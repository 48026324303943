import React from 'react'
import { HiOutlineLogout, HiOutlineRefresh } from 'react-icons/hi'
import { useMsal } from '@azure/msal-react'
import { BrowserUtils } from '@azure/msal-browser'

import AuthPageLayout from '@/components/AuthPageLayout'
import PageLoading from '@/components/PageLoading'
import RequestContextInformationDialogButton from '@/components/ClientPages/RequestContextInformationDialogButton'

import useFetchAccessToken from '@/hooks/useFetchAccessToken'
import { queryClient } from '@/index'
import { cn } from '@/utils/cn'

const buttonClassName =
  'inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium border rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'

function NoAuthorizedClients() {
  const { instance: msalInstance, inProgress } = useMsal()

  const fetchAccessToken = useFetchAccessToken()
  const [refreshTokenLoading, setRefreshTokenLoading] =
    React.useState<boolean>(false)

  const handleLogout = () => {
    msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    })
  }

  if (inProgress === 'acquireToken' || inProgress === 'handleRedirect') {
    return <PageLoading />
  }

  return (
    <AuthPageLayout showAppLogo>
      <h2 className='mt-6 text-3xl font-extrabold text-center text-text-primary'>
        No authorized contexts available
      </h2>

      <p className='mt-4 text-sm text-center text-text-tertiary'>
        {`You don't have permission to view any contexts in this dashboard. If you believe this is an error, try re-authenticating.`}
      </p>

      <div className='pt-6 space-y-2'>
        <div>
          <RequestContextInformationDialogButton />
        </div>

        <div>
          <button
            className={cn(
              buttonClassName,
              'text-elements-primary-main border-elements-primary-main ring-elements-primary-main hover:bg-elements-primary-main/5',
            )}
            onClick={async () => {
              try {
                setRefreshTokenLoading(true)
                await fetchAccessToken(true)
                queryClient.refetchQueries()
              } finally {
                setRefreshTokenLoading(false)
              }
            }}
          >
            <HiOutlineRefresh
              className={cn('w-5 h-5 mr-2', {
                'animate-spin': refreshTokenLoading,
              })}
            />
            Refresh Azure Roles
          </button>
        </div>

        <div>
          <button
            className={cn(
              buttonClassName,
              'bg-transparent border-transparent text-red-500 shadow-none hover:underline hover:bg-transparent focus-visible:ring-red-500',
            )}
            onClick={handleLogout}
          >
            <HiOutlineLogout className='w-5 h-5 mr-2' />
            Log out
          </button>
        </div>
      </div>
    </AuthPageLayout>
  )
}

export default NoAuthorizedClients
