import create from 'zustand'

export interface StoreEventI extends Conviva2EventI {
  id?: string
  streamType?: StreamTypes
  config?: ConfigI
}
export interface EventStoreState {
  open: boolean
  event: StoreEventI
  hoveredEventFlag: string | null
  isOcellus: boolean
  setIsOcellus: (value: boolean) => void
  onOpenEvent: (event: StoreEventI) => void
  onHoverEventFlag: (id: string | null) => void
  onClose: () => void
}

const emptyEvent: StoreEventI = {} as StoreEventI

export const useEventStore = create<EventStoreState>((set) => ({
  open: false,
  event: emptyEvent,
  hoveredEventFlag: null,
  isOcellus: false,
  setIsOcellus: (value) => set(() => ({ isOcellus: value })),
  onOpenEvent: (event) => set(() => ({ open: true, event })),
  onHoverEventFlag: (id) => set(() => ({ hoveredEventFlag: id })),
  onClose: () => set(() => ({ open: false })),
}))
