import { addLabelsToData } from '@/config/dataFormatters/utils/conviva'
import percentile from 'stats-percentile'

const getPercentileValues = ({ data, dataKey, percentileValue }) => {
  const dataEntries = data?.data || {}

  const calculatePercentile = (dataPoints, percentileValue) => {
    if (!dataPoints || dataPoints.length === 0) {
      return null
    }

    const extractedValues = dataPoints?.map((dataPoint) => dataPoint[dataKey])

    const calculatedPercentileValue =
      percentile(extractedValues, percentileValue) || 0

    let timestamps = []
    dataPoints?.map((dataPoint) => {
      if (dataPoint[dataKey] === calculatedPercentileValue) {
        timestamps.push(dataPoint.timestamp)
      }
    })

    return { value: calculatedPercentileValue, timestamps }
  }

  return Object.keys(dataEntries)
    .map((splitKey) => {
      const percentileValues = calculatePercentile(
        dataEntries[splitKey],
        percentileValue,
      )
      if (percentileValues) {
        return {
          id: splitKey || 'Unknown',
          ...percentileValues,
          isTotal: splitKey === 'total',
        }
      }
      return null
    })
    .filter((item) => item !== null)
}

export const timeseriesLegendP75 = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const percentileValuesBySplit = getPercentileValues({
    data,
    dataKey,
    percentileValue: 75,
  })

  const dataWithLabels = addLabelsToData({
    data: percentileValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}

export const timeseriesLegendP95 = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const percentileValuesBySplit = getPercentileValues({
    data,
    dataKey,
    percentileValue: 95,
  })

  const dataWithLabels = addLabelsToData({
    data: percentileValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}

export const timeseriesLegendP97 = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const percentileValuesBySplit = getPercentileValues({
    data,
    dataKey,
    percentileValue: 97,
  })

  const dataWithLabels = addLabelsToData({
    data: percentileValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}

export const timeseriesLegendP99 = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const percentileValuesBySplit = getPercentileValues({
    data,
    dataKey,
    percentileValue: 99,
  })

  const dataWithLabels = addLabelsToData({
    data: percentileValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}

export const timeseriesLegendMedian = ({
  settings = {},
  config = {},
  data,
}) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const percentileValuesBySplit = getPercentileValues({
    data,
    dataKey,
    percentileValue: 50,
  })

  const dataWithLabels = addLabelsToData({
    data: percentileValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}
