import moment, { Moment } from 'moment-timezone'

// To avoid cyclic dependencies, the default timezone is declared here. It needs to match with the option declared in CLIENT_DEFAULT_TIMEZONES const
const DEFAULT_TIMEZONE = 'America/New_York'

const getNowRounded = () => {
  return moment()
    .clone()
    .tz(DEFAULT_TIMEZONE)
    .subtract(1, 'minute')
    .startOf('minute')
}

const relativeDates: RelativeDateI[] = [
  {
    group: 'minute',
    id: 'last-15-minutes',
    name: 'Last 15 Minutes',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(15, 'minutes')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'minute',
    id: 'last-30-minutes',
    name: 'Last 30 Minutes',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(30, 'minutes')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-hour',
    name: 'Last Hour',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-3-hours',
    name: 'Last 3 Hours',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(3, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-6-hours',
    name: 'Last 6 Hours',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(6, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-12-hours',
    name: 'Last 12 Hours',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(12, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-24-hours',
    name: 'Last 24 Hours',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(24, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'hour',
    id: 'last-48-hours',
    name: 'Last 48 Hours',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(48, 'hours')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    isDefault: true,
    group: 'day',
    id: 'today',
    name: 'Today',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('day')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'yesterday',
    name: 'Yesterday',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'day').startOf('day')
      const end = now.clone().subtract(1, 'day').endOf('day')
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'last-weekend',
    name: 'Last Weekend',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('week').subtract(2, 'days')
      const end = now.clone().startOf('week')
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'last-7-days',
    name: 'Last 7 Days',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(7, 'days')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'last-14-days',
    name: 'Last 14 Days',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(14, 'days')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'last-31-days',
    name: 'Last 31 Days',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(31, 'days')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'week',
    id: 'this-week',
    name: 'This Week',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('week')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'week',
    id: 'last-week',
    name: 'Last Week',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'week').startOf('week')
      const end = now.clone().subtract(1, 'week').endOf('week')
      return { start, end }
    },
  },
  {
    group: 'week',
    id: 'last-2-weeks',
    name: 'Last 2 Weeks',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(2, 'weeks')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'week',
    id: 'last-4-weeks',
    name: 'Last 4 Weeks',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(4, 'weeks')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'week',
    id: 'last-52-weeks',
    name: 'Last 52 Weeks',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(52, 'weeks')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'month',
    id: 'this-month',
    name: 'This Month',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('month')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'month',
    id: 'last-month',
    name: 'Last Month',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'month').startOf('month')
      const end = now.clone().subtract(1, 'month').endOf('month')
      return { start, end }
    },
  },
  {
    group: 'month',
    id: 'last-3-months',
    name: 'Last 3 Months',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(3, 'months')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'month',
    id: 'last-6-months',
    name: 'Last 6 Months',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(6, 'months')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'month',
    id: 'last-12-months',
    name: 'Last 12 Months',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(12, 'months')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'quarter',
    id: 'this-quarter',
    name: 'This Quarter',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('quarter')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'quarter',
    id: 'last-quarter',
    name: 'Last Quarter',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'quarter').startOf('quarter')
      const end = now.clone().subtract(1, 'quarter').endOf('quarter')
      return { start, end }
    },
  },
  {
    group: 'quarter',
    id: 'last-2-quarters',
    name: 'Last 2 Quarters',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(2, 'quarters')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'year',
    id: 'this-year',
    name: 'This Year',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().startOf('year')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'year',
    id: 'last-year',
    name: 'Last Year',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'year').startOf('year')
      const end = now.clone().subtract(1, 'year').endOf('year')
      return { start, end }
    },
  },
  {
    group: 'other',
    id: 'since-peacock-launch',
    name: 'Since Peacock Launch',
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = getNowRounded()
      const start = moment(new Date(2020, 3, 14, 5)).clone()
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'other',
    id: 'custom',
    name: 'Custom Date Range',
    getDateRange: (): null => {
      return null
    },
  },
]

export const futureRelativeDates: RelativeDateI[] = [
  {
    group: 'other',
    id: 'all-time',
    name: 'All time',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().subtract(2, 'years')
      const end = now.clone().add(2, 'years')
      return { start, end } // a 4 year interval should be enough time to fetch all events
    },
  },
  {
    group: 'future',
    id: 'from-today',
    name: 'From today',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('day')
      const end = now.clone().add(2, 'years')
      return { start, end }
    },
  },
  {
    group: 'future',
    id: 'this-week',
    name: 'This week',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('week')
      const end = now.clone().endOf('week')
      return { start, end }
    },
  },
  {
    group: 'future',
    id: 'this-weekend',
    name: 'This weekend',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('week')
      const end = now.clone().endOf('week').subtract(2, 'days').startOf('day')
      return { start, end }
    },
  },
  {
    group: 'future',
    id: 'next-2-weeks',
    name: 'Next 2 weeks',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('day')
      const end = now.clone().add(2, 'weeks').endOf('day')
      return { start, end }
    },
  },
  {
    group: 'future',
    id: 'this-month',
    name: 'This month',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('month')
      const end = now.clone().endOf('month')
      return { start, end }
    },
  },

  {
    group: 'future',
    id: 'next-2-months',
    name: 'Next 2 months',
    getDateRange: (isUTC): { start: Moment; end: Moment } => {
      const now = isUTC ? getNowRounded().tz('UTC') : getNowRounded()
      const start = now.clone().startOf('day')
      const end = now.clone().add(2, 'months').endOf('day')
      return { start, end }
    },
  },
]

export const conviva2RelativeDates: RelativeDateI[] = [
  {
    group: 'minute',
    id: 'now',
    name: 'Now',
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': 'now' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'hour')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'day',
    id: 'today',
    name: 'Today',
    isDefault: true,
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': 'today' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().startOf('day')
      const end = now.clone()
      return { start, end }
    },
  },

  {
    group: 'day',
    id: '7d',
    name: 'Last 7 Days',
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': '7d' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().subtract(7, 'day')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'day',
    id: '30d',
    name: 'Last 30 Days',
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': '30d' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().subtract(30, 'day')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'year',
    id: '1y',
    name: 'Last Year',
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': '1y' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().subtract(1, 'year')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'all-time',
    id: 'all-time',
    name: 'All time',
    getDateRange: (): { 'time-period': string } => {
      return { 'time-period': 'all-time' }
    },
    getStartEndParams: () => {
      const now = getNowRounded()
      const start = now.clone().subtract(5, 'year')
      const end = now.clone()
      return { start, end }
    },
  },
  {
    group: 'other',
    id: 'custom',
    name: 'Custom Date Range',
    getDateRange: (): null => {
      return null
    },
  },
]

export default relativeDates
