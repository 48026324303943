import { Configuration, LogLevel } from '@azure/msal-browser'

import { env } from './env'
import { AZURE_AD_CLIENT_ID } from './config/constants'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig: Configuration = {
  auth: {
    clientId: AZURE_AD_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${env.REACT_APP_AZURE_AD_TENANT_ID}`,
    postLogoutRedirectUri: window.location.origin, // '/'
    redirectUri: window.location.origin, // '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // Commented out unecessary logs in the browser. Uncomment for debugging if needed.
            // console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: ['openid', 'User.Read'],
}
