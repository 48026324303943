import create from 'zustand'

interface ModalStoreStateI {
  open: boolean
  modal: any
  openModal: (modal: any) => void
  closeModal: () => void
}

const emptyModal = {
  title: null,
  description: null,
  icon: null,
  appearance: null,
  action: null,
  onAction: () => false,
}

export const useModalStore = create<ModalStoreStateI>((set) => ({
  open: false,
  modal: emptyModal,
  openModal: (modal) => set(() => ({ open: true, modal })),
  closeModal: () => set(() => ({ open: false })),
}))
