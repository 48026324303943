import { HiOutlineTicket, HiTicket } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonBigvivaSdpConfig from './common/bigvivaSdp'

const cancellations: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const bigvivaSdpConfig = commonBigvivaSdpConfig({ variants })

  return {
    ...bigvivaSdpConfig,
    value: 'cancellations',
    label: 'Pass Cancellations',
    dataKey: 'cancellations',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: false,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    icon: HiOutlineTicket,
    solidIcon: HiTicket,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    invertedChangeColor: true,
    info: 'Pass Cancellations shows the total number of cancelled Now or Sky X passes during a given interval.',
    aggregationType: 'total',
    defaultConfig: {
      ...bigvivaSdpConfig.defaultConfig,
      metric: 'cancellations',
    },
  }
}

export default cancellations
