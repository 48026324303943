import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/utils/cn'
import { Slot } from '@radix-ui/react-slot'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-elements-primary-main focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-neutral-dimmed-heavy',
  {
    variants: {
      variant: {
        default:
          'bg-elements-primary-main text-elements-primary-contrastText hover:bg-elements-primary-main/90',
        destructive:
          'bg-helpers-error-main text-helpers-error-contrastText hover:bg-helpers-error-main/90',
        outline:
          'border border-border-main hover:bg-neutral text-text-secondary hover:text-text-primary',
        secondary:
          'bg-neutral text-text-secondary hover:text-text-primary hover:bg-neutral/80',
        ghost: 'hover:bg-neutral text-text-secondary hover:text-text-primary',
        link: 'underline-offset-4 hover:underline text-elements-primary-main',
      },
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-3 rounded-md',
        lg: 'h-11 px-8 rounded-md',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)

export { Button, buttonVariants }
