import moment from 'moment-timezone'
import { CgSpinner } from 'react-icons/cg'

import { useTimeStore } from '@/stores/time.store'
import { cn } from '@/utils/cn'
import getTimezoneLabel from '@/utils/getTimezoneLabel'

const LastUpdatedTime = ({
  isRefreshing,
  timestamp,
  containerClasses,
}: {
  isRefreshing?: boolean
  timestamp?: number
  containerClasses?: string
}) => {
  const timezone = useTimeStore((state) => state.timezone)
  if (!timestamp) return null

  return (
    <div
      className={cn(
        'text-[10px] text-text-tertiary flex items-center space-x-1',
        containerClasses,
      )}
    >
      {isRefreshing && <CgSpinner className={cn('w-3 h-3 animate-spin')} />}
      <p className='text-[10px]'>Last updated</p>
      <span>{moment(timestamp).tz(timezone).format('HH:mm')}</span>
      <span>{getTimezoneLabel(timestamp, timezone)}</span>
    </div>
  )
}

export default LastUpdatedTime
