/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const colors = require('tailwindcss/colors')
const getFullPalette = require('../utils/getFullPalette')

// Palette
// Disable linting for 'primary' as it might be used in the future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const secondary = 'amber'
const success = 'emerald'
const error = 'red'
const info = 'blue'
const warning = 'amber'

const neutral = 'zinc'
const standard = 'white'

const primaryColors = {
  // https://www.tailwindshades.com/#color=265.94594594594594%2C100%2C29.01960784313726&step-up=14&step-down=4&hue-shift=0&name=pigment-indigo&base-stop=5&v=1&overrides=e30%3D
  50: '#8BD6FF',
  100: '#76CFFF',
  200: '#4DC0FF',
  300: '#25B2FF',
  400: '#00A2FB',
  500: '#0088D2',
  600: '#00649A',
  700: '#003F62',
  800: '#001B2A',
  900: '#000000',
  950: '#000000',
}

const light = {
  type: 'light',
  value: 'comcast-light',
  label: 'Comcast Light',
  selectors: ['.comcast-light'],
  theme: {
    chartColors: [
      // Initial set of chart colors, always use tailwind colors to avoid duplicates
      colors.gray['500'],
      colors.orange['500'],
      colors.red['500'],
      colors.purple['500'],
      colors.sky['500'],
      colors.green['500'],
      colors.yellow['500'],
    ],
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      DEFAULT: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px',
    },
    colors: {
      // Primary and Secondary colours
      'elements-primary-50': primaryColors['50'],
      'elements-primary-100': primaryColors['100'],
      'elements-primary-200': primaryColors['200'],
      'elements-primary-300': primaryColors['300'],
      'elements-primary-400': primaryColors['400'],
      'elements-primary-500': primaryColors['500'],
      'elements-primary-600': primaryColors['600'],
      'elements-primary-700': primaryColors['700'],
      'elements-primary-800': primaryColors['800'],
      'elements-primary-900': primaryColors['900'],
      'elements-primary-950': primaryColors['950'],
      'elements-primary-dimmed': primaryColors['400'],
      'elements-primary-main': primaryColors['500'],
      'elements-primary-shadow': primaryColors['600'],
      'elements-primary-highlight': primaryColors['300'],
      'elements-primary-contrastText': '#ffffff',

      // Secondary
      'elements-secondary-dimmed': colors[secondary]['400'],
      'elements-secondary-main': colors[secondary]['500'],
      'elements-secondary-shadow': colors[secondary]['600'],
      'elements-secondary-highlight': colors[secondary]['300'],
      'elements-secondary-contrastText': '#ffffff',
      ...getFullPalette('elements-secondary', secondary),

      // Homepage realtime gradient
      'headline-gradient-from': 'rgb(0, 163, 251)', // Comcast Blue lightened 20%
      'headline-gradient-via': 'rgb(0, 136, 210)', // Comcast Blue
      'headline-gradient-to': 'rgb(0, 122, 189)', // Comcast Blue darkened 10%

      'event-backdrop': 'rgb(0, 0, 0)',

      // Alert
      'helpers-error-dimmed': colors[error]['100'],
      'helpers-error-main': colors[error]['500'],
      'helpers-error-button': colors[error]['600'],
      'helpers-error-button-hover': colors[error]['700'],

      'helpers-warning-dimmed': colors[warning]['100'],
      'helpers-warning-main': colors[warning]['500'],
      'helpers-warning-button': colors[warning]['600'],
      'helpers-warning-button-hover': colors[warning]['700'],

      'helpers-success-dimmed': colors[success]['100'],
      'helpers-success-main': colors[success]['500'],
      'helpers-success-button': colors[success]['600'],
      'helpers-success-button-hover': colors[success]['700'],

      'helpers-info-dimmed': colors[info]['100'],
      'helpers-info-main': colors[info]['500'],
      'helpers-info-button': colors[info]['600'],
      'helpers-info-button-hover': colors[info]['700'],

      // Custom brand colours (e.g. Sky palette, Peacock palette)
      'brand-1': '#ffffff',

      // Text
      'text-primary': colors[neutral]['900'],
      'text-secondary': colors[neutral]['700'],
      'text-tertiary': colors[neutral]['500'],
      'text-dimmed': colors[neutral]['400'],
      'text-disabled': colors[neutral]['300'],

      // Accent / Background
      'divider-dimmed': colors[neutral]['100'],
      'divider-main': colors[neutral]['200'],
      'divider-shadow': colors[neutral]['300'],

      'border-dimmed': colors[neutral]['100'],
      'border-main': colors[neutral]['200'],
      'border-shadow': colors[neutral]['300'],

      'neutral-dimmed-heavy': colors[standard],
      'neutral-dimmed': colors[neutral]['50'],
      neutral: colors[neutral]['100'],
      'neutral-shadow': colors[neutral]['200'],
      'neutral-shadow-heavy': colors[neutral]['300'],
    },
  },
  muiThemeBase: {
    palette: {
      type: 'light',
      primary: {
        main: primaryColors['500'],
        light: primaryColors['400'],
        dark: primaryColors['600'],
      },
      secondary: {
        main: colors[secondary]['500'],
        light: colors[secondary]['400'],
        dark: colors[secondary]['600'],
      },
      error: {
        main: colors[error]['500'],
        light: colors[error]['400'],
        dark: colors[error]['600'],
      },
      text: {
        disabled: colors[neutral]['400'],
        icon: 'rgba(0, 0, 0, 0.5)',
        primary: colors[neutral]['900'],
        secondary: colors[neutral]['700'],
      },
      divider: colors[neutral]['200'],
      gray: colors[neutral],
      background: {
        default: colors[neutral]['50'],
        paper: colors[standard],
      },
    },
  },
}

module.exports = light
