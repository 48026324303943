import { cn } from '@/utils/cn'

const Chip = ({
  label,
  image,
  collapseLabel = false,
  className,
  labelClassName,
  theme,
}: any) => {
  return (
    <span
      className={cn(
        `inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium mr-1 flex-shrink-0 overflow-hidden`,
        {
          'bg-neutral-shadow text-text-secondary': !theme || theme === 'light',
          'bg-elements-primary-400 text-elements-primary-contrastText':
            theme === 'dark',
        },
        className,
        {},
      )}
    >
      {image && <img className='w-4 h-4 rounded-sm' src={image} alt={label} />}
      <span
        className={cn(
          {
            'hidden md:inline-block ml-2': collapseLabel,
            'ml-2': !collapseLabel && image,
            labelClassName,
          },
          ' whitespace-nowrap',
        )}
      >
        {label}
      </span>
    </span>
  )
}

export default Chip
