import clsx from 'clsx'

import { Switch } from '@headlessui/react'
import { GROUPS } from '@/config/metricsSettings/groups'

import { FEATURE_FLAGS } from '@/config/constants'
import useFeatureFlags from '@/hooks/useFeatureFlags'

export default function OcellusEventSwitch({
  checked = false,
  onChange,
}: {
  checked: boolean
  onChange: () => void
}): JSX.Element | null {
  const { getFeatureFlag } = useFeatureFlags()

  const ocellusEventsEnabled = getFeatureFlag(
    FEATURE_FLAGS.OCELLUS_EVENTS_ENABLED,
  )

  if (!ocellusEventsEnabled) return null

  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch.Label
        as='button'
        className={clsx(
          checked
            ? 'text-text-tertiary hover:text-text-secondary'
            : 'text-text-primary font-medium',
          'text-sm mr-3',
        )}
      >
        <div className={clsx(checked ? 'grayscale hover:grayscale-0' : '')}>
          <img src={GROUPS.CONVIVA.icon.imageUrl} className='w-5 h-5 inline' />
          <span className='pl-1.5'>Conviva</span>
        </div>
      </Switch.Label>
      <Switch
        checked={checked}
        onChange={onChange}
        className='bg-elements-primary-main relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main focus-visible:ring-offset-neutral'
      >
        <span
          aria-hidden='true'
          className={clsx(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>

      <Switch.Label
        as='button'
        className={clsx(
          checked
            ? 'text-text-primary font-medium'
            : 'text-text-tertiary hover:text-text-secondary',
          'text-sm ml-3',
        )}
      >
        <div className={clsx(checked ? '' : 'grayscale hover:grayscale-0')}>
          <img src={GROUPS.OCELLUS.icon.imageUrl} className='w-5 h-5 inline' />
          <span className='pl-1.5'>Ocellus</span>
        </div>
      </Switch.Label>
    </Switch.Group>
  )
}
