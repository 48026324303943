import AuthPageLayout from '@/components/AuthPageLayout'
import ClientSelect from '@/components/Nav/components/ClientSelect'
import RequestContextInformationDialogButton from '@/components/ClientPages/RequestContextInformationDialogButton'

import { useSelectedTheme } from '@/hooks/useTheme'
import useSettings from '@/hooks/useSettings'

export default function ClientNotAuthorized() {
  const { clientSettings } = useSettings()
  const selectedTheme = useSelectedTheme()

  return (
    <AuthPageLayout>
      <div className='flex items-center justify-center'>
        <div className='relative'>
          <img
            className='w-auto rounded bg-neutral-dimmed-heavy h-14'
            src={clientSettings?.logo?.[selectedTheme]?.app}
            alt={`${clientSettings?.name} logo`}
          />
        </div>
      </div>
      <h2 className='mt-6 text-3xl font-extrabold text-center text-text-primary'>
        Context not authorized
      </h2>
      <p className='mt-4 text-sm text-center text-text-tertiary max-w'>
        You have not have access to the {clientSettings.name} client. Feel free
        to try another of your authorized contexts below.
      </p>

      <ClientSelect className='mt-6' variant='button-list' />

      <RequestContextInformationDialogButton className='mt-6' />
    </AuthPageLayout>
  )
}
