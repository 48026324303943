import { HiOutlineXCircle, HiXCircle } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import commonConvivaConfig from './common/conviva'

const exitsBeforeVideoStart: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'exits-before-video-start',
    label: 'Exits Before Video Start',
    shortLabel: 'EBVS',
    dataKey: 'exitsBeforeVideoStart',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: false,
        },
      },
    },
    icon: HiOutlineXCircle,
    solidIcon: HiXCircle,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Exits Before Video Start measures the number of viewing attempts that were terminated before the video started.',
    aggregationType: 'total',
    invertedChangeColor: true,
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'exits-before-video-start',
    },
  }
}

export default exitsBeforeVideoStart
