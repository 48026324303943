import React from 'react'

import minutesUniquesSettings from '@/config/minutesUniquesRealtimeSettings'
import { MetricSettingsI, VariantsT } from '@/config/types'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import { useUserStore } from '@/stores/user.store'

import metrics from '../config/metricsSettings'
import { useClientStore } from '../stores/client.store'

interface PropsI {
  metric: string
  extendSettings?: (settings: MetricSettingsI) => MetricSettingsI
  variants?: VariantsT
}

const useMetricSettings = ({
  metric,
  extendSettings,
  variants,
}: PropsI): MetricSettingsI => {
  const client = useClientStore((state) => state.client)
  const { featureFlags } = useFeatureFlags()
  const roles = useUserStore((state) => state.roles)

  const isOcellus = (variants?.isOcellus && client === 'peacock') || false

  if (!metric) throw new Error('Metric not provided to useMetricSettings')

  // Filter compatibility check uses useMetricSettings as a source for all metric settings.
  // The below is a workaround for this specific metric that is not included in the metrics lists
  if (metric === 'total-minutes-unique-customers') {
    return minutesUniquesSettings[client] as unknown as MetricSettingsI
  }

  const settings = isOcellus
    ? metrics[client][`${metric}-ocellus`]
    : metrics[client][metric]

  if (!settings)
    throw new Error(
      `Settings not found in useMetricSettings. Metric: ${metric}, Client: ${client}`,
    )

  if (extendSettings) {
    return React.useMemo(
      () => extendSettings(settings({ featureFlags, variants, roles })),
      [settings, extendSettings, featureFlags, variants, roles],
    )
  } else {
    return React.useMemo(
      () => settings({ featureFlags, variants, roles }),
      [settings, featureFlags, variants, roles],
    )
  }
}

export default useMetricSettings
