import { HiDatabase, HiOutlineDatabase } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreConfig from './common/prometheusCore'

const utilisedPods: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    value: 'utilised-pods',
    label: 'Utilised Pods',
    shortLabel: 'Utilised Pods',
    dataKey: 'utilisedPods',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiDatabase,
    solidIcon: HiOutlineDatabase,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Utilised Pods refers to the number of running or active pods that are currently serving a specific workload within a cluster. Monitoring and understanding the utilisation of pods are essential for managing the efficiency, performance, and resource utilisation of containerised applications within a cluster.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'utilised-pods',
    },
  }
}

export default utilisedPods
