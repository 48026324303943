import PageLoading from '@/components/PageLoading'
import {
  CONVIVA_2_V4_API_DOMAIN,
  clientsWithDynamicConfig,
} from '@/config/constants'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import useMetricSettingsList from '@/hooks/useMetricSettingsList'

// Load dynamic comnfig once on initial load so that subsequent attempts to get it go to cache
const GetDynamicConfig = ({ children }: { children: any }) => {
  const metrics = useMetricSettingsList({ variants: { withClient: true } })
  const client = metrics[0].defaultConfig?.client

  const dynamicConfigEnabled =
    !!client && clientsWithDynamicConfig.includes(client)

  // Dynamic config currently available for SST territories only
  const availableTerritories = useAuthenticatedQuery<{ tags: string[] }>(
    'sst-territories',
    `${CONVIVA_2_V4_API_DOMAIN}/tags/territory?client=${client}`,
    { enabled: dynamicConfigEnabled },
  )

  if (
    !dynamicConfigEnabled ||
    (dynamicConfigEnabled && availableTerritories.status !== 'loading')
  ) {
    return children
  } else {
    return <PageLoading />
  }
}

export default GetDynamicConfig
