import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusPersistenceConfig from './common/prometheusPersistence'

const throughputPersistence: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusPersistenceConfig = commonPrometheusPersistenceConfig()

  return {
    ...prometheusPersistenceConfig,
    value: 'throughput-persistence',
    apiParam: 'throughput',
    label: 'Throughput (Persistence)',
    shortLabel: 'Throughput',
    dataKey: 'throughput',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Throughput is a key metric in assessing the performance and efficiency of a network, it is expressed in terms of how many requests are made. Throughput measures the amount of data that can be transmitted successfully over a network in a given period of time.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusPersistenceConfig.defaultConfig,
      metric: 'throughput-persistence',
    },
  }
}

export default throughputPersistence
