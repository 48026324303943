import { EventsSettingsI, SettingsI, VariantsT } from '@/config/types'

import React from 'react'
import eventsSettings from '../config/pagesSettings/eventsPageSettings'
import { useClientStore } from '../stores/client.store'
import useFeatureFlags from './useFeatureFlags'
import { useUserStore } from '@/stores/user.store'

interface PropsI {
  extendSettings?: (settings: SettingsI) => SettingsI
  variants?: VariantsT
}
const useEventsSettings = ({
  extendSettings,
  variants,
}: PropsI = {}): EventsSettingsI => {
  const client = useClientStore((state) => state.client)
  const roles = useUserStore((state) => state.roles)
  const { featureFlags } = useFeatureFlags()

  const settings = eventsSettings[client]

  if (!settings)
    throw new Error(
      `Settings not found in useEventsSettings. Client: ${client}`,
    )

  if (extendSettings) {
    return React.useMemo(
      () => extendSettings(settings({ featureFlags, variants, roles })),
      [settings, featureFlags, variants, roles],
    )
  } else {
    return React.useMemo(
      () => settings({ featureFlags, variants, roles }),
      [settings, featureFlags, variants, roles],
    )
  }
}

export default useEventsSettings
