import {
  CLIENT_DEFAULT_TIMEZONES,
  CONVIVA_2_V4_API_DOMAIN,
  CONVIVA_API_DOMAIN,
  DEFAULT_DATE,
} from '@/config/constants'
import {
  DEVICE_TYPE,
  OPERATING_SYSTEM,
  SPLIT_BY,
  STREAM_TYPE,
  TERRITORY,
  showmaxTerritoryOptions,
} from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI, VariantsT } from '@/config/types'

const commonConvivaConfig = ({ variants }: { variants?: VariantsT }) => {
  const withClient = variants?.withClient || false

  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      options: [
        SPLIT_BY['territory'],
        SPLIT_BY['stream-type'],
        SPLIT_BY['device-type'],
        SPLIT_BY['device'],
        SPLIT_BY['operating-system'],
      ],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...showmaxTerritoryOptions],
    },
    {
      value: 'stream-type',
      type: 'button-group',
      multi: true,
      options: [STREAM_TYPE['all'], STREAM_TYPE['vod'], STREAM_TYPE['sle']],
    },
    {
      value: 'device-type',
      type: 'button-group',
      multi: true,
      options: [
        DEVICE_TYPE['all'],
        DEVICE_TYPE['stb'],
        DEVICE_TYPE['mobile'],
        DEVICE_TYPE['smart-tv'],
        DEVICE_TYPE['console'],
        DEVICE_TYPE['web'],
        DEVICE_TYPE['streaming-stick'],
      ],
    },
    {
      value: 'operating-system',
      type: 'button-group',
      multi: true,
      options: [
        OPERATING_SYSTEM['all'],
        OPERATING_SYSTEM['amazon-fire'],
        OPERATING_SYSTEM['android'],
        OPERATING_SYSTEM['android-tv'],
        OPERATING_SYSTEM['apple-tv'],
        OPERATING_SYSTEM['chromecast'],
        OPERATING_SYSTEM['cox'],
        OPERATING_SYSTEM['desktop'],
        OPERATING_SYSTEM['explora'],
        OPERATING_SYSTEM['fire-os'],
        OPERATING_SYSTEM['hisense-tv'],
        OPERATING_SYSTEM['huawei'],
        OPERATING_SYSTEM['ios'],
        OPERATING_SYSTEM['lg-tv'],
        OPERATING_SYSTEM['linux'],
        OPERATING_SYSTEM['playstation'],
        OPERATING_SYSTEM['rdk'],
        OPERATING_SYSTEM['roku'],
        OPERATING_SYSTEM['samsung-tv'],
        OPERATING_SYSTEM['streama'],
        OPERATING_SYSTEM['tizen'],
        OPERATING_SYSTEM['tv-os'],
        OPERATING_SYSTEM['vizio'],
        OPERATING_SYSTEM['web'],
        OPERATING_SYSTEM['web-os'],
        OPERATING_SYSTEM['xbox'],
        OPERATING_SYSTEM['xclass-tv'],
        OPERATING_SYSTEM['xfinity'],
        OPERATING_SYSTEM['youview'],
      ],
    },
  ]

  return {
    dataSource: 'conviva',
    legacyGroup: 'conviva',
    group: GROUPS.CONVIVA,
    apiDomain: withClient ? CONVIVA_2_V4_API_DOMAIN : CONVIVA_API_DOMAIN,
    supportsClient: true,
    timezone: CLIENT_DEFAULT_TIMEZONES['showmax'],
    useTimezone: !!withClient,
    tags: {
      enabled: !!withClient,
      endpoint: '/tags',
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonMinuteGranularity,
    defaultConfig: {
      'split-by': 'territory',
      territory: ['all'],
      'stream-type': ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      proposition: ['showmax'],
      ...DEFAULT_DATE('showmax'),
      ...(withClient ? { client: 'showmax' } : {}),
    },
  }
}
export default commonConvivaConfig
