import clsx from 'clsx'
import React from 'react'

import relativeDates, { futureRelativeDates } from '@/config/relativeDates'
import { MetricSettingsI, ReportSettingsI, SettingsI } from '@/config/types'
import useMetricSettingsList from '@/hooks/useMetricSettingsList'
import useSettings from '@/hooks/useSettings'
import { useClientStore } from '@/stores/client.store'
import { logEvent } from '@/utils/firebaseAnalytics'

import DatePickerSwitch from './components/DatePickerSwitch'
import FormGroup from './components/FormGroup'

let timeout: any

export interface FilterPanelPropsI {
  config: ConfigI
  settings: SettingsI | ReportSettingsI | MetricSettingsI
  onUpdateConfig: (updatedConfig: ConfigI, isSilent?: boolean) => void | any
  excludeDatepicker?: boolean
  includeMetricSelection?: boolean
  relative?: boolean
  datePickerProps?: { [key: string]: any }
  forceSlideover?: boolean
  includeFutureGroup?: boolean
  classNames?: { [key: string]: any }
  showCustomDateRangeDisclaimer?: boolean
}

const handleAnalytics = (updatedConfig: ConfigI) => {
  if (updatedConfig.dateType) {
    const { relativeDate, ...restOfConfig } = updatedConfig
    logEvent('select_date', {
      dateType: updatedConfig.dateType,
      ...relativeDate,
      ...restOfConfig,
    })
  } else {
    logEvent('select_filter', {
      filterName: Object.keys(updatedConfig)[0],
      ...updatedConfig,
    })
  }
}

const FilterPanel = ({
  config: _config = {},
  settings,
  onUpdateConfig = () => null,
  excludeDatepicker = false,
  includeMetricSelection = false,
  relative = true,
  datePickerProps,
  forceSlideover,
  includeFutureGroup = false,
  classNames,
  showCustomDateRangeDisclaimer = false,
}: FilterPanelPropsI) => {
  const client = useClientStore((state) => state.client)

  const dates = React.useMemo(() => {
    if (includeFutureGroup) {
      return [
        ...futureRelativeDates[client],
        ...(settings?.dates || relativeDates[client]),
      ]
    }
    return settings?.dates || relativeDates[client]
  }, [includeFutureGroup, settings?.dates])

  const [config, setConfig] = React.useState(_config)
  const { clientSettings } = useSettings()
  const metrics = useMetricSettingsList({ variants: { withClient: true } })

  const onUpdateLocalConfig = (updatedConfig: ConfigI, delay = 0) => {
    handleAnalytics(updatedConfig)

    const nextConfig = { ...config, ...updatedConfig }
    setConfig(nextConfig)

    clearTimeout(timeout)
    timeout = null
    timeout = setTimeout(() => {
      onUpdateConfig(nextConfig)
    }, delay)
  }

  React.useEffect(() => {
    setConfig(_config)
  }, [_config])

  const form = settings.form

  return (
    <aside
      className={clsx(
        classNames?.container ??
          'px-3 py-8 overflow-y-auto bg-neutral-dimmed-heavy 2xl:bg-neutral',
        forceSlideover
          ? 'border-l border-border-main '
          : '2xl:border-l 2xl:border-border-main 2xl:w-80 ',
      )}
    >
      <div>
        <ul className='space-y-6'>
          {!excludeDatepicker && (
            <div className='pb-8 border-b border-border-main'>
              <div className='pl-2 mb-3 text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                Date Picker
              </div>
              <DatePickerSwitch
                relative={relative}
                config={config}
                dates={dates}
                onUpdateLocalConfig={onUpdateLocalConfig}
                datePickerProps={datePickerProps}
                showCustomDateRangeDisclaimer={showCustomDateRangeDisclaimer}
              />
            </div>
          )}
          {includeMetricSelection && (
            <div className='pb-8 border-b border-border-main'>
              <FormGroup
                metricComparison={clientSettings.metricComparison.enabled}
                config={config}
                onUpdateConfig={onUpdateLocalConfig}
                type='button-group'
                value='metric'
                options={metrics
                  .filter((metric) => !metric.hideInLegacyMetrics)
                  .map((metric) => {
                    return [metric.value, metric]
                  })}
              />
            </div>
          )}

          {form
            .filter((f) => f.type !== 'hidden')
            .map((props, index) => (
              <div
                key={`${props.value}-${index}`}
                className={clsx({
                  'border-b pb-8 border-border-main': index !== form.length - 1,
                })}
              >
                <FormGroup
                  config={config}
                  onUpdateConfig={onUpdateLocalConfig}
                  {...props}
                />
              </div>
            ))}
        </ul>
      </div>
    </aside>
  )
}

export default FilterPanel
