import axios from 'axios'

import { loginRequest } from '../authConfig'
import { msalInstance } from '../index'

export const getUser = async () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    return null
  }

  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    .catch((error) => {
      console.log('error fetching access token for getUser', error)
    })

  if (response && response.accessToken) {
    return axios
      .get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: 'Bearer ' + response.accessToken,
        },
      })
      .then((response) => {
        if (response && response.status === 200) {
          return response.data
        } else {
          return null
        }
      })
      .catch((err) => {
        throw err
      })
  } else {
    return null
  }
}

export const getAvatar = async (id) => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    )
  }

  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    .catch((error) => {
      console.log('error fetching access token for getAvatar', error)
    })

  if (response && response.accessToken) {
    return axios
      .get(`https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + response.accessToken,
        },
      })
      .then((response) => {
        if (response && response.status === 200) {
          return response.data
        } else {
          return null
        }
      })
      .catch((err) => {
        throw err
      })
  } else {
    return null
  }
}