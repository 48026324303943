import { RoadmapSettingsI } from '../../types'

const roadmap: RoadmapSettingsI = [
  {
    date: 'May 2021',
    title: 'Beta Release',
    description:
      "Beta release of the OTT Insights dashboard, which includes the Metrics, Events and Support pages. The Metrics page will include 8 commonly used Conviva Metrics across 8 propositions (including Peacock) and 6 territories, viewable across different date ranges, all in realtime. Users will also be able to view supplementary data in an MVP of the 'Insights Panel' such as the top linear and VOD events, data aggregation and notable dates. The events page will include an 'infinitely' loading list of the top Linear and VOD events, their respective concurrency/plays, all in a new timeline UI component.",
    complete: true,
  },
  {
    date: 'June 2021',
    title: 'Migrated to AI Engineering OTT Insights API',
    description:
      "Migrated all data ingestion and API's to operate exclusively from our own stack, this change will give us access to 1 minute granularity and additional filters such as Device Type and Operating System.",
    complete: true,
  },

  {
    date: 'July 2021',
    title: 'Reports, Insights Panel and Bug Fixes',
    description:
      'Released the ‘Insights Panel’ within the Metrics page, started work on automated reports such as the Video Quality KIP and Weekly Viewing Breakdown, fixed various bugs and cleaned up the code.',
    complete: true,
  },

  {
    date: 'September 2021',
    title: 'Dashboard and App Launch',
    description:
      'OTT Insights is officially live! We have also got a a fully native mobile app.',
    complete: true,
  },

  {
    date: 'October 2021',
    title: 'Sky Glass Support',
    description: 'Introduced metric ingest and filters for SoIP (Sky Glass).',
    complete: true,
  },

  {
    date: 'January 2022',
    title: 'Event Report MVP',
    description:
      'New section for dedicated live event report viewers. This functionality allows the operational team to set up dedicated pages for events across different channels/propositions/territories, containing video quality metrics, as well as event information such as scores, goalscorers, red cards and commentary from various Sky Sports feeds. This is initially launching for Football, with other sport fixtures coming soon.',
    complete: true,
  },

  {
    date: 'March 2022',
    title: 'Event Report Enhancements',
    description:
      'Building up on the MVP functionality to now support F1 and all special sporting linear events.',
    complete: true,
  },

  {
    date: 'August 2022',
    title: 'Dedicated reporting for House of the Dragon',
    description:
      'New section within OTT Insights dedicated to reporting for linear and VOD showings for House of the Dragon Season 1, this includes the total plays split by proposition and territory, video quality metrics and peak concurrency per episode.',
    complete: true,
  },

  {
    date: 'September 2022',
    title: 'Upcoming Events',
    description:
      'New page within OTT Insights containing upcoming fixtures for the UK for Football, Cricket and Rugby. Additional territories and event types will be added in the future, once data sources are available.',
    complete: true,
  },
  {
    date: 'September 2022',
    title: 'Sky Showtime Insights v1',
    description:
      'Release of the Sky Showtime Insights dashboard, which includes the Metrics, Events and Support pages. The Metrics page contains 8 commonly used Conviva Metrics, Activations and Cancellations across the territories Sky Showtime will be launched in, viewable across different data ranges, device types and operating systems. The Event page includes a loading list of the top VOD events, with the total plays and corresponding time series data. Sky Showtime Insights also has a fully native mobile app, launching at the same time.',
    complete: true,
  },
  {
    date: 'October 2022',
    title: 'SkyShowtime Portugal & Netherlands Launch Support',
    description:
      'This released added Sign In data from Identity’s prometheus instance, which can be split by territory, new territories launched: Netherland and Portugal and various bug fixes.',
    complete: true,
  },
  {
    date: 'November 2022',
    title: 'Select 2 metrics',
    description:
      'Users can now select two metrics within the metrics page, to compare and plot on the same graph.',
    complete: true,
  },
  {
    date: 'December 2022',
    title: 'SkyShowtime Adriatics Launch Support',
    description:
      'This release contained various enhancements, inclduing adding Sign Up data from Identity (providing users with real time data compared to the Activation data from SDP Big Query), device split for Sign In & Sign Up data and adding in the new territories launching this month:  Bosnia & Herzegovina, Bulgaria, Croatia, Montenegro, Serbia and Slovenia.',
    complete: true,
  },
  {
    date: 'January 2023',
    title: 'Support Dynamic form options for SkyShowtime territories',
    description:
      'This release ensured that territories are now config driven rather than hard coded. It allows any future territory launches to not require a full dashboard and app release.',
    complete: true,
  },
  {
    date: 'January 2023',
    title: 'Bug fixes and dedicated The Last of Us reporting',
    description:
      'Deployed a fix to hide the unique customers section until the delayed metrics arrive. Also published a new section within OTT Insights dedicated to reporting for linear and VOD showings for the The Last of Us Season 1. This includes the total plays split by proposition and territory, video quality and peak concurrency per episode.',
    complete: true,
  },
  {
    date: 'February 2023',
    title: 'Support for new SkyShowtime Central Europe territories launch',
    description:
      'This release added support for Central Europe territories: Romania, Slovakia, Poland, Czech Republic and Hungary',
    complete: true,
  },
  {
    date: 'February 2023',
    title: 'Bug Fixes',
    description:
      "Hide 'Pass Activations' when filtering by USA in Event Reports and also added new filters which pull figures specifically for Sky Stream and Sky Glass.",
    complete: true,
  },
  {
    date: 'March 2023',
    title:
      'Restructured Navigation Panel, Enhanced Analytics and Feature Flags',
    description:
      "We have restructured the Navigation Panel to improve UX. This includes grouping event pages into one 'Content' section, renaming 'Event Reports' to 'High Profile Events' and creating a main page for Series reports. We are also able to capture more events through our Firebase Analytics. New features can now be programatically enabled through a users Azure profile.",
    complete: true,
  },
  {
    date: 'April 2023',
    title: 'Bug Fixes',
    description:
      'We have added a fix for missing NOW logo and filtering issues. We have also deployed a fix for issues with Unique Customers for NOW.',
    complete: true,
  },
  {
    date: 'June 2023',
    title:
      'Added RDK to Conviva filter options to support Vodafone Ziggo (SST first MVPD) launch',
    description:
      'This release added support for Vodafone Ziggo and also updated the dashboard external links in navigation menu to globalreliability.sky domains.',
    complete: true,
  },
  {
    date: 'August 2023',
    title: 'Platform Dashboard',
    description:
      'We want to match the customer experience with platform data in a single place with real time data, to gain insights into how customer behaviour and engagement impact the overall customer experience and to be able to understand how customer behaviour and engagement varies across different markets or product offerings. This includes integrating with new platform data sources and revamping the metrics page.',
    complete: false,
  },
]

export default roadmap
