/* eslint-disable @typescript-eslint/no-unused-vars */
// The state values are needed for isListening to work even though they aren't used
import { useEffect, useState } from 'react'
import { HiMicrophone, HiOutlineMicrophone } from 'react-icons/hi'
import {
  VoiceSearchHelper,
  connectSearchBox,
  createVoiceSearchHelper,
} from 'react-instantsearch-dom'

import IconButton from '@mui/material/IconButton'

function VoiceSearch(props: any) {
  const [voiceSearchHelper, setVoiceSearchHelper] =
    useState<null | VoiceSearchHelper>(null)
    
  const [status, setStatus] = useState<string>('')
  const [transcript, setTranscript] = useState<string>('')
  const [isSpeechFinal, setIsSpeechFinal] = useState<boolean>(false)
  const [errorCode, setErrorCode] = useState<string>('')

  useEffect(() => {
    const { refine } = props
    const voiceSearch = createVoiceSearchHelper({
      searchAsYouSpeak: false,
      onQueryChange: (query) => refine(query),
      onStateChange: () => {
        setStatus(voiceSearch.getState().status)
        setTranscript(voiceSearch.getState().transcript ?? '')
        setIsSpeechFinal(voiceSearch.getState().isSpeechFinal)
        setErrorCode(voiceSearch.getState().errorCode ?? '')
      },
    })

    setVoiceSearchHelper(voiceSearch)

    return () => {
      if (voiceSearch) {
        voiceSearch.dispose()
      }
    }
  }, [props])

  if (!voiceSearchHelper) {
    return null
  }

  const { isBrowserSupported, isListening, toggleListening } = voiceSearchHelper

  return (
    <div>
      <IconButton
        color={isListening() ? 'error' : 'default'}
        onClick={toggleListening}
        disabled={!isBrowserSupported()}
        aria-label='Voice Search'
        size='medium'
      >
        {isListening() ? <HiMicrophone /> : <HiOutlineMicrophone />}
      </IconButton>
      {/* <div className='hidden'>
        <p>status: {status}</p>
        <p>transcript: {transcript}</p>
        <p>isSpeechFinal: {isSpeechFinal ? 'true' : 'false'}</p>
        <p>errorCode: {errorCode}</p>
        <p>isListening: {isListening() ? 'true' : 'false'}</p>
        <p>isBrowserSupported: {isBrowserSupported() ? 'true' : 'false'}</p>
      </div> */}
    </div>
  )
}

const CustomVoiceSearch = connectSearchBox(VoiceSearch)

export default CustomVoiceSearch
