import { CgSpinner } from 'react-icons/cg'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonOcellusConfig from './common/ocellus'

const rebufferingRatioOcellus: MetricSettingsFn = ({
  variants,
  featureFlags,
}): MetricSettingsI => {
  const ocellusConfig = commonOcellusConfig({ variants, featureFlags })

  const withClient = variants?.withClient || false

  return {
    ...ocellusConfig,
    value: 'rebuffering-ratio-ocellus',
    apiParam: 'rebuffering-ratio',
    label: 'Rebuffering',
    dataKey: 'rebufferingRatio',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: CgSpinner,
    solidIcon: CgSpinner,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value * (withClient ? 1 : 100)),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value * (withClient ? 1 : 100)),
    info: 'Rebuffering Ratio is the percentage of video viewing time when viewers experienced rebuffering issues.',
    aggregationType: 'average',
    invertedChangeColor: true,
    ragThresholds: {
      green: 0,
      amber: 0.2,
      red: 0.4,
    },
    defaultConfig: {
      ...ocellusConfig.defaultConfig,
      metric: 'rebuffering-ratio-ocellus',
    },
  }
}

export default rebufferingRatioOcellus
