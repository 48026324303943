import { MdMemory, MdOutlineMemory } from 'react-icons/md'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import formatDataSize from '@/utils/formatDataSize'

import commonPrometheusCoreConfig from './common/prometheusCore'

const memoryUsage: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    value: 'memory-usage',
    label: 'Memory Usage',
    shortLabel: 'Memory',
    dataKey: 'memoryUsage',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: MdMemory,
    solidIcon: MdOutlineMemory,
    yAxisFormat: (value: number): string => {
      return formatDataSize({ value, unit: 'byte' })
    },
    valueFormat: (value: number): string => {
      return formatDataSize({ value, unit: 'byte' })
    },
    info: 'Memory Usage refers to the amount of random access memory (RAM) being utilised by the network devices, such as routers, switches, and other networking equipment that form the core infrastructure. Memory Usage in the core network is a key metric for monitoring and managing the performance of networking devices such as routers, switches, and other network elements.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'memory-usage',
    },
  }
}

export default memoryUsage
