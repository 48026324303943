const fallback = []

const convivaTimeseriesToSparkline = ({ settings = {}, data }) => {
  const { dataKey } = settings

  if (data?.data) {
    const total = data?.data?.total
    if (!total || data?.data?.total.length === 0) return fallback

    return [...total].map((datum) => {
      return {
        x: datum?.timestamp,
        y: datum[dataKey],
      }
    })
  } else {
    return fallback
  }
}

export default convivaTimeseriesToSparkline
