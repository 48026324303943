import { MinutesUniquesSettingsI } from '@/config/types'

import {
  PROPOSITION,
  SPLIT_BY,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '../formOptions'

import commonBigvivaConfig from './common/bigvivaConfig'

const minutesAndUniquesSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaConfig,
  form: [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [SPLIT_BY['proposition'], SPLIT_BY['territory']],
    },
    {
      value: 'proposition',
      type: 'button-group',
      multi: true,
      options: [
        PROPOSITION['all'],
        PROPOSITION['peacock'],
        PROPOSITION['now-tv'],
        PROPOSITION['skyshowtime'],
      ],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [
        TERRITORY['all'],
        TERRITORY['uk'],
        TERRITORY['us'],
        TERRITORY['de'],
        TERRITORY['it'],
        TERRITORY['roi'],
        ...skyShowTimeTerritoryOptions,
      ],
    },
  ],
}

export default Object.freeze(minutesAndUniquesSettings)
