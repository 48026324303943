import { HiOutlineShoppingCart, HiShoppingCart } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import commonPeacockSdpConfig from './common/peacockSdp'

const adFreeTransactions: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const peacockSdpConfig = commonPeacockSdpConfig({ variants })

  return {
    ...peacockSdpConfig,
    value: 'ad-free-transactions',
    label: 'Ad Free Transactions',
    shortLabel: 'Ad-free Trans.',
    dataKey: 'adFreeTransactions',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: HiOutlineShoppingCart,
    solidIcon: HiShoppingCart,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Ad Free Transactions shows the number of purchases, renewals or reinstatements of premium tier subscriptions. Please note that this data currently includes trialists.',
    aggregationType: 'total',
    defaultConfig: {
      ...peacockSdpConfig.defaultConfig,
      metric: 'ad-free-transactions',
      'split-by': null,
    },
  }
}

export default adFreeTransactions
