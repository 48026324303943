import 'react-popper-tooltip/dist/styles.css'

import moment from 'moment'
import React from 'react'
import {
  HiOutlineFilter,
  HiOutlineInformationCircle,
  HiOutlineRefresh,
  HiOutlineShare,
} from 'react-icons/hi'
import InfiniteScroll from 'react-infinite-scroller'
import { useLocation, useNavigate } from 'react-router-dom'

import XAxis from '@/components/Chart/XAxis'
import DevUrl from '@/components/DevUrl'
import FilterPanel from '@/components/FilterPanel'
import InfoModalContent from '@/components/InfoModalContent'
import LastUpdatedTime from '@/components/LastUpdatedTime'
import Loading from '@/components/Loading'
import PageHeader from '@/components/PageHeader'
import ShareModalContent from '@/components/ShareModalContent'
import Status from '@/components/Status'
import { conviva2RelativeDates } from '@/config/relativeDates'
import useCurrentMinute from '@/hooks/useCurrentMinute'
import useEventsData from '@/hooks/useEventsData'
import useEventsSettings from '@/hooks/useEventsSettings'
import { useClientStore } from '@/stores/client.store'
import { useEventStore } from '@/stores/event.store'
import { useModalStore } from '@/stores/modal.store'
import { useUiStore } from '@/stores/ui.store'
import cleanseConfig from '@/utils/cleanseConfig'
import createUrlFromConfig from '@/utils/createUrlFromConfig'
import { logEvent } from '@/utils/firebaseAnalytics'
import flattenEventsData from '@/utils/flattenEventsData'
import getConfigFromUrl from '@/utils/getConfigFromUrl'
import getDateRangeFromConfig from '@/utils/getDateRangeFromConfig'
import getEventsConfigDateRange from '@/utils/getEventsConfigDateRange'
import getEventsConfigMetrics from '@/utils/getEventsConfigMetrics'
import getUniqueEventId from '@/utils/getUniqueEventId'

import { DiffBar } from './DiffBar'
import { EventItem } from './EventItem'

// ** Plays metrics test: Adding `plays` to the quality events query is as a means to check wether old linear or sle events should display quality metrics.
// Instead of null, the %metrics(VPF VSF) might come as 0 and display 0 values, but if Plays is null, we know not to display anything

export const QUALITY_METRICS = [
  'connection-induced-rebuffering-ratio',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
]

const AllEvents = () => {
  const isOcellus = useEventStore((state) => state.isOcellus)
  const scrollParentRef = React.useRef(null)
  const navigate = useNavigate()
  const client = useClientStore((state) => state.client)
  const openModal = useModalStore((state) => state.openModal)
  const developerMode = useUiStore((state) => state.developerMode)
  const baseSettings = useEventsSettings({
    variants: {
      withClient: true,
      isOcellus: isOcellus && client === 'peacock',
    },
  })

  const vodSettings = {
    ...baseSettings,
    dates: conviva2RelativeDates[client],
  }

  const searchParamsConfig = getConfigFromUrl({
    metrics: [baseSettings],
    isEvents: true,
  })

  const [config, setConfig] = React.useState<ConfigI>(() => {
    const isVod =
      searchParamsConfig['stream-type']?.includes('vod') ||
      baseSettings.defaultConfig['stream-type']?.includes('vod')

    const initialSettings = isVod ? vodSettings : baseSettings

    const conviva2SupportedConfig = getEventsConfigDateRange({
      config: { ...initialSettings.defaultConfig, ...searchParamsConfig },
      settings: initialSettings,
    })
    return getEventsConfigMetrics({
      settings: initialSettings,
      config: {
        ...conviva2SupportedConfig,
        metric: [...QUALITY_METRICS, 'plays'], // see  ** explanation at the top of the file
      },
    })
  })

  const isVod = config['stream-type']?.includes('vod')
  const isLinear = config['stream-type']?.includes('linear')
  const isSle = config['stream-type']?.includes('sle')
  const isNow = config['time-period']?.includes('now')

  const settings = isVod ? vodSettings : baseSettings

  const [filterPanelOpen, setFilterPanelOpen] = React.useState(false)
  const [diffBarHovered, setDiffBarHovered] = React.useState<{
    enabled: boolean
    start: any
    end: any
  }>()
  const [silentRefresh, setSilentRefresh] = React.useState(false)

  const currentMinute = useCurrentMinute()

  const onUpdateConfig = (updatedConfig: ConfigI, isSilent = false) => {
    const nextConfig = { ...config, ...updatedConfig }
    if (!isSilent) {
      events.remove()
    }
    const { start, end } = getDateRangeFromConfig(nextConfig)
    setSilentRefresh(isSilent)
    setConfig({ ...nextConfig, start, end })
  }

  const cleansedConfig = React.useMemo(() => cleanseConfig(config), [config])
  const location = useLocation()

  const { events, url } = useEventsData({
    key: 'events',
    config: cleansedConfig,
    reactQueryProps: {
      refetchInterval: false,
    },
    variants: {
      withClient: true,
      isOcellus: isOcellus && client === 'peacock',
    },
  })

  React.useEffect(() => {
    navigate(location.pathname)
  }, [navigate, location.pathname])

  // change metrics on vod change
  React.useEffect(() => {
    const initialSettings = isVod ? vodSettings : baseSettings
    const conviva2SupportedConfig = getEventsConfigDateRange({
      config: {
        ...initialSettings.defaultConfig,
        ...searchParamsConfig,
        ...config,
      },
      settings: initialSettings,
    })
    setConfig(
      getEventsConfigMetrics({
        settings: initialSettings,
        config: {
          ...conviva2SupportedConfig,
          metric: [...QUALITY_METRICS, 'plays'], // see  ** explanation at the top of the file
        },
      }),
    )
  }, [isVod, isNow])

  React.useEffect(() => {
    if (events?.data?.pages && events?.data?.pages?.length <= 1) {
      const { start, end } = getDateRangeFromConfig(config)
      if (start !== config?.start || end !== config?.end) {
        onUpdateConfig({ start, end }, true)
      }
    }
  }, [currentMinute])

  React.useEffect(() => {
    setSilentRefresh(false)
  }, [location])

  return (
    <main className='flex flex-1 overflow-hidden'>
      <div className='flex flex-col flex-1'>
        <div className='relative px-6 py-6 lg:py-8 lg:px-8'>
          <PageHeader
            ocellusEventsSwitch={settings.ocellusSwitchEnabled}
            title={settings?.label}
            buttons={[
              {
                label: 'Refresh',
                Icon: HiOutlineRefresh,
                onClick: () => {
                  setSilentRefresh(false)
                  events.remove()
                  events.refetch()
                  logEvent('select_refresh')
                },
                labelClassName: 'hidden md:inline-block',
              },
              {
                label: 'Info',
                Icon: HiOutlineInformationCircle,
                onClick: () => {
                  openModal({
                    title: `${settings?.label} Information`,
                    description: (
                      <InfoModalContent
                        settings={settings}
                        config={config}
                        isEvent
                      />
                    ),
                    appearance: 'info',
                  })
                  logEvent('select_info')
                },
                labelClassName: 'hidden md:inline-block',
              },
              {
                label: 'Share',
                Icon: HiOutlineShare,
                onClick: () => {
                  const shareUrl = createUrlFromConfig(config, settings)

                  openModal({
                    title: 'Share',
                    description: <ShareModalContent url={shareUrl} />,
                    appearance: 'info',
                  })
                  logEvent('select_share')
                },
                labelClassName: 'hidden md:inline-block',
              },
              {
                label: 'Filters',
                Icon: HiOutlineFilter,
                onClick: () => {
                  setFilterPanelOpen(true)
                },
                className: '2xl:hidden',
              },
            ]}
          />
        </div>

        <section className='flex flex-col flex-1 overflow-hidden border-t border-divider-main'>
          {developerMode && <DevUrl url={url} />}

          <Status
            {...events}
            isEmpty={flattenEventsData(events?.data).length === 0}
            silentRefresh={silentRefresh}
            loadingMessage={`We're aggregating through millions of events, so this can take a while...`}
            comingSoon={baseSettings?.comingSoon}
            comingSoonMessage='Events data is coming soon'
            comingSoonSubMessage="We're in the process of integrating Merlin data to provide this feature"
          >
            <div className='flex justify-center flex-1 overflow-hidden'>
              <div
                className='flex-1 w-full overflow-y-auto xl:px-12 bg-neutral-dimmed'
                ref={scrollParentRef}
              >
                <div className='mx-auto max-w-7xl'>
                  <div className='sticky top-0 z-20 px-1 py-3 space-y-3 text-sm font-medium border-b text-text-tertiary border-divider-main bg-neutral-dimmed'>
                    {(isSle || isLinear) && (
                      <div className='hidden sm:block'>
                        <XAxis
                          range={[
                            Number(moment(config.start).unix()) * 1000,
                            Number(moment(config.end).unix()) * 1000,
                          ]}
                          interval={16}
                        />
                        <div className='absolute left-0 z-10 w-full px-1 top-1'>
                          <DiffBar
                            start={config.start}
                            end={config.end}
                            itemStart={diffBarHovered?.start}
                            itemEnd={diffBarHovered?.end}
                            enabled={diffBarHovered?.enabled}
                            animate
                            bgClassName='bg-neutral-dimmed'
                          />
                        </div>
                      </div>
                    )}

                    <div className='relative grid grid-cols-12 gap-4 pb-3 sm:px-4'>
                      <div className='col-span-3 2xl:col-span-2'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Rank
                        </p>
                      </div>
                      <div className='col-span-3'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Event
                        </p>
                      </div>
                      <div className='col-span-6 2xl:col-span-7'>
                        <p className='text-xs font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                          Metrics
                        </p>
                      </div>
                      <LastUpdatedTime
                        isRefreshing={
                          events.isLoading ||
                          events.isFetching ||
                          events.isRefetching
                        }
                        timestamp={events.dataUpdatedAt}
                        containerClasses='absolute right-3 sm:right-4 -bottom-1.5  text-text-tertiary opacity-80'
                      />
                    </div>
                  </div>

                  <ol className='py-4 pt-24 -mt-24 overflow-hidden divide-y shadow divide-divider-main rounded-xl bg-neutral-dimmed-heavy'>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={() => {
                        if (
                          events.hasNextPage &&
                          !events.isLoading &&
                          !events.isFetching &&
                          !events.isFetchingNextPage &&
                          !events.isError
                        ) {
                          events.fetchNextPage()
                        }
                      }}
                      hasMore={events.hasNextPage}
                      loader={
                        <div className='justify-center w-full py-8' key={0}>
                          <Loading
                            includeLogo={false}
                            width={64}
                            lightTheme={false}
                          />
                        </div>
                      }
                      threshold={24}
                      useWindow={false}
                      getScrollParent={() => scrollParentRef.current}
                    >
                      {flattenEventsData(events?.data)?.map((event, index) => {
                        const id = getUniqueEventId(event)
                        return (
                          <EventItem
                            key={id}
                            id={id}
                            event={event}
                            index={index}
                            config={config}
                            settings={settings}
                            setDiffBarHovered={setDiffBarHovered}
                          />
                        )
                      })}
                    </InfiniteScroll>
                  </ol>
                </div>
              </div>
            </div>
          </Status>
        </section>
      </div>

      <FilterPanel
        config={config}
        settings={settings}
        onUpdateConfig={onUpdateConfig}
        open={filterPanelOpen}
        setOpen={setFilterPanelOpen}
        showCustomDateRangeDisclaimer={isVod}
      />
    </main>
  )
}

export default AllEvents
