import useTheme from '@/hooks/useTheme'

import getChartColors from '@/config/chartColors'

export default function useChartColors() {
  const { theme } = useTheme()!

  const chartColors = getChartColors(
    'chartColors' in theme ? theme.chartColors : [],
  )

  return chartColors
}
