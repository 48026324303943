import jwt_decode from 'jwt-decode'
import { sortBy } from 'lodash'
import React from 'react'

import { ClientT } from '@/config/types'
import { useUserStore } from '@/stores/user.store'
import getAccessToken from '@/utils/getAccessToken'

import useSettings from './useSettings'

const getAuthorizedClients = (
  roles: string[],
  availableClients: ClientT[],
): ClientT[] => {
  const filteredRoles =
    roles?.filter((role) => role.startsWith('client_')) || []
  // Also sort roles by the same index they are in within appSettings.clients
  const clientNames = sortBy(
    filteredRoles
      .map((role) => role.replace('client_', ''))
      .filter((role) => availableClients.includes(role as ClientT)),
    (role) => availableClients.indexOf(role as ClientT),
  )
  return clientNames as ClientT[]
}

type FetchAccessToken = (forceRefresh?: boolean) => void

const useFetchAccessToken = () => {
  const { appSettings } = useSettings()

  const setAuthorizedClients = useUserStore(
    (state) => state.setAuthorizedClients,
  )
  const setRoles = useUserStore((state) => state.setRoles)

  const fetchAccessToken = React.useCallback<FetchAccessToken>(
    async (forceRefresh?: boolean) => {
      const token = await getAccessToken(forceRefresh)
      if (token && typeof token === 'string') {
        const decodedToken = jwt_decode<any>(token)
        const roles = decodedToken.roles
        const authorizedClients = getAuthorizedClients(
          roles,
          appSettings.clients,
        )
        setRoles(roles)
        setAuthorizedClients(authorizedClients)
      }
      return
    },
    [setRoles],
  )

  return fetchAccessToken
}

export default useFetchAccessToken
