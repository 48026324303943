import { PROPOSITION, SPLIT_BY, TERRITORY } from '@/config/formOptions'

import { CONVIVA_2_V4_API_DOMAIN } from '../../../constants'
import { FormI, WeeklyViewingReportSettingsI } from '../../../types'
import getStartAndEndConfig from './utils/getStartAndEndConfig'

const form: FormI[] = [
  {
    value: 'split-by',
    label: 'Split By',
    type: 'button-group',
    multi: false,
    options: [
      SPLIT_BY['proposition'],
      SPLIT_BY['territory'],
      SPLIT_BY['device-type'],
    ],
  },

  {
    value: 'proposition',
    type: 'button-group',
    multi: true,
    options: [
      PROPOSITION['all'],
      PROPOSITION['now'],
      PROPOSITION['sky-go'],
      PROPOSITION['sky-q'],
      PROPOSITION['sky-stream'],
      PROPOSITION['soip'],
      PROPOSITION['sky-sports'],
      PROPOSITION['sky-x'],
      PROPOSITION['sky-store'],
      PROPOSITION['sky-sports-box-office'],
    ],
  },
  {
    value: 'territory',
    type: 'button-group',
    multi: true,
    options: [
      TERRITORY['all'],
      TERRITORY['uk'],
      TERRITORY['de'],
      TERRITORY['it'],
      TERRITORY['roi'],
      TERRITORY['at'],
    ],
  },
]

const weeklyViewing: WeeklyViewingReportSettingsI = {
  apiDomain: CONVIVA_2_V4_API_DOMAIN,
  form,
  highProfileEventsEnabled: true,
  minutesAndUniquesEnabled: true,
  defaultConfig: {
    metric: 'weekly-viewing',
    'split-by': 'proposition',
    proposition: ['all'],
    territory: ['all'],
    'device-type': ['all'],
    dateType: 'week',
    isRange: false,
    client: 'sky-europe',
    ...getStartAndEndConfig(),
  },
}

export default Object.freeze(weeklyViewing)
