import { HiOutlineTicket, HiTicket } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonBigvivaSdpConfig from './common/bigvivaSdp'

const cancellations: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const bigvivaSdpConfig = commonBigvivaSdpConfig({ variants })

  return {
    ...bigvivaSdpConfig,
    value: 'cancellations',
    label: 'Cancellations',
    dataKey: 'cancellations',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: false,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlineTicket,
    solidIcon: HiTicket,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Cancellations shows the total number of cancelled accounts during a given interval. The metric is up to the start of the current hour because of a limitation on the datasource.',
    aggregationType: 'total',
    invertedChangeColor: true,
    defaultConfig: {
      ...bigvivaSdpConfig.defaultConfig,
      metric: 'cancellations',
    },
  }
}

export default cancellations
