import { MdMemory, MdOutlineMemory } from 'react-icons/md'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPrometheusCoreConfig from './common/prometheusCore'

const cpuUsage: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    hideInMetricsExplorer: true,
    value: 'cpu-usage',
    label: 'CPU Usage',
    shortLabel: 'CPU',
    dataKey: 'CPUUsage',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: MdMemory,
    solidIcon: MdOutlineMemory,
    yAxisFormat: (value: number): string => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format(value)} core seconds/min`
    },
    valueFormat: (value: number): string => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format(value)} Core seconds/min`
    },
    info: `CPU Usage refers to the percentage of a central processing unit (CPU) that is actively engaged in processing tasks related to network functions. CPU Usage is a crucial metric in network monitoring and management because it provides insights into the workload and resource utilisation of the network device's processing unit.`,
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'cpu-usage',
    },
  }
}

export default cpuUsage
