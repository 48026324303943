import { GRAFANA_FARO_API_KEY, GRAFANA_FARO_URL } from '@/config/constants'
import {
  FetchTransport,
  getWebInstrumentations,
  initializeFaro,
} from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'

import { env } from '../env'
import packageJson from '../../package.json'

const { name, version } = packageJson

const isDevelopment = env.NODE_ENV === 'development'

const getAppName = () => {
  let appName = name

  if (isDevelopment) {
    appName = appName + '-dev'
  }

  return appName
}

export const initFaro = () => {
  return null //stopping faro for now
  // if (isDevelopment) return null

  const appName = getAppName()

  initializeFaro({
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: false,
      }),
      new TracingInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url: GRAFANA_FARO_URL,
        apiKey: GRAFANA_FARO_API_KEY,
      }),
    ],
    app: {
      name: appName,
      version,
    },
  })
}
