/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const colors = require('tailwindcss/colors')
const tinycolor = require('tinycolor2')

const getFullPalette = require('../utils/getFullPalette')

const primaryColors = {
  // https://www.tailwindshades.com/#color=265.94594594594594%2C100%2C29.01960784313726&step-up=14&step-down=4&hue-shift=0&name=pigment-indigo&base-stop=5&v=1&overrides=e30%3D
  50: '#FFA4C2',
  100: '#FF8FB5',
  200: '#FF669A',
  300: '#FF3E7F',
  400: '#FF1564',
  500: '#EB004F',
  600: '#B3003C',
  700: '#7B0029',
  800: '#430016',
  900: '#0B0004',
  950: '#000000',
}

// Palette
const secondary = 'pink'
const success = 'green'
const error = 'red'
const info = 'blue'
const warning = 'orange'

const neutral = 'zinc'

const dark = {
  value: 'showmax-dark',
  type: 'dark',
  label: 'Showmax Dark',
  selectors: ['.showmax-dark'],
  theme: {
    chartColors: [
      // Initial set of chart colors, always use tailwind colors to avoid duplicates
      colors.gray['500'],
      colors.orange['500'],
      colors.indigo['500'],
      colors.pink['500'],
    ],
    colors: {
      // Primary and Secondary colours
      // Primary colours
      'elements-primary-50': primaryColors['50'],
      'elements-primary-100': primaryColors['100'],
      'elements-primary-200': primaryColors['200'],
      'elements-primary-300': primaryColors['300'],
      'elements-primary-400': primaryColors['400'],
      'elements-primary-500': primaryColors['500'],
      'elements-primary-600': primaryColors['600'],
      'elements-primary-700': primaryColors['700'],
      'elements-primary-800': primaryColors['800'],
      'elements-primary-900': primaryColors['900'],
      'elements-primary-950': primaryColors['950'],
      'elements-primary-dimmed': primaryColors['400'],
      'elements-primary-main': primaryColors['500'],
      'elements-primary-shadow': primaryColors['600'],
      'elements-primary-highlight': primaryColors['300'],
      'elements-primary-contrastText': '#ffffff',

      'elements-secondary-dimmed': colors[secondary]['400'],
      'elements-secondary-main': colors[secondary]['500'],
      'elements-secondary-shadow': colors[secondary]['600'],
      'elements-secondary-highlight': colors[secondary]['300'],
      'elements-secondary-contrastText': '#ffffff',
      ...getFullPalette('elements-secondary', secondary),

      // Homepage realtime gradient
      'headline-gradient-from': 'rgb(254, 26, 103)', // Showmax Dark Pink lightened 20%
      'headline-gradient-via': 'rgb(235, 0, 79)', // Showmax Dark Pink
      'headline-gradient-to': 'rgb(211, 0, 71)', // Showmax Dark Pink darkened 10%

      'event-backdrop': 'rgb(0, 0, 0)',

      // Alert
      'helpers-error-dimmed': colors[neutral]['700'],
      'helpers-error-main': colors[error]['400'],
      'helpers-error-button': colors[error]['500'],
      'helpers-error-button-hover': colors[error]['400'],
      'helpers-error-contrastText': '#ffffff',

      'helpers-warning-dimmed': colors[neutral]['700'],
      'helpers-warning-main': colors[warning]['400'],
      'helpers-warning-button': colors[warning]['500'],
      'helpers-warning-button-hover': colors[warning]['400'],

      'helpers-success-dimmed': colors[neutral]['700'],
      'helpers-success-main': colors[success]['400'],
      'helpers-success-button': colors[success]['500'],
      'helpers-success-button-hover': colors[success]['400'],

      'helpers-info-dimmed': colors[neutral]['700'],
      'helpers-info-main': colors[info]['400'],
      'helpers-info-button': colors[info]['500'],
      'helpers-info-button-hover': colors[info]['400'],

      // Custom Brand colours (e.g. Sky palette, Peacock palette)
      'brand-1': '#ffffff',

      // Text
      'text-primary': '#ffffff',
      'text-secondary': colors[neutral]['300'],
      'text-tertiary': colors[neutral]['400'],
      'text-dimmed': colors[neutral]['500'],
      'text-disabled': colors[neutral]['600'],

      // Neutral
      'divider-dimmed': colors[neutral]['700'],
      'divider-main': colors[neutral]['600'],
      'divider-shadow': colors[neutral]['500'],

      'border-dimmed': colors[neutral]['700'],
      'border-main': colors[neutral]['600'],
      'border-shadow': colors[neutral]['500'],

      'neutral-dimmed-heavy': tinycolor(colors[neutral]['800'])
        .lighten(5)
        .toString(),
      'neutral-dimmed': tinycolor(colors[neutral]['800'])
        .lighten(2.5)
        .toString(),
      neutral: colors[neutral]['800'],
      'neutral-shadow': colors[neutral]['900'],
      'neutral-shadow-heavy': tinycolor(colors[neutral]['900'])
        .darken(7.5)
        .toString(),
    },
  },
  muiThemeBase: {
    palette: {
      type: 'dark',
      primary: {
        main: primaryColors['500'],
        light: primaryColors['400'],
        dark: primaryColors['600'],
      },
      secondary: {
        main: colors[secondary]['400'],
        light: colors[secondary]['500'],
        dark: colors[secondary]['300'],
      },
      error: {
        main: colors[error]['400'],
        light: colors[error]['600'],
        dark: colors[error]['300'],
      },
      text: {
        disabled: colors[neutral]['600'],
        icon: 'rgba(255, 255, 255, 0.5)',
        primary: '#fff',
        secondary: colors[neutral]['200'],
      },
      divider: colors[neutral]['600'],
      gray: colors[neutral],
      background: {
        default: '#262626',
        paper: '#333333',
      },
    },
  },
}

module.exports = dark
