import camelCase from 'lodash/camelCase'
import 'firebase/analytics'

import firebase from 'firebase/app'

import appSettings from '@/config/appSettings'

// Firebase doesn't allow custom definitions to be set up with hyphenation
// Standardise everything to camelCase
function convertKeysToCamelCase(obj: Record<string, any>): Record<string, any> {
  const safeList = ['page_path'] // List of keys to exclude from camelCase conversion
  const camelCasedObj: Record<string, any> = {}

  Object.keys(obj).forEach((key) => {
    if (safeList.includes(key)) {
      camelCasedObj[key] = obj[key]
    } else {
      camelCasedObj[camelCase(key)] = obj[key]
    }
  })

  return camelCasedObj
}

const analyticsEnabled = !!firebase.analytics && appSettings?.analytics?.enabled

export const logEvent = (eventName: string, eventParams?: object): void => {
  if (analyticsEnabled) {
    const camelCasedParams = eventParams
      ? convertKeysToCamelCase(eventParams)
      : {}

    firebase.analytics().logEvent(eventName, camelCasedParams)
  }
}

export const setUserProperties = (properties: object): void => {
  if (analyticsEnabled) {
    firebase.analytics().setUserProperties(properties)
  }
}

export const setCurrentScreen = (currentScreen: string): void => {
  if (analyticsEnabled) {
    firebase.analytics().setCurrentScreen(currentScreen)
  }
}

export const setUserId = (userId: string): void => {
  if (analyticsEnabled) {
    firebase.analytics().setUserId(userId)
  }
}
