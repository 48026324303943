import { ClientT, RoadmapSettingsI } from '@/config/types'

// As clients become more varied, start creating Roadmaps unique to each client
import commonRoadmap from './common'

export const faqs: Record<ClientT, RoadmapSettingsI> = {
  'nbcu-dtc': commonRoadmap,
  now: commonRoadmap,
  peacock: commonRoadmap,
  showmax: commonRoadmap,
  'sky-europe-and-peacock': commonRoadmap,
  'sky-europe': commonRoadmap,
  skyshowtime: commonRoadmap,
  'now-gold': commonRoadmap,
}

export default faqs
