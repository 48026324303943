import { motion } from 'framer-motion'
import React from 'react'
import Draggable from 'react-draggable'
import Particles from 'react-tsparticles'

import useSettings from '@/hooks/useSettings'
import { useSelectedTheme } from '@/hooks/useTheme'

import { cn } from '@/utils/cn'

const AuthenticationPageLayout = ({
  children,
  showAppLogo,
}: {
  children: React.ReactNode
  showAppLogo?: boolean
}) => {
  const [firstAnimation, setFirstAnimation] = React.useState(true)

  const selectedTheme = useSelectedTheme()
  const { appSettings } = useSettings()

  const { name = '', logo, easterEggs, login } = appSettings

  return (
    <div
      className={cn(
        'flex flex-col justify-center h-screen overflow-y-auto',
        login?.backgroundClassName,
      )}
    >
      <Particles
        className='absolute z-0 w-full h-full'
        options={{
          particles: {
            number: {
              value: Math.sqrt(window.innerWidth * window.innerHeight) / 30,
            },
            // @ts-ignore
            color: login?.particlesColor || '#ffffff',
            size: {
              value: 3,
            },
            move: {
              speed: 0.9,
              enable: true,
            },
            line_linked: {
              enable: true,
              color: login?.particlesColor,
            },
          },
          ...login?.particlesConfig,
        }}
      />

      <div className='z-10 px-2 py-4 sm:py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: firstAnimation ? 0.4 : 0 }}
            onAnimationComplete={() => setFirstAnimation(false)}
            className='px-4 py-12 rounded-md shadow bg-neutral-dimmed-heavy sm:rounded-lg sm:px-10'
          >
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
              {showAppLogo && (
                <div className='flex items-center justify-center'>
                  <Draggable
                    handle='.draggable-handle'
                    disabled={!easterEggs?.draggableLogo}
                  >
                    <div className='z-10 draggable-handle'>
                      <img
                        className='w-auto rounded pointer-events-none bg-neutral-dimmed-heavy h-14'
                        src={logo?.[selectedTheme]?.app}
                        alt={`${name} logo`}
                      />
                    </div>
                  </Draggable>
                  {easterEggs?.draggableLogo && (
                    <img
                      alt='Easter Egg'
                      className='absolute z-0 w-auto h-12 pointer-events-none bg-neutral-dimmed-heavy'
                      src='/images/draggable-logo-easter-egg.png'
                    />
                  )}
                </div>
              )}
              {children}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationPageLayout
