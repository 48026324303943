import { EventsSettingsI, VariantsT } from '@/config/types'
import { QueryFunctionContext, useInfiniteQuery, useQuery } from 'react-query'

import React from 'react'
import axios from 'axios'
import createVariantsSupportedConfig from '@/utils/createVariantsSupportedConfig'
import { flatten } from 'lodash'
import getAccessToken from '../utils/getAccessToken'
import getUrl from '../utils/getUrl'
import { useClientStore } from '@/stores/client.store'
import useEventsSettings from './useEventsSettings'
import useWindowFocus from 'use-window-focus'

const maxNumOfEvents = 100

const fetchEvents = async ({
  queryKey,
  pageParam = 0,
}: QueryFunctionContext) => {
  const { settings, config } = queryKey[1] as {
    settings: EventsSettingsI
    config: ConfigI
  }

  const url = getUrl({
    settings,
    config: { ...config, page: pageParam },
  })

  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

function useEventsData<T = Conviva2EventI>({
  key = 'events',
  config: initialConfig,
  extendSettings = (settings) => settings,
  reactQueryProps,
  singleQuery = false,
  variants,
}: {
  key: any
  config: ConfigI
  extendSettings?: (settings: any) => any
  reactQueryProps?: any
  singleQuery?: boolean
  variants?: VariantsT
}) {
  const client = useClientStore((state) => state.client)
  const windowFocused = useWindowFocus()
  const [errorCount, setErrorCount] = React.useState(0)

  const settings: EventsSettingsI = useEventsSettings({
    extendSettings,
    variants,
  })
  const config = createVariantsSupportedConfig({
    config: initialConfig,
    variants,
    client,
    settings,
  })

  const isOcellus = (variants?.isOcellus && client === 'peacock') || false

  const queryKey = isOcellus
    ? [`${key}-ocellus`, { config, settings }]
    : [key, { config, settings }]

  const enabled =
    errorCount < 3 &&
    windowFocused &&
    (reactQueryProps.enabled || reactQueryProps.enabled === undefined) // Don't repoll when window isn't focused, but also capture initial enabled prop

  const events = useInfiniteQuery<
    any,
    any,
    { meta: Conviva2MetaI; data: T[] },
    any
  >(queryKey, fetchEvents, {
    getNextPageParam: (lastPage, pages) => {
      const morePagesExist = lastPage?.data && lastPage?.data.length === 10
      const eventsOverMax = flatten(pages).length >= maxNumOfEvents
      if (!morePagesExist || eventsOverMax) return false
      return pages.length
    },
    ...reactQueryProps,
    enabled: enabled && !singleQuery,
  })

  const event = useQuery<{
    meta: Conviva2MetaI
    data: T[]
  }>(queryKey, fetchEvents, {
    ...reactQueryProps,
    enabled: enabled && singleQuery,
  })

  React.useEffect(() => {
    if (events.status === 'error') {
      setErrorCount((count) => count + 1)
    } else if (events.status === 'success') {
      setErrorCount(0)
    }
  }, [events.status])

  const url = getUrl({
    settings,
    config,
  })

  const refetchEventQuery = () => {
    event.refetch()
  }

  return {
    events,
    event,
    settings,
    url,
    refetchEventQuery,
  }
}

export default useEventsData
