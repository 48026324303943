import { ClientSettingsI } from '../types'

const clientSettings: ClientSettingsI = {
  name: 'SkyShowtime',
  key: 'skyshowtime',
  themes: {
    light: 'skyshowtime-light',
    dark: 'skyshowtime-dark',
  },
  logo: {
    light: {
      app: '/images/skyshowtime/app-logo.png',
    },
    dark: {
      app: '/images/skyshowtime/app-logo.png',
    },
  },
  supportedStreamTypes: ['vod'],
  metricComparison: { enabled: true }, // TODO: Deprecate me after Metrics Explorer launch
  metricsExplorer: {
    eventTimelineDisabled: true,
    busiestDays: {
      // Using concurrent-plays instead of plays, because "busy", from platform point of view, is how many plays are happening at the same time
      metric: 'concurrent-plays',
      includeTopEvents: true,
    },
  },
}

export default clientSettings
