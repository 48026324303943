import clsx from 'clsx'
import {
  HighlightProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'
import { connectHighlight, connectStateResults } from 'react-instantsearch-dom'

import useTheme from '@/hooks/useTheme'

const Highlight = ({
  highlight,
  attribute,
  hit,
  searchState,
}: HighlightProvided &
  StateResultsProvided & { hit: any; attribute: string }) => {
  const theme = useTheme()
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  })

  const selectedFacets =
    searchState?.refinementList && searchState?.refinementList[attribute]
      ? searchState?.refinementList[attribute]
      : []

  return (
    <span>
      {parsedHit.map((part: any, index: any) =>
        part.isHighlighted || selectedFacets.includes(hit[attribute]) ? (
          <mark
            className={clsx({
              'text-elements-primary-500 bg-elements-primary-100':
                theme?.type === 'light',
              'text-elements-primary-300 bg-elements-primary-900':
                theme?.type === 'dark',
            })}
            key={index}
          >
            {part.value}
          </mark>
        ) : (
          <span key={index}>{part.value}</span>
        ),
      )}
    </span>
  )
}

const ConnectedHighlight = connectStateResults(Highlight)
const CustomHighlight = connectHighlight(ConnectedHighlight)

export default CustomHighlight
