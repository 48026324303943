import clsx from 'clsx'
import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { NavigationItemChildI } from '@/config/types'

import NavigationItem from './NavigationItem'

const NavigationExpandable = ({
  id,
  label,
  icon: Icon,
  children = [],
  url,
  expandable = true,
  defaultExpanded = false,
}: any) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(
    expandable ? defaultExpanded : true,
  )
  const isActive = !!useMatch({ path: url, end: !expandable })

  return (
    <div className='space-y-1'>
      <button
        onClick={() => {
          if (!expandable) {
            navigate(url)
          } else {
            setExpanded(!expanded)
          }
        }}
        type='button'
        className={clsx(
          'text-text-secondary hover:bg-neutral-dimmed hover:text-text-primary group w-full flex items-center pl-2 pr-1 py-2 text-base xl:text-sm font-medium rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-elements-primary-main',
          {
            'bg-neutral xl:bg-neutral-shadow text-text-primary hover:bg-neutral xl:hover:bg-neutral-shadow':
              isActive,
          },
        )}
        aria-controls={`sub-menu-${id}`}
        aria-expanded={expanded}
      >
        {Icon && (
          <Icon
            className={clsx('group-hover:text-text-tertiary mr-3 h-6 w-6', {
              'text-text-tertiary': isActive,
              'text-text-dimmed': !isActive,
            })}
          />
        )}
        {label}
        {expandable && (
          <svg
            className={clsx(
              'ml-auto h-5 w-5 transform group-hover:text-text-tertiary transition-colors ease-in-out duration-150',
              {
                'text-text-tertiary': isActive,
                'rotate-90': expanded,
                'text-text-dimmed': !isActive,
              },
            )}
            viewBox='0 0 20 20'
            aria-hidden='true'
          >
            <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
          </svg>
        )}
      </button>

      <div
        className={clsx('space-y-1', { hidden: !expanded && expandable })}
        id={`sub-menu-${id}`}
      >
        {children
          .filter((item: NavigationItemChildI) => !item.hidden)
          .map((item: NavigationItemChildI) => (
            <NavigationItem
              key={item.id}
              className='w-full pl-6 pr-2'
              {...item}
            />
          ))}
      </div>
    </div>
  )
}
export default NavigationExpandable
