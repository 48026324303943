import { PROPOSITION, SPLIT_BY, TERRITORY } from '@/config/formOptions'
import { MinutesUniquesSettingsI } from '@/config/types'

import { CLIENT_DEFAULT_TIMEZONES } from '../constants'
import commonBigvivaRealtimeConfig from './common/bigvivaRealtimeConfig'

const minutesAndUniquesRealtimeSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaRealtimeConfig,
  timezone: CLIENT_DEFAULT_TIMEZONES['sky-europe'],
  form: [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [SPLIT_BY['proposition'], SPLIT_BY['territory']],
    },
    {
      value: 'proposition',
      type: 'button-group',
      multi: true,
      options: [
        PROPOSITION['all'],
        PROPOSITION['now-tv'],
        PROPOSITION['sky-go'],
        PROPOSITION['sky-q'],
        PROPOSITION['soip'],
        PROPOSITION['sky-stream'],
        PROPOSITION['sky-sports'],
        PROPOSITION['sky-x'],
        PROPOSITION['sky-store'],
        PROPOSITION['sky-sports-box-office'],
      ],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [
        TERRITORY['all'],
        TERRITORY['uk'],
        TERRITORY['de'],
        TERRITORY['it'],
        TERRITORY['roi'],
        TERRITORY['at'],
      ],
    },
  ],
}

export default Object.freeze(minutesAndUniquesRealtimeSettings)
