import moment from 'moment'

const now = moment(new Date())

export default function getStartAndEndConfig() {
  return {
    start: now.clone().utc().startOf('isoWeek').subtract(1, 'week').format(),
    end: now.clone().utc().endOf('isoWeek').subtract(1, 'week').format(),
  }
}
