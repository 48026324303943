import { PROPOSITION } from '@/config/formOptions'
import { MinutesUniquesSettingsI } from '@/config/types'

import { CLIENT_DEFAULT_TIMEZONES } from '../constants'
import commonBigvivaRealtimeConfig from './common/bigvivaRealtimeConfig'

const minutesAndUniquesRealtimeSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaRealtimeConfig,
  timezone: CLIENT_DEFAULT_TIMEZONES['peacock'],
  form: [
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['peacock']],
    },
  ],
}

export default Object.freeze(minutesAndUniquesRealtimeSettings)
