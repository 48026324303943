import { PROMETHEUS_API_DOMAIN, DEFAULT_DATE } from '@/config/constants'
import { SPLIT_BY } from '@/config/formOptions'

import { FormI } from '@/config/types'

import { GROUPS } from '@/config/metricsSettings/groups'

import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'

const commonPrometheusConfig = () => {
  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [
        SPLIT_BY['proposition'],
        SPLIT_BY['department'],
        SPLIT_BY['cloud-region'],
        SPLIT_BY['status'],
        SPLIT_BY['team'],
        SPLIT_BY['application'],
      ],
    },
    // Rest of options will come from tags endpoint
  ]

  return {
    dataSource: 'Prometheus',
    legacyGroup: 'prometheus',
    group: GROUPS.PROMETHEUS,
    apiDomain: PROMETHEUS_API_DOMAIN,
    supportsClient: true,
    hideInLegacyMetrics: true,
    timeseriesAsParam: true,
    tags: {
      enabled: true,
      endpoint: '/tags',
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonFiveMinuteGranularity,
    defaultConfig: {
      'split-by': 'proposition',
      proposition: ['all'],
      department: ['all'],
      ['cloud-region']: ['all'],
      ['status']: ['all'],
      team: ['all'],
      application: ['all'],
      ...DEFAULT_DATE('sky-europe-and-peacock'),
    },
  }
}
export default commonPrometheusConfig
