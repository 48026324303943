import clsx from 'clsx'
import { IconType } from 'react-icons/lib'

import { useEventStore } from '@/stores/event.store'

import OcellusEventSwitch from '../Event/OcellusEventsSwitch'

const PageHeader = ({
  title,
  buttons = [],
  ocellusEventsSwitch = false,
  children,
  childrenAsTitle,
  datePicker,
}: {
  title: string
  buttons?: {
    label?: string
    onClick?: () => void
    Icon?: IconType
    className?: string
    labelClassName?: string
  }[]
  ocellusEventsSwitch?: boolean
  children?: React.ReactNode
  childrenAsTitle?: boolean
  datePicker?: React.ReactNode
}): JSX.Element => {
  const isOcellus = useEventStore((state) => state.isOcellus)
  const setIsOcellus = useEventStore((state) => state.setIsOcellus)

  return (
    <div className='relative md:flex md:items-center md:justify-between'>
      <div className='flex-1 min-w-0'>
        {childrenAsTitle && children}
        <h2 className='text-xl font-bold leading-7 text-text-primary sm:text-2xl sm:truncate'>
          {title}
        </h2>
      </div>
      <div className='flex mt-4 md:mt-0 md:ml-4'>
        {ocellusEventsSwitch && (
          <div className='flex items-center pr-3'>
            <OcellusEventSwitch
              checked={isOcellus}
              onChange={() => setIsOcellus(!isOcellus)}
            />
          </div>
        )}
        {buttons.map(
          ({ label, onClick, Icon, className, labelClassName }, index, all) => {
            return (
              <button
                key={(label || '') + index}
                onClick={onClick}
                type='button'
                className={clsx(
                  'inline-flex items-center px-4 py-2 text-sm font-medium text-text-secondary bg-neutral-dimmed-heavy border border-border-main rounded-md shadow-sm hover:bg-neutral-dimmed focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-500 ring-offset-neutral',
                  { 'ml-2': index > 0 },
                  { 'mr-2': index === all.length - 1 && !!datePicker },
                  className,
                )}
              >
                {Icon && (
                  <Icon
                    className='w-5 h-5 -ml-1 text-text-dimmed'
                    aria-hidden='true'
                  />
                )}
                <span className={clsx('ml-2', labelClassName)}>{label}</span>
              </button>
            )
          },
        )}
        {datePicker}
      </div>
      {!childrenAsTitle && children}
    </div>
  )
}

export default PageHeader
