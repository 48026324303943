import { FormOptionI } from '@/config/types'
import FormOptions from './FormOptions'
import InfoText from '@/components/InfoText'
import { startCase } from 'lodash'

interface PropsI {
  multi?: boolean
  metricComparison?: boolean
  options: FormOptionI[]
  type?: 'button-group' | 'select' | 'hidden'
  value: string
  label?: string
  config: ConfigI
  onUpdateConfig: (updatedConfig: ConfigI, delay?: number) => void
}

const FormGroup = ({
  multi = false,
  metricComparison = false,
  options = [],
  type,
  value,
  label,
  config,
  onUpdateConfig,
}: PropsI) => {
  const handleUpdateConfig = (newValue: string | string[], delay?: number) => {
    let updatedValues

    if (multi) {
      const existingValues = [
        ...((config[value as keyof ConfigI] as string[]) || []),
      ]

      if (type === 'button-group') {
        if (existingValues.includes(newValue as string)) {
          updatedValues = existingValues.filter((v) => v !== newValue)
          if (updatedValues.length === 0) {
            updatedValues = ['all']
          }
        } else {
          if (newValue === 'all') {
            updatedValues = ['all']
          } else {
            updatedValues = [
              ...existingValues.filter((v) => v !== 'all'),
              newValue,
            ]
          }
        }
      } else if (type === 'select') {
        updatedValues = (newValue as string[]).filter((v) => v !== 'all')
        if (updatedValues.length === 0) {
          updatedValues = ['all']
        }
      }
    } else {
      updatedValues = newValue
    }

    // We want to be able to select 2 metrics in the metrics form if the metricComparison param is true. This adds a value to the `comparisonMetric` on the config.
    // The code below checks that always at least 1 value and maximum 2 values are selected, and that confic.metric always has a value.
    if (metricComparison && typeof updatedValues === 'string') {
      // If a new metric is selected, update comparisonMetric key on the config
      if (
        config.metric &&
        config.metric !== updatedValues &&
        config.comparisonMetric !== updatedValues
      ) {
        onUpdateConfig({ comparisonMetric: updatedValues }, delay)
        // else if an already selected `metric` is clicked again, replace the value with that of `comparisonMetric`
      } else if (
        config.metric &&
        config.metric === updatedValues &&
        config.comparisonMetric
      ) {
        onUpdateConfig(
          { metric: config.comparisonMetric, comparisonMetric: undefined },
          delay,
        )
        // else if an already selected `comparisonMetric` is clicked again, remove the value
      } else if (
        config.comparisonMetric &&
        config.comparisonMetric === updatedValues
      ) {
        onUpdateConfig(
          { metric: config.metric, comparisonMetric: undefined },
          delay,
        )
      }

      // If there are 2 metrics selected, do now allow more selections
    } else if (
      JSON.stringify(updatedValues) !==
      JSON.stringify(config[value as keyof ConfigI])
    ) {
      onUpdateConfig({ [value]: updatedValues }, delay)
    }
  }

  return (
    <li role='group' aria-labelledby={`label-${value}`}>
      <div className='flex items-start justify-between w-full'>
        <label className='pl-2 mb-3 text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
          {label || startCase(value)}{' '}
        </label>
        {metricComparison && (
          <InfoText
            className='flex items-center justify-center w-5 h-5 mr-2 text-xs font-bold lowercase border rounded-full border-border-main text-text-tertiary'
            text={'?'}
            tooltip={`You can select up to 2 metrics to compare in the adjacent chart. \n
            The additional metric might introduce a slight lag.`}
          />
        )}
      </div>

      <FormOptions
        name={value}
        options={options}
        type={type}
        multi={multi || metricComparison}
        selected={
          value === 'metric' && config.comparisonMetric && config.metric && typeof config.metric === 'string'&& typeof config.comparisonMetric === 'string'
            ? [config.metric, config.comparisonMetric]
            : (config[value as keyof ConfigI] as string | string[])
        }
        handleUpdateConfig={handleUpdateConfig}
      />
    </li>
  )
}

export default FormGroup
