import {
  aggregationToPie,
  aggregationTotal,
  aggregationLegend,
  timeseriesAverage,
  timeseriesMin,
  timeseriesPeak,
  timeseriesToLine,
  timeseriesToSparkline,
  timeseriesTotal,
  timeseriesLegendMax,
  timeseriesLegendMin,
} from '@/config/dataFormatters/conviva'

import {
  timeseriesLegendP75,
  timeseriesLegendP95,
  timeseriesLegendP97,
  timeseriesLegendP99,
  timeseriesLegendMedian,
} from '../dataFormatters/conviva/convivaTimeseriesStatisticsLegend'

export const convivaStyleDataFormatters = {
  timeseries: {
    line: timeseriesToLine,
    average: timeseriesAverage,
    min: timeseriesMin,
    peak: timeseriesPeak,
    total: timeseriesTotal,
    sparkline: timeseriesToSparkline,
    legendMin: timeseriesLegendMin,
    legendMax: timeseriesLegendMax,
    legendP75: timeseriesLegendP75,
    legendP95: timeseriesLegendP95,
    legendP97: timeseriesLegendP97,
    legendP99: timeseriesLegendP99,
    legendMedian: timeseriesLegendMedian,
  },
  aggregation: {
    total: aggregationTotal,
    pie: aggregationToPie,
    legend: aggregationLegend,
  },
}
