import { useEffect, useState, useCallback } from 'react'

const useCurrentMinute = ({
  onChange,
}: {
  onChange?: (minute: number) => void
} = {}): number => {
  const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes())

  const handleCheckMinute = useCallback(() => {
    const minute = new Date().getMinutes()
    if (minute !== currentMinute) {
      setCurrentMinute(minute)
      if (onChange) onChange(minute)
    }
  }, [currentMinute])

  useEffect(() => {
    const interval = setInterval(handleCheckMinute, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [handleCheckMinute])

  return currentMinute
}

export default useCurrentMinute
