import { NavLink, useMatch } from 'react-router-dom'

import { NavigationItemI } from '@/config/types'
import { useClientStore } from '@/stores/client.store'
import { cn } from '@/utils/cn'
import { logEvent } from '@/utils/firebaseAnalytics'

interface PropsI extends NavigationItemI {
  className?: string
}

const NavigationItem = ({
  label,
  icon: Icon,
  url,
  external,
  badge,
  badgeClassName,
  className,
  disabled,
}: PropsI) => {
  const client = useClientStore((state) => state.client)
  const urlWithClient = `/${client}` + url

  const isActive = !!useMatch({ path: urlWithClient, end: true })

  if (external) {
    return (
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        className={cn(
          'text-text-secondary hover:text-text-primary hover:bg-neutral-dimmed group flex items-center px-2 py-2 text-base xl:text-sm font-medium rounded-md outline-none focus:ring-2 focus:ring-elements-primary-main',
          className,
          { 'pointer-events-none': disabled },
        )}
        onClick={() => logEvent('select_external', { externalUrl: url })}
      >
        {Icon && (
          <Icon className='w-6 h-6 mr-3 group-hover:text-text-tertiary text-text-dimmed' />
        )}
        {label}
      </a>
    )
  } else {
    return (
      <NavLink
        to={urlWithClient}
        className={cn(
          'group flex items-center justify-between px-2 py-2 text-base xl:text-sm font-medium rounded-md outline-none focus:ring-2 focus:ring-elements-primary-main',
          {
            'bg-neutral xl:bg-neutral-shadow text-text-primary hover:bg-neutral xl:hover:bg-neutral-shadow':
              isActive,
            'text-text-secondary hover:text-text-primary hover:bg-neutral-dimmed':
              !isActive,
          },
          className,
          { 'pointer-events-none': disabled },
        )}
      >
        <span className='inline-flex items-center'>
          {Icon && (
            <Icon
              className={cn('group-hover:text-text-tertiary mr-3 h-6 w-6', {
                'text-text-tertiary': isActive,
                'text-text-dimmed': !isActive,
              })}
            />
          )}
          {label}
        </span>
        {!!badge && (
          <span
            className={cn(
              'rounded-full text-xs py-1 px-2.5 text-text-secondary bg-neutral-shadow-heavy',
              badgeClassName,
            )}
          >
            {badge}
          </span>
        )}
      </NavLink>
    )
  }
}

export default NavigationItem
