import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ClientNotAuthorized from '@/components/ClientPages/ClientNotAuthorized'
import NoAuthorizedClientsPage from '@/components/ClientPages/NoAuthorizedClients'
import { CLIENT_DEFAULT_TIMEZONES, FEATURE_FLAGS } from '@/config/constants'
import { ClientT } from '@/config/types'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import useSettings from '@/hooks/useSettings'
import { useClientStore } from '@/stores/client.store'
import { useSharedChartStore } from '@/stores/shared-chart.store'
import { useTimeStore } from '@/stores/time.store'
import { useUserStore } from '@/stores/user.store'
import { setUserProperties } from '@/utils/firebaseAnalytics'

const ClientAuthorization = ({
  children,
}: {
  children: React.ReactElement
}): React.ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const { appSettings } = useSettings()
  const supportedClients = appSettings.clients

  const setTimezone = useTimeStore((state) => state.setTimezone)
  const client = useClientStore((state) => state.client)
  const setClient = useClientStore((state) => state.setClient)

  const authorizedClients = useUserStore((state) => state.authorizedClients)

  const setSharedZoomInfo = useSharedChartStore(
    (state) => state.setSharedZoomInfo,
  )

  const clientParam = location.pathname
    .split('/')
    .slice(1, 2)
    .join('') as ClientT

  React.useEffect(() => {
    // Redirects/URL synchronsiation within this useEffect
    const clientParamIsSupported = supportedClients.includes(clientParam)

    if (!clientParamIsSupported) {
      // We're going to make an assumption here that this is a legacy URL,
      // (e.g. /content/all-events, and try to provide some backwards compatibility
      // OR, no client is provided (e.g. '/')
      navigate(`/${client}${location.pathname}`)
    } else {
      if (clientParam && client !== clientParam) {
        // Synchronise the clientParam with the client store
        setClient(clientParam)
        // Change the dashboard timezone to the default client timezone
        setTimezone(CLIENT_DEFAULT_TIMEZONES[clientParam])
        // Reset sharedChartZoomInfo when client changes
        setSharedZoomInfo(null, null)
      }
    }

    return
  }, [clientParam, client, supportedClients])

  React.useEffect(() => {
    setUserProperties({ insightsDashboardClient: client })
  }, [client])

  // Authorization Code
  if (authorizedClients.length === 0) {
    return <NoAuthorizedClientsPage />
  }

  // NOW Gold
  const { getFeatureFlag } = useFeatureFlags()
  const nowGoldEnabled = getFeatureFlag(FEATURE_FLAGS.FF_NOW_GOLD_ENABLED)

  if (!authorizedClients.includes(client) && !nowGoldEnabled) {
    return <ClientNotAuthorized />
  }

  return children
}

export default ClientAuthorization
