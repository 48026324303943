import { cn } from '@/utils/cn'
import { maxBy } from 'lodash'

export function sortLegendItems(data?: MetricDatumI[]) {
  return (
    data?.sort(
      (a, b) =>
        (b.isTotal ? Infinity : b.value || 0) -
        (a.isTotal ? Infinity : a.value || 0),
    ) || []
  )
}

interface GetLegendItemColor {
  id: string
  color?: string
  isTotal?: boolean
  totalColor: string
  aggregationData?: MetricDatumI[]
  chartColors: string[]
}

export function getLegendItemColor({
  id,
  color,
  isTotal,
  totalColor,
  aggregationData,
  chartColors,
}: GetLegendItemColor) {
  if (isTotal) return totalColor
  if (color) return color

  const aggregationDataIndex = sortLegendItems(aggregationData)?.findIndex(
    (aggregationDatum) => aggregationDatum.id === id,
  )

  return chartColors[aggregationDataIndex]
}

export function getComparisonItem({
  id,
  comparisonData,
}: {
  id: string
  comparisonData?: MetricDatumI[]
}) {
  const comparisonDataPoint = comparisonData?.find(
    (comparisonDatum) => comparisonDatum.id === id,
  )
  if (!comparisonDataPoint) return null

  return {
    value: comparisonDataPoint.value || 0,
    timestamps: comparisonDataPoint.timestamps || [],
  }
}

export function formatDifference(number: number) {
  const numberFormat = new Intl.NumberFormat('en', { maximumFractionDigits: 1 })
  const absNumber = Math.abs(number)

  let formattedNumber

  if (absNumber >= 1000000) {
    formattedNumber = numberFormat.format(absNumber / 1000000) + 'M'
  } else if (absNumber >= 10000) {
    formattedNumber = numberFormat.format(absNumber / 1000) + 'k'
  } else {
    formattedNumber = numberFormat.format(absNumber)
  }

  // prepend '-' sign if original number is negative
  return (number < 0 ? '-' : '') + formattedNumber
}

export function getDifferenceClassName({
  diff,
  reverseRag,
  themeType,
}: {
  diff: number
  reverseRag?: boolean
  themeType: string
}): string {
  const red = cn(
    { 'bg-red-500/10 text-red-600': themeType === 'light' },
    { 'bg-red-500/20 text-red-400': themeType === 'dark' },
  )
  const green = cn(
    { 'bg-emerald-500/10 text-emerald-600': themeType === 'light' },
    { 'bg-emerald-500/20 text-emerald-400': themeType === 'dark' },
  )
  const neutral = 'bg-neutral text-text-secondary'

  if (diff === 0) {
    return neutral
  }

  return (diff > 0 && !reverseRag) || (diff < 0 && reverseRag) ? green : red
}

export function checkIsEmpty({
  query,
  dataKey,
}: {
  query: any
  dataKey: string
}) {
  const aggregationIsEmpty =
    !query || !query?.data || query?.data?.[dataKey]?.length === 0

  return aggregationIsEmpty
}

export function getTotalValue({
  data,
}: {
  data?: MetricDatumI[]
}): number | null {
  if (!data) return null

  const maxData = maxBy(data, 'value')
  if (!maxData) return null

  return maxData.value || 0
}
