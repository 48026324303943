import clsx from 'clsx'
import Skeleton from 'react-loading-skeleton'
import { usePopperTooltip } from 'react-popper-tooltip'
import { UseQueryResult, useQuery } from 'react-query'

import { getAvatar } from '../../utils/getUser'
import { cn } from '@/utils/cn'

type User = Pick<ADUserI, 'email' | 'givenName' | 'surname' | 'roles'> & {
  oid?: string
}

interface PropsI {
  size?: number | 'sm' | 'md' | 'lg'
  user?: User
  showTooltip?: boolean
  className?: string
}

const Avatar = ({
  user,
  avatarData,
  className,
  pixelSize,
}: {
  user: User | undefined
  avatarData: Blob | undefined
  className: string | undefined
  pixelSize: number
}) => {
  let initials

  if (user && user.givenName && user.surname) {
    initials = `${user.givenName.slice(0, 1)}${user.surname.slice(0, 1)}`
  }

  if (avatarData) {
    return (
      <img
        className={cn('rounded-full bg-neutral-shadow-heavy', className)}
        src={URL.createObjectURL(avatarData)}
        alt='User Avatar'
      />
    )
  }
  if (initials) {
    return (
      <span
        className={cn(
          'inline-flex items-center justify-center rounded-full bg-neutral-shadow',
          className,
        )}
        style={{ width: pixelSize, height: pixelSize }}
      >
        <span className='text-sm font-semibold leading-none text-text-tertiary'>
          {initials}
        </span>
      </span>
    )
  }

  return (
    <span
      className={cn(
        'inline-block overflow-hidden rounded-full bg-neutral-shadow text-text-dimmed',
        className,
      )}
      style={{ width: pixelSize, height: pixelSize }}
    >
      <svg
        className='w-full h-full text-layer'
        fill='currentColor'
        viewBox='0 0 24 24'
      >
        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
      </svg>
    </span>
  )
}

const AvatarContainer = ({
  size = 'sm',
  user,
  showTooltip = false,
  className,
}: PropsI): JSX.Element => {
  const avatar: UseQueryResult<Blob, any> = useQuery(
    `avatar-${user?.email}`,
    () => getAvatar(user?.email || ''),
    {
      enabled: typeof user?.email === 'string',
    },
  )

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'auto' })

  let pixelSize = 36

  if (typeof size === 'number') {
    pixelSize = size
  } else if (size === 'lg') {
    pixelSize = 44
  } else if (size === 'md') {
    pixelSize = 36
  } else if (size === 'sm') {
    pixelSize = 32
  }

  if (
    (avatar.status === 'loading' || avatar.isLoading) &&
    !(avatar.data || avatar.error)
  ) {
    return (
      <Skeleton
        width={pixelSize}
        height={pixelSize}
        circle
        className={clsx(
          'ring-2 ring-neutral-dimmed-heavy ring-inset-2 h-full w-full',
          className,
        )}
      />
    )
  }
  return (
    <div
      style={{ height: pixelSize, width: pixelSize }}
      className='flex-shrink-0'
      ref={setTriggerRef}
      aria-label={`${user?.givenName} ${user?.surname}`}
    >
      <Avatar
        avatarData={avatar.data}
        className={clsx('h-full w-full object-cover', className)}
        pixelSize={pixelSize}
        user={user}
      />
      {showTooltip && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container-themed',
          })}
        >
          <p className='text-sm font-medium text-text-secondary'>
            {user?.givenName} {user?.surname}
          </p>

          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  )
}

export default AvatarContainer
