import { ClientSettingsI } from '../types'

const clientSettings: ClientSettingsI = {
  name: 'Showmax',
  key: 'showmax',
  themes: {
    light: 'showmax-light',
    dark: 'showmax-dark',
  },
  logo: {
    light: {
      app: '/images/showmax/app-logo.png',
    },
    dark: {
      app: '/images/showmax/app-logo.png',
    },
  },
  supportedStreamTypes: ['linear', 'sle', 'vod'],
  metricComparison: { enabled: true }, // TODO: Deprecate me after Metrics Explorer launch
  metricsExplorer: {
    eventTimelineDisabled: true,
    busiestDays: {
      // Using concurrent-plays instead of plays, because "busy", from platform point of view, is how many plays are happening at the same time
      metric: 'concurrent-plays',
      includeTopEvents: true,
    },
  },
}

export default clientSettings
