import { Navigate, useLocation } from 'react-router-dom'

const ReplaceRoute = ({ replace }: { replace: [string, string] }) => {
  const location = useLocation()
  const pathname = location.pathname
  const queryString = location.search
  const redirectUrl = `${pathname.replace(
    replace[0],
    replace[1],
  )}${queryString}`
  return <Navigate to={redirectUrl} />
}

export default ReplaceRoute
