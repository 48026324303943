import { Link } from 'react-router-dom'

import PageHeader from '@/components/PageHeader'
import useSettings from '@/hooks/useSettings'
import { useClientStore } from '@/stores/client.store'
import { getHomeRoute } from '@/utils/routes'

function PageNotSupportedWithinClient({
  title,
  subtitle,
  link,
  linkLabel = 'Homepage',
}: {
  title: string
  subtitle?: string
  link?: string
  linkLabel?: string
}) {
  const client = useClientStore((state) => state.client)
  const { clientSettings } = useSettings()
  const defaultLink = getHomeRoute({ client })

  return (
    <main className='flex flex-1 overflow-y-auto'>
      <div className='flex flex-col flex-1'>
        {title !== '' && (
          <div className='px-6 py-6 border-b lg:py-8 lg:px-8'>
            <PageHeader title={title} />
          </div>
        )}
        <div className='flex items-center h-full max-w-4xl px-4 py-8 mx-auto sm:px-6 lg:px-8'>
          <div className='container grid grid-cols-1 gap-6 xl:grid-cols-3'>
            <div className='flex flex-col justify-center space-y-3 xl:col-span-2'>
              <img
                src={clientSettings.logo.light.app}
                className='relative w-12 h-12 mb-2 rounded-lg'
              />
              <h2 className='text-3xl font-bold leading-tight text-text-primary'>
                Context not supported
              </h2>
              <p className='text-xl leading-normal text-text-secondary'>
                {title ? `'${title}'` : 'This page'} is not available on the{' '}
                {`${clientSettings.name}`} context
              </p>

              <p className='pb-8 text-sm leading-normal text-text-dimmed'>
                {subtitle ??
                  'Sadly, not all features are available across all contexts due to data limitations'}
              </p>

              <div>
                <Link
                  to={link || defaultLink}
                  className='inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm text-elements-primary-contrastText bg-elements-primary-main hover:bg-elements-primary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main'
                >
                  {`Back to ${linkLabel}`}
                </Link>
              </div>
            </div>
            <div className='flex justify-center'>
              <img
                alt='Fed up minion from Despicable me'
                className='mt-8 max-h-96 xl:mt-0'
                src='/images/client-not-supported.png'
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PageNotSupportedWithinClient
