import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

interface UIState {
  xmasMode: boolean
  setXmasMode: (devMode: boolean) => void
  developerMode: boolean
  setDeveloperMode: (devMode: boolean) => void
  adminMode: boolean
  setAdminMode: (devMode: boolean) => void
  dismissedGlobalAlerts: { id: number; timestamp: number }[]
  setDismissedGlobalAlerts: (
    alerts: { id: number; timestamp: number }[],
  ) => void
}

type Persist = (
  config: StateCreator<UIState>,
  options: PersistOptions<UIState>,
) => StateCreator<UIState>

export const useUiStore = create<UIState>(
  (persist as Persist)(
    (set) => ({
      xmasMode: false,
      setXmasMode: (xmasMode) => set(() => ({ xmasMode })),
      developerMode: false,
      setDeveloperMode: (developerMode) => set(() => ({ developerMode })),
      adminMode: false,
      setAdminMode: (adminMode) => set(() => ({ adminMode })),
      dismissedGlobalAlerts: [],
      setDismissedGlobalAlerts: (dismissedGlobalAlerts) =>
        set(() => ({ dismissedGlobalAlerts })),
    }),
    {
      name: 'ui',
    },
  ),
)
