import '@/styles/antPickerStyles.css'

import { DatePicker } from 'antd'
import {
  PickerProps,
  RangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import moment, { Moment } from 'moment'

import { useTimeStore } from '@/stores/time.store'
import getTimezoneLabel from '@/utils/getTimezoneLabel'

import { MIN_DATE } from '../../config/constants'

type PickerPropsWithShowIcons = any // A workaround for https://github.com/ant-design/ant-design/issues/21972

const TypeFixPicker = DatePicker as unknown as PickerPropsWithShowIcons
// const TypeFixPicker = DatePicker
const { RangePicker } = TypeFixPicker

moment.locale('en', {
  week: {
    dow: 1,
  },
})

function SwitchableDatePicker({
  isUTC,
  allowClear,
  selected,
  onChange,
  datePickerProps = {},
  selectDatesInTheFuture = false,
  disabledDate,
}: {
  isUTC?: boolean
  allowClear?: boolean
  selected: {
    dateType?: ConfigI['dateType']
    isRange?: boolean
    start: any
    end: any
  }
  onChange: (value: any) => void
  datePickerProps?: PickerProps<Moment> | RangePickerProps<Moment>
  selectDatesInTheFuture?: boolean
  disabledDate?: (current: any) => boolean
}) {
  const { dateType, isRange, start, end } = selected
  const contextTimezone = useTimeStore((state) => state.timezone)
  const timezone = isUTC ? 'UTC' : contextTimezone

  const handleChange = (date: any) => {
    let _start, _end
    if (date) {
      if (isRange) {
        if (dateType === 'datetime') {
          _start = date[0].clone()
          _end = date[1].clone()
        } else {
          _start = date[0].clone().tz(timezone).startOf(dateType)
          _end = date[1].clone().tz(timezone).endOf(dateType)
        }
      } else {
        _start = date.clone().tz(timezone).startOf(dateType)
        _end = date.clone().tz(timezone).endOf(dateType)
      }
    }

    onChange({
      ...selected,
      start: _start?.utc().format(),
      end: _end?.utc().format(),
    })
  }

  function disabledDateDefault(current: any) {
    // Can not select days in the future
    if (selectDatesInTheFuture) {
      return current && current.valueOf() < MIN_DATE
    } else {
      return (
        (current && current.valueOf() > Date.now()) ||
        (current && current.valueOf() < MIN_DATE)
      )
    }
  }

  return (
    <>
      {isRange ? (
        <RangePicker
          allowClear={false}
          className='w-full shadow-sm'
          value={[moment(start).tz(timezone), moment(end).tz(timezone)]}
          disabledDate={disabledDate ?? disabledDateDefault}
          picker={
            dateType === 'datetime' || dateType === 'relative'
              ? 'date'
              : dateType
          }
          showTime={dateType === 'datetime'}
          onChange={handleChange}
          format={`DD/MM/YY HH:mm [${getTimezoneLabel(start, timezone)}]`}
          suffixIcon={null}
          {...(datePickerProps as RangePickerProps<Moment>)}
        />
      ) : (
        <TypeFixPicker
          allowClear={allowClear}
          className='w-full shadow-sm'
          value={start ? moment(start).tz(timezone) : null} // Allows placeholder
          disabledDate={disabledDate ?? disabledDateDefault}
          picker={
            dateType === 'datetime' || dateType === 'relative'
              ? 'date'
              : dateType
          }
          format={
            start
              ? `DD/MM/YY HH:mm [${getTimezoneLabel(start, timezone)}]`
              : 'DD/MM/YY HH:mm'
          }
          showTime={dateType === 'datetime'}
          onChange={handleChange}
          {...(datePickerProps as PickerProps<Moment>)}
        />
      )}
    </>
  )
}

export default SwitchableDatePicker
