import clsx from 'clsx'
import { startCase } from 'lodash'

import {
  ClearRefinements,
  RangeSlider,
  RefinementList,
} from '@/components/InstantSearch'
import { formOptions } from '@/config/formOptions'
import { FormOptionI, InstantSearchSettingsI } from '@/config/types'

export interface InstantSearchFilterPanelPropsI {
  settings: InstantSearchSettingsI
  forceSlideover?: boolean
  classNames?: { [key: string]: any }
}

const InstantSearchFilterPanel = ({
  settings,
  forceSlideover,
  classNames,
}: InstantSearchFilterPanelPropsI) => {
  return (
    <aside
      className={clsx(
        'relative',
        classNames?.container ??
          'px-3 pt-8 pb-16 overflow-y-auto bg-neutral-dimmed-heavy 2xl:bg-neutral',
        forceSlideover
          ? 'border-l border-border-main '
          : '2xl:border-l 2xl:border-border-main 2xl:w-80',
      )}
    >
      <ClearRefinements className='fixed bottom-0 right-0 z-10 w-screen max-w-md p-4 border-t border-l border-border-main 2xl:w-80 bg-neutral-dimmed-heavy/70 2xl:bg-neutral/70 backdrop-blur-2xl' />
      <ul className='flex flex-col gap-y-4'>
        {settings.form.map((formGroup) => {
          if (formGroup.type === 'refinementList') {
            return (
              <RefinementList
                key={formGroup.label}
                formGroup={formGroup}
                transformItems={(items: any) =>
                  items.map((item: any) => {
                    let matchingFormOption: FormOptionI = [item.value, {}]

                    if (formGroup.formGroupValue) {
                      if (formOptions[formGroup.formGroupValue]?.[item.label])
                        matchingFormOption =
                          formOptions[formGroup.formGroupValue]?.[item.label]
                    }

                    return {
                      ...item,
                      label: startCase(item.label),
                      ...(matchingFormOption && matchingFormOption[1]
                        ? { ...matchingFormOption[1] }
                        : {}),
                    }
                  })
                }
                {...formGroup.refinementListProps}
              />
            )
          }
          if (formGroup.type === 'rangeSlider') {
            return (
              <RangeSlider
                key={formGroup.label}
                formGroup={formGroup}
                {...formGroup.rangeSliderProps}
              />
            )
          }
          return null
        })}
      </ul>
    </aside>
  )
}

export default InstantSearchFilterPanel
