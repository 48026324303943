function getChannelLogo(
  meta?: Conviva2EventI['meta'] | ConvivaEventI['meta'],
  territories?: TerritoryT[],
) {
  if (!meta) return null

  const { channelName = '', channelSid, imageUrl = '' } = meta

  const territory = territories ? territories[0] : ''

  if (!territory || !territories || !channelName) return null

  const channelIdentifier = `${channelSid}${channelName
    .replace(/\s/g, '')
    .replace("'", '')
    .replace('.', '')
    .replace('skyspfootball', 'skyspfball') // might remove
    .replace('skyspfootballhd', 'skyspfballhd') // might remove
    .toLowerCase()}` //Add SID followed by lowercase squashed channel name
    // Extra replacements for Upcoming Events page
    .replace('skysportsmainevent', 'skyspmainev')
    .replace('skysportsfootball', 'skyspfball')

  if (
    territories.includes('uk') ||
    territories.includes('roi') ||
    territory === 'uk' ||
    territory === 'roi'
  ) {
    return `https://images.metadata.sky.com/pd-logo/skychb_${channelIdentifier}/LOGO_NON_PADDED/200`
  }
  if (territories.includes('it') || territory === 'it') {
    return `https://it.imageservice.sky.com//logo/skychb_${channelIdentifier}/LOGO_NON_PADDED/200`
  }
  if (territories.includes('at') || territory === 'at') {
    return `https://at.imageservice.sky.com//logo/skychb_${channelIdentifier}/LOGO_NON_PADDED/200`
  }
  if (territories.includes('de') || territory === 'de') {
    return `https://de.imageservice.sky.com//logo/skychb_${channelIdentifier}/LOGO_NON_PADDED/200`
  }
  if (territories.includes('us') || territory === 'us') {
    const sidQueryParam =
      imageUrl.indexOf('&sid') !== -1
        ? imageUrl.substring(imageUrl.indexOf('&sid'))
        : ''

    if (!sidQueryParam) return null
    return `https://imageservice.disco.peacocktv.com/logo/skychb_${sidQueryParam.replace(
      '&sid=',
      '',
    )}_darknow/AGG_SOURCE/200/200?proposition=NBCUOTT&territory=US`
  }

  return null
}

export default getChannelLogo
