import { VideoFormatConfig } from '@/config/types'

const getVideoFormatConfig = (theme: ThemeI): VideoFormatConfig => {
  return {
    total: {
      name: 'total',
      label: 'Total',
      color: theme.colors['elements-primary-main'],
    },
    sd: {
      name: 'sd',
      label: 'SD',
      color: theme.chartColors[2],
    },
    hd: {
      name: 'hd',
      label: 'HD',
      color: theme.chartColors[4],
    },
    uhd: {
      name: 'uhd',
      label: 'UHD',
      color: theme.chartColors[1],
    },
    unknown: {
      name: 'unknown',
      label: 'Unknown',
      color: theme.chartColors[0],
    },
  }
}

export default getVideoFormatConfig
