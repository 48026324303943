import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreLoadBalancerConfig from './common/prometheusCoreLoadBalancer'

const openConnections: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreLoadBalancerConfig =
    commonPrometheusCoreLoadBalancerConfig()

  return {
    ...prometheusCoreLoadBalancerConfig,
    value: 'open-connections',
    label: 'Open Connections',
    shortLabel: 'Open Con.',
    dataKey: 'openConnections',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Open Connections refers to the number of active connections that are currently established between the load balancer and the backend servers. The term is commonly used to assess the load and performance of the servers in a server pool or cluster.',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusCoreLoadBalancerConfig.defaultConfig,
      metric: 'open-connections',
    },
  }
}

export default openConnections
