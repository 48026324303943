import { startCase } from 'lodash'

import Select from '@/components/Select'
import { FormOptionI } from '@/config/types'

import FormButtonGroup from './FormButtonGroup'

export const remapOptions = (options: FormOptionI[] = []) => {
  return options
    .filter(([value]) => value !== 'all')
    .map(([value, config]) => {
      return {
        value,
        label: config?.label || startCase(value),
        ...config,
      }
    })
}

interface PropsI {
  type?: 'button-group' | 'select' | 'hidden'
  name: string
  options: FormOptionI[]
  multi?: boolean
  selected: string | string[]
  handleUpdateConfig: (newValue: any, delay?: any) => void
}

const FormOptions = ({ type = 'button-group', ...props }: PropsI) => {
  if (type === 'button-group') {
    return (
      <div className='-mx-1'>
        <FormButtonGroup {...props} />
      </div>
    )
  } else if (type === 'select') {
    const selected =
      typeof props.selected === 'string' ? [props.selected] : props.selected

    return (
      <div className=''>
        <Select
          isMulti
          {...props}
          overrideStyles={{
            placeholder: { fontSize: '0.875rem', lineHeight: '1.25rem' },
            input: {
              // paddingLeft: '0.75rem',
              // paddingRight: '2.5rem',
              // paddingTop: '0.5rem',
              // paddingBottom: '0.5rem',
            },
            control: {
              paddingTop: '1px',
              paddingBottom: '1px',
              borderRadius: '0.375rem',
            },
          }}
          options={remapOptions(props.options)}
          value={remapOptions(props.options)
            .filter((option) => {
              return (
                selected?.findIndex(
                  (selectedOption) => selectedOption === option.value,
                ) !== -1
              )
            })
            .map((option) => option.value)}
          defaultValue={remapOptions(props.options).filter((option) => {
            return (
              selected?.findIndex((selectedOption) => {
                return selectedOption === option.value
              }) !== -1
            )
          })}
          onChange={(value: string | string[]) => {
            props.handleUpdateConfig(value)
          }}
          placeholder={`Filter by ${startCase(props.name)}...`}
          closeMenuOnSelect={false}
          isOptionSelected={(option: any) => {
            const isSelected =
              selected?.findIndex((_option: any) => {
                return JSON.stringify(option) === JSON.stringify(_option)
              }) !== -1
            return isSelected
          }}
        />
      </div>
    )
  } else {
    return <></>
  }
}

export default FormOptions
