import {
  CLIENT_DEFAULT_TIMEZONES,
  CONVIVA_2_V4_API_DOMAIN,
  DEFAULT_DATE,
  PEACOCK_API_DOMAIN,
} from '@/config/constants'
import {
  DEVICE,
  DEVICE_TYPE,
  ISP,
  OPERATING_SYSTEM,
  PACKAGE,
  SPLIT_BY,
  STATE,
  STREAM_TYPE,
} from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI, VariantsT } from '@/config/types'

const commonConvivaConfig = ({ variants }: { variants?: VariantsT }) => {
  const withClient = variants?.withClient || false

  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      options: [
        SPLIT_BY['stream-type'],
        SPLIT_BY['device-type'],
        SPLIT_BY['device'],
        SPLIT_BY['operating-system'],
        SPLIT_BY['package'],
        SPLIT_BY['state'],
        SPLIT_BY['isp'],
      ],
    },
    {
      value: 'stream-type',
      type: 'button-group',
      multi: true,
      options: [
        STREAM_TYPE['all'],
        STREAM_TYPE['vod'],
        STREAM_TYPE['linear'],
        STREAM_TYPE['sle'],
        STREAM_TYPE['trailers'],
        STREAM_TYPE['clips'],
      ],
    },
    {
      value: 'device-type',
      type: 'button-group',
      multi: true,
      options: [
        DEVICE_TYPE['all'],
        DEVICE_TYPE['stb'],
        DEVICE_TYPE['mobile'],
        DEVICE_TYPE['smart-tv'],
        DEVICE_TYPE['console'],
        DEVICE_TYPE['web'],
        DEVICE_TYPE['streaming-stick'],
      ],
      unsupportedFilters: { 'split-by': ['state', 'isp'] },
    },
    {
      value: 'device',
      type: 'button-group',
      multi: true,
      options: Object.values(DEVICE),
      unsupportedFilters: { 'split-by': ['state', 'isp'] },
    },
    {
      value: 'operating-system',
      type: 'button-group',
      multi: true,
      options: [
        OPERATING_SYSTEM['all'],
        OPERATING_SYSTEM['amazon-fire'],
        OPERATING_SYSTEM['android'],
        OPERATING_SYSTEM['android-tv'],
        OPERATING_SYSTEM['apple-tv'],
        OPERATING_SYSTEM['chromecast'],
        OPERATING_SYSTEM['cox'],
        OPERATING_SYSTEM['desktop'],
        OPERATING_SYSTEM['fire-os'],
        OPERATING_SYSTEM['ios'],
        OPERATING_SYSTEM['lg-tv'],
        OPERATING_SYSTEM['linux'],
        OPERATING_SYSTEM['playstation'],
        OPERATING_SYSTEM['rdk'],
        OPERATING_SYSTEM['roku'],
        OPERATING_SYSTEM['samsung-tv'],
        OPERATING_SYSTEM['tizen'],
        OPERATING_SYSTEM['tv-os'],
        OPERATING_SYSTEM['vidaa-tv'],
        OPERATING_SYSTEM['vizio'],
        OPERATING_SYSTEM['web'],
        OPERATING_SYSTEM['web-os'],
        OPERATING_SYSTEM['xbox'],
        OPERATING_SYSTEM['xclass-tv'],
        OPERATING_SYSTEM['xfinity'],
        OPERATING_SYSTEM['youview'],
      ],
      unsupportedFilters: { 'split-by': ['state', 'isp'] },
    },
    {
      value: 'package',
      type: 'button-group',
      multi: true,
      options: [
        PACKAGE['all'],
        PACKAGE['free'],
        PACKAGE['premium'],
        PACKAGE['prem-plus'],
      ],
      unsupportedFilters: { 'split-by': ['state', 'isp'] },
    },

    {
      value: 'state',
      type: 'button-group',
      multi: true,
      options: Object.values(STATE),
      unsupportedFilters: {
        'split-by': [
          'stream-type',
          'device',
          'device-type',
          'operating-system',
          'channel',
          'isp',
        ],
      },
    },
    {
      value: 'isp',
      label: 'ISP',
      type: 'button-group',
      multi: true,
      options: Object.values(ISP),
      unsupportedFilters: {
        'split-by': [
          'stream-type',
          'device',
          'device-type',
          'operating-system',
          'channel',
          'state',
        ],
      },
    },
  ]

  return {
    dataSource: 'conviva',
    legacyGroup: 'conviva',
    group: GROUPS.CONVIVA,
    apiDomain: withClient ? CONVIVA_2_V4_API_DOMAIN : PEACOCK_API_DOMAIN,
    supportsClient: true,
    timezone: CLIENT_DEFAULT_TIMEZONES['peacock'],
    useTimezone: !!withClient,
    tags: {
      enabled: !!withClient,
      endpoint: '/tags',
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonMinuteGranularity,
    defaultConfig: {
      'split-by': 'stream-type',
      'stream-type': ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      package: ['all'],
      proposition: ['peacock'],
      state: ['all'],
      isp: ['all'],
      ...DEFAULT_DATE('peacock'),
      ...(withClient ? { client: 'peacock' } : {}),
    },
  }
}
export default commonConvivaConfig
