import { HiOutlineUserAdd, HiUserAdd } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '../../../types'
import commonSdpConfig from './common/sdp'

const signUps: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const sdpConfig = commonSdpConfig({ variants })

  return {
    ...sdpConfig,
    value: 'sign-ups',
    label: 'Profiles Created',
    labelDisclaimer: `Previously known as 'Sign Ups'`,
    shortLabel: 'Profiles Created',
    dataKey: 'signUps',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlineUserAdd,
    solidIcon: HiUserAdd,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: `The Profiles Created metric reflects the number of new profiles established within a specific time frame. Formerly labeled as 'Sign Ups,' this metric has been renamed to be more accurate with its description. Please take note that the presented data also encompasses trialists.`,
    aggregationType: 'total',
    defaultConfig: {
      ...sdpConfig.defaultConfig,
      metric: 'sign-ups',
    },
  }
}

export default signUps
