import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreConfig from './common/prometheusCore'

const utilisedNodes: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    hideInMetricsExplorer: true,
    value: 'utilised-nodes',
    label: 'Utilised Nodes',
    shortLabel: 'Utilised Nodes',
    dataKey: 'utilisedNodes',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Utilised Nodes in the context of a computer system or network refers to the number of nodes or computing devices that are actively performing tasks or processing data within the core infrastructure. Utilised Nodes is relevant for monitoring and managing resources effectively, optimising performance, and ensuring that computing resources are efficiently utilised for the tasks at hand.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'utilised-nodes',
    },
  }
}

export default utilisedNodes
