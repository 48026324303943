import HighchartsReact from 'highcharts-react-official'
import Highcharts, { SeriesOptionsType } from 'highcharts/highstock'
import moment from 'moment'
import React from 'react'

import SharedHighchartsReact from '@/components/Chart/SharedHighchartsReact'
import useTheme from '@/hooks/useTheme'
import useWindowSize from '@/hooks/useWindowSize'
import { useTimeStore } from '@/stores/time.store'

const getDataFromRange = (
  range: [number, number] | [] = [],
  interval = 8,
): SeriesOptionsType[] => {
  const [from, to] = range
  const _from = Number(moment(from).clone().format('x'))
  const _to = Number(moment(to).clone().format('x'))

  const differenceInMs = _to - _from
  const stepInMs = differenceInMs / interval

  const data = []

  for (let i = 0; i <= interval; i++) {
    data.push([Math.floor(_from + stepInMs * i), 1])
  }

  return [
    {
      name: 'xAxis',
      type: 'line',
      data,
    },
  ]
}

const styledChart = (chartOptions: Highcharts.Options, colors: any) => {
  const xAxis = chartOptions.xAxis as Highcharts.YAxisOptions[]
  const yAxis = chartOptions.yAxis as Highcharts.YAxisOptions[]
  const styledChart: Highcharts.Options = {
    ...chartOptions,
    chart: {
      ...chartOptions?.chart,
      style: {
        ...chartOptions?.chart?.style,
        fontFamily: 'inherit',
      },
    },
    xAxis: [
      {
        ...xAxis[0],
        lineColor: colors['divider-main'],
        tickColor: colors['divider-shadow'],
        crosshair: {
          ...(xAxis[0]?.crosshair as Highcharts.AxisCrosshairOptions),
          color: colors['divider-shadow'],
        },

        labels: {
          ...(xAxis[0]?.labels as Highcharts.XAxisLabelsOptions),
          style: {
            ...xAxis[0]?.labels?.style,
            color: colors['text-dimmed'],
            fontSize: '0.75rem',
          },
        },
      },
    ],
    yAxis: [
      {
        ...yAxis[0],
        gridLineColor: colors['divider-main'],
        lineColor: colors['divider-main'],
        tickColor: colors['divider-shadow'],
        labels: {
          ...yAxis[0]?.labels,
          style: {
            ...yAxis[0]?.labels?.style,
            color: colors['text-dimmed'],
            fontSize: '0.75rem',
          },
        },
      },
    ],
  }
  return styledChart
}

const XAxis = ({
  range,
  interval = 8,
  spacingLeft = 0,
  spacingRight = 0,
  spacingTop = 0,
  spacingBottom = 12,
  useSharedChart = false,
  height = 28,
}: {
  range: [number, number]
  interval: number
  spacingLeft?: number
  spacingRight?: number
  spacingTop?: number
  spacingBottom?: number
  useSharedChart?: boolean
  height?: number
}) => {
  const { theme } = useTheme()!
  const chartComponent = React.useRef<any>(null)
  const timezone = useTimeStore((state) => state.timezone)
  const { width } = useWindowSize()

  const [chartOptions, setChartOptions] = React.useState(() => {
    const defaultChart: Highcharts.Options = {
      chart: {
        type: 'line',
        height,
        animation: false,
        reflow: true,
        spacingLeft: spacingLeft,
        spacingRight: spacingRight,
        spacingBottom: spacingBottom,
        spacingTop: spacingTop,
        backgroundColor: 'transparent',
      },
      legend: {
        enabled: false,
      },
      xAxis: [
        {
          type: 'datetime',
          startOnTick: false,
          endOnTick: false,
          labels: {
            style: {
              whiteSpace: 'nowrap',
            },
          },
        },
      ],
      series: getDataFromRange(range, interval),
      credits: {
        enabled: false,
      },
      time: {
        timezone,
      },
      rangeSelector: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        shared: true,
        split: false,
        useHTML: true,
        pointFormat: '',
        headerFormat:
          '<div class="px-3 py-2 shadow-md bg-neutral-dimmed-heavy rounded-md border border-border-main">' +
          '<p class="font-sans text-xs font-medium text-text-tertiary">{point.key}' +
          '</p>' +
          '</div>',
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        shadow: false,
      },

      yAxis: [
        {
          visible: false,
          opposite: false,
        },
      ],
      plotOptions: {
        line: {
          lineWidth: 0,
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
    }

    return styledChart(defaultChart, theme?.colors)
  })

  React.useEffect(() => {
    setChartOptions((chartOptions) => {
      return {
        ...chartOptions,
        series: getDataFromRange(range, interval),
      }
    })
  }, [range[0], range[1], interval])

  React.useEffect(() => {
    setChartOptions((chartOptions) => {
      return {
        ...chartOptions,
        time: {
          timezone,
        },
      }
    })
  }, [timezone])

  React.useEffect(() => {
    setChartOptions((chartOptions) => styledChart(chartOptions, theme?.colors))
  }, [theme])

  React.useEffect(() => {
    if (chartComponent?.current) {
      chartComponent.current.chart.reflow()
    }
  }, [width])

  const HighchartsComponent = useSharedChart
    ? SharedHighchartsReact
    : HighchartsReact

  return (
    <div className='w-full h-full'>
      <HighchartsComponent
        ref={chartComponent}
        immutable
        constructorType='stockChart'
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{
          style: { height: '100%', width: '100%' },
        }}
        updateArgs={[true, true, true]} // redraw, oneToOne, animation
      />
    </div>
  )
}

export default XAxis
