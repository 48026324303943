import { Fragment } from 'react'
import { HiOutlineX } from 'react-icons/hi'

import { Dialog, Transition } from '@headlessui/react'
import { Drawer } from '@mui/material'

export type SlideOverTypeT = 'headlessui' | 'mui'

interface SlideOverPropsI {
  type?: SlideOverTypeT
  children: React.ReactElement
  title?: string
  open: boolean
  onClose: () => void
}

export default function SlideOver({
  type = 'headlessui',
  children,
  title = '',
  open = true,
  onClose,
}: SlideOverPropsI) {
  if (type === 'headlessui') {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 z-50 overflow-hidden'
          open={open}
          onClose={onClose}
        >
          <div className='absolute inset-0 overflow-hidden'>
            <Dialog.Overlay className='absolute inset-0 bg-black bg-opacity-20' />

            <div className='fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='w-screen max-w-md'>
                  <div className='flex flex-col h-full overflow-y-auto shadow-xl bg-neutral-dimmed-heavy'>
                    <div className='px-6 py-3'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-lg font-medium text-text-primary'>
                          {title}
                        </Dialog.Title>
                        <div className='flex items-center ml-3 h-7'>
                          <button
                            className='rounded-md text-text-dimmed bg-neutral-dimmed-heavy hover:text-text-tertiary focus-visible:ring-2 focus-visible:ring-elements-primary-main'
                            onClick={onClose}
                          >
                            <span className='sr-only'>Close panel</span>
                            <HiOutlineX
                              className='w-6 h-6'
                              aria-hidden='true'
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='flex-1 border-t border-divider-main'>
                      {children}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  if (type === 'mui') {
    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
        variant='temporary'
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className='w-screen h-full max-w-md'>
          <div className='flex flex-col h-full overflow-y-scroll shadow-xl bg-neutral-dimmed-heavy'>
            <div className='p-6'>
              <div className='flex items-start justify-between'>
                <h2 className='text-lg font-medium text-text-primary'>
                  {title}
                </h2>
                <div className='flex items-center ml-3 h-7'>
                  <button
                    className='rounded-md text-text-dimmed bg-neutral-dimmed-heavy hover:text-text-tertiary focus-visible:ring-2 focus-visible:ring-elements-primary-main'
                    onClick={onClose}
                  >
                    <span className='sr-only'>Close panel</span>
                    <HiOutlineX className='w-6 h-6' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
            <div className='flex-1 border-t border-divider-main'>
              {children}
            </div>
          </div>
        </div>
      </Drawer>
    )
  }
  return null
}
