import create from 'zustand'

import { ClientT, FeatureFlagsT } from '@/config/types'

interface UserState {
  user: ADUserI
  setUser: (user: ADUserI) => void
  roles: string[]
  setRoles: (roles: string[]) => void
  authorizedClients: ClientT[]
  setAuthorizedClients: (authorizedClients: ClientT[]) => void
  featureFlags: FeatureFlagsT
  setFeatureFlags: (featureFlags: FeatureFlagsT) => void
}

export const useUserStore = create<UserState>((set) => ({
  user: {} as ADUserI,
  setUser: (user) => set(() => ({ user })),
  roles: [],
  setRoles: (roles) => set(() => ({ roles })),
  authorizedClients: [],
  setAuthorizedClients: (authorizedClients) =>
    set(() => ({ authorizedClients })),
  featureFlags: {},
  setFeatureFlags: (featureFlags) => set(() => ({ featureFlags })),
}))
