import { CgSpinner } from 'react-icons/cg'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonOcellusConfig from './common/ocellus'

const connectionInducedRebufferingRatioOcellus: MetricSettingsFn = ({
  variants,
  featureFlags,
}): MetricSettingsI => {
  const ocellusConfig = commonOcellusConfig({ variants, featureFlags })

  const withClient = variants?.withClient || false

  return {
    ...ocellusConfig,
    value: 'connection-induced-rebuffering-ratio-ocellus',
    apiParam: 'connection-induced-rebuffering-ratio',
    label: 'Connection Induced Rebuffering',
    shortLabel: 'CIRR',
    dataKey: 'connectionInducedRebufferingRatio',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: CgSpinner,
    solidIcon: CgSpinner,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value * (withClient ? 1 : 100)),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value * (withClient ? 1 : 100)),
    info: 'Connection Induced Rebuffering Ratio measures the percentage of video sessions that encounter rebuffering solely due to network conditions, excluding rebuffering caused by user interactions or other factors. CIRR helps assess the quality of the streaming experience by focusing specifically on issues related to the network connection.',
    aggregationType: 'average',
    invertedChangeColor: true,
    ragThresholds: {
      green: 0,
      amber: 0.2,
      red: 0.4,
    },
    defaultConfig: {
      ...ocellusConfig.defaultConfig,
      metric: 'connection-induced-rebuffering-ratio-ocellus',
    },
  }
}

export default connectionInducedRebufferingRatioOcellus
