import { ClientSettingsI } from '../types'

const clientSettings: ClientSettingsI = {
  name: 'Global DTC',
  key: 'nbcu-dtc',
  themes: {
    light: 'nbcu-dtc-light',
    dark: 'nbcu-dtc-dark',
  },
  logo: {
    light: {
      app: '/images/nbcu-dtc/app-logo.png',
    },
    dark: {
      app: '/images/nbcu-dtc/app-logo.png',
    },
  },
  supportedStreamTypes: ['linear', 'sle', 'vod'],
  metricComparison: { enabled: true }, // TODO: Deprecate me after Metrics Explorer launch
  metricsExplorer: {
    busiestDays: {
      metric: 'concurrent-plays',
      includeTopEvents: true,
    },
  },
}

export default clientSettings
