import {
  CLIENT_DEFAULT_TIMEZONES,
  DEFAULT_DATE,
  PEACOCK_SDP_API_DOMAIN,
} from '@/config/constants'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { VariantsT } from '@/config/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonPeacockSdpConfig = ({ variants }: { variants?: VariantsT }) => {
  return {
    dataSource: 'SDP',
    legacyGroup: 'sdp',
    group: GROUPS.SDP,
    apiDomain: PEACOCK_SDP_API_DOMAIN,
    supportsClient: false,
    timezone: CLIENT_DEFAULT_TIMEZONES['peacock'],
    useTimezone: true,
    formatter: convivaStyleDataFormatters,
    form: [],
    granularity: commonFiveMinuteGranularity,
    defaultConfig: {
      ...DEFAULT_DATE('peacock'),
      proposition: ['peacock'],
    },
  }
}

export default commonPeacockSdpConfig
