import {
  EventsSettingsI,
  MetricSettingsI,
  ReportSettingsI,
  SettingsI,
} from '@/config/types'

import queryString from 'query-string'

function getMetricSettings(
  metric: string,
  metrics: (MetricSettingsI | SettingsI | EventsSettingsI | ReportSettingsI)[],
): MetricSettingsI | SettingsI | EventsSettingsI | ReportSettingsI | undefined {
  return metrics?.find(({ value }) => value === metric)
}

const getConfigFromUrl = ({
  metrics,
  isEvents,
}: {
  metrics: (MetricSettingsI | SettingsI | EventsSettingsI | ReportSettingsI)[]
  isEvents?: boolean
}) => {
  const searchString = window.location.search

  const searchParams: {
    [key: string]: any
  } = {
    ...queryString.parse(searchString, {
      arrayFormat: 'comma',
    }),
  }

  const typedMetricSettings = metrics as MetricSettingsI[]

  const metricSettings = isEvents
    ? metrics[0]
    : getMetricSettings(searchParams?.metric, typedMetricSettings)

  if (!metricSettings) {
    delete searchParams.metric
  }

  if (
    searchParams.start &&
    searchParams.end &&
    searchParams?.metric !== 'kpi' &&
    searchParams.metric !== 'weekly-viewing'
  ) {
    searchParams.dateType = 'datetime'
    searchParams.relativeDate = {
      group: 'other',
      id: 'custom',
      name: 'Custom Date Range',
    }
  }

  Object.keys(searchParams).forEach((key) => {
    // Cast to array if a multi type
    const searchParamValue = searchParams[key]

    const formOptions = metricSettings?.form?.find(({ value }) => value === key)

    if (formOptions?.multi && !Array.isArray(searchParamValue)) {
      searchParams[key] = [searchParamValue]
    }
  })

  return searchParams
}

export default getConfigFromUrl
