import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

import appSettings from '@/config/appSettings'
import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'

const defaultClient = appSettings.defaultClient
const defaultTimezone = CLIENT_DEFAULT_TIMEZONES[defaultClient]

export interface TimeStoreState {
  timezone: string
  setTimezone: (value: string) => void
}

type Persist = (
  config: StateCreator<TimeStoreState>,
  options: PersistOptions<TimeStoreState>,
) => StateCreator<TimeStoreState>

export const useTimeStore = create<TimeStoreState>(
  (persist as Persist)(
    (set) => ({
      timezone: defaultTimezone,
      setTimezone: (timezone) =>
        set(() => ({
          timezone,
        })),
    }),
    {
      name: 'timezone',
    },
  ),
)
