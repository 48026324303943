import { ThreeDots, useLoading } from '@agney/react-loading'

import clsx from 'clsx'
import useSettings from '../../hooks/useSettings'
import { useThemeStore } from '../../stores/theme.store'

const Loading = ({
  width = 84,
  lightTheme,
  includeLogo = true,
}: {
  width?: number
  lightTheme?: boolean
  includeLogo?: boolean
}) => {
  const { clientSettings } = useSettings()
  const { name, logo } = clientSettings
  const theme = useThemeStore((state) => state.theme)

  // Force Loaders to accept props. Adequate typings don't seem to exist for this package
  const TypedThreeDots = ThreeDots as any

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <div
        className={clsx({
          'text-divider-shadow': !lightTheme,
          'text-white text-opacity-50': lightTheme,
        })}
      >
        <TypedThreeDots width={width} />
      </div>
    ),
  })

  return (
    <div
      className='flex flex-col items-center justify-center'
      {...containerProps}
    >
      {includeLogo && (
        <img
          className='w-auto h-24 mx-auto mb-8 rounded-lg'
          src={logo[theme]?.app}
          alt={`${name} logo`}
        />
      )}
      {indicatorEl}
    </div>
  )
}

export default Loading
