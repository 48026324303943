import Avatar from '@/components/Avatar'
import { Menu } from '@headlessui/react'

import UserMenuDropDown from './UserMenuDropDown'

interface MobileUserMenuProps {
  user?: ADUserI
  userLoading: boolean
  logout: () => void
  theme: string
  setTheme: (theme: ThemeT) => void
}

const MobileUserMenu = ({
  user,
  userLoading,
  logout,
  theme,
  setTheme,
}: MobileUserMenuProps) => {
  return (
    <Menu as='div' className='relative ml-3'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className='flex items-center max-w-xs text-sm rounded-full hover:bg-neutral-dimmed bg-neutral-dimmed-heavy focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-4 focus-visible:ring-elements-primary-main focus-visible:ring-offset-elements-primary-main'>
              <span className='sr-only'>Open user menu</span>
              {user && (
                <Avatar
                  user={{
                    ...user,
                    email: user.userPrincipalName,
                  }}
                  size='sm'
                />
              )}
            </Menu.Button>
          </div>
          <UserMenuDropDown
            open={open}
            logout={logout}
            user={user}
            userLoading={userLoading}
            theme={theme}
            setTheme={setTheme}
            className='absolute right-0 w-56 mt-2 origin-top-right divide-y rounded-md shadow-lg bg-neutral-dimmed-heavy ring-1 ring-black ring-opacity-5 divide-divider-main focus:outline-none'
          />
        </>
      )}
    </Menu>
  )
}

export default MobileUserMenu
