import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  HiX,
  HiOutlineExclamation,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
} from 'react-icons/hi'
import clsx from 'clsx'

const getIcon = (appearance) => {
  switch (appearance) {
    case 'error':
      return HiOutlineExclamationCircle
    case 'warning':
      return HiOutlineExclamation
    case 'success':
      return HiOutlineCheckCircle
    case 'info':
    default:
      return HiOutlineInformationCircle
  }
}

const Modal = ({
  closeModal,
  title = '',
  description = '',
  icon,
  appearance = 'info',
  action = null,
  showIcon = true,
  onAction = () => false,
}) => {
  const Icon = icon || getIcon(appearance)

  return (
    <Transition.Child
      as={React.Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
      enterTo='opacity-100 translate-y-0 sm:scale-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100 translate-y-0 sm:scale-100'
      leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
    >
      <div className='inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform shadow-xl rounded-xl bg-neutral-dimmed-heavy sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
        <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
          <button
            type='button'
            className='rounded-md bg-neutral text-text-dimmed hover:text-text-tertiary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus:elements-primary-main'
            onClick={closeModal}
          >
            <span className='sr-only'>Close</span>
            <HiX className='w-6 h-6' aria-hidden='true' />
          </button>
        </div>
        <div className='sm:flex sm:items-start'>
          {showIcon && (
            <div
              className={clsx(
                'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                {
                  'bg-helpers-warning-dimmed': appearance === 'warning',
                  'bg-helpers-success-dimmed': appearance === 'success',
                  'bg-helpers-error-dimmed': appearance === 'error',
                  'bg-neutral': appearance === 'info',
                },
              )}
            >
              <Icon
                className={clsx('h-6 w-6', {
                  'text-helpers-warning-main': appearance === 'warning',
                  'text-helpers-success-main': appearance === 'success',
                  'text-helpers-error-main': appearance === 'error',
                  'text-text-tertiary': appearance === 'info',
                })}
                aria-hidden='true'
              />
            </div>
          )}
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
            <Dialog.Title
              as='h3'
              className='text-lg font-medium leading-6 text-text-primary'
            >
              {title}
            </Dialog.Title>
            <div className='mt-2'>
              <p className='text-sm text-text-tertiary'>{description}</p>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          {action && (
            <button
              type='button'
              className={clsx(
                'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-elements-primary-contrastText focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
                {
                  'bg-helpers-warning-button hover:bg-helpers-warning-button-hover focus-visible:ring-helpers-warning-button':
                    appearance === 'warning',
                  'bg-helpers-success-button hover:bg-helpers-success-button-hover focus-visible:ring-helpers-success-button':
                    appearance === 'success',
                  'bg-helpers-error-button hover:bg-helpers-error-button-hover focus-visible:ring-helpers-error-button':
                    appearance === 'error',
                  'bg-helpers-info-button hover:bg-helpers-info-button-hover focus-visible:ring-helpers-info-button':
                    appearance === 'info',
                },
              )}
              onClick={() => {
                onAction()
                closeModal()
              }}
            >
              {action}
            </button>
          )}
          <button
            type='button'
            className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium border rounded-md shadow-sm border-divider-shadow bg-neutral-dimmed-heavy hover:bg-neutral-dimmed text-text-secondary hover:text-text-tertiary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main sm:mt-0 sm:w-auto sm:text-sm'
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Transition.Child>
  )
}

export default Modal
