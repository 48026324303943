import { DEVICE_TYPE } from '@/config/formOptions'

import { CONVIVA_2_V4_API_DOMAIN } from '../../../constants'
import { FormI, WeeklyViewingReportSettingsI } from '../../../types'
import getStartAndEndConfig from './utils/getStartAndEndConfig'

const form: FormI[] = [
  {
    value: 'device-type',
    type: 'button-group',
    multi: true,
    options: [
      DEVICE_TYPE['all'],
      DEVICE_TYPE['desktop'],
      DEVICE_TYPE['mobile'],
      DEVICE_TYPE['stb'],
      DEVICE_TYPE['smart-tv'],
      DEVICE_TYPE['tv-stick'],
      DEVICE_TYPE['console'],
      DEVICE_TYPE['web'],
      DEVICE_TYPE['streaming-stick'],
    ],
  },
]

const weeklyViewing: WeeklyViewingReportSettingsI = {
  apiDomain: CONVIVA_2_V4_API_DOMAIN,
  form,
  highProfileEventsEnabled: true,
  minutesAndUniquesEnabled: false,
  defaultConfig: {
    metric: 'weekly-viewing',
    'split-by': 'device-type',
    proposition: ['all'],
    'device-type': ['all'],
    dateType: 'week',
    isRange: false,
    client: 'peacock',
    ...getStartAndEndConfig(),
  },
}

export default Object.freeze(weeklyViewing)
