import { FormOptionI } from '../types'

import { getPropositionImage, getTerritoryImage } from '@/config/formOptions'

// TODO add colors for all form options

export const propositionFormOptions: FormOptionI[] = [
  ['all', { label: 'All Propositions', color: 'darkgrey' }],
  [
    'peacock',
    {
      exclude: ['activations', 'cancellations'],
      image: getPropositionImage('peacock'),
      color: '#ffb607', // yellow
    },
  ],
  [
    'now-tv',
    {
      label: 'Now',
      image: getPropositionImage('now-tv'),
      color: '#007f85', // now green
    },
  ],
  [
    'sky-go',
    {
      exclude: ['activations', 'cancellations'],
      image: getPropositionImage('sky-go'),
      color: '#009DFF', // light blue
    },
  ],
  [
    'sky-q',
    {
      exclude: ['activations', 'cancellations'],
      image: getPropositionImage('sky-q'),
      color: '#2967EC', // dark blue
    },
  ],
  [
    'soip',
    {
      exclude: ['activations', 'cancellations', 'video-quality'],
      label: 'Sky Glass',
      image: getPropositionImage('sky-glass'),
      color: '#a4009b', // sky-glass purple
    },
  ],
  [
    'sky-stream',
    {
      exclude: ['activations', 'cancellations', 'video-quality'],
      label: 'Sky Stream',
      image: getPropositionImage('sky-stream'),
      color: '#100956', // sky-stream blue
    },
  ],
  [
    'sky-sports',
    {
      exclude: ['activations', 'cancellations', 'video-quality'],
      image: getPropositionImage('sky-sports'),
      color: '#e7001c', // sky red
    },
  ],
  [
    'sky-x',
    {
      image: getPropositionImage('sky-x'),
      color: '#8500db', // sky x purple
    },
  ],
  [
    'sky-kids',
    {
      exclude: ['activations', 'cancellations', 'video-quality'],
      image: getPropositionImage('sky-kids'),
      color: '#ff349a', // sky kids pink
    },
  ],
  [
    'sky-store',
    {
      exclude: ['events', 'activations', 'cancellations', 'video-quality'],
      image: getPropositionImage('sky-store'),
      color: '#002f80', // sky store dark blue
    },
  ],

  [
    'sky-sports-box-office',
    {
      exclude: ['events', 'activations', 'cancellations', 'video-quality'],
      image: getPropositionImage('sky-sports-box-office'),
      color: '#0284C7', // light-blue-600
    },
  ],
]

export const territoryFormOptions: FormOptionI[] = [
  ['all', { label: 'All Territories', color: 'darkgrey' }],
  [
    'uk',
    {
      label: 'UK',
      image: getTerritoryImage('uk'),
      color: '#2967EC', // dark blue
    },
  ],
  [
    'de',
    {
      label: 'Germany',
      image: getTerritoryImage('de'),
      color: '#ffb607', // yellow
    },
  ],
  [
    'it',
    {
      label: 'Italy',
      image: getTerritoryImage('it'),
      color: '#16A34A', // green
    },
  ],
  [
    'us',
    {
      exclude: ['activations', 'cancellations'],
      label: 'USA',
      image: getTerritoryImage('us'),
      color: '#ff005c', // red
    },
  ],
  [
    'roi',
    {
      label: 'ROI',
      image: getTerritoryImage('roi'),
      color: '#ff6e33', //orange
    },
  ],
  [
    'at',
    {
      label: 'Austria',
      image: getTerritoryImage('at'),
      color: '#a000f5', // Sky X Purple
    },
  ],
]

export const nowTerritories = ['all', 'uk', 'de', 'it', 'roi']

export const streamTypeFormOptions: FormOptionI[] = [
  ['all', { label: 'All Stream Types' }],
  ['vod', { label: 'VOD' }],
  ['linear', { label: 'Linear' }],
  ['trailers', { label: 'Trailers' }],
  ['sle', { label: 'SLE' }],
  ['clips', { label: 'Clips' }],
]

export const deviceTypeFormOptions: FormOptionI[] = [
  ['all', { label: 'All Device Types' }],
  ['stb', { label: 'STB' }],
  ['mobile', { label: 'Mobile' }],
  ['smart-tv', { label: 'Smart TV' }],
  ['tv-stick', { label: 'TV Stick' }],
  ['console', { label: 'Console' }],
  ['web', { label: 'Web' }],
  ['desktop', { label: 'Desktop' }],
  ['streaming-stick', { label: 'Streaming Stick' }],
]

export const operatingSystemFormOptions: FormOptionI[] = [
  ['all', { label: 'All Operating Systems' }],
  ['amazon-fire', { label: 'Amazon Fire' }],
  ['android', { label: 'Android' }],
  ['android-tv', { label: 'Android TV' }],
  ['apple-tv', { label: 'Apple TV' }],
  ['chromecast', { label: 'Chromecast' }],
  ['desktop', { label: 'Desktop' }],
  ['ios', { label: 'iOS' }],
  ['lg-tv', { label: 'LG TV' }],
  ['linux', { label: 'Linux' }],
  ['playstation', { label: 'Playstation' }],
  ['roku', { label: 'ROKU' }],
  ['samsung-tv', { label: 'Samsung TV' }],
  ['xbox', { label: 'Xbox' }],
  ['youview', { label: 'YouView' }],
  ['cox', { label: 'Cox' }],
  ['fire-os', { label: 'Fire OS' }],
  ['tizen', { label: 'Tizen' }],
  ['tv-os', { label: 'TV OS' }],
  ['vizio', { label: 'Vizio' }],
  ['web', { label: 'Web' }],
  ['web-os', { label: 'Web OS' }],
  ['xfinity', { label: 'XFinity' }],
]

export const productFormOptions: FormOptionI[] = [
  ['all', { label: 'All Products' }],
  ['sport'],
  ['hayu'],
  ['cinema'],
  ['entertainment'],
  ['boost'],
  ['other'],
]
