import { ClientT } from '../types'
import nbcuDtcRelativeDates, {
  conviva2RelativeDates as nbcuDtcConviva2RelativeDates,
  futureRelativeDates as nbcuDtcFutureRelativeDates,
} from './nbcu-dtc'
import nowRelativeDates, {
  conviva2RelativeDates as nowConviva2RelativeDates,
  futureRelativeDates as nowFutureRelativeDates,
} from './now'
import peacockRelativeDates, {
  conviva2RelativeDates as peacockConviva2RelativeDates,
  futureRelativeDates as peacockFutureRelativeDates,
} from './peacock'
import showmaxRelativeDates, {
  conviva2RelativeDates as showmaxConviva2RelativeDates,
  futureRelativeDates as showmaxFutureRelativeDates,
} from './showmax'
import skyEuropeRelativeDates, {
  conviva2RelativeDates as skyEuropeConviva2RelativeDates,
  futureRelativeDates as skyEuropeFutureRelativeDates,
} from './sky-europe'
import skyEuropeAndPeacockRelativeDates, {
  conviva2RelativeDates as skyEuropeAndPeacockConviva2RelativeDates,
  futureRelativeDates as skyEuropeAndPeacockFutureRelativeDates,
} from './sky-europe-and-peacock'
import skyshowtimeRelativeDates, {
  conviva2RelativeDates as skyshowtimeConviva2RelativeDates,
  futureRelativeDates as skyshowtimeFutureRelativeDates,
} from './skyshowtime'

export const relativeDates: Record<ClientT, RelativeDateI[]> = {
  peacock: peacockRelativeDates,
  skyshowtime: skyshowtimeRelativeDates,
  now: nowRelativeDates,
  'nbcu-dtc': nbcuDtcRelativeDates,
  'sky-europe-and-peacock': skyEuropeAndPeacockRelativeDates,
  'sky-europe': skyEuropeRelativeDates,
  showmax: showmaxRelativeDates,
  'now-gold': nowRelativeDates,
}

export const futureRelativeDates: Record<ClientT, RelativeDateI[]> = {
  peacock: peacockFutureRelativeDates,
  skyshowtime: skyshowtimeFutureRelativeDates,
  now: nowFutureRelativeDates,
  'nbcu-dtc': nbcuDtcFutureRelativeDates,
  'sky-europe-and-peacock': skyEuropeAndPeacockFutureRelativeDates,
  'sky-europe': skyEuropeFutureRelativeDates,
  showmax: showmaxFutureRelativeDates,
  'now-gold': nowFutureRelativeDates,
}

export const conviva2RelativeDates: Record<ClientT, RelativeDateI[]> = {
  peacock: peacockConviva2RelativeDates,
  skyshowtime: skyshowtimeConviva2RelativeDates,
  now: nowConviva2RelativeDates,
  'nbcu-dtc': nbcuDtcConviva2RelativeDates,
  'sky-europe-and-peacock': skyEuropeAndPeacockConviva2RelativeDates,
  'sky-europe': skyEuropeConviva2RelativeDates,
  showmax: showmaxConviva2RelativeDates,
  'now-gold': nowConviva2RelativeDates,
}

export default relativeDates
