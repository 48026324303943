import queryString from "query-string";

const createUrlFromConfig = (config, settings, includeOrigin = true) => {
  if (!config || !settings) return "";

  const _config = { ...config };

  delete _config.relativeDate;
  delete _config.dateType;

  const searchParams = {};

  const defaultConfig = settings.defaultConfig || {};

  Object.keys(_config).forEach((formGroup) => {
    if (
      formGroup === "start" ||
      formGroup === "end" ||
      formGroup === "metric" ||
      JSON.stringify(_config[formGroup]) !==
        JSON.stringify(defaultConfig[formGroup])
    ) {
      searchParams[formGroup] = _config[formGroup];
    }
  });

  const searchString = queryString.stringify(searchParams, {
    arrayFormat: 'comma',
    skipNull: true,
  })

  if (includeOrigin) {
    return `${window.location}?${searchString}`;
  } else {
    return searchString;
  }
};

export default createUrlFromConfig;
