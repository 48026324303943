import {
  BIGVIVA_API_DOMAIN,
  BIGVIVA_INTERNAL_API_DOMAIN,
  BIGVIVA_SDP_API_DOMAIN,
  BIGVIVA_SDP_INTERNAL_API_DOMAIN,
  CMDM_API_DOMAIN,
  CMDM_INTERNAL_API_DOMAIN,
  CONVIVA_2_V4_API_DOMAIN,
  CONVIVA_2_V4_INTERNAL_API_DOMAIN,
  CONVIVA_API_DOMAIN,
  CONVIVA_INTERNAL_API_DOMAIN,
  CONVIVA_PCK_API_DOMAIN,
  CONVIVA_PCK_INTERNAL_API_DOMAIN,
  CSI_API_DOMAIN,
  CSI_INTERNAL_API_DOMAIN,
  EVENT_CONFIG_API_DOMAIN,
  EVENT_CONFIG_INTERNAL_API_DOMAIN,
  NOTIFICATIONS_CONFIG_API_DOMAIN,
  NOTIFICATIONS_CONFIG_INTERNAL_API_DOMAIN,
  PEACOCK_API_DOMAIN,
  PEACOCK_INTERNAL_API_DOMAIN,
  PROMETHEUS_API_DOMAIN,
  PROMETHEUS_INTERNAL_API_DOMAIN,
  SEARCH_API_DOMAIN,
  SEARCH_INTERNAL_API_DOMAIN,
  SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN,
  SKYSHOWTIME_BIGVIVA_SDP_INTERNAL_API_DOMAIN,
  TWITTER_API_DOMAIN,
  TWITTER_INTERNAL_API_DOMAIN,
  UPCOMING_EVENTS_API_DOMAIN,
  UPCOMING_EVENTS_INTERNAL_API_DOMAIN,
} from '../config/constants'

const internaliseUrl = (url) => {
  return url
    .replace(CONVIVA_API_DOMAIN, CONVIVA_INTERNAL_API_DOMAIN)
    .replace(CONVIVA_PCK_API_DOMAIN, CONVIVA_PCK_INTERNAL_API_DOMAIN)
    .replace(PEACOCK_API_DOMAIN, PEACOCK_INTERNAL_API_DOMAIN)
    .replace(BIGVIVA_API_DOMAIN, BIGVIVA_INTERNAL_API_DOMAIN)
    .replace(BIGVIVA_SDP_API_DOMAIN, BIGVIVA_SDP_INTERNAL_API_DOMAIN)
    .replace(CONVIVA_2_V4_API_DOMAIN, CONVIVA_2_V4_INTERNAL_API_DOMAIN)
    .replace(
      SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN,
      SKYSHOWTIME_BIGVIVA_SDP_INTERNAL_API_DOMAIN,
    )
    .replace(UPCOMING_EVENTS_API_DOMAIN, UPCOMING_EVENTS_INTERNAL_API_DOMAIN)
    .replace(TWITTER_API_DOMAIN, TWITTER_INTERNAL_API_DOMAIN)
    .replace(SEARCH_API_DOMAIN, SEARCH_INTERNAL_API_DOMAIN)
    .replace(PROMETHEUS_API_DOMAIN, PROMETHEUS_INTERNAL_API_DOMAIN)
    .replace(EVENT_CONFIG_API_DOMAIN, EVENT_CONFIG_INTERNAL_API_DOMAIN)
    .replace(CSI_API_DOMAIN, CSI_INTERNAL_API_DOMAIN)
    .replace(
      NOTIFICATIONS_CONFIG_API_DOMAIN,
      NOTIFICATIONS_CONFIG_INTERNAL_API_DOMAIN,
    )
    .replace(CMDM_API_DOMAIN, CMDM_INTERNAL_API_DOMAIN)
  // .replace(SDP_API_DOMAIN, SDP_INTERNAL_API_DOMAIN) DO NOT ADD BACK IN, TOP SECRET METRIC
}

export default internaliseUrl
