import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

const DEFAULT_COMPLETE_STATE = {
  portkey: false,
  metricsExplorer: false,
}

interface InformationalDialogStoreStateI {
  complete: {
    portkey: boolean
    metricsExplorer: boolean
  }
  setComplete: (
    name: keyof typeof DEFAULT_COMPLETE_STATE,
    complete: boolean,
  ) => void
  resetComplete: () => void
}

type Persist = (
  config: StateCreator<InformationalDialogStoreStateI>,
  options: PersistOptions<InformationalDialogStoreStateI>,
) => StateCreator<InformationalDialogStoreStateI>

export const useInformationalDialogStore =
  create<InformationalDialogStoreStateI>(
    (persist as Persist)(
      (set) => ({
        complete: DEFAULT_COMPLETE_STATE,
        setComplete: (name, complete) =>
          set((state) => ({
            ...state,
            complete: {
              ...state.complete,
              [name]: complete,
            },
          })),
        resetComplete: () => set(() => ({ complete: DEFAULT_COMPLETE_STATE })),
      }),
      {
        name: 'informational-dialog',
      },
    ),
  )
