import clsx from 'clsx'
import {
  FcHighPriority,
  FcLock,
  FcOpenedFolder,
  FcOvertime,
} from 'react-icons/fc'
import { HiRefresh } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import Button from '@/components/Button'
import Loading from '@/components/Loading'
import { useClientStore } from '@/stores/client.store'
import { cn } from '@/utils/cn'
import { getRequestPrivilegesRoute } from '@/utils/routes'

interface StatusWrapperI {
  style: any
  className: string
  children: any
}

const StatusWrapper = ({
  children,
  style = {},
  className,
}: StatusWrapperI): JSX.Element => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center flex-1',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export interface StatusPropsI {
  status: any
  isFetching?: any
  isFetchingNextPage?: any
  metaLoading?: boolean
  error: any
  isEmpty: boolean
  small?: boolean
  statusWrapperProps?: any
  silentRefresh?: boolean
  customMessage?: {
    enabled: boolean
    CustomIcon: (props: any) => any
    message: string
    subMessage: string
  }
  isEmptyMessage?: string
  isEmptySubMessage?: string
  showErrorMessage?: boolean
  isErrorMessage?: string
  isEmptyIcon?: any
  isEmptyImage?: string
  isEmptyImageClassName?: string
  lightTheme?: boolean
  loadingMessage?: string | null
  children: any
  refetch?: () => void
  showReloadButton?: boolean
  LoadingComponent?: (args?: any) => JSX.Element
  EmptyComponent?: (args?: any) => JSX.Element
  comingSoon?: boolean
  comingSoonMessage?: string
  comingSoonSubMessage?: string
  includeLogo?: boolean
}

const Status = ({
  refetch,
  status,
  isFetching,
  isFetchingNextPage,
  error,
  metaLoading,
  isEmpty,
  small = false,
  statusWrapperProps,
  silentRefresh = false,
  customMessage,
  isEmptyMessage,
  isEmptySubMessage,
  isErrorMessage,
  showErrorMessage,
  showReloadButton = true,
  isEmptyIcon: IsEmptyIcon,
  isEmptyImage,
  isEmptyImageClassName,
  lightTheme,
  includeLogo = true,
  loadingMessage,
  children,
  comingSoon,
  comingSoonMessage,
  comingSoonSubMessage,
  LoadingComponent,
  EmptyComponent,
}: StatusPropsI): JSX.Element => {
  const client = useClientStore((state) => state.client)
  const isLoading =
    (status === 'loading' || isFetching || metaLoading) && !isFetchingNextPage

  const refreshInBackground = silentRefresh && !isEmpty

  const iconContainerClassName = clsx('rounded-full', small ? 'p-4' : 'p-6', {
    'bg-neutral bg-opacity-100': !lightTheme,
    'bg-white bg-opacity-20': lightTheme,
  })

  const messageClassName = cn('text-center', {
    'text-text-tertiary': !lightTheme,
    'text-white': lightTheme,
    'block text-sm font-medium mt-4': small,
    'mt-6 text-base text-center font-medium px-6': !small,
  })

  const subMessageClassName = cn('text-sm text-center mt-2', {
    'text-text-dimmed': !lightTheme,
    'text-white text-opacity-75': lightTheme,
    'mt-2': !small,
    'mt-1.5': small,
  })

  const iconClassName = small ? 'w-6 h-6' : 'w-12 h-12'

  const buttonClassName = cn(
    'flex justify-center px-8 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm text-elements-primary-contrastText neutral-primary-shadow bg-elements-primary-main hover:bg-elements-primary-shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main',
    { 'mt-4': small, 'mt-6': !small },
  )

  const is404 = error?.response?.status === 404

  if (comingSoon) {
    return (
      <StatusWrapper {...statusWrapperProps}>
        <div className={iconContainerClassName}>
          <FcOvertime className={clsx(iconClassName)} />
        </div>
        <p className={messageClassName}>{comingSoonMessage || 'Coming Soon'}</p>
        {comingSoonSubMessage && (
          <p className={subMessageClassName}>{comingSoonSubMessage}</p>
        )}
      </StatusWrapper>
    )
  }

  if (customMessage?.enabled) {
    return (
      <StatusWrapper {...statusWrapperProps}>
        <div className={iconContainerClassName}>
          <customMessage.CustomIcon className={clsx(iconClassName)} />
        </div>
        <p className={messageClassName}>{customMessage?.message}</p>
        {customMessage?.subMessage && (
          <p className={subMessageClassName}>{customMessage?.subMessage}</p>
        )}
      </StatusWrapper>
    )
  }

  if (isLoading && !refreshInBackground) {
    if (LoadingComponent) {
      return <LoadingComponent />
    }
    return (
      <StatusWrapper {...statusWrapperProps}>
        <Loading
          includeLogo={!small && includeLogo}
          width={small ? 64 : 84}
          lightTheme={lightTheme}
        />
        {!!loadingMessage && (
          <div className='mt-8 max-w-[300px] text-center'>
            <p className='text-sm text-text-tertiary'>{loadingMessage}</p>
          </div>
        )}
      </StatusWrapper>
    )
  }

  if (status === 'error' && !is404) {
    const status = error?.response?.status
    if (status === 403) {
      return (
        <StatusWrapper {...statusWrapperProps}>
          <div className={iconContainerClassName}>
            <FcLock className={clsx(iconClassName)} />
          </div>
          <p className={messageClassName}>Insufficient privileges</p>
          <p className={subMessageClassName}>
            We have restricted this data to approved employees only
          </p>

          <Link
            to={{
              pathname: getRequestPrivilegesRoute({ client }),
              search: `?resource=${error.request.responseURL}`,
            }}
            className={clsx(buttonClassName)}
          >
            Request Privileges
          </Link>
        </StatusWrapper>
      )
    } else {
      return (
        <StatusWrapper {...statusWrapperProps}>
          <div className={iconContainerClassName}>
            <FcHighPriority className={clsx(iconClassName)} />
          </div>
          <p className={messageClassName}>
            {isErrorMessage || 'Sorry, there was an error fetching this data.'}
          </p>
          {error.message && showErrorMessage && (
            <p className={subMessageClassName}>{error.message}</p>
          )}
          {showReloadButton && (
            <div className='mx-auto mt-4 w-42'>
              <Button
                type='button'
                onClick={() => (refetch ? refetch() : window.location.reload())}
                className='flex items-center space-x-2'
              >
                <HiRefresh className='w-4 h-4' /> <span>Reload</span>
              </Button>
            </div>
          )}
        </StatusWrapper>
      )
    }
  }

  if (!isLoading && (isEmpty || is404)) {
    if (EmptyComponent) {
      return <EmptyComponent />
    }
    return (
      <StatusWrapper {...statusWrapperProps}>
        {isEmptyImage ? (
          <img
            src={isEmptyImage}
            alt='empty'
            className={cn(
              'object-cover origin-center rounded-full grayscale opacity-80',
              small ? 'w-4 h-4' : 'w-28 h-28',
              isEmptyImageClassName,
            )}
          />
        ) : (
          <div className={iconContainerClassName}>
            {IsEmptyIcon ? (
              <IsEmptyIcon className={clsx(iconClassName)} />
            ) : (
              <FcOpenedFolder className={clsx(iconClassName)} />
            )}
          </div>
        )}
        <p className={messageClassName}>
          {isEmptyMessage || 'No data could be found'}
        </p>
        {isEmptySubMessage && (
          <p className={subMessageClassName}>{isEmptySubMessage}</p>
        )}
      </StatusWrapper>
    )
  }

  return children
}

export default Status
