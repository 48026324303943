import { ClientT, NotificationsSettingsI } from '@/config/types'

import peacockNotifications from './peacock'
import skyEuropeNotifications from './sky-europe'
import skyEuropeAndPeacockNotifications from './sky-europe-and-peacock'
import skyshowtimeNotifications from './skyshowtime'

export const notifications: Record<ClientT, NotificationsSettingsI> = {
  peacock: peacockNotifications,
  skyshowtime: skyshowtimeNotifications,
  now: skyEuropeAndPeacockNotifications, // TODO: Update me
  'nbcu-dtc': skyEuropeAndPeacockNotifications, // TODO: Update me
  'sky-europe-and-peacock': skyEuropeAndPeacockNotifications,
  'sky-europe': skyEuropeNotifications,
  showmax: skyEuropeAndPeacockNotifications, // TODO: Update me
  'now-gold': skyEuropeAndPeacockNotifications,
}

export default notifications
