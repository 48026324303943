import moment, { Moment } from 'moment-timezone'
import { HiOutlineCog, HiOutlineGlobe } from 'react-icons/hi'

import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import {
  OPERATING_SYSTEM,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '@/config/formOptions'
import { FormI, HomeSettingsI, SelectOptionI } from '@/config/types'

export const qualityMetrics = [
  'average-bitrate',
  'connection-induced-rebuffering-ratio',
  'video-startup-time',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
  'exits-before-video-start-percentage',
]

export const filterOptions: FormI[] = [
  {
    value: 'territory',
    type: 'button-group',
    multi: true,
    options: [TERRITORY['all'], ...skyShowTimeTerritoryOptions],
  },
  {
    value: 'operating-system',
    type: 'button-group',
    multi: true,
    // Options copied from /tags endpoint response for SST client
    options: [
      OPERATING_SYSTEM['android'],
      OPERATING_SYSTEM['android-tv'],
      OPERATING_SYSTEM['chromecast'],
      OPERATING_SYSTEM['fire-os'],
      OPERATING_SYSTEM['ios'],
      OPERATING_SYSTEM['lg-tv'],
      OPERATING_SYSTEM['rdk'],
      OPERATING_SYSTEM['samsung-tv'],
      OPERATING_SYSTEM['tv-os'],
      OPERATING_SYSTEM['web'],
    ],
  },
]

export const splitByOptions: SelectOptionI[] = [
  {
    label: 'Split by Territory',
    icon: HiOutlineGlobe,
    value: 'territory',
  },
  {
    label: 'Split by Operating System',
    icon: HiOutlineCog,
    value: 'operating-system',
  },
]

export const defaultConfig = {
  'split-by': 'territory',
  proposition: ['skyshowtime'],
  territory: ['all'],
  'device-type': ['all'],
  start: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['skyshowtime'])
    .startOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
  end: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['skyshowtime'])
    .endOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
}

const homeSettings: HomeSettingsI = {
  qualityMetrics,
  filterOptions,
  splitByOptions,
  defaultConfig,
  realtime: {
    topEvents: {
      url: {
        attach: 'param',
        formatter: { 'time-period': 'now' },
        remove: ['start', 'end'],
      },
      streamTypeOptions: ['vod'],
      defaultStreamType: 'vod',
      label: 'Top Events Now',
    },
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = moment()
        .clone()
        .tz(CLIENT_DEFAULT_TIMEZONES['skyshowtime'])
        .subtract(1, 'minute')
        .startOf('minute')

      const start = now.clone().subtract(30, 'minutes')
      const end = now.clone()

      return { start, end }
    },
  },
  topEvents: {
    url: {
      attach: 'param',
      formatter: { 'time-period': 'today' },
      remove: ['start', 'end'],
    },
    streamTypeOptions: ['vod'],
    defaultStreamType: 'vod',
    hoisted: true,
  },

  passActivationsAndCancellations: {
    enabled: false,
  },
  minutesAndUniques: {
    enabled: true,
  },
  quality: {
    enabled: true,
  },
  signInsAndSignUps: {
    enabled: true,
  },
  weeklyViewingReport: {
    enabled: false,
  },
}

export default Object.freeze(homeSettings)
