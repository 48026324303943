import { connectPagination } from 'react-instantsearch-dom'

import { Pagination as MuiPagination } from '@mui/material'

const Pagination = ({ refine, nbPages, currentRefinement }: any) => {
  return (
    <MuiPagination
      page={currentRefinement}
      count={nbPages}
      showFirstButton
      showLastButton
      onChange={(e, page) => refine(page)}
    />
  )
}

const CustomPagination = connectPagination(Pagination)

export default CustomPagination
