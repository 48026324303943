import axios from 'axios'
import React from 'react'
import { QueryFunctionContext, useQuery } from 'react-query'
import useWindowFocus from 'use-window-focus'

import { EventsSettingsI, VariantsT } from '@/config/types'
import { useClientStore } from '@/stores/client.store'
import createVariantsSupportedConfig from '@/utils/createVariantsSupportedConfig'

import getAccessToken from '../utils/getAccessToken'
import getUrl from '../utils/getUrl'
import useEventsSettings from './useEventsSettings'

const fetchEvents = async ({ queryKey }: QueryFunctionContext) => {
  const { settings, config } = queryKey[1] as {
    settings: EventsSettingsI
    config: ConfigI
  }

  const subGenreFilter = queryKey[2] as string

  const url = getUrl({
    settings,
    config: { ...config, page: 0 },
  })

  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }

    return {
      ...response.data,
      data: response.data.data.filter((event: Conviva2EventI) =>
        subGenreFilter
          ? event.meta.subGenre?.toLowerCase() === subGenreFilter.toLowerCase()
          : true,
      ),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

function useOlympicEvents<T = Conviva2EventI>({
  key = 'olympic-events',
  subGenreFilter = 'olympics',
  config: initialConfig,
  extendSettings = (settings) => settings,
  reactQueryProps,
  variants,
}: {
  key: any
  subGenreFilter?: string
  config: ConfigI
  extendSettings?: (settings: any) => any
  reactQueryProps?: any
  variants?: VariantsT
}) {
  const client = useClientStore((state) => state.client)
  const windowFocused = useWindowFocus()
  const [errorCount, setErrorCount] = React.useState(0)

  const settings: EventsSettingsI = useEventsSettings({
    extendSettings,
    variants,
  })
  const config = createVariantsSupportedConfig({
    config: initialConfig,
    variants,
    client,
    settings,
  })

  const queryKey = [key, { config, settings }, subGenreFilter]

  const enabled =
    errorCount < 3 &&
    windowFocused &&
    (reactQueryProps.enabled || reactQueryProps.enabled === undefined) // Don't repoll when window isn't focused, but also capture initial enabled prop

  const events = useQuery<{
    meta: Conviva2MetaI
    data: T[]
  }>(queryKey, fetchEvents, {
    ...reactQueryProps,
    enabled,
  })

  React.useEffect(() => {
    if (events.status === 'error') {
      setErrorCount((count) => count + 1)
    } else if (events.status === 'success') {
      setErrorCount(0)
    }
  }, [events.status])

  const url = getUrl({
    settings,
    config,
  })

  return {
    events,
    settings,
    url,
  }
}

export default useOlympicEvents
