import { startCase } from 'lodash'

import getFormOption from '@/utils/getFormOption'

export function addLabelsToData({ data = [], splitBy, settings }) {
  if (!settings || !splitBy) return data
  return data?.map(({ id, value, ...rest }) => {
    const { label, image, color } = getFormOption({
      value: id,
      formGroup: splitBy,
      settings,
    })

    return { id: label || startCase(id), value, image, color, ...rest }
  })
}
