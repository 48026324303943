import { startCase } from 'lodash'

const getLabel = ({ value, formGroup, settings }) => {
  if (formGroup) {
    const formSettings = settings?.form?.find(
      ({ value }) => value === formGroup,
    )
    if (formSettings?.options?.length > 0) {
      const formOption = formSettings?.options?.find((option) => {
        return option[0] === value || (value === 'total' && option[0] === 'all')
      })
      if (formOption && formOption[1] && formOption[1].label) {
        return formOption[1].label
      }
    }
  }
  return startCase(value)
}

export default getLabel
