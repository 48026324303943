import clsx from 'clsx'
import React from 'react'
import { IconType } from 'react-icons'
import { Hit, RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'

import Button from '@/components/Button'
import { InstantSearchRefinementListFormI } from '@/config/types'

type RefinementListItemT = Hit<{
  count: number
  isRefined: boolean
  label: string
  value: string[]
}>

const RefinementListItem = ({
  label,
  image,
  Icon,
  value,
  count,
  isRefined,
  refine,
}: Pick<RefinementListItemT, 'count' | 'isRefined' | 'label' | 'value'> &
  Pick<RefinementListProvided, 'refine'> & {
    value?: string[]
    image?: string
    Icon?: IconType
  }) => {
  const checked = isRefined
  const multi = true

  return (
    <li>
      <label
        key={label}
        onChange={() => refine(value)}
        className={clsx(
          'relative py-2 border border-transparent px-4 cursor-pointer focus:outline-none flex items-center mx-1 my-1 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-elements-primary-main focus-within:ring-offset-neutral-dimmed-heavy group',
          {
            'bg-neutral 2xl:bg-neutral-shadow': checked,
            'hover:bg-neutral-dimmed': !checked,
          },
        )}
      >
        <div className='flex items-center h-5'>
          <input
            checked={isRefined}
            onChange={() => refine(value)}
            id={label}
            name={label}
            type={multi ? 'checkbox' : 'radio'}
            className={clsx(
              'w-4 h-4 bg-neutral-dimmed-heavy border-border-shadow cursor-pointer text-elements-primary-shadow focus:ring-elements-primary-main focus:ring-offset-neutral-shadow',
              {
                rounded: multi,
              },
            )}
          />
        </div>
        <div
          className={clsx(
            'ml-3 flex items-center font-medium text-sm text-text-secondary group-hover:text-text-primary truncate',
          )}
        >
          {Icon && (
            <Icon
              className={clsx('w-5 h-5 mr-2 group-hover:text-text-tertiary', {
                'text-text-dimmed': !isRefined,
                'text-text-tertiary': isRefined,
              })}
            />
          )}
          {image && (
            <img
              src={image}
              alt={label}
              className='max-h-5 w-5 object-contain ml-0.5 mr-2 rounded-sm'
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <span className='truncate'>{label}</span>
          {count > 0 && (
            <span
              className={clsx(
                'text-xs text-text-tertiary px-2 py-0.5 ml-2 rounded-full',
                {
                  'bg-neutral-shadow': !isRefined,
                  'bg-neutral-shadow-heavy': isRefined,
                },
              )}
            >
              {count}
            </span>
          )}
        </div>
      </label>
    </li>
  )
}

export const RefinementList = ({
  canRefine,
  currentRefinement,
  items,
  refine,
  limit = 0,
  showMore = false,
  formGroup,
}: {
  showMore?: boolean
  limit?: number
  formGroup: InstantSearchRefinementListFormI
} & RefinementListProvided) => {
  const [showMoreSelected, setShowMoreSelected] = React.useState(false)
  const limitItems = showMore && !showMoreSelected

  return (
    <ul className='-mx-1'>
      {canRefine && (
        <RefinementListItem
          count={0}
          key='all'
          value={[]}
          label={formGroup.allLabel}
          isRefined={currentRefinement.length === 0}
          refine={refine}
        />
      )}

      {canRefine &&
        items
          .slice(0, limitItems ? limit : items.length)
          .map((item: RefinementListItemT) => (
            <RefinementListItem key={item.label} {...item} refine={refine} />
          ))}
      {showMore && items.length > limit && (
        <div className='px-4 mt-4'>
          <Button
            className='border border-divider-shadow bg-neutral-dimmed-heavy 2xl:bg-neutral lg:hover:bg-neutral-dimmed text-text-primary'
            onClick={() => setShowMoreSelected(!showMoreSelected)}
          >
            Show {showMoreSelected ? 'less' : 'more'}
          </Button>
        </div>
      )}
    </ul>
  )
}

const RefinementListFormGroup = ({
  formGroup,
  ...refinementListProps
}: {
  formGroup: InstantSearchRefinementListFormI
} & RefinementListProvided) => {
  if (!refinementListProps.canRefine) return null
  return (
    <li
      role='group'
      className='pb-8 mt-2 border-b border-border-main'
      key={formGroup.label}
    >
      <label className='pl-2 mb-3 text-xs font-semibold tracking-wider uppercase align-top text-text-tertiary'>
        {formGroup.label}
      </label>
      <RefinementList formGroup={formGroup} {...refinementListProps} />
    </li>
  )
}

const CustomRefinementList = connectRefinementList(RefinementListFormGroup)

export default CustomRefinementList
