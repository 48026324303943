import { sortBy } from 'lodash'

import { addLabelsToData } from '@/config/dataFormatters/utils/conviva'

const fallback = []

const convivaAggregationLegend = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  if (data?.data) {
    const formattedData = Object.keys(data?.data).map((splitKey) => {
      const splitData = data?.data[splitKey]
      return {
        id: splitKey || 'Unknown',
        value: splitData[0][dataKey] || 0,
        isTotal: splitKey === 'total',
      }
    })

    const dataWithLabels = addLabelsToData({
      data: formattedData,
      settings,
      splitBy,
    })

    const sortedDataWithLabels = sortBy(dataWithLabels, ({ id }) => id)

    return sortedDataWithLabels
  } else {
    return fallback
  }
}

export default convivaAggregationLegend
