import moment, { Moment } from 'moment-timezone'
import { HiOutlineCollection, HiOutlineGlobeAlt } from 'react-icons/hi'

import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import { PROPOSITION, TERRITORY } from '@/config/formOptions'

import { FormI, HomeSettingsI, SelectOptionI } from '../../types'

export const qualityMetrics = [
  'average-bitrate',
  'connection-induced-rebuffering-ratio',
  'video-startup-time',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
  'exits-before-video-start-percentage',
]

export const filterOptions: FormI[] = [
  {
    value: 'proposition',
    type: 'select',
    multi: true,
    options: [
      PROPOSITION['all'],
      PROPOSITION['peacock'],
      PROPOSITION['now'],
      PROPOSITION['sky-go'],
      PROPOSITION['sky-q'],
      PROPOSITION['soip'],
      PROPOSITION['sky-stream'],
      PROPOSITION['sky-sports'],
      PROPOSITION['sky-x'],
      PROPOSITION['sky-store'],
      PROPOSITION['sky-sports-box-office'],
    ],
  },
  {
    value: 'territory',
    type: 'select',
    multi: true,
    options: [
      TERRITORY['all'],
      TERRITORY['uk'],
      TERRITORY['us'],
      TERRITORY['de'],
      TERRITORY['it'],
      TERRITORY['roi'],
      TERRITORY['at'],
    ],
  },
]

export const splitByOptions: SelectOptionI[] = [
  {
    label: 'Split by Proposition',
    icon: HiOutlineCollection,
    value: 'proposition',
  },
  {
    label: 'Split by Territory',
    icon: HiOutlineGlobeAlt,
    value: 'territory',
  },
]

export const defaultConfig = {
  'split-by': 'proposition',
  proposition: [],
  territory: [],
  start: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['sky-europe-and-peacock'])
    .startOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
  end: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['sky-europe-and-peacock'])
    .endOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
}

const homeSettings: HomeSettingsI = {
  qualityMetrics,
  filterOptions,
  splitByOptions,
  defaultConfig,
  realtime: {
    topEvents: {
      streamTypeOptions: ['sle', 'linear'],
      defaultStreamType: 'linear',
      label: 'Top Live Events',
    },
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = moment()
        .clone()
        .tz(CLIENT_DEFAULT_TIMEZONES['sky-europe-and-peacock'])
        .subtract(1, 'minute')
        .startOf('minute')

      const start = now.clone().subtract(30, 'minutes')
      const end = now.clone()

      return { start, end }
    },
  },
  topEvents: {
    streamTypeOptions: ['vod', 'sle', 'linear'],
    defaultStreamType: 'linear',
  },
  passActivationsAndCancellations: {
    enabled: false,
  },
  minutesAndUniques: {
    enabled: true,
    config: {
      'exclude-proposition': ['skyshowtime'],
    },
  },
  quality: {
    enabled: true,
  },
  signInsAndSignUps: {
    enabled: true,
  },
  weeklyViewingReport: {
    enabled: true,
  },
}

export default Object.freeze(homeSettings)
