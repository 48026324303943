import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreLoadBalancerConfig from './common/prometheusCoreLoadBalancer'

const maxConnections: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreLoadBalancerConfig =
    commonPrometheusCoreLoadBalancerConfig()

  return {
    ...prometheusCoreLoadBalancerConfig,
    value: 'max-connections',
    label: 'Max Connections',
    shortLabel: 'Max Con.',
    dataKey: 'maxConnections',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Max Connections refers to the maximum number of concurrent connections that the load balancer can handle at any given time. It defines the upper limit on the number of simultaneous connections that the load balancer can manage effectively. If the number of connections surpasses this limit, additional connection attempts may be denied or queued, depending on the load balancing strategy and configuration.',
    aggregationType: 'peak',
    defaultConfig: {
      ...prometheusCoreLoadBalancerConfig.defaultConfig,
      metric: 'max-connections',
    },
  }
}

export default maxConnections
