import { DurationInputArg1, DurationInputArg2 } from 'moment'

// Some abstraction of common granularity options in here
// This could definitely be improved to offer more control of returned options
// However, as most data sources are consistent, this should be ok for now

const MS_48_HOURS = 172800000
const MS_2_WEEKS = 1209600000
const MS_1_YEAR = 31536000000

export const commonFiveMinuteGranularity = (
  start: number | string | Date,
  end: number | string | Date,
): [DurationInputArg1, DurationInputArg2] => {
  const startMs = new Date(start).getTime()
  const endMs = new Date(end).getTime()
  const differenceMs = endMs - startMs

  if (differenceMs > 0 && differenceMs <= MS_48_HOURS) {
    // 0 and 48 hours
    return [5, 'minute']
  } else if (differenceMs > MS_48_HOURS && differenceMs <= MS_2_WEEKS) {
    // 48 hours and 2 weeks
    return [1, 'hour']
  } else if (differenceMs > MS_2_WEEKS && differenceMs <= MS_1_YEAR) {
    // 2 weeks and 1 year
    return [1, 'day']
  } else {
    // over 1 year
    return [1, 'month']
  }
}

export const commonMinuteGranularity = (
  start: number | string | Date,
  end: number | string | Date,
): [DurationInputArg1, DurationInputArg2] => {
  const startMs = new Date(start).getTime()
  const endMs = new Date(end).getTime()
  const differenceMs = endMs - startMs

  if (differenceMs > 0 && differenceMs <= MS_48_HOURS) {
    // 0 and 48 hours
    return [1, 'minute']
  } else if (differenceMs > MS_48_HOURS && differenceMs <= MS_2_WEEKS) {
    // 48 hours and 2 weeks
    return [1, 'hour']
  } else if (differenceMs > MS_2_WEEKS && differenceMs <= MS_1_YEAR) {
    // 2 weeks and 1 year
    return [1, 'day']
  } else {
    // over 1 year
    return [1, 'month']
  }
}

export const commonTenMinuteGranularity = (
  start: number | string | Date,
  end: number | string | Date,
): [DurationInputArg1, DurationInputArg2] => {
  const startMs = new Date(start).getTime()
  const endMs = new Date(end).getTime()
  const differenceMs = endMs - startMs

  if (differenceMs > 0 && differenceMs <= MS_48_HOURS) {
    // 0 and 48 hours
    return [10, 'minute']
  } else if (differenceMs > MS_48_HOURS && differenceMs <= MS_2_WEEKS) {
    // 48 hours and 2 weeks
    return [1, 'hour']
  } else if (differenceMs > MS_2_WEEKS && differenceMs <= MS_1_YEAR) {
    // 2 weeks and 1 year
    return [1, 'day']
  } else {
    // over 1 year
    return [1, 'month']
  }
}