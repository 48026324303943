import { HiOutlinePlay } from 'react-icons/hi'

import {
  EVENT_STREAM_TYPE_WITH_CLIENT,
  METRIC,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '@/config/formOptions'
import { conviva2RelativeDates } from '@/config/relativeDates/skyshowtime'

import { CONVIVA_2_V4_API_DOMAIN } from '../../constants'
import { VariantsT } from '../../types'

const dates = conviva2RelativeDates

const defaultRelativeDate = dates.find(({ isDefault }) => isDefault)
const defaultDateParams = defaultRelativeDate?.getDateRange()

// @ts-ignore
const timePeriod = defaultDateParams['time-period'] || null

export const DEFAULT_DATE = {
  dateType: 'relative',
  relativeDate: defaultRelativeDate,
  'time-period': timePeriod,
}

// SkyShowtime Only supports withClient variant
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const events = ({ variants }: { variants?: VariantsT }) => {
  return {
    default: true,
    value: 'events',
    label: 'Content',
    apiDomain: CONVIVA_2_V4_API_DOMAIN + '/events',
    supportsClient: true,
    icon: HiOutlinePlay,
    info: 'This page shows a table ranking of the most watched events during a given interval.',
    dataSource: 'conviva',
    useTimezone: true,
    metricsAsDomainGroup: true,
    showIcons: {
      proposition: { enabled: false },
      territory: { enabled: true, accessor: 'territories' },
    },
    form: [
      {
        value: 'stream-type',
        type: 'hidden',
        options: [EVENT_STREAM_TYPE_WITH_CLIENT.vod],
      },
      {
        value: 'territory',
        type: 'button-group',
        multi: true,
        options: [TERRITORY['all'], ...skyShowTimeTerritoryOptions],
      },
      {
        value: 'metric',
        type: 'hidden',
        multi: true,
        options: [
          METRIC['all'],
          METRIC['plays'],
          METRIC['rebuffering-ratio'],
          METRIC['total-attempts'],
          METRIC['connection-induced-rebuffering-ratio'],
          METRIC['average-bitrate'],
          METRIC['exits-before-video-start'],
          METRIC['video-startup-time'],
          // METRIC['video-restart-time'],
          // METRIC['ended-plays'],
          METRIC['video-start-failures-percentage'],
          METRIC['video-start-failures-technical-percentage'],
          METRIC['video-start-failures-business-percentage'],
          METRIC['video-playback-failures-percentage'],
          METRIC['video-playback-failures-technical-percentage'],
          METRIC['video-playback-failures-business-percentage'],
        ],
      },
    ],
    dates,
    defaultConfig: {
      proposition: ['skyshowtime'],
      'stream-type': ['vod'],
      territory: ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      metric: ['all'],
      client: 'skyshowtime',
      ...DEFAULT_DATE,
      // Can't be defined here, it gets passed to url as param
      // enableAdditionalEventQualityMetrics: {
      //   vod: false,
      // },
    },
  }
}

export default events
