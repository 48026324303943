export const GROUPS = {
  CONVIVA: {
    value: 'conviva',
    label: 'Conviva',
    icon: {
      imageUrl: '/images/dataSource/conviva.png',
    },
  },
  OCELLUS: {
    value: 'ocellus',
    label: 'Ocellus',
    icon: {
      imageUrl: '/images/dataSource/ocellus.png',
    },
  },
  SDP: {
    value: 'sdp',
    label: 'SDP',
    icon: {
      imageUrl: '/images/dataSource/sdp.png',
    },
  },
  IDENTITY: {
    value: 'identity',
    label: 'Identity',
    icon: {
      imageUrl: '/images/dataSource/identity.png',
    },
  },
  GCP_UPTIME: {
    value: 'gcpUptime',
    label: 'GCP Uptime Checks',
    icon: {
      imageUrl: '/images/dataSource/uptime.png',
    },
  },
  // TODO: Remove after all clients are using Prometheus Application and Core
  PROMETHEUS: {
    value: 'prometheus',
    label: 'Prometheus',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CONCURRENCY: {
    value: 'concurrency',
    label: 'Prometheus (Concurrency)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_APPLICATION: {
    value: 'application',
    label: 'Prometheus (Application)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CLOUD_ENG_GATEWAY: {
    value: 'cloud-eng-gw',
    label: 'Prometheus (Cloud Eng Gateway)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CORE: {
    value: 'core',
    label: 'Prometheus (Core)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CORE_NETWORK: {
    value: 'core-network',
    label: 'Prometheus (Core Network)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CORE_INGRESS: {
    value: 'core-ingress',
    label: 'Prometheus (Core Ingress)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_CORE_LOAD_BALANCER: {
    value: 'core-lb',
    label: 'Prometheus (Core Load Balancer)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  PROMETHEUS_PERSISTENCE: {
    value: 'persistence',
    label: 'Prometheus (Persistence)',
    icon: {
      imageUrl: '/images/dataSource/prometheus.png',
    },
  },
  TOUCHSTREAM: {
    value: 'touchstream',
    label: 'Touchstream',
    icon: {
      imageUrl: '/images/dataSource/touchstream.png',
    },
  },
}
