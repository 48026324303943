import { EventsSettingsI } from '@/config/types'

const getEventsConfigMetrics = ({
  config,
  settings,
}: {
  config?: ConfigI
  settings: EventsSettingsI
}): ConfigI => {
  let streamType =
    (config?.['stream-type'] as StreamTypes | StreamTypes[] | undefined) ||
    'linear'
  if (Array.isArray(streamType)) {
    streamType = streamType[0]
  }

  const timePeriod = config?.['time-period']

  const newConfig: ConfigI = config
    ? { metric: config.metric ?? ['all'], ...config }
    : { metric: ['all'] }
  const metricFormSettings = settings.form.find(
    (formGroup) => formGroup.value === 'metric',
  )
  // ensure metric is always array
  if (typeof newConfig.metric === 'string') {
    newConfig.metric = [newConfig.metric]
  }

  // if metric value is 'all' replace with all metric values
  if (config?.metric?.[0] === 'all') {
    newConfig.metric = metricFormSettings?.options
      .filter((formOption) => formOption[0] !== 'all')
      .map((formOption) => formOption[0])
  }

  // for VOD streamType, we filter out concurrent-plays. However, we query both concurrent-plays and plays for other stream types
  if (streamType === 'vod' && timePeriod !== 'now') {
    newConfig.metric = newConfig.metric?.filter(
      (metric) => metric !== 'concurrent-plays',
    )
    if (!newConfig.metric?.includes('plays')) {
      newConfig.metric?.unshift('plays')
    }
  } else if (!newConfig.metric?.includes('concurrent-plays')) {
    newConfig.metric?.unshift('concurrent-plays')
  }

  return newConfig
}

export default getEventsConfigMetrics
