import moment from 'moment'
import React from 'react'
import { HiMenuAlt1, HiX } from 'react-icons/hi'
import { RiTimeZoneLine } from 'react-icons/ri'
import { UseQueryResult } from 'react-query'

import clientSettings from '@/config/clientSettings'
import { useClientStore } from '@/stores/client.store'
import { Dialog, Transition } from '@headlessui/react'

import InfoText from '../InfoText'
import ClientSelect from './components/ClientSelect'
import MobileUserMenu from './components/MobileUserMenu'
import Navigation from './components/Navigation'
import UserMenu from './components/UserMenu'

interface PropsI {
  logout: () => void
  user: UseQueryResult<any, any>
  avatar: UseQueryResult<any, any>
  // notifications: UseQueryResult<any, any>
  theme: string
  setTheme: (theme: ThemeT) => void
  children: any
}

const ClientDefaultTimezone = () => {
  const client = useClientStore((state) => state.client)
  const { name } = clientSettings[client]
  const clientDefaultTimezone = useClientStore(
    (state) => state.clientDefaultTimezone,
  )

  const clientTimezoneName = moment
    .tz(moment(), clientDefaultTimezone)
    .format('z')

  return (
    <div className='flex items-center mx-5 mt-4'>
      <RiTimeZoneLine className='h-6 w-6 text-text-tertiary mr-3' />
      <InfoText
        text={`Timezone: ${clientTimezoneName}`}
        tooltip={`Dates and times are displayed in the context's default timezone. For ${name} context it's ${clientTimezoneName}.`}
        className='text-sm font-medium  text-text-primary'
      />
    </div>
  )
}

const Nav1 = ({
  children,
  logout,
  user,
  // notifications,
  theme,
  setTheme = () => null,
}: PropsI) => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
  // const lastNotificationsVisit = useVisitStore(
  //   (state) => state.lastNotificationsVisit,
  // )

  // const unseenNotifications =
  //   notifications.status === 'success'
  //     ? notifications.data?.pages[0]?.history?.filter(
  //         (notification: NotificationI) => {
  //           return (
  //             moment(notification.timestamp).unix() * 1000 >
  //             lastNotificationsVisit
  //           )
  //         },
  //       )
  //     : []

  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={React.Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 z-50 flex xl:hidden'
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={React.Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-opacity-75 bg-zinc-600' />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex flex-col flex-1 w-full max-w-xs py-4 bg-neutral-dimmed-heavy'>
              <Transition.Child
                as={React.Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 pt-2 -mr-12'>
                  <button
                    className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-white'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <HiX className='w-6 h-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>

              {/* <!-- Mobile Menu Client select dropdown --> */}
              <ClientSelect />

              {/* Mobile Menu Client Default Timezone */}
              <ClientDefaultTimezone />

              <div className='flex-1 h-0 mt-5 overflow-y-auto'>
                <Navigation />
                {/* {developerMode && (
                  <div className='absolute flex items-center justify-end space-x-2 bottom-4 right-3'>
                    <div className='absolute hidden'>
                      <NotificationsButton
                        unseenNotifications={unseenNotifications}
                        notifications={
                          notifications.status === 'success'
                            ? notifications.data?.pages[0]?.history
                            : []
                        }
                        className='bg-neutral-dimmed-heavy hover:bg-neutral focus:bg-neutral active:bg-neutral'
                      />
                    </div>
                  </div>
                )} */}

                <div className='absolute hidden bottom-4 right-16 xl:block'></div>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* <!-- Static sidebar for desktop --> */}
      <div className='hidden xl:flex xl:flex-shrink-0'>
        <div className='flex flex-col w-64 py-5 border-r border-divider-main bg-neutral'>
          {/* <!-- Client select dropdown --> */}
          <ClientSelect />

          {/* Client default timezone */}
          <ClientDefaultTimezone />

          {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
          <div className='flex flex-col flex-1 h-0 overflow-y-auto'>
            {/* <!-- Navigation --> */}
            <Navigation />
          </div>
          {/* {developerMode && (
            <div className='flex justify-end px-2 mb-2 space-x-2 xl:px-3'>
              <NotificationsButton
                unseenNotifications={unseenNotifications}
                notifications={
                  notifications.status === 'success'
                    ? notifications.data?.pages[0]?.history
                    : []
                }
              />
            </div>
          )} */}

          {/* <!-- User account dropdown --> */}
          <UserMenu
            userLoading={user.status === 'loading'}
            user={user?.data}
            logout={logout}
            theme={theme}
            setTheme={setTheme}
          />

          <div className='flex justify-end px-2 xl:px-3'></div>
        </div>
      </div>
      {/* <!-- Main column --> */}
      <div className='flex flex-col flex-1 w-0 overflow-hidden'>
        {/* <!-- Mobile header --> */}
        <div className='relative z-50 flex flex-shrink-0 h-16 border-b bg-neutral-dimmed-heavy border-divider-main xl:hidden'>
          {/* <!-- Sidebar toggle, controls the 'mobileMenuOpen' sidebar state. --> */}
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className='px-4 border-r border-divider-main text-text-tertiary hover:text-text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main xl:hidden'
          >
            <span className='sr-only'>Open sidebar</span>
            <HiMenuAlt1 aria-hidden='true' className='w-6 h-6' />
          </button>
          <div className='flex justify-between flex-1 px-4 sm:px-6 xl:px-8'>
            <div className='flex flex-1'></div>
            <div className='flex items-center'>
              {/* <!-- Mobile User Menu Dropdown --> */}
              {/* {developerMode && (
                <NotificationsDropdown
                  unseenNotifications={unseenNotifications}
                  notificationsLoading={notifications.status === 'loading'}
                  notifications={
                    notifications.status === 'success'
                      ? notifications.data?.pages[0]?.history
                      : []
                  }
                />
              )} */}

              <MobileUserMenu
                userLoading={user.status === 'loading'}
                user={user?.data}
                logout={logout}
                theme={theme}
                setTheme={setTheme}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  )
}

export default Nav1
