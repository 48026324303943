import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

const DEFAULT_THEME = 'light'

export interface ThemeStoreState {
  theme: ThemeT
  setTheme: (theme: ThemeT) => void
}

type Persist = (
  config: StateCreator<ThemeStoreState>,
  options: PersistOptions<ThemeStoreState>,
) => StateCreator<ThemeStoreState>

export const useThemeStore = create<ThemeStoreState>(
  (persist as Persist)(
    (set) => ({
      theme: DEFAULT_THEME,
      setTheme: (theme) => set(() => ({ theme })),
    }),
    {
      name: 'theme',
    },
  ),
)
