import { BsCollectionPlay } from 'react-icons/bs'
import { HiOutlineCollection, HiOutlineTicket } from 'react-icons/hi'
import { RiSignalTowerFill } from 'react-icons/ri'

import { FormOptionT } from '../types'

export function getTerritoryImage(territoryCode: string) {
  return `/images/territory/${territoryCode}.svg`
}

export function getPropositionImage(propositionSlug: string) {
  return `/images/proposition/${propositionSlug}-app.png`
}

export const SPLIT_BY: FormOptionT = {
  proposition: ['proposition', {}],
  territory: ['territory', {}],
  'stream-type': ['stream-type', {}],
  device: ['device', {}],
  'device-type': ['device-type', {}],
  'device-platform': ['device-platform', {}],
  'operating-system': ['operating-system', {}],
  product: ['product', {}],
  package: ['package', {}],
  'subscription-type': ['subscription-type', {}],
  department: ['department', {}],
  team: ['team', {}],
  region: ['region', {}],
  'cloud-region': ['cloud-region', {}],
  status: ['status', {}],
  application: ['application', {}],
  vendor: ['vendor', {}],
  environment: ['environment', {}],
  'usage-family': ['usage-family', {}],
  'service-name': ['service-name', {}],
  component: ['component', {}],
  subcomponent: ['subcomponent', {}],
  cdn: ['cdn', { label: 'CDN' }],
  format: ['format', {}],
  channel: ['channel', {}],
  state: ['state', {}],
  isp: ['isp', { label: 'ISP' }],
  persistence: ['persistence', {}],
  service: ['service', {}],
  variant: ['variant', {}],
  resident: ['resident', {}],
  'status-group': ['status-group', {}],
  dimension: ['dimension', {}],
}

export const PROPOSITION: FormOptionT = {
  all: ['all', { label: 'All Propositions', color: 'darkgrey' }],
  peacock: [
    'peacock',
    {
      image: getPropositionImage('peacock'),
      color: '#ffb607', // yellow
    },
  ],
  now: [
    'now',
    {
      label: 'Now',
      image: getPropositionImage('now-tv'),
      color: '#007f85', // now green
    },
  ],
  ['now-tv']: [
    'now-tv',
    {
      label: 'Now',
      image: getPropositionImage('now-tv'),
      color: '#007f85', // now green
    },
  ],
  'sky-go': [
    'sky-go',
    {
      image: getPropositionImage('sky-go'),
      color: '#009DFF', // light blue
    },
  ],
  'sky-q': [
    'sky-q',
    {
      image: getPropositionImage('sky-q'),
      color: '#2967EC', // dark blue
    },
  ],
  soip: [
    'soip',
    {
      label: 'Sky Glass',
      image: getPropositionImage('sky-glass'),
      color: '#a4009b', // sky-glass purple
    },
  ],
  'sky-stream': [
    'sky-stream',
    {
      label: 'Sky Stream',
      image: getPropositionImage('sky-stream'),
      color: '#100956', // sky-stream blue
    },
  ],
  'sky-sports': [
    'sky-sports',
    {
      exclude: ['activations', 'cancellations', 'video-quality'],
      image: getPropositionImage('sky-sports'),
      color: '#e7001c', // sky red
    },
  ],
  'sky-x': [
    'sky-x',
    {
      image: getPropositionImage('sky-x'),
      color: '#8500db', // sky x purple
    },
  ],
  'sky-kids': [
    'sky-kids',
    {
      image: getPropositionImage('sky-kids'),
      color: '#ff349a', // sky kids pink
    },
  ],
  'sky-store': [
    'sky-store',
    {
      image: getPropositionImage('sky-store'),
      color: '#002f80', // sky store dark blue
    },
  ],
  'sky-sports-box-office': [
    'sky-sports-box-office',
    {
      image: getPropositionImage('sky-sports-box-office'),
      color: '#0284C7', // light-blue-600
    },
  ],
  skyshowtime: [
    'skyshowtime',
    {
      image: getPropositionImage('skyshowtime'),
      label: 'SkyShowtime',
      color: '#795FE3', // skyshowtime purple
    },
  ],
  'sky-europe': [
    'sky-europe',
    {
      image: getPropositionImage('sky-europe'),
      label: 'Sky Europe',
      color: '#ff8d00',
    },
  ],
  showmax: [
    'showmax',
    {
      image: getPropositionImage('showmax'),
      label: 'Showmax',
      color: '#eb004f', //Showmax dark pink
    },
  ],
  // Comcast
  'comcast-stream-on-flex': [
    'comcast-stream-on-flex',
    {
      image: getPropositionImage('xfinity-stream-on-flex'),
      label: 'Xfinity Stream on Flex',
    },
  ],
  'comcast-stream': [
    'comcast-stream',
    {
      image: getPropositionImage('xfinity-stream'),
      label: 'Xfinity Stream',
    },
  ],
  'comcast-flex': [
    'comcast-flex',
    { image: getPropositionImage('xfinity-flex'), label: 'Xfinity Flex' },
  ],
  'comcast-x1': [
    'comcast-x1',
    { image: getPropositionImage('xfinity-flex'), label: 'Xfinity X1' },
  ],
}

export const comcastPropositionOptions = [
  PROPOSITION['comcast-x1'],
  PROPOSITION['comcast-stream'],
  PROPOSITION['comcast-flex'],
  PROPOSITION['comcast-stream-on-flex'],
]

// Flag images are from this repo: https://github.com/lipis/flag-icons/tree/main/flags/1x1
export const TERRITORY: FormOptionT = {
  all: ['all', { label: 'All Territories', color: 'darkgrey' }],
  uk: [
    'uk',
    {
      label: 'UK',
      image: getTerritoryImage('uk'),
      color: '#2967EC', // dark blue
    },
  ],
  de: [
    'de',
    {
      label: 'Germany',
      image: getTerritoryImage('de'),
      color: '#ffb607', // yellow
    },
  ],
  it: [
    'it',
    {
      label: 'Italy',
      image: getTerritoryImage('it'),
      color: '#16A34A', // green
    },
  ],
  us: [
    'us',
    {
      exclude: ['activations', 'cancellations'],
      label: 'USA',
      image: getTerritoryImage('us'),
      color: '#ff005c', // red
    },
  ],
  roi: [
    'roi',
    {
      label: 'ROI',
      image: getTerritoryImage('ie'),
      color: '#ff6e33', // orange
    },
  ],
  at: [
    'at',
    {
      label: 'Austria',
      image: getTerritoryImage('at'),
      color: '#a000f5', // Sky X Purple
    },
  ],
  dk: [
    'dk',
    {
      label: 'Denmark',
      image: getTerritoryImage('dk'),
      color: '#ca0c30', // Denmark red
    },
  ],
  se: [
    'se',
    {
      label: 'Sweden',
      image: getTerritoryImage('se'),
      color: '#006AA7', // Sweden blue
    },
  ],
  no: [
    'no',
    {
      label: 'Norway',
      image: getTerritoryImage('no'),
      color: '#DC38D6', // Steel pink
    },
  ],
  fi: [
    'fi',
    {
      label: 'Finland',
      image: getTerritoryImage('fi'),
      color: '#b5c9d8', // Finland blue
    },
  ],
  es: [
    'es',
    {
      label: 'Spain',
      image: getTerritoryImage('es'),
      color: '#f6b511', // Spain yellow
    },
  ],
  pl: [
    'pl',
    {
      label: 'Poland',
      image: getTerritoryImage('pl'),
      color: '#ff349a', // Pink
    },
  ],
  ro: [
    'ro',
    {
      label: 'Romania',
      image: getTerritoryImage('ro'),
      color: '#002f80', // Dark blue
    },
  ],
  nl: [
    'nl',
    {
      label: 'Netherlands',
      image: getTerritoryImage('nl'),
      color: '#FF4F00', // Light-blue-600
    },
  ],
  cz: [
    'cz',
    {
      label: 'Czech Republic',
      image: getTerritoryImage('cz'),
      color: '#6A5D7B', // Lavender
    },
  ],
  pt: [
    'pt',
    {
      label: 'Portugal',
      image: getTerritoryImage('pt'),
      color: '#046a38', // Portugal green
    },
  ],
  hu: [
    'hu',
    {
      label: 'Hungary',
      image: getTerritoryImage('hu'),
      color: '#558564', // Forest green
    },
  ],
  rs: [
    'rs',
    {
      label: 'Serbia',
      image: getTerritoryImage('rs'),
      color: '#da1356', // Sienna
    },
  ],
  bg: [
    'bg',
    {
      label: 'Bulgaria',
      image: getTerritoryImage('bg'),
      color: '#52FFB8', // Light green
    },
  ],
  sk: [
    'sk',
    {
      label: 'Slovakia',
      image: getTerritoryImage('sk'),
      color: '#ffb607', // Yellow
    },
  ],
  hr: [
    'hr',
    {
      label: 'Croatia',
      image: getTerritoryImage('hr'),
      color: '#34cbcd', // Light teal
    },
  ],
  ba: [
    'ba',
    {
      label: 'Bosnia & Herzegovina',
      image: getTerritoryImage('ba'),
      color: '#ff005c', // Red
    },
  ],
  al: [
    'al',
    {
      label: 'Albania',
      image: getTerritoryImage('al'),
      color: '#ff6e33', // Orange
    },
  ],
  mk: [
    'mk',
    {
      label: 'North Macedonia',
      image: getTerritoryImage('mk'),
      color: '#006D77', // Teal
    },
  ],
  si: [
    'si',
    {
      label: 'Slovenia',
      image: getTerritoryImage('si'),
      color: '#EDD83D', // Yellow
    },
  ],
  xk: [
    'xk',
    {
      label: 'Kosovo',
      image: getTerritoryImage('xk'),
      color: '#C46BAE', // Light pink
    },
  ],
  me: [
    'me',
    {
      label: 'Montenegro',
      image: getTerritoryImage('me'),
      color: '#890620', // Burgundy
    },
  ],
  ad: [
    'ad',
    {
      label: 'Andorra',
      image: getTerritoryImage('ad'),
      color: '#0F67F5', // Blue
    },
  ],
  // Showmax
  ao: [
    'ao',
    {
      label: 'Angola',
      image: getTerritoryImage('ao'),
      color: '#fcd116', // Angola yellow
    },
  ],
  bf: [
    'bf',
    {
      label: 'Burkina Faso',
      image: getTerritoryImage('bf'),
      color: '#009e49', // Burkina Faso green
    },
  ],
  bj: [
    'bj',
    {
      label: 'Benin',
      image: getTerritoryImage('bj'),
      color: '#fcd116', // Benin yellow
    },
  ],
  bw: [
    'bw',
    {
      label: 'Botswana',
      image: getTerritoryImage('bw'),
      color: '#76a5af', // Botswana light blue
    },
  ],
  cd: [
    'cd',
    {
      label: 'Democratic Republic of the Congo',
      image: getTerritoryImage('cd'),
      color: '#007fff', // DR Congo blue
    },
  ],
  cf: [
    'cf',
    {
      label: 'Central African Republic',
      image: getTerritoryImage('cf'),
      color: '#6a8e23', // CAR green
    },
  ],
  cg: [
    'cg',
    {
      label: 'Republic of the Congo',
      image: getTerritoryImage('cg'),
      color: '#009543', // Congo green
    },
  ],
  ci: [
    'ci',
    {
      label: "Ivory Coast (Côte d'Ivoire)",
      image: getTerritoryImage('ci'),
      color: '#f77f00', // Ivory Coast orange
    },
  ],
  cm: [
    'cm',
    {
      label: 'Cameroon',
      image: getTerritoryImage('cm'),
      color: '#007a5e', // Cameroon green
    },
  ],
  cv: [
    'cv',
    {
      label: 'Cape Verde',
      image: getTerritoryImage('cv'),
      color: '#0033aa', // Cape Verde blue
    },
  ],
  dj: [
    'dj',
    {
      label: 'Djibouti',
      image: getTerritoryImage('dj'),
      color: '#6ab2e7', // Djibouti light blue
    },
  ],
  er: [
    'er',
    {
      label: 'Eritrea',
      image: getTerritoryImage('er'),
      color: '#12a9ed', // Eritrea blue
    },
  ],
  et: [
    'et',
    {
      label: 'Ethiopia',
      image: getTerritoryImage('et'),
      color: '#078930', // Ethiopia green
    },
  ],
  ga: [
    'ga',
    {
      label: 'Gabon',
      image: getTerritoryImage('ga'),
      color: '#009e49', // Gabon green
    },
  ],
  gh: [
    'gh',
    {
      label: 'Ghana',
      image: getTerritoryImage('gh'),
      color: '#fcd116', // Ghana yellow
    },
  ],
  gm: [
    'gm',
    {
      label: 'Gambia',
      image: getTerritoryImage('gm'),
      color: '#ce1126', // Gambia red
    },
  ],
  gn: [
    'gn',
    {
      label: 'Guinea',
      image: getTerritoryImage('gn'),
      color: '#ce1126', // Guinea red
    },
  ],
  gq: [
    'gq',
    {
      label: 'Equatorial Guinea',
      image: getTerritoryImage('gq'),
      color: '#0072c6', // Equatorial Guinea blue
    },
  ],
  gw: [
    'gw',
    {
      label: 'Guinea-Bissau',
      image: getTerritoryImage('gw'),
      color: '#ce1126', // Guinea-Bissau red
    },
  ],
  ke: [
    'ke',
    {
      label: 'Kenya',
      image: getTerritoryImage('ke'),
      color: '#009639', // Kenya green
    },
  ],
  km: [
    'km',
    {
      label: 'Comoros',
      image: getTerritoryImage('km'),
      color: '#fcd116', // Comoros yellow
    },
  ],
  ls: [
    'ls',
    {
      label: 'Lesotho',
      image: getTerritoryImage('ls'),
      color: '#00209f', // Lesotho blue
    },
  ],
  mg: [
    'mg',
    {
      label: 'Madagascar',
      image: getTerritoryImage('mg'),
      color: '#fc3d32', // Madagascar red
    },
  ],
  ml: [
    'ml',
    {
      label: 'Mali',
      image: getTerritoryImage('ml'),
      color: '#14b53a', // Mali green
    },
  ],
  mr: [
    'mr',
    {
      label: 'Mauritania',
      image: getTerritoryImage('mr'),
      color: '#006233', // Mauritania green
    },
  ],
  mu: [
    'mu',
    {
      label: 'Mauritius',
      image: getTerritoryImage('mu'),
      color: '#00a551', // Mauritius green
    },
  ],
  mw: [
    'mw',
    {
      label: 'Malawi',
      image: getTerritoryImage('mw'),
      color: '#ce1126', // Malawi red
    },
  ],
  mz: [
    'mz',
    {
      label: 'Mozambique',
      image: getTerritoryImage('mz'),
      color: '#d21034', // Mozambique red
    },
  ],
  na: [
    'na',
    {
      label: 'Namibia',
      image: getTerritoryImage('na'),
      color: '#003580', // Namibia blue
    },
  ],
  ne: [
    'ne',
    {
      label: 'Niger',
      image: getTerritoryImage('ne'),
      color: '#e32118', // Niger orange
    },
  ],
  ng: [
    'ng',
    {
      label: 'Nigeria',
      image: getTerritoryImage('ng'),
      color: '#008753', // Nigeria green
    },
  ],
  rw: [
    'rw',
    {
      label: 'Rwanda',
      image: getTerritoryImage('rw'),
      color: '#20603d', // Rwanda green
    },
  ],
  sc: [
    'sc',
    {
      label: 'Seychelles',
      image: getTerritoryImage('sc'),
      color: '#0033a1', // Seychelles blue
    },
  ],
  sl: [
    'sl',
    {
      label: 'Sierra Leone',
      image: getTerritoryImage('sl'),
      color: '#0072c6', // Sierra Leone blue
    },
  ],
  sn: [
    'sn',
    {
      label: 'Senegal',
      image: getTerritoryImage('sn'),
      color: '#008753', // Senegal green
    },
  ],
  st: [
    'st',
    {
      label: 'Sao Tome and Principe',
      image: getTerritoryImage('st'),
      color: '#12ad2b', // Sao Tome and Principe green
    },
  ],
  sz: [
    'sz',
    {
      label: 'Eswatini (Swaziland)',
      image: getTerritoryImage('sz'),
      color: '#3e4095', // Eswatini blue
    },
  ],
  td: [
    'td',
    {
      label: 'Chad',
      image: getTerritoryImage('td'),
      color: '#c60c30', // Chad blue
    },
  ],
  tg: [
    'tg',
    {
      label: 'Togo',
      image: getTerritoryImage('tg'),
      color: '#ffce00', // Togo yellow
    },
  ],
  tz: [
    'tz',
    {
      label: 'Tanzania',
      image: getTerritoryImage('tz'),
      color: '#00a2dd', // Tanzania blue
    },
  ],
  ug: [
    'ug',
    {
      label: 'Uganda',
      image: getTerritoryImage('ug'),
      color: '#fcdd09', // Uganda yellow
    },
  ],
  za: [
    'za',
    {
      label: 'South Africa',
      image: getTerritoryImage('za'),
      color: '#007a4d', // South Africa green
    },
  ],
  zm: [
    'zm',
    {
      label: 'Zambia',
      image: getTerritoryImage('zm'),
      color: '#199c38', // Zambia green
    },
  ],
  zw: [
    'zw',
    {
      label: 'Zimbabwe',
      image: getTerritoryImage('zw'),
      color: '#de2010', // Zimbabwe red
    },
  ],
}

export const STATE: FormOptionT = {
  all: ['all', { label: 'All States', color: 'darkgrey' }],
  al: ['al', { label: 'Alabama', color: '#E57373' }],
  ak: ['ak', { label: 'Alaska', color: '#F06292' }],
  az: ['az', { label: 'Arizona', color: '#BA68C8' }],
  ar: ['ar', { label: 'Arkansas', color: '#9575CD' }],
  ca: ['ca', { label: 'California', color: '#7986CB' }],
  co: ['co', { label: 'Colorado', color: '#64B5F6' }],
  ct: ['ct', { label: 'Connecticut', color: '#4FC3F7' }],
  dc: ['dc', { label: 'DC (District of Columbia)', color: '#6AC63D' }],
  de: ['de', { label: 'Delaware', color: '#4DD0E1' }],
  fl: ['fl', { label: 'Florida', color: '#4DB6AC' }],
  ga: ['ga', { label: 'Georgia', color: '#81C784' }],
  hi: ['hi', { label: 'Hawaii', color: '#AED581' }],
  id: ['id', { label: 'Idaho', color: '#DCE775' }],
  il: ['il', { label: 'Illinois', color: '#FFF176' }],
  in: ['in', { label: 'Indiana', color: '#FFD54F' }],
  ia: ['ia', { label: 'Iowa', color: '#FFB74D' }],
  ks: ['ks', { label: 'Kansas', color: '#FF8A65' }],
  ky: ['ky', { label: 'Kentucky', color: '#A1887F' }],
  la: ['la', { label: 'Louisiana', color: '#90A4AE' }],
  me: ['me', { label: 'Maine', color: '#78909C' }],
  md: ['md', { label: 'Maryland', color: '#B0BEC5' }],
  ma: ['ma', { label: 'Massachusetts', color: '#EF9A9A' }],
  mi: ['mi', { label: 'Michigan', color: '#FFAB91' }],
  mn: ['mn', { label: 'Minnesota', color: '#BCAAA4' }],
  ms: ['ms', { label: 'Mississippi', color: '#FFCC80' }],
  mo: ['mo', { label: 'Missouri', color: '#FFD180' }],
  mt: ['mt', { label: 'Montana', color: '#FFCE58' }],
  ne: ['ne', { label: 'Nebraska', color: '#D4E157' }],
  nv: ['nv', { label: 'Nevada', color: '#AED581' }],
  nh: ['nh', { label: 'New Hampshire', color: '#81C784' }],
  nj: ['nj', { label: 'New Jersey', color: '#64B5F6' }],
  nm: ['nm', { label: 'New Mexico', color: '#4DD0E1' }],
  ny: ['ny', { label: 'New York', color: '#4FC3F7' }],
  nc: ['nc', { label: 'North Carolina', color: '#7986CB' }],
  nd: ['nd', { label: 'North Dakota', color: '#9575CD' }],
  oh: ['oh', { label: 'Ohio', color: '#BA68C8' }],
  ok: ['ok', { label: 'Oklahoma', color: '#F06292' }],
  or: ['or', { label: 'Oregon', color: '#E57373' }],
  pa: ['pa', { label: 'Pennsylvania', color: '#FFAB91' }],
  ri: ['ri', { label: 'Rhode Island', color: '#FFCC80' }],
  sc: ['sc', { label: 'South Carolina', color: '#FFCE58' }],
  sd: ['sd', { label: 'South Dakota', color: '#D4E157' }],
  tn: ['tn', { label: 'Tennessee', color: '#AED581' }],
  tx: ['tx', { label: 'Texas', color: '#81C784' }],
  ut: ['ut', { label: 'Utah', color: '#64B5F6' }],
  vt: ['vt', { label: 'Vermont', color: '#4FC3F7' }],
  va: ['va', { label: 'Virginia', color: '#7986CB' }],
  wa: ['wa', { label: 'Washington', color: '#9575CD' }],
  wv: ['wv', { label: 'West Virginia', color: '#BA68C8' }],
  wi: ['wi', { label: 'Wisconsin', color: '#F06292' }],
  wy: ['wy', { label: 'Wyoming', color: '#E57373' }],
}

export const ISP: FormOptionT = {
  all: ['all', { label: 'All ISPs', color: 'darkgrey' }],
  'at&t': ['at&t', { label: 'AT&T' }],
}

export const skyShowTimeTerritoryOptions = [
  TERRITORY['dk'],
  TERRITORY['se'],
  TERRITORY['no'],
  TERRITORY['fi'],
  TERRITORY['es'],
  TERRITORY['pl'],
  TERRITORY['ro'],
  TERRITORY['nl'],
  TERRITORY['cz'],
  TERRITORY['pt'],
  TERRITORY['hu'],
  TERRITORY['rs'],
  TERRITORY['bg'],
  TERRITORY['sk'],
  TERRITORY['hr'],
  TERRITORY['ba'],
  TERRITORY['al'],
  TERRITORY['mk'],
  TERRITORY['si'],
  TERRITORY['xk'],
  TERRITORY['me'],
  TERRITORY['ad'],
]

export const nowTerritoryOptions = [
  TERRITORY['uk'],
  TERRITORY['de'],
  TERRITORY['it'],
  TERRITORY['roi'],
]

export const showmaxTerritoryOptions = [
  TERRITORY['ao'],
  TERRITORY['bf'],
  TERRITORY['bj'],
  TERRITORY['bw'],
  TERRITORY['cf'],
  TERRITORY['cd'],
  TERRITORY['cg'],
  TERRITORY['ci'],
  TERRITORY['cm'],
  TERRITORY['cv'],
  TERRITORY['dj'],
  TERRITORY['er'],
  TERRITORY['et'],
  TERRITORY['ga'],
  TERRITORY['gh'],
  TERRITORY['gm'],
  TERRITORY['gn'],
  TERRITORY['gq'],
  TERRITORY['gw'],
  TERRITORY['ke'],
  TERRITORY['km'],
  TERRITORY['ls'],
  TERRITORY['mg'],
  TERRITORY['ml'],
  TERRITORY['mr'],
  TERRITORY['mu'],
  TERRITORY['mw'],
  TERRITORY['mz'],
  TERRITORY['na'],
  TERRITORY['ne'],
  TERRITORY['ng'],
  TERRITORY['rw'],
  TERRITORY['sc'],
  TERRITORY['sl'],
  TERRITORY['sn'],
  TERRITORY['st'],
  TERRITORY['sz'],
  TERRITORY['td'],
  TERRITORY['tg'],
  TERRITORY['tz'],
  TERRITORY['ug'],
  TERRITORY['za'],
  TERRITORY['zm'],
  TERRITORY['zw'],
]

export const STREAM_TYPE: FormOptionT = {
  all: ['all', { label: 'All Stream Types' }],
  vod: ['vod', { label: 'VOD' }],
  linear: ['linear', { label: 'Linear' }],
  trailers: ['trailer', { label: 'Trailers' }],
  sle: ['sle', { label: 'SLE' }],
  clips: ['clip', { label: 'Clips' }],
  cdvr: ['cdvr', { label: 'cDVR' }],
}

export const DEVICE: FormOptionT = {
  all: ['all', { label: 'All Device Types' }],
  'apple-tv': ['apple-tv', { label: 'Apple TV' }],
  'cdvr-x2-android': ['cdvr-x2-android', { label: 'CDVR X2 Android' }],
  'cdvr-x2-desktop': ['cdvr-x2-desktop', { label: 'CDVR X2 Desktop' }],
  'cdvr-x2-ios': ['cdvr-x2-ios', { label: 'CDVR X2 iOS' }],
  'cdvr-x2-roku': ['cdvr-x2-roku', { label: 'CDVR X2 Roku' }],
  'fire-tv': ['fire-tv', { label: 'Fire TV' }],
  ios: ['ios', { label: 'iOS' }],
  'js-testplayer': ['js-testplayer', { label: 'JS Test Player' }],
  'lg-tv': ['lg-tv', { label: 'LG TV' }],
  ottxflexapp: ['ottxflexapp', { label: 'OTTX Flex App' }],
  playerplatformjs_test: [
    'playerplatformjs_test',
    { label: 'Player Platform JS Test' },
  ],
  rdk: ['rdk', { label: 'RDK' }],
  roku: ['roku', { label: 'Roku' }],
  'samsung-tv': ['samsung-tv', { label: 'Samsung TV' }],
  'stb-': ['stb-', { label: 'STB' }],
  'stb-rng150': ['stb-rng150', { label: 'STB RNG150' }],
  'stb-undefined': ['stb-undefined', { label: 'STB Undefined' }],
  'stb-xg1': ['stb-xg1', { label: 'STB XG1' }],
  'stb-xg1rm': ['stb-xg1rm', { label: 'STB XG1 RM' }],
  'stb-xg1v3': ['stb-xg1v3', { label: 'STB XG1 V3' }],
  'stb-xg1v4': ['stb-xg1v4', { label: 'STB XG1 V4' }],
  'stb-xg2v2': ['stb-xg2v2', { label: 'STB XG2 V2' }],
  'stb-xi3': ['stb-xi3', { label: 'STB XI3' }],
  'stb-xi5': ['stb-xi5', { label: 'STB XI5' }],
  'stb-xi6': ['stb-xi6', { label: 'STB XI6' }],
  'stb-xid': ['stb-xid', { label: 'STB XID' }],
  'stb-xione': ['stb-xione', { label: 'STB XI One' }],
  'stb-xione-scb': ['stb-xione-scb', { label: 'STB XI One SCB' }],
  stream: ['stream', { label: 'Stream' }],
  'stream-ios': ['stream-ios', { label: 'Stream iOS' }],
  'test-playerplatform-js': [
    'test-playerplatform-js',
    { label: 'Test Player Platform JS' },
  ],
  'vidaa-tv': ['vidaa-tv', { label: 'VIDAA TV' }],
  x1: ['x1', { label: 'X1' }],
  'x1-cox': ['x1-cox', { label: 'X1 Cox' }],
  'x2-chromecast': ['x2-chromecast', { label: 'X2 Chromecast' }],
  'x2-html': ['x2-html', { label: 'X2 HTML' }],
  'x2-sof': ['x2-sof', { label: 'X2 SOF' }],
  xbox: ['xbox', { label: 'Xbox' }],
  'xclass-tv': ['xclass-tv', { label: 'XClass TV' }],
}

export const DEVICE_TYPE: FormOptionT = {
  all: ['all', { label: 'All Device Types' }],
  stb: ['stb', { label: 'STB' }],
  mobile: ['mobile', { label: 'Mobile' }],
  'smart-tv': ['smart-tv', { label: 'Smart TV' }],
  'tv-stick': ['tv-stick', { label: 'TV Stick' }],
  console: ['console', { label: 'Console' }],
  web: ['web', { label: 'Web' }],
  desktop: ['desktop', { label: 'Desktop' }],
  'streaming-stick': ['streaming-stick', { label: 'Streaming Stick' }],
}

export const OPERATING_SYSTEM: FormOptionT = {
  all: ['all', { label: 'All Operating Systems' }],
  'amazon-fire': ['amazon-fire', { label: 'Amazon Fire' }],
  android: ['android', { label: 'Android' }],
  'android-tv': ['android-tv', { label: 'Android TV' }],
  'apple-tv': ['apple-tv', { label: 'Apple TV' }],
  chromecast: ['chromecast', { label: 'Chromecast' }],
  cox: ['cox', { label: 'Cox' }],
  desktop: ['desktop', { label: 'Desktop' }],
  explora: ['explora', { label: 'Explora' }],
  'fire-os': ['fire-os', { label: 'Fire OS' }],
  'hisense-tv': ['hisense-tv', { label: 'Hisense TV' }],
  huawei: ['huawei', { label: 'Huawei' }],
  ios: ['ios', { label: 'iOS' }],
  'lg-tv': ['lg-tv', { label: 'LG TV' }],
  linux: ['linux', { label: 'Linux' }],
  osx: ['osx', { label: 'macOS' }],
  playstation: ['playstation', { label: 'Playstation' }],
  roku: ['roku', { label: 'ROKU' }],
  rdk: ['rdk', { label: 'RDK' }],
  'samsung-tv': ['samsung-tv', { label: 'Samsung TV' }],
  'sky-q': ['sky-q', { label: 'Sky Q' }],
  streama: ['streama', { label: 'Streama' }],
  tizen: ['tizen', { label: 'Tizen' }],
  'tv-os': ['tv-os', { label: 'TV OS' }],
  vidaa: ['vidaa', { label: 'VIDAA' }],
  'vidaa-tv': ['vidaa-tv', { label: 'VIDAA TV' }],
  vizio: ['vizio', { label: 'Vizio' }],
  web: ['web', { label: 'Web' }],
  'web-os': ['web-os', { label: 'Web OS' }],
  windows: ['windows', { label: 'Windows' }],
  xbox: ['xbox', { label: 'Xbox' }],
  'xclass-tv': ['xclass-tv', { label: 'XClass TV' }],
  xfinity: ['xfinity', { label: 'XFinity' }],
  youview: ['youview', { label: 'YouView' }],
}

export const PACKAGE: FormOptionT = {
  all: ['all', { label: 'All Packages' }],
  free: ['free', {}],
  premium: ['premium', {}],
  'prem-plus': ['premium-plus', { label: 'Premium Plus' }],
  //SST package options
  standard: ['standard', { label: 'Standard' }],
  'standard-plus': ['standard-plus', { label: 'Standard Plus' }],
  unknown: ['unknown', { label: 'Unknown' }],
}

export const PRODUCT: FormOptionT = {
  all: ['all', { label: 'All Products' }],
  sport: ['sport', {}],
  hayu: ['hayu', {}],
  cinema: ['cinema', {}],
  entertainment: ['entertainment', {}],
  boost: ['boost', {}],
  other: ['other', {}],
}

export const EVENT_STREAM_TYPE: FormOptionT = {
  linear: [
    'linear',
    {
      label: 'Linear',
      icon: RiSignalTowerFill,
      url: {
        attach: 'resource',
        formatter: 'linear',
        remove: ['stream-type'],
      },
    },
  ],
  vod: [
    'vod',
    {
      label: 'VOD',
      icon: BsCollectionPlay,
      url: {
        attach: 'resource',
        formatter: 'vod',
        remove: ['stream-type'],
      },
    },
  ],
  sle: [
    'sle',
    {
      label: 'SLE',
      icon: HiOutlineTicket,
      url: {
        attach: 'resource',
        formatter: 'sle',
        remove: ['stream-type'],
      },
    },
  ],
  cdvr: [
    'cdvr',
    {
      label: 'cDVR',
      icon: HiOutlineCollection,
      url: {
        attach: 'resource',
        formatter: 'cdvr',
        remove: ['stream-type'],
      },
    },
  ],
}

export const EVENT_STREAM_TYPE_WITH_CLIENT: FormOptionT = {
  linear: [
    'linear',
    {
      label: 'Linear',
      icon: RiSignalTowerFill,
      url: {
        attach: 'resource',
        formatter: 'linear',
        remove: ['stream-type'],
      },
    },
  ],
  vod: [
    'vod',
    {
      label: 'VOD',
      icon: BsCollectionPlay,
      url: {
        attach: 'resource',
        formatter: 'vod',
        remove: ['stream-type'],
      },
    },
  ],
  sle: [
    'sle',
    {
      label: 'SLE',
      icon: HiOutlineTicket,
      url: {
        attach: 'resource',
        // attach: 'param',
        // TODO AK: Duct tape solution to pass additional quality metrics to move on with the rest of the work work, needs to be fixed
        formatter: 'sle',
        remove: ['stream-type'],
      },
    },
  ],
  cdvr: [
    'cdvr',
    {
      label: 'cDVR',
      icon: HiOutlineCollection,
      url: {
        attach: 'resource',
        formatter: 'cdvr', //WHAT IS THIS STREAM'S DEFAULT METRIC
        remove: ['stream-type'],
      },
    },
  ],
}

export const EVENT_REPORT_RANK: FormOptionT = {
  all: ['all', { label: 'All ranks' }],
  platinum: ['platinum', { label: 'Platinum' }],
  gold: ['gold', { label: 'Gold' }],
  silver: ['silver', { label: 'Silver' }],
}

export const INCIDENT_SEVERITY: FormOptionT = {
  '1': ['1', { label: 'P1' }],
  '2': ['2', { label: 'P2' }],
  '3': ['3', { label: 'P3' }],
  '4': ['4', { label: 'P4' }],
}

export const DEPARTMENT: FormOptionT = {
  all: ['all', { label: 'All departments' }],
  aieng: [
    'aieng',
    {
      label: 'AI Engineering',
      resources: [
        {
          type: 'slack',
          href: 'https://sky.slack.com/archives/CTK3E0PRU',
          label: '#help-ai-engineering',
        },
        {
          type: 'confluence',
          href: 'https://wiki.at.sky/pages/viewpage.action?pageId=182722141',
        },
        {
          type: 'email',
          href: 'mailto:aiengineering@sky.uk',
          label: 'aiengineering@sky.uk',
        },
      ],
    },
  ],
  ovp: [
    'ovp',
    {
      label: 'OVP',
      resources: [
        {
          type: 'slack',
          href: 'https://sky.slack.com/archives/C0C1GTTTJ',
          label: '#ovp',
        },
        {
          type: 'confluence',
          href: 'https://wiki.at.sky/display/OVP/OTT+Video+Platform+Home',
        },
      ],
    },
  ],
  sas: [
    'sas',
    {
      label: 'Sales & Service',
      resources: [
        {
          type: 'slack',
          href: 'https://sky.slack.com/archives/CJJV35N7P',
          label: '#sales-and-service',
        },
        {
          type: 'confluence',
          href: 'https://wiki.at.sky/pages/viewpage.action?pageId=62855510',
        },
      ],
    },
  ],
  identity: [
    'identity',
    {
      label: 'Identity',
      resources: [
        {
          type: 'slack',
          href: 'https://sky.slack.com/archives/C2RQRC7JL',
          label: '#group-identity-and-access-management-help',
        },
        {
          type: 'confluence',
          href: 'https://wiki.at.sky/display/OTTIDENT',
        },
      ],
    },
  ],
  sdp: [
    'sdp',
    {
      label: 'SDP',
      resources: [
        {
          type: 'confluence',
          href: 'https://wiki.at.sky/display/DAP/Strategic+Data+Platform',
        },
      ],
    },
  ],
  discovery: [
    'discovery',
    {
      label: 'Discovery',
      resources: [
        {
          type: 'slack',
          href: 'https://sky.slack.com/archives/C6K9T6RL5',
          label: '#disco-department',
        },
        {
          type: 'github',
          href: 'https://github.com/sky-uk/discovery',
        },
      ],
    },
  ],
}

export const REGION: FormOptionT = {
  all: ['all', { label: 'All regions' }],
  'eu-central-1': ['eu-central-1', { label: 'EU Central 1' }],
  'eu-west-1': ['eu-west-1', { label: 'EU West 1' }],
  'us-east-2': ['us-east-2', { label: 'US East 2' }],
  'us-west-2': ['us-west-2', { label: 'US West 2' }],
}

export const HPE_CATEGORY: FormOptionT = {
  all: ['all', { label: 'All Categories' }],
  'football-pl': ['football-pl', { label: 'Football – Premier League' }],
  'football-efl': ['football-efl', { label: 'Football – EFL Championship' }],
  'football-sp': ['football-sp', { label: 'Football – Scottish Premier' }],
  'football-bundes': ['football-bundes', { label: 'Football - Bundesliga' }],
  'football-seriea': ['football-seriea', { label: 'Football - Serie A' }],
  'football-eu': ['football-eu', { label: 'Football - European Club' }],
  'football-fifa-2022': [
    'football-fifa-2022',
    { label: 'Football - FIFA World Cup 2022' },
  ],
  'football-int': ['football-int', { label: 'Football - International' }],
  'football-other': ['football-other', { label: 'Football - Other' }],
  formula: ['formula', { label: 'Formula 1' }],
  'americanfootball-nfl': [
    'americanfootball-nfl',
    { label: 'American Football - NFL' },
  ],
  'americanfootball-college': [
    'americanfootball-college',
    { label: 'American Football - College' },
  ],
  wwe: ['wwe', { label: 'WWE' }],
  cricket: ['cricket', { label: 'Cricket' }],
  darts: ['darts', { label: 'Darts' }],
  golf: ['golf', { label: 'Golf' }],
  tennis: ['tennis', { label: 'Tennis' }],
  'rugby-union': ['rugby-union', { label: 'Rugby Union' }],
  'rugby-league': ['rugby-league', { label: 'Rugby League' }],
  cycling: ['cycling', { label: 'Cycling' }],
  boxing: ['boxing', { label: 'Boxing' }],
  basketball: ['basketball', { label: 'Basketball' }],
  baseball: ['baseball', { label: 'Baseball' }],
  motorsports: ['motorsports', { label: 'Motorsports' }],
  netball: ['netball', { label: 'Netball' }],
  'other-sports': ['other-sports', { label: 'Other Sports' }],
  'non-sport': ['non-sport', { label: 'Non-Sport' }],
}

export const HPE_TYPE: FormOptionT = {
  all: ['all', { label: 'All Event Types' }],
  f1: ['f1', { label: 'F1' }],
}

export const STATUS_GROUP: FormOptionT = {
  all: ['all', { label: 'All Status Groups' }],
  'n/a': ['n/a', { label: 'N/A' }],
  '1xx': ['1xx', { label: '1xx' }],
  '2xx': ['2xx', { label: '2xx' }],
  '3xx': ['3xx', { label: '3xx' }],
  '4xx': ['4xx', { label: '4xx' }],
  '5xx': ['5xx', { label: '5xx' }],
}
//    video-restart-time connection-induced-rr, ended-plays,
export const METRIC: FormOptionT = {
  all: [
    'all',
    {
      label: 'All',
    },
  ],
  plays: [
    'plays',
    {
      label: 'Plays',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'plays',
      },
    },
  ],
  'concurrent-plays': [
    'concurrent-plays',
    {
      label: 'Concurrent Plays',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'concurrent-plays',
        remove: ['metric'],
      },
    },
  ],
  'total-attempts': [
    'total-attempts',
    {
      label: 'Attempts',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'total-attempts',
        remove: ['metric'],
      },
    },
  ],
  'rebuffering-ratio': [
    'rebuffering-ratio',
    {
      label: 'Rebuffering',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'rebuffering-ratio',
        remove: ['metric'],
      },
    },
  ],
  'connection-induced-rebuffering-ratio': [
    'connection-induced-rebuffering-ratio',
    {
      label: 'Connection Induced Rebuffering',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'connection-induced-rebuffering-ratio',
        remove: ['metric'],
      },
    },
  ],
  'average-bitrate': [
    'average-bitrate',
    {
      label: 'Average Bitrate',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'average-bitrate',
        remove: ['metric'],
      },
    },
  ],
  'exits-before-video-start': [
    'exits-before-video-start',
    {
      label: 'Exits Before Video Start',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'exits-before-video-start',
        remove: ['metric'],
      },
    },
  ],
  'video-startup-time': [
    'video-startup-time',
    {
      label: 'Video Startup Time (s)',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-startup-time',
        remove: ['metric'],
      },
    },
  ],
  'video-restart-time': [
    'video-restart-time',
    {
      label: 'Video Restart Time (s)',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-restart-time',
        remove: ['metric'],
      },
    },
  ],
  'ended-plays': [
    'ended-plays',
    {
      label: 'Ended Plays',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'ended-plays',
        remove: ['metric'],
      },
    },
  ],
  'video-start-failures-percentage': [
    'video-start-failures-percentage',
    {
      label: 'Video Start Failures %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-start-failures-percentage',
        remove: ['metric'],
      },
    },
  ],
  'video-start-failures-technical-percentage': [
    'video-start-failures-technical-percentage',
    {
      label: 'Video Start Failures Technical %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-start-failures-technical-percentage',
        remove: ['metric'],
      },
    },
  ],
  'video-start-failures-business-percentage': [
    'video-start-failures-business-percentage',
    {
      label: 'Video Start Failures Business %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-start-failures-business-percentage',
        remove: ['metric'],
      },
    },
  ],
  'video-playback-failures-percentage': [
    'video-playback-failures-percentage',
    {
      label: 'Video Playback Failures %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-playback-failures-percentage',
        remove: ['metric'],
      },
    },
  ],
  'video-playback-failures-technical-percentage': [
    'video-playback-failures-technical-percentage',
    {
      label: 'Video Payback Failures Technical %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-playback-failures-technical-percentage',
        remove: ['metric'],
      },
    },
  ],
  'video-playback-failures-business-percentage': [
    'video-playback-failures-business-percentage',
    {
      label: 'Video Playback Failures Business %',
      url: {
        attach: 'resource',
        resourceGroupDomain: 'metrics',
        resourceGroup: 'metrics',
        resourceIndex: 1,
        formatter: 'video-playback-failures-business-percentage',
        remove: ['metric'],
      },
    },
  ],
}

export const formOptions: Record<string, FormOptionT> = {
  proposition: PROPOSITION,
  territory: TERRITORY,
  'stream-type': STREAM_TYPE,
  device: DEVICE,
  'device-type': DEVICE_TYPE,
  'operating-system': OPERATING_SYSTEM,
  package: PACKAGE,
  product: PRODUCT,
  department: DEPARTMENT,
  region: REGION,
  'hpe-category': HPE_CATEGORY,
  'hpe-type': HPE_TYPE,
  'status-group': STATUS_GROUP,
  state: STATE,
  isp: ISP,
}
