import { HiLightningBolt, HiOutlineLightningBolt } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusApplicationConfig from './common/prometheusApplication'

const totalResponses: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusApplicationConfig = commonPrometheusApplicationConfig()

  return {
    ...prometheusApplicationConfig,
    value: 'total-responses',
    label: 'Total Responses',
    shortLabel: 'Total Res.',
    dataKey: 'totalResponses',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: HiLightningBolt,
    solidIcon: HiOutlineLightningBolt,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Total Responses measures the total number of responses generated by a monitored system or service.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusApplicationConfig.defaultConfig,
      metric: 'total-responses',
    },
  }
}

export default totalResponses
