import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCloudEngGatewayConfig from './common/prometheusCloudEngGateway'

const throughputCloudEngGW: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCloudEngGatewayConfig =
    commonPrometheusCloudEngGatewayConfig()

  return {
    ...prometheusCloudEngGatewayConfig,
    value: 'throughput-cloud-eng-gw',
    apiParam: 'throughput',
    label: 'Throughput (Cloud Eng GW)',
    shortLabel: 'Throughput (Cloud Eng GW)',
    dataKey: 'throughput',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format(
        value,
      ),
    info: 'Throughput refers to the amount of data or operations that can be processed and transferred successfully within a specified time frame in a cloud computing environment. It is often measured in terms of data transfer rates, operations per second, or other relevant units, depending on the specific context.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCloudEngGatewayConfig.defaultConfig,
      metric: 'throughput-cloud-eng-gw',
    },
  }
}

export default throughputCloudEngGW
