import clsx from 'clsx'
import { connectRange } from 'react-instantsearch-dom'

import { InstantSearchRangeSliderFormI } from '@/config/types'
import { Slider } from '@mui/material'
import { makeStyles } from '@mui/styles'

const HISTOGRAM_HEIGHT = 40
const NUMBER_OF_STEPS = 20

const getStep = (min: number, max: number, numberOfSteps: number): number => {
  // Find the range between min and max
  const range = max - min

  // Find the order of magnitude of the range
  const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(range)) - 1)

  // Calculate the step size by dividing the range by the number of steps
  // and rounding to the nearest multiple of the order of magnitude
  let stepSize =
    Math.round(range / numberOfSteps / orderOfMagnitude) * orderOfMagnitude

  // If the step size is less than 1, we need to recalculate it
  if (stepSize < 1) {
    // Find the order of magnitude of the step size
    const newOrderOfMagnitude = Math.pow(
      10,
      Math.floor(Math.log10(stepSize)) - 1,
    )

    // Calculate the step size by dividing the original step size by the
    // new order of magnitude and rounding to the nearest multiple of the new order of magnitude
    stepSize = Math.round(stepSize / newOrderOfMagnitude) * newOrderOfMagnitude
  }

  // Return the step size
  return stepSize
}

const useStyles = makeStyles((theme: any) => ({
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.background.default || '#fff',
    border: '2px solid currentColor',
    '&:hover': {
      boxShadow: `0px 0px 0px 4px rgba(var(--colors-elements-primary-main) / 16%)`,
    },
    '&$focused, &.Mui-focusVisible, &$activated, &$jumped': {
      boxShadow: `0px 0px 0px 6px rgba(var(--colors-elements-primary-main) / 16%)`,
    },
  },
}))

const Histogram = ({
  data,
  stepIndexes,
}: {
  data: number[]
  stepIndexes: [number, number]
}): any => {
  const maxValue = Math.max(...data)
  return (
    <div className='flex items-end justify-between mb-1'>
      {data.map((value: number, index) => {
        return (
          <div
            key={index}
            className={clsx('bg-elements-primary-main', {
              'bg-opacity-20':
                index < stepIndexes[0] || index >= stepIndexes[1],
            })}
            style={{
              height: (value / maxValue) * HISTOGRAM_HEIGHT,
              width: `${80 / data.length}%`,
            }}
          />
        )
      })}
    </div>
  )
}

const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-GB', {}).format(value)
}

interface RangeSliderPropsI {
  currentRefinement: { min?: number; max?: number }
  count: { value: string }[]
  refine: (params: { min?: number; max?: number }) => void
  canRefine: boolean
}

export const RangeSlider = ({
  currentRefinement,
  count,
  refine,
}: RangeSliderPropsI) => {
  const classes = useStyles()

  const countValues = count
    .map(({ value }: { value: string }) => Number(value))
    .sort((a: number, b: number) => a - b)

  const min = 0
  const max = Math.max(...countValues) || 0
  const step = getStep(min, max, NUMBER_OF_STEPS)

  const histogramData = new Array(Math.floor(max / step) + 1).fill(0)

  countValues.forEach((value: number) => {
    const histogramStep = Math.floor(value / step)
    histogramData[histogramStep]++
  })

  const value = [
    currentRefinement.min ?? 0,
    Math.min(currentRefinement.max ?? max, max),
  ]

  return (
    <div>
      <div className='flex mb-4 text-xs font-medium text-text-secondary gap-x-1'>
        <p>{formatNumber(value[0])}</p> - <p>{formatNumber(value[1])}</p>
      </div>
      <Histogram
        data={histogramData}
        stepIndexes={[value[0] / step, value[1] / step]}
      />
      <Slider
        classes={{
          thumb: classes.thumb,
        }}
        min={min}
        max={max}
        step={step}
        onChange={(event, newValue: number[] | number) => {
          if (Array.isArray(newValue)) {
            const [min, max] = newValue
            refine({ min, max })
          }
        }}
        value={value}
        defaultValue={value}
      />
      <div className='flex justify-between text-xs font-medium text-text-tertiary'>
        <p>{formatNumber(min)}</p>
        <p>{formatNumber(max)}</p>
      </div>
    </div>
  )
}

const RangeSliderFormGroup = ({
  formGroup,
  ...rangeSliderProps
}: {
  formGroup: InstantSearchRangeSliderFormI
} & any) => {
  if (!rangeSliderProps.canRefine) return null
  return (
    <li
      role='group'
      className='pb-8 mt-2 border-b border-border-main'
      key={formGroup.label}
    >
      <label className='pl-2 mb-3 text-xs font-semibold uppercase align-top text-text-tertiary'>
        {formGroup.label}
      </label>
      <div className='px-2 mt-4'>
        <RangeSlider formGroup={formGroup} {...rangeSliderProps} />
      </div>
    </li>
  )
}

const CustomRangeSlider = connectRange(RangeSliderFormGroup)

export default CustomRangeSlider
