import moment from 'moment-timezone'

import getTimezoneLabel from './getTimezoneLabel'

const getDateRangeString = ({
  start,
  end,
  includeDay = false,
  getRelative = false,
  timezone,
}: {
  start: any
  end?: any
  includeDay?: boolean
  getRelative?: boolean
  timezone?: string
}): any => {
  const _start = timezone
    ? moment(start).tz(timezone).clone()
    : moment(start).clone()

  let format
  if (includeDay) {
    format = 'ddd Do MMM YY, HH:mm'
  } else {
    format = 'Do MMM YY, HH:mm'
  }

  if (!end && getRelative) {
    const relative = moment().isBefore(_start)
      ? `Starts ${_start.clone().fromNow()}`
      : `Started ${_start.clone().fromNow()}`
    return { start: _start.format(format), relative }
  }
  if (!end) {
    return {
      start: timezone
        ? _start.format(`${format} [${getTimezoneLabel(_start, timezone)}]`)
        : _start.format(format),
    }
  } else {
    const _end = timezone
      ? moment(end).tz(timezone).clone()
      : moment(end).clone()

    const relative = moment().isAfter(_end)
      ? `Ended ${_end.clone().fromNow()}`
      : moment().isBefore(_start)
      ? `Starts ${_start.clone().fromNow()}`
      : `Started ${_start.clone().fromNow()}`
    // const relative = `Started ${_start.clone().fromNow()}`;

    const isSameDay = _start.isSame(_end, 'day')

    const isSameTzLabel =
      timezone &&
      getTimezoneLabel(_start, timezone) === getTimezoneLabel(_end, timezone)

    if (isSameDay) {
      if (timezone) {
        return {
          relative,
          start: _start.format(format),
          end: _end.format(`HH:mm [${getTimezoneLabel(_end, timezone)}]`),
        }
      }
      return {
        relative,
        start: _start.format(format),
        end: _end.format('HH:mm'),
      }
    } else {
      if (timezone) {
        return {
          relative,
          start: isSameTzLabel
            ? _start.format(format)
            : _start.format(
                `${format} [${getTimezoneLabel(_start, timezone)}]`,
              ),
          end: _end.format(`${format} [${getTimezoneLabel(_end, timezone)}]`),
        }
      }

      return {
        relative,
        start: _start.format(format),
        end: _end.format(format),
      }
    }
  }
}

export default getDateRangeString
