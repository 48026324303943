import { BsDatabaseFillUp, BsDatabaseUp } from 'react-icons/bs'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreNetworkConfig from './common/prometheusCoreNetwork'

const trafficIn: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreNetworkConfig = commonPrometheusCoreNetworkConfig()

  return {
    ...prometheusCoreNetworkConfig,
    value: 'traffic-in',
    label: 'Traffic In',
    shortLabel: 'Traffic In',
    dataKey: 'trafficIn',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: BsDatabaseFillUp,
    solidIcon: BsDatabaseUp,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Traffic In refers to the incoming data or communication flowing into the core network from external sources. Incoming traffic monitoring is crucial for managing network resources efficiently, optimising performance, and ensuring a seamless experience for end-users.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreNetworkConfig.defaultConfig,
      metric: 'traffic-in',
    },
  }
}

export default trafficIn
