import clsx from 'clsx'
import { usePopperTooltip } from 'react-popper-tooltip'

interface PropsI {
  text?: string
  className?: string
  style?: any
  tooltip?: string
  delay?: number
  component?: JSX.Element
}

const InfoText = ({
  text,
  className,
  style,
  tooltip,
  delay,
  component,
}: PropsI): JSX.Element => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'auto', delayShow: delay })

  return (
    <>
      <p
        ref={setTriggerRef}
        className={clsx(
          'border-b',
          'border-dashed',
          'border-zinc-400',
          'cursor-help',
          className,
        )}
        style={style}
      >
        {text}
        {component}
      </p>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container-themed',
          })}
        >
          <p className='w-full max-w-sm text-sm font-medium text-text-secondary'>
            {tooltip}
          </p>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  )
}
export default InfoText
