import React from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { Outlet } from 'react-router-dom'

import { useUiStore } from '@/stores/ui.store'
import { setUserProperties } from '@/utils/firebaseAnalytics'
import { BrowserUtils } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import { env } from '../../env'
// Hooks
// import useHistoricNotificationsData from '../../hooks/useHistoricNotificationsData'
import useSettings from '../../hooks/useSettings'
import { useSelectedTheme } from '../../hooks/useTheme'
// Store
import { useThemeStore } from '../../stores/theme.store'
// Utils
import { getUser } from '../../utils/getUser'
// Components
import GlobalAlerts from '../GlobalAlerts'
import Lights from '../Lights'
// Navigation Type Components
import Nav1 from '../Nav/Nav1'

const NavigationSwitch = (props: any) => {
  const { appSettings } = useSettings()
  const { navigation } = appSettings

  switch (navigation?.type) {
    default:
      return <Nav1 {...props} />
  }
}

const Layout = () => {
  const { instance: msalInstance } = useMsal()
  const { appSettings } = useSettings()
  const setTheme = useThemeStore((state) => state.setTheme)
  const theme = useThemeStore((state) => state.theme)
  const xmasMode = useUiStore((state) => state.xmasMode)
  const selectedTheme = useSelectedTheme()

  const user = useQuery('user', getUser)

  // const { notifications } = useHistoricNotificationsData({
  //   key: 'notifications',
  //   config: { ...notificationsSettings.defaultConfig, 'page-size': 10 },
  //   reactQueryProps: {
  //     refetchInterval: 60 * 1000, // 1 minute
  //   },
  // })

  const handleLogout = () => {
    msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    })
  }

  const handleSetTheme = (selectedTheme: ThemeT) => {
    setTheme(selectedTheme)
  }

  const isDevelopmentDeployment = env.REACT_APP_ENV === 'development'

  const title = `${isDevelopmentDeployment ? '[DEV] ' : ''} ${
    appSettings?.name
  }`

  React.useEffect(() => {
    setUserProperties({ insightsDashboardTheme: theme })
  }, [theme])

  return (
    <>
      <Helmet>
        <meta property='og:site_name' content={appSettings.name} />
        <meta property='og:type' content='object' />
        <meta property='og:image' content={appSettings.logo.light.app} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='600' />
        <meta property='og:description' content={appSettings.description} />
        <title>{title}</title>
        <meta name='description' content={appSettings.description} />
      </Helmet>

      <div className='relative flex h-screen overflow-hidden bg-neutral-dimmed-heavy'>
        <NavigationSwitch
          // notifications={notifications}
          logout={handleLogout}
          user={user}
          theme={selectedTheme}
          setTheme={handleSetTheme}
        >
          {xmasMode && <Lights />}

          <GlobalAlerts restrictedRoutes={['/metrics']} />
          <Outlet />
        </NavigationSwitch>
      </div>
    </>
  )
}

export default Layout
