import clsx from 'clsx'
import {
  HiOutlineCheckCircle,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiX,
} from 'react-icons/hi'

import IconButton from '../IconButton'

const getIcon = (appearance: GlobalAlertT['type']) => {
  switch (appearance) {
    case 'CRITICAL':
      return HiOutlineExclamationCircle
    case 'WARNING':
      return HiOutlineExclamation
    case 'SUCCESS':
      return HiOutlineCheckCircle
    case 'INFO':
    default:
      return HiOutlineInformationCircle
  }
}

const Banner = ({
  dismissable = false,
  message = '',
  appearance = 'INFO',
  showIcon = true,
  onClick,
}: {
  dismissable?: boolean
  message: string
  appearance: GlobalAlertT['type']
  showIcon: boolean
  onClick?: (v?: any) => void
}) => {
  const Icon = getIcon(appearance)
  return (
    <div
      className={clsx(
        {
          'bg-helpers-warning-main': appearance === 'WARNING',
          'bg-helpers-success-main': appearance === 'SUCCESS',
          'bg-helpers-error-main': appearance === 'CRITICAL',
          'bg-helpers-info-main': appearance === 'INFO',
        },

        {
          'w-[500px] rounded shadow-lg': dismissable,
        },
        {
          'outline-helpers-warning-button-hover/10 outline-1 outline':
            appearance === 'WARNING' && dismissable,
          'outline-helpers-success-button-hover/10 outline-1 outline':
            appearance === 'SUCCESS' && dismissable,
          'outline-helpers-error-button-hover/10 outline-1 outline':
            appearance === 'CRITICAL' && dismissable,
          'outline-helpers-info-button-hover/10 outline-1 outline':
            appearance === 'INFO' && dismissable,
        },
      )}
    >
      <div
        className={clsx('px-3 py-3 mx-auto', {
          'sm:px-6 lg:px-8': !dismissable,
        })}
      >
        <div className='flex flex-wrap items-center justify-between'>
          <div className='flex items-center flex-1 w-0'>
            {showIcon && (
              <span
                className={clsx('flex p-2 rounded-lg', {
                  'bg-helpers-warning-button': appearance === 'WARNING',
                  'bg-helpers-success-button': appearance === 'SUCCESS',
                  'bg-helpers-error-button': appearance === 'CRITICAL',
                  'bg-helpers-info-button': appearance === 'INFO',
                })}
              >
                <Icon className='w-6 h-6 text-white' />
              </span>
            )}
            <p
              className={clsx('flex-1 ml-6 font-medium text-white', {
                'text-sm': dismissable,
              })}
            >
              {message}
            </p>
            {onClick && dismissable && (
              <IconButton
                className='bg-transparent border-none text-white/90 hover:text-white/100 hover:bg-neutral-dimmed-heavy/40'
                Icon={HiX}
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
