import { useClientStore } from '@/stores/client.store'
import ReactImageFallback from 'react-image-fallback'

interface ImageFallbackProps {
  src: string
  alt: string
  className?: string
}

function ImageFallback({ src, alt, className }: ImageFallbackProps) {
  const client = useClientStore((state) => state.client)
  const fallbackImage = `/images/${client}/image-placeholder.png`

  return (
    <ReactImageFallback
      src={src}
      fallbackImage={fallbackImage}
      initialImage={fallbackImage}
      alt={alt}
      className={className}
    />
  )
}

export default ImageFallback
