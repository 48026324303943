import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonConvivaConfig from './common/conviva'

const concurrentPlays: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    default: true,
    value: 'concurrent-plays',
    label: 'Concurrency',
    dataKey: 'concurrentPlays',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 2,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    aggregationType: 'peak',
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    icon: HiOutlineUserGroup,
    solidIcon: HiUserGroup,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Concurrency (Concurrent Plays) shows the maximum number of simultaneous plays during a given interval.',
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'concurrent-plays',
    },
  }
}

export default concurrentPlays
