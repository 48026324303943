import React from 'react'

import useTheme from '../hooks/useTheme'

const ThemeProvider = ({ children }) => {
  const theme = useTheme()

  React.useEffect(() => {
    const element = document.documentElement
    element.classList.remove(...element.classList)
    element.classList.add(theme?.value)
    element.setAttribute('data-color-scheme', theme?.type)
  }, [theme])

  return children
}

export default ThemeProvider
