import { CgSpinner } from 'react-icons/cg'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonConvivaConfig from './common/conviva'

const connectionInducedRebufferingRatio: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'connection-induced-rebuffering-ratio',
    label: 'Connection Induced Rebuffering',
    shortLabel: 'CIRR',
    dataKey: 'connectionInducedRebufferingRatio',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: CgSpinner,
    solidIcon: CgSpinner,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    info: 'Connection Induced Rebuffering Ratio measures the percentage of video sessions that encounter rebuffering solely due to network conditions, excluding rebuffering caused by user interactions or other factors. CIRR helps assess the quality of the streaming experience by focusing specifically on issues related to the network connection.',
    aggregationType: 'average',
    invertedChangeColor: true,
    ragThresholds: {
      green: 0,
      amber: 0.2,
      red: 0.4,
    },
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'connection-induced-rebuffering-ratio',
    },
  }
}

export default connectionInducedRebufferingRatio
