import React from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { useModalStore } from '../stores/modal.store'

import Modal from '../components/Modal'

const ModalProvider = () => {
  const open = useModalStore((state) => state.open)
  const modal = useModalStore((state) => state.modal)
  const closeModal = useModalStore((state) => state.closeModal)

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-30 overflow-y-auto'
        open={open}
        onClose={closeModal}
      >
        <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-zinc-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <Modal {...modal} closeModal={closeModal} />
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalProvider
