import { HiOutlinePlay, HiPlay } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonOcellusConfig from './common/ocellus'

const playsOcellus: MetricSettingsFn = ({
  variants,
  featureFlags,
}): MetricSettingsI => {
  const ocellusConfig = commonOcellusConfig({ variants, featureFlags })

  return {
    ...ocellusConfig,
    value: 'plays-ocellus',
    apiParam: 'plays',
    label: 'Plays',
    dataKey: 'plays',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: HiOutlinePlay,
    solidIcon: HiPlay,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Plays shows the number of sessions where at least one frame of a video was displayed over a given interval.',
    aggregationType: 'total',
    defaultConfig: {
      ...ocellusConfig.defaultConfig,
      metric: 'plays-ocellus',
    },
  }
}

export default playsOcellus
