import moment from 'moment-timezone'

// America/New_York, Europe/London

export default function getTimezoneLabel(
  date: any,
  timezone: string,
  longLabel?: boolean,
) {
  const abbrs: { [key: string]: string } = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
    UTC: 'Coordinated Universal Time',
    BST: 'British Summer Time',
    GMT: 'Greenwich Mean Time',
  }

  const display = moment.tz(date, timezone).zoneAbbr()

  if (longLabel && abbrs[display]) {
    return abbrs[display]
  }

  return display
}
