import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreIngressConfig from './common/prometheusCoreIngress'

const writingConnections: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreIngressConfig = commonPrometheusCoreIngressConfig()

  return {
    ...prometheusCoreIngressConfig,
    value: 'writing-connections',
    label: 'Writing Connections',
    shortLabel: 'Writing Con.',
    dataKey: 'writingConnections',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Writing Connections refers to the number of active connections in which data is currently being written or transmitted.',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusCoreIngressConfig.defaultConfig,
      metric: 'writing-connections',
    },
  }
}

export default writingConnections
