import { HiOutlineInformationCircle } from 'react-icons/hi'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'

import RequestContextContent from '@/config/pagesSettings/faqsPageSettings/components/RequestContextContent'

import { cn } from '@/utils/cn'

function RequestContextInformationDialogContent() {
  return (
    <DialogContent className='sm:max-w-[425px] overflow-y-auto overflow-x-hidden max-h-screen'>
      <DialogHeader>
        <DialogTitle>Requesting a context</DialogTitle>
      </DialogHeader>

      <div className='pt-4 border-t border-border-main text-text-primary'>
        <RequestContextContent />
      </div>
    </DialogContent>
  )
}

interface RequestContextInformationDialogButtonProps {
  className?: string
}

export default function RequestContextInformationDialogButton({
  className,
}: RequestContextInformationDialogButtonProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className={cn('w-full', className)}>
          <HiOutlineInformationCircle className='w-5 h-5 mr-2' />
          How can I request a context?
        </Button>
      </DialogTrigger>
      <RequestContextInformationDialogContent />
    </Dialog>
  )
}
