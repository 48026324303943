import useSettings from '@/hooks/useSettings'
import { useUserStore } from '@/stores/user.store'
import { cn } from '@/utils/cn'

export default function RequestContextContent({
  className,
}: {
  className?: string
}) {
  const { contactPageSettings } = useSettings()
  const user = useUserStore((state) => state.user)

  const isNBCU = JSON.stringify(user).includes('@nbcuni.com')

  const teamId = contactPageSettings.slack.teamId[isNBCU ? 'nbcu' : 'sky']

  return (
    <div className={cn(className)}>
      <p className='mb-4'>
        To request client access, follow these steps in Spark:
      </p>
      <ol className='list-decimal list-inside'>
        <li>
          Visit the{' '}
          <a
            href='https://tech.at.sky/techss?id=sc_cat_item_guide&sys_id=a3a1274137b52f00c4950c5754990e68'
            target='_blank'
            rel='noreferrer'
            className='text-elements-primary-main hover:underline'
          >
            System Access Request form in Spark
          </a>
          .
        </li>
        <li>Choose a user.</li>
        <li>{`Pick 'OTT Insights' as the 'Required System'.`}</li>
        <li>{`In the next step, select your desired context from the 'Required Access Rights' dropdown.`}</li>
      </ol>
      <p className='mt-6 text-sm italic'>
        Need more help? Visit{' '}
        <a
          href={`slack://channel?team=${teamId}&id=${contactPageSettings.slack.id}`}
          target='_blank'
          rel='noreferrer'
          className='text-elements-primary-main hover:underline'
        >
          {contactPageSettings.slack.channel}
        </a>{' '}
        on Slack, or email{' '}
        <a
          href={`mailto:${contactPageSettings.email.address}`}
          target='_blank'
          rel='noreferrer'
          className='text-elements-primary-main hover:underline'
        >
          {contactPageSettings.email.address}
        </a>
        .
      </p>
    </div>
  )
}
