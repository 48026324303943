import * as React from 'react'
import { HiClipboard } from 'react-icons/hi'

import IconButton from '@/components/IconButton'
import Switch from '@/components/Switch'

import internaliseUrl from '../../utils/internaliseUrl'

const DevUrl = ({ url, payload }: { url: string; payload?: any }) => {
  const [isInternal, setIsInternal] = React.useState(true)

  const internalisedUrl = internaliseUrl(url)
  const payloadString = JSON.stringify(payload, null, 2)

  const resolvedUrl = isInternal ? internalisedUrl : url

  return (
    <div
      className='flex-col px-3 py-1 mb-4 space-y-2 rounded-md bg-neutral'
      onClick={(e) => e.stopPropagation()}
    >
      <Switch
        label='Internal'
        checked={isInternal}
        onChange={() => setIsInternal((prevIsInternal) => !prevIsInternal)}
      />
      <a
        href={resolvedUrl}
        target='_blank'
        rel='noreferrer'
        className='text-xs line-clamp-2 hover:underline text-text-secondary'
      >
        {resolvedUrl}
      </a>
      {payloadString && (
        <pre
          style={{
            position: 'relative',
            color: 'chartreuse',
            backgroundColor: 'black',
            fontSize: '.55rem',
            padding: '.25rem .5rem',
            overflow: 'scroll',
            maxHeight: '150px',
          }}
        >
          <IconButton
            className='absolute right-2 top-2'
            Icon={HiClipboard}
            label='Copy to clipboard'
            delayShowTooltip={2000}
            onClick={() => navigator.clipboard.writeText(payloadString)}
          />
          <code>{payloadString}</code>
        </pre>
      )}
    </div>
  )
}

export default DevUrl
