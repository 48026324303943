const fallback = null

const convivaAggregationTotal = ({ settings = {}, data }) => {
  const { dataKey } = settings

  if (data?.data?.total) {
    const valueExists =
      data?.data?.total?.[0]?.[dataKey] ||
      data?.data?.total?.[0]?.[dataKey] === 0
    return valueExists ? data?.data?.total?.[0]?.[dataKey] : fallback
  } else {
    return fallback
  }
}

export default convivaAggregationTotal
