/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const colors = require('tailwindcss/colors')
const getFullPalette = require('../utils/getFullPalette')
const tinycolor = require('tinycolor2')

// Palette
const secondary = 'amber'
const success = 'green'
const error = 'red'
const info = 'blue'
const warning = 'orange'

const neutral = 'neutral'

const primaryColors = {
  // https://www.tailwindshades.com/#color=184.125%2C100%2C31.372549019607842&step-up=15&step-down=5&hue-shift=0&name=persian-green&base-stop=5&v=1&overrides=e30%3D
  50: '#f8f6ee',
  100: '#ede8d4',
  200: '#dcd1ac',
  300: '#c8b47c',
  400: '#b79958',
  500: '#a68549',
  600: '#906d3e',
  700: '#745334',
  800: '#624631',
  900: '#553c2e',
  950: '#311f17',
}

const dark = {
  name: 'now-gold-dark',
  value: 'now-gold-dark',
  type: 'dark',
  label: 'NOW Gold Insights Dark',
  selectors: ['.now-gold-dark'], //data-theme="dark" on html
  theme: {
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      DEFAULT: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px',
    },
    chartColors: [
      // Initial set of chart colors, always use tailwind colors to avoid duplicates
      colors.gray['500'],
      colors.orange['500'],
      colors.purple['500'],
      colors.yellow['500'],
      colors.pink['500'],
      colors.emerald['500'],
      colors.red['500'],
      colors.blue['500'],
      colors.lime['500'],
    ],
    colors: {
      // Primary and Secondary colours
      'elements-primary-50': primaryColors['50'],
      'elements-primary-100': primaryColors['100'],
      'elements-primary-200': primaryColors['200'],
      'elements-primary-300': primaryColors['300'],
      'elements-primary-400': primaryColors['400'],
      'elements-primary-500': primaryColors['500'],
      'elements-primary-600': primaryColors['600'],
      'elements-primary-700': primaryColors['700'],
      'elements-primary-800': primaryColors['800'],
      'elements-primary-900': primaryColors['900'],
      'elements-primary-950': primaryColors['950'],
      'elements-primary-dimmed': primaryColors['400'],
      'elements-primary-main': primaryColors['500'],
      'elements-primary-shadow': primaryColors['600'],
      'elements-primary-highlight': primaryColors['300'],
      'elements-primary-contrastText': '#ffffff',

      // Secondary
      'elements-secondary-dimmed': colors[secondary]['400'],
      'elements-secondary-main': colors[secondary]['500'],
      'elements-secondary-shadow': colors[secondary]['600'],
      'elements-secondary-highlight': colors[secondary]['300'],
      'elements-secondary-contrastText': '#ffffff',
      ...getFullPalette('elements-secondary', secondary),

      // Homepage realtime gradient
      'headline-gradient-from': 'rgb(166, 133, 73)', // #A68549 - NOW Gold Brand color
      'headline-gradient-via': 'rgb(140, 110, 64)', //
      'headline-gradient-to': 'rgb(90, 71, 49)',

      'event-backdrop': 'rgb(9, 35, 37)',

      // Alert
      'helpers-error-dimmed': colors[neutral]['700'],
      'helpers-error-main': colors[error]['400'],
      'helpers-error-button': colors[error]['500'],
      'helpers-error-button-hover': colors[error]['400'],

      'helpers-warning-dimmed': colors[neutral]['700'],
      'helpers-warning-main': colors[warning]['400'],
      'helpers-warning-button': colors[warning]['500'],
      'helpers-warning-button-hover': colors[warning]['400'],

      'helpers-success-dimmed': colors[neutral]['700'],
      'helpers-success-main': colors[success]['400'],
      'helpers-success-button': colors[success]['500'],
      'helpers-success-button-hover': colors[success]['400'],

      'helpers-info-dimmed': colors[neutral]['700'],
      'helpers-info-main': colors[info]['400'],
      'helpers-info-button': colors[info]['500'],
      'helpers-info-button-hover': colors[info]['400'],

      // Custom Brand colours (e.g. Sky palette, Peacock palette)
      'brand-1': '#ffffff',

      // Text
      'text-primary': '#ffffff',
      'text-secondary': colors[neutral]['200'],
      'text-tertiary': colors[neutral]['300'],
      'text-dimmed': colors[neutral]['400'],
      'text-disabled': colors[neutral]['600'],

      // Neutral
      'divider-dimmed': tinycolor(neutral).lighten(10).toString(),
      'divider-main': tinycolor(neutral).lighten(12.5).toString(),
      'divider-shadow': tinycolor(neutral).lighten(15).toString(),

      'border-dimmed': tinycolor(neutral).lighten(10).toString(),
      'border-main': tinycolor(neutral).lighten(12.5).toString(),
      'border-shadow': tinycolor(neutral).lighten(15).toString(),

      'neutral-dimmed-heavy': tinycolor(colors[neutral]['800'])
        .lighten(5)
        .toString(),
      'neutral-dimmed': tinycolor(colors[neutral]['800'])
        .lighten(2.5)
        .toString(),
      neutral: colors[neutral]['800'],
      'neutral-shadow': colors[neutral]['900'],
      'neutral-shadow-heavy': tinycolor(colors[neutral]['900'])
        .darken(7.5)
        .toString(),
    },
  },
  muiThemeBase: {
    palette: {
      type: 'dark',
      primary: {
        main: primaryColors['400'],
        light: primaryColors['500'],
        dark: primaryColors['300'],
      },
      secondary: {
        main: colors[secondary]['400'],
        light: colors[secondary]['500'],
        dark: colors[secondary]['300'],
      },
      error: {
        main: colors[error]['400'],
        light: colors[error]['600'],
        dark: colors[error]['300'],
      },
      text: {
        disabled: colors[neutral]['600'],
        icon: 'rgba(255, 255, 255, 0.5)',
        primary: '#fff',
        secondary: colors[neutral]['200'],
      },
      divider: colors[neutral]['600'],
      gray: colors[neutral],
      background: {
        default: 'rgb(48,48,54)',
        paper: 'rgb(48,48,54)',
      },
    },
  },
}

module.exports = dark
