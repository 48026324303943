import axios from 'axios'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { QueryFunctionContext, useQuery } from 'react-query'

import { ClientT } from '@/config/types'
import { useClientStore } from '@/stores/client.store'
import { useUiStore } from '@/stores/ui.store'

import { CONTROL_CENTRE_API_DOMAIN } from '../config/constants'
import getAccessToken from '../utils/getAccessToken'

const fetchGlobalAlerts = async ({
  queryKey,
}: QueryFunctionContext<
  [string, { route: string; key: string; client: ClientT }]
>) => {
  const { route, key, client } = queryKey[1]

  const url = `${CONTROL_CENTRE_API_DOMAIN}/alert/my`
  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      params: {
        url: `${key}.dashboard${route}`,
        client,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

function useGlobalAlerts({ route = '', key = '' }) {
  const client = useClientStore((state) => state.client)

  const { dismissedGlobalAlerts, setDismissedGlobalAlerts } = useUiStore(
    (state) => ({
      dismissedGlobalAlerts: state.dismissedGlobalAlerts,
      setDismissedGlobalAlerts: state.setDismissedGlobalAlerts,
    }),
  )
  const alertsQuery = useQuery(
    ['global-alerts', { route, key, client }],
    fetchGlobalAlerts,
    {},
  )

  const alerts = useRef<GlobalAlertT[]>([])

  // Methods
  const dismissAlert = (id: number) => {
    setDismissedGlobalAlerts([
      ...dismissedGlobalAlerts,
      {
        id,
        timestamp: moment().unix(),
      },
    ])
  }
  const isDismissed = (id: number) => {
    return !!dismissedGlobalAlerts.find(
      (dismissedAlert) => dismissedAlert.id === id,
    )
  }
  const wipeAlert = (id: number) => {
    setDismissedGlobalAlerts(
      dismissedGlobalAlerts.filter(
        ({ id: persistedAlertId }) => persistedAlertId !== id,
      ),
    )
  }
  const wipeAlerts = () => {
    setDismissedGlobalAlerts([])
  }

  // Check for old dismissed alerts, wipe after 3 days in localhost
  useEffect(() => {
    dismissedGlobalAlerts.forEach((persistedAlert) => {
      if (
        moment(persistedAlert.timestamp * 1000)
          .clone()
          .add(3, 'days')
          .isBefore(moment())
      ) {
        wipeAlert(persistedAlert.id)
      }
    })
  }, [])

  if (alertsQuery.status === 'success') {
    const globalAlerts: GlobalAlertT[] = alertsQuery.data ?? []

    // Wipe dismissed alerts that have not been returned
    if (globalAlerts.length > 0) {
      dismissedGlobalAlerts.forEach((dismissedAlert) => {
        if (
          !globalAlerts.find(
            (globalAlert) => globalAlert.id === dismissedAlert.id,
          )
        ) {
          wipeAlert(dismissedAlert.id)
        }
      })
    } else if (dismissedGlobalAlerts.length > 0) {
      wipeAlerts()
    }

    // Filter response alerts against dismissed list
    const filteredGlobalAlerts = globalAlerts.filter(
      (globalAlert) => !isDismissed(globalAlert.id),
    )

    // Check if alerts ref needs updating
    if (!isEqualAlerts(filteredGlobalAlerts, alerts.current)) {
      alerts.current = filteredGlobalAlerts
    }
  }

  return { dismissAlert, alerts: alerts.current }
}

export default useGlobalAlerts

function isEqualAlerts(arr1: GlobalAlertT[], arr2: GlobalAlertT[]) {
  const ids1 = arr1.map((alert) => alert.id).sort()
  const ids2 = arr2.map((alert) => alert.id).sort()
  return isEqual(ids1, ids2)
}
