import { sortBy, startCase } from 'lodash'

import { BIGVIVA_REALTIME_API_DOMAIN } from '@/config/constants'
import { MinutesUniquesSettingsI, SettingsI } from '@/config/types'
import getFormOption from '@/utils/getFormOption'
import renameNowToNowTv from '@/utils/renameNowToNowTv'
import valueFormat from '@/utils/valueFormat'

const bigvivaRealtimeConfig: Omit<MinutesUniquesSettingsI, 'form'> = {
  dataSource: 'conviva',
  apiDomain: BIGVIVA_REALTIME_API_DOMAIN,
  supportsClient: true,
  useTimezone: true,
  value: 'total-minutes-unique-customers',
  label: 'Unique Customers / Mins per Customer',
  info: '...',
  valueFormat: {
    minsPerCustomer: (value: number) => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 1,
      }).format(value)} min`
    },
    uniqueCustomers: (value: number) => {
      return new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 0,
      }).format(value)
    },
  },
  yAxisFormat: (value: number) => valueFormat(value, false, true),
  totalFormatter: {
    total: ({ data = [] }: any) => {
      const _data = { uniqueCustomers: 0, minsPerCustomer: 0 }
      data?.forEach((datum: any) => {
        _data.minsPerCustomer = datum?.totalMinsPerCustomers
        _data.uniqueCustomers = datum?.uniqueCustomers
      })
      return _data
    },
  },
  formatter: {
    uniqueCustomersPie: ({
      settings,
      config,
      data = [],
    }: {
      settings: SettingsI
      config: ConfigI
      data: any[]
    }) => {
      const _data = data
        ?.map((datum: any) => {
          const splitBy = config['split-by'] as string
          const splitName = renameNowToNowTv(datum[splitBy])
          // const weekCommencing = config['week-commencing']

          if (splitName !== 'other') {
            const { label, image, color } = getFormOption({
              value: splitName,
              formGroup: splitBy,
              settings,
            })

            return {
              id: label || startCase(splitName),
              image,
              color,
              value: datum?.uniqueCustomers,
            }
          } else {
            return null
          }
        })
        .filter((datum: any) => !!datum)

      return _data
    },
    bubble: ({ settings, config, data = [] }) => {
      const _data = data
        ?.map((datum: any) => {
          const splits = config['split-by'].split(',')
          const splitBy = splits[0]
          const subSplitBy = splits[1] || null
          const splitName = renameNowToNowTv(datum[splitBy])
          const subSplitName = datum[subSplitBy]

          if (splitName !== 'other' && subSplitName !== 'other') {
            if (!subSplitName) {
              const { label, image, color } = getFormOption({
                value: splitName,
                formGroup: splitBy,
                settings,
              })

              return {
                name: label || startCase(splitName),
                'split-by': splitName,
                image,
                color,
                x: datum?.totalMinsPerCustomers,
                y: datum?.uniqueCustomers,
                z: datum?.uniqueCustomers,
              }
            } else {
              const splitByConfig = getFormOption({
                value: splitName,
                formGroup: splitBy,
                settings,
              })

              const subSplitByConfig = getFormOption({
                value: subSplitName,
                formGroup: subSplitBy,
                settings,
              })

              const splitByLabel = splitByConfig?.label || startCase(splitName)
              const subSplitByLabel =
                subSplitByConfig?.label || startCase(subSplitName)

              return {
                name: `${splitByLabel} · ${subSplitByLabel}`,
                'split-by': splitName,
                'sub-split-by': subSplitName,
                image: splitByConfig?.image,
                image2: subSplitByConfig?.image,
                x: datum?.totalMinsPerCustomers,
                y: datum?.uniqueCustomers,
                z: datum?.uniqueCustomers,
                color: splitByConfig?.color,
              }
            }
          } else {
            return null
          }
        })
        .filter((datum: any) => !!datum)

      return sortBy(_data, (datum) => -datum.y)
    },
  },
}

export default bigvivaRealtimeConfig
