import { HiOutlinePlay } from 'react-icons/hi'

import {
  EVENT_STREAM_TYPE,
  EVENT_STREAM_TYPE_WITH_CLIENT,
  METRIC,
  PROPOSITION,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '@/config/formOptions'

import {
  CLIENT_DEFAULT_TIMEZONES,
  CONVIVA_2_V4_API_DOMAIN,
  CONVIVA_API_DOMAIN,
  DEFAULT_DATE,
} from '../../constants'
import { VariantsT } from '../../types'

const events = ({ variants }: { variants?: VariantsT }) => {
  const withClient = variants?.withClient || false

  return {
    default: true,
    value: 'events',
    label: 'Events',
    apiDomain:
      (withClient ? CONVIVA_2_V4_API_DOMAIN : CONVIVA_API_DOMAIN) + '/events',
    supportsClient: true,
    icon: HiOutlinePlay,
    info: 'This page shows a table ranking of the most watched events during a given interval.',
    dataSource: 'conviva',
    timezone: CLIENT_DEFAULT_TIMEZONES['nbcu-dtc'],
    useTimezone: !!withClient,
    metricsAsDomainGroup: true,
    showIcons: {
      proposition: {
        enabled: true,
        accessor: withClient ? 'propositions' : 'meta.proposition',
      },
      territory: {
        enabled: true,
        accessor: withClient ? 'territories' : 'meta.territory',
      },
    },

    form: [
      {
        value: 'stream-type',
        type: 'button-group' as const,
        options: withClient
          ? [
              EVENT_STREAM_TYPE_WITH_CLIENT.sle,
              EVENT_STREAM_TYPE_WITH_CLIENT.vod,
              EVENT_STREAM_TYPE_WITH_CLIENT.linear,
            ]
          : [
              EVENT_STREAM_TYPE.sle,
              EVENT_STREAM_TYPE.vod,
              EVENT_STREAM_TYPE.linear,
            ],
      },
      {
        value: 'proposition',
        type: 'button-group' as const,
        multi: true,
        options: [
          PROPOSITION['all'],
          PROPOSITION['peacock'],
          PROPOSITION['skyshowtime'],
        ],
      },
      {
        value: 'territory',
        type: 'button-group' as const,
        multi: true,
        options: [
          TERRITORY['all'],
          TERRITORY['us'],
          ...skyShowTimeTerritoryOptions,
        ],
      },
      {
        value: 'metric',
        type: 'hidden',
        multi: true,
        options: [
          METRIC['all'],
          METRIC['concurrent-plays'],
          METRIC['plays'],
          METRIC['rebuffering-ratio'],
          METRIC['total-attempts'],
          METRIC['connection-induced-rebuffering-ratio'],
          METRIC['average-bitrate'],
          METRIC['exits-before-video-start'],
          METRIC['video-startup-time'],
          // METRIC['video-restart-time'],
          // METRIC['ended-plays'],
          METRIC['video-start-failures-percentage'],
          METRIC['video-start-failures-technical-percentage'],
          METRIC['video-start-failures-business-percentage'],
          METRIC['video-playback-failures-percentage'],
          METRIC['video-playback-failures-technical-percentage'],
          METRIC['video-playback-failures-business-percentage'],
        ],
      },
    ],

    defaultConfig: {
      'stream-type': ['sle'],
      proposition: ['all'],
      territory: ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      metric: ['all'],
      ...(withClient ? { client: 'nbcu-dtc' } : {}),
      ...DEFAULT_DATE('nbcu-dtc'),
      // Can't be defined here, it gets passed to url as param
      // enableAdditionalEventQualityMetrics: {
      //   linear: true,
      //   vod: false,
      //   sle: true,
      // },
    },
  }
}

export default events
