import { MetricSettingsI, VariantsT } from '@/config/types'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import { useUserStore } from '@/stores/user.store'

import metrics from '../config/metricsSettings'
import { useClientStore } from '../stores/client.store'

interface PropsI {
  variants?: VariantsT
}

const useMetricSettingsList = ({
  variants,
}: PropsI = {}): MetricSettingsI[] => {
  const client = useClientStore((state) => state.client)
  const roles = useUserStore((state) => state.roles)
  const { featureFlags } = useFeatureFlags()

  return Object.keys(metrics[client])
    .map((metric) => {
      const settings = metrics[client][metric]
      return settings({ variants, featureFlags, roles })
    })
    .filter((metricSettings) => !!metricSettings)
}

export default useMetricSettingsList
