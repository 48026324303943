import { GiRaiseZombie, GiSpikedDragonHead } from 'react-icons/gi'
import { HiClipboardList } from 'react-icons/hi'

import { TERRITORY } from '@/config/formOptions'

import { EVENT_CONFIG_API_DOMAIN } from '../../../constants'
import { SeriesReportsSettingsI } from '../../../types'

const seriesReportSettings: SeriesReportsSettingsI = {
  value: 'series-reports',
  label: 'Series Reports',
  apiDomain: EVENT_CONFIG_API_DOMAIN + '/config',
  icon: HiClipboardList,
  info: 'This page shows a table ranking of the most watched events during a given interval.',
  dataSource: 'conviva',
  showIcons: {
    proposition: { enabled: true },
    territory: { enabled: true },
  },
  seriesSettings: [
    {
      name: 'House of the Dragon',
      key: 'hotd',
      icon: GiSpikedDragonHead,
      defaultStreamType: 'linear',
      defaultConcurrencyAndPlaysIndex: 0,
      emptyInsightsImage: '/images/reports/hotd/snow-jon-snow.gif',
    },
    {
      name: 'The Last of Us',
      key: 'lou',
      icon: GiRaiseZombie,
      defaultStreamType: 'vod',
      defaultConcurrencyAndPlaysIndex: 2,
      emptyInsightsImage: '/images/reports/lou/confused-joel.gif',
    },
  ],
  form: [
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [
        TERRITORY['all'],
        TERRITORY['uk'],
        TERRITORY['de'],
        TERRITORY['it'],
        TERRITORY['roi'],
        TERRITORY['at'],
      ],
    },
  ],
  defaultConfig: {
    proposition: ['all'],
    territory: ['all'],
    'split-by': 'territory',
    client: 'now',
  },
}

export default Object.freeze(seriesReportSettings)
