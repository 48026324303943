import { connectSortBy } from 'react-instantsearch-dom'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const SortBy = ({
  items,
  defaultRefinement,
  currentRefinement,
  refine,
}: any) => {
  return (
    <FormControl fullWidth>
      <InputLabel id='instantsearch-sortby-label'>Sort By</InputLabel>

      <Select
        onChange={(event: SelectChangeEvent) =>
          refine(event.target.value as string)
        }
        value={currentRefinement ?? defaultRefinement}
        label='Sort By'
        labelId='instantsearch-sortby-label'
        id='instantsearch-sortby'
      >
        {items.map(({ value, label }: any) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const CustomSortBy = connectSortBy(SortBy)

export default CustomSortBy
