import { HiSearch, HiX } from 'react-icons/hi'
import { connectSearchBox } from 'react-instantsearch-dom'

import { IconButton, InputAdornment, TextField } from '@mui/material'

const SearchBox = ({ refine, currentRefinement }: any) => {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      noValidate
      autoComplete='off'
      role='search'
    >
      <TextField
        fullWidth
        type='search'
        label='Search'
        id='instantsearch-searchbox'
        // disabled={isSearchStalled}
        value={currentRefinement}
        onChange={(event: any) => refine(event.target.value)}
        InputProps={{
          classes: {
            input: 'focus:ring-0',
          },
          startAdornment: (
            <InputAdornment position='start'>
              <HiSearch className='w-5 h-5' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='clear search'
                onClick={() => refine('')}
                edge='end'
              >
                <HiX />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
