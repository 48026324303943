import { HiClipboardList } from 'react-icons/hi'

import { INSTANT_SEARCH_INDEX_NAMES } from '@/config/constants'
import { InstantSearchSettingsI } from '@/config/types'

const clientName = 'sky-eu'

const instantSearchEventReportSettings: InstantSearchSettingsI = {
  clientName,
  value: 'instant-search-event-reports',
  label: 'Event Reports',
  icon: HiClipboardList,
  info: 'This page shows a table ranking of the most watched events during a given interval.',
  dataSource: 'conviva',
  showIcons: {
    proposition: { enabled: true },
    territory: { enabled: true },
  },
  sortBy: {
    items: [
      {
        label: 'Date (desc)',
        value: `${INSTANT_SEARCH_INDEX_NAMES.HIGH_PROFILE_EVENTS}:coverageStartTimeUTC:desc`,
      },
      {
        label: 'Date (asc)',
        value: `${INSTANT_SEARCH_INDEX_NAMES.HIGH_PROFILE_EVENTS}:coverageStartTimeUTC:asc`,
      },
      {
        label: 'Concurrency (desc)',
        value: `${INSTANT_SEARCH_INDEX_NAMES.HIGH_PROFILE_EVENTS}:searchableMetrics.peakConcurrency.${clientName}:desc`,
      },
      {
        label: 'Concurrency (asc)',
        value: `${INSTANT_SEARCH_INDEX_NAMES.HIGH_PROFILE_EVENTS}:searchableMetrics.peakConcurrency.${clientName}:asc`,
      },
    ],
  },
  form: [
    {
      label: 'Concurrency',
      type: 'rangeSlider',
      rangeSliderProps: {
        attribute: `searchableMetrics.peakConcurrency.${clientName}`,
      },
    },
    {
      label: 'Rank',
      allLabel: 'All Ranks',
      type: 'refinementList',
      formGroupValue: 'hpe-rank',
      refinementListProps: {
        attribute: 'eventRank',
        operator: 'or',
      },
    },
    {
      label: 'Type',
      allLabel: 'All Event Types',
      type: 'refinementList',
      formGroupValue: 'hpe-type',
      refinementListProps: {
        attribute: 'eventType',
        operator: 'or',
      },
    },
    {
      label: 'Category',
      allLabel: 'All Categories',
      type: 'refinementList',
      formGroupValue: 'hpe-category',
      refinementListProps: {
        attribute: 'category',
        operator: 'or',
        limit: 5,
        showMore: true,
      },
    },
    {
      label: 'Competition',
      allLabel: 'All Competitions',
      type: 'refinementList',
      formGroupValue: 'hpe-competition',
      refinementListProps: {
        attribute: 'competition',
        operator: 'or',
        limit: 5,
        showMore: true,
      },
    },
    {
      label: 'Territories',
      allLabel: 'All Territories',
      type: 'refinementList',
      formGroupValue: 'territory',
      refinementListProps: {
        attribute: 'territories.name',
        operator: 'or',
      },
    },
    {
      label: 'Propositions',
      allLabel: 'All Propositions',
      type: 'refinementList',
      formGroupValue: 'proposition',
      refinementListProps: {
        attribute: 'territories.propositions.name',
        operator: 'or',
      },
    },
  ],
}

export default Object.freeze(instantSearchEventReportSettings)
