import React from 'react'
import { HiOutlineCheckCircle, HiOutlineClipboardCopy } from 'react-icons/hi'

import { logEvent } from '@/utils/firebaseAnalytics'

const ShareModalContent = ({ url }) => {
  const [shareCopied, setShareCopied] = React.useState(false)

  return (
    <div>
      <p className='text-sm text-text-tertiary'>
        The below URL contains the configuration for your current visualisation.
      </p>
      <div className='flex w-full my-4 rounded-md shadow-sm'>
        <div className='relative flex items-stretch w-full grow focus-within:z-10'>
          <input
            value={url}
            disabled
            type='text'
            name='share-url'
            id='share-url'
            className='block w-full rounded-none border-divider-shadow focus-visible:ring-elements-primary-main focus-visible:border-elements-primary-main rounded-l-md sm:text-sm bg-neutral-dimmed-heavy text-text-primary'
            placeholder='Share URL'
          />
        </div>
        <button
          className='relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border text-text-secondary border-divider-shadow rounded-r-md bg-neutral-dimmed hover:bg-neutral focus:outline-none focus-visible:ring-1 focus-visible:ring-elements-primary-main focus-visible:border-elements-primary-main'
          onClick={() => {
            navigator.clipboard.writeText(url).then(
              () => {
                setShareCopied(true)
              },
              () => {
                setShareCopied(false)
                /* clipboard write failed */
              },
            )
            logEvent('select_share_copy', { copiedUrl: url })
          }}
        >
          {shareCopied ? (
            <>
              <HiOutlineCheckCircle
                className='w-5 h-5 text-helpers-success-main'
                aria-hidden='true'
              />
              <span>Copied!</span>
            </>
          ) : (
            <>
              <HiOutlineClipboardCopy
                className='w-5 h-5 text-text-tertiary'
                aria-hidden='true'
              />
              <span>Copy</span>
            </>
          )}
        </button>
      </div>
    </div>
  )
}

export default ShareModalContent
