import {
  CLIENT_DEFAULT_TIMEZONES,
  DEFAULT_DATE,
  IDENTITY_API_DOMAIN,
} from '@/config/constants'
import {
  DEVICE_TYPE,
  OPERATING_SYSTEM,
  PROPOSITION,
  SPLIT_BY,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI, VariantsT } from '@/config/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonIdentityConfig = ({ variants }: { variants?: VariantsT }) => {
  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      options: [
        SPLIT_BY['territory'],
        SPLIT_BY['device-type'],
        SPLIT_BY['operating-system'],
      ],
    },
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['skyshowtime']],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...skyShowTimeTerritoryOptions],
    },

    {
      value: 'device-type',
      type: 'button-group',
      multi: true,
      options: [
        DEVICE_TYPE['all'],
        DEVICE_TYPE['stb'],
        DEVICE_TYPE['mobile'],
        DEVICE_TYPE['smart-tv'],
        DEVICE_TYPE['tv-stick'],
        DEVICE_TYPE['console'],
        DEVICE_TYPE['web'],
        DEVICE_TYPE['desktop'],
        DEVICE_TYPE['streaming-stick'],
      ],
    },
    {
      value: 'operating-system',
      type: 'button-group',
      multi: true,
      options: [
        OPERATING_SYSTEM['all'],
        OPERATING_SYSTEM['amazon-fire'],
        OPERATING_SYSTEM['android'],
        OPERATING_SYSTEM['android-tv'],
        OPERATING_SYSTEM['apple-tv'],
        OPERATING_SYSTEM['chromecast'],
        OPERATING_SYSTEM['cox'],
        OPERATING_SYSTEM['desktop'],
        OPERATING_SYSTEM['fire-os'],
        OPERATING_SYSTEM['ios'],
        OPERATING_SYSTEM['lg-tv'],
        OPERATING_SYSTEM['linux'],
        OPERATING_SYSTEM['playstation'],
        OPERATING_SYSTEM['rdk'],
        OPERATING_SYSTEM['roku'],
        OPERATING_SYSTEM['samsung-tv'],
        OPERATING_SYSTEM['tizen'],
        OPERATING_SYSTEM['tv-os'],
        OPERATING_SYSTEM['vizio'],
        OPERATING_SYSTEM['web'],
        OPERATING_SYSTEM['web-os'],
        OPERATING_SYSTEM['xbox'],
        OPERATING_SYSTEM['xclass-tv'],
        OPERATING_SYSTEM['xfinity'],
        OPERATING_SYSTEM['youview'],
      ],
    },
  ]

  return {
    dataSource: 'Identity',
    legacyGroup: 'identity',
    group: GROUPS.IDENTITY,
    apiDomain: IDENTITY_API_DOMAIN,
    supportsClient: true,
    timezone: CLIENT_DEFAULT_TIMEZONES['skyshowtime'],
    useTimezone: true,
    formatter: convivaStyleDataFormatters,
    form,
    granularity: commonFiveMinuteGranularity,
    defaultConfig: {
      ...DEFAULT_DATE('skyshowtime'),
      'split-by': 'territory',
      territory: ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      client: 'skyshowtime',
    },
  }
}

export default commonIdentityConfig
