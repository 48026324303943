import { LuBookOpen } from 'react-icons/lu'

import { DEFAULT_DATE, PROMETHEUS_API_DOMAIN } from '@/config/constants'
import { SPLIT_BY } from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI } from '@/config/types'

const commonPrometheusPersistenceConfig = () => {
  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [
        SPLIT_BY['proposition'],
        SPLIT_BY['cloud-region'],
        SPLIT_BY['department'],
        SPLIT_BY['persistence'],
        SPLIT_BY['service'],
        SPLIT_BY['team'],
        SPLIT_BY['variant'],
      ],
    },
    // Rest of options will come from tags endpoint
  ]

  return {
    dataSource: 'Prometheus',
    legacyGroup: 'prometheus',
    group: GROUPS.PROMETHEUS_PERSISTENCE,
    apiDomain: PROMETHEUS_API_DOMAIN,
    supportsClient: true,
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: true,
    timeseriesAsParam: true,
    groupAsParam: true,
    tags: {
      enabled: true,
      endpoint: '/tags',
      params: { 'metric-group': GROUPS.PROMETHEUS_PERSISTENCE.value },
    },
    disclaimer:
      'This is a visualisation of the data received from GST Persistence teams.',
    externalLinks: [
      {
        icon: LuBookOpen,
        label: 'Prometheus Metrics Data Sources',
        href: 'https://wiki.at.sky/pages/viewpage.action?pageId=509238217',
      },
    ],
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonMinuteGranularity,
    defaultConfig: {
      'split-by': 'proposition',
      proposition: ['all'],
      ['cloud-region']: ['all'],
      department: ['all'],
      persistence: ['all'],
      service: ['all'],
      team: ['all'],
      variant: ['all'],
      ...DEFAULT_DATE('showmax'),
    },
  }
}
export default commonPrometheusPersistenceConfig
