import { meanBy } from "lodash";

const fallback = 0;

const convivaTimeseriesAverage = ({ settings = {}, data }) => {
  const { dataKey } = settings;

  if (data?.data?.total) {
    return meanBy(data.data.total, dataKey) || fallback;
  } else {
    return fallback;
  }
};

export default convivaTimeseriesAverage;
