import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreIngressConfig from './common/prometheusCoreIngress'

const waitingConnections: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreIngressConfig = commonPrometheusCoreIngressConfig()

  return {
    ...prometheusCoreIngressConfig,
    value: 'waiting-connections',
    label: 'Waiting Connections',
    shortLabel: 'Waiting Con.',
    dataKey: 'waitingConnections',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Waiting Connections refers to the number of incoming connections that are queued or waiting to be accepted by the core ingress component of a system, such as a load balancer or a network gateway. Managing the Waiting Connections metric is crucial for understanding the capacity and performance of the core ingress component. It helps in identifying potential bottlenecks, optimizing resource allocation, and ensuring that incoming traffic is handled efficiently and in a timely manner.',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusCoreIngressConfig.defaultConfig,
      metric: 'waiting-connections',
    },
  }
}

export default waitingConnections
