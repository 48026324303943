import moment from 'moment-timezone'

import {
  CLIENT_DEFAULT_TIMEZONES,
  DEFAULT_DATE,
  NOTIFICATIONS_CONFIG_API_DOMAIN,
} from '@/config/constants'
import { NotificationsSettingsI } from '@/config/types'

const now = moment()
  .clone()
  .tz(CLIENT_DEFAULT_TIMEZONES['peacock'])
  .subtract(1, 'minute')
  .startOf('minute')

const notificationsSettings: NotificationsSettingsI = {
  apiDomain: NOTIFICATIONS_CONFIG_API_DOMAIN + '/history',
  info: 'This page shows a list of the latest triggered notifications',
  defaultConfig: {
    ...DEFAULT_DATE('peacock'),
    tags: ['peacock'],
    start: now.clone().subtract(1, 'year').utc().format(),
    end: now.utc().format(),
  },
}

export default Object.freeze(notificationsSettings)
