function ErrorFallback({ error }) {
  return (
    <main className='flex flex-1 min-h-screen overflow-y-auto'>
      <div className='flex flex-col flex-1'>
        <div className='flex items-center h-full max-w-4xl px-4 py-8 mx-auto sm:px-6 lg:px-8'>
          <div className='container grid grid-cols-1 gap-6 xl:grid-cols-3'>
            <div className='flex justify-center'>
              <img
                alt='Gru from Despicable Me looking angry'
                className='mt-8 max-h-90 xl:mt-0'
                src='/images/error.png'
              />
            </div>
            <div className='flex flex-col justify-center space-y-3 xl:col-span-2'>
              <div className='text-3xl font-bold leading-tight text-text-primary'>
                Error
              </div>

              <p className='text-xl leading-normal md:text-2xl text-text-secondary'>
                {`Something went wrong`}
              </p>

              <p className='pb-8 text-sm leading-normal text-text-dimmed'>
                {error.message}
              </p>

              <div>
                <a
                  href='/'
                  className='inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm text-elements-primary-contrastText bg-elements-primary-main hover:bg-elements-primary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main'
                >
                  Back to Homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ErrorFallback
