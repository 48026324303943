import { sortBy } from 'lodash'

import isNumber from '@/utils/isNumber'

import { addLabelsToData } from '@/config/dataFormatters/utils/conviva'

const fallback = []

const convivaAggregationToPie = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  if (data?.data) {
    const formattedData = Object.keys(data?.data)
      .filter(
        (split) => split !== 'total' && isNumber(data?.data[split][0][dataKey]),
      )
      .map((split) => {
        const splitData = data?.data[split]
        return {
          id: split,
          value: splitData[0][dataKey],
        }
      })

    const dataWithLabels = addLabelsToData({
      data: formattedData,
      settings,
      splitBy,
    })

    const sortedDataWithLabels = sortBy(dataWithLabels, ({ id }) => id)

    return sortedDataWithLabels
  } else {
    return fallback
  }
}

export default convivaAggregationToPie
