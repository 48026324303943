import { cn } from '@/utils/cn'

interface PropsI {
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e?: any) => void
  className?: string
  disabled?: boolean
  secondary?: boolean
  children?: React.ReactNode
}

const Button = ({
  type = 'button',
  onClick = () => null,
  className = '',
  children,
  disabled = false,
  secondary = false,
}: PropsI): JSX.Element => {
  return (
    <button
      type={type}
      onClick={(e) => {
        if (!disabled) {
          onClick(e)
        }
      }}
      className={cn(
        'flex justify-center w-full px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ',
        {
          'bg-neutral-shadow-heavy cursor-not-allowed text-text-tertiary':
            disabled,
          'cursor-pointer text-text-tertiary bg-neutral-shadow hover:bg-neutral-shadow-heavy focus-visible:ring-elements-primary-main':
            !disabled && secondary,
          'cursor-pointer text-elements-primary-contrastText bg-elements-primary-main hover:bg-elements-primary-shadow focus-visible:ring-elements-primary-main':
            !disabled,
        },
        className,
      )}
    >
      {children}
    </button>
  )
}

export default Button
