import {
  SPLIT_BY,
  TERRITORY,
  showmaxTerritoryOptions,
} from '@/config/formOptions'

import { CONVIVA_2_V4_API_DOMAIN } from '../../../constants'
import { FormI, WeeklyViewingReportSettingsI } from '../../../types'
import getStartAndEndConfig from './utils/getStartAndEndConfig'

const form: FormI[] = [
  {
    value: 'split-by',
    label: 'Split By',
    type: 'button-group',
    multi: false,
    options: [SPLIT_BY['territory'], SPLIT_BY['device-type']],
  },
  {
    value: 'territory',
    type: 'button-group',
    multi: true,
    options: [TERRITORY['all'], ...showmaxTerritoryOptions],
  },
]

const weeklyViewing: WeeklyViewingReportSettingsI = {
  apiDomain: CONVIVA_2_V4_API_DOMAIN,
  form,
  highProfileEventsEnabled: false,
  minutesAndUniquesEnabled: true,
  defaultConfig: {
    metric: 'weekly-viewing',
    'split-by': 'territory',
    proposition: ['all'],
    territory: ['all'],
    'device-type': ['all'],
    dateType: 'week',
    isRange: false,
    client: 'showmax',
    ...getStartAndEndConfig(),
  },
}

export default Object.freeze(weeklyViewing)
