import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreConfig from './common/prometheusCore'

const totalNodes: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    value: 'total-nodes',
    label: 'Total Nodes',
    shortLabel: 'Total Nodes',
    dataKey: 'totalNodes',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Total Nodes refers to the total number of network nodes or devices that are part of the core infrastructure. Total nodes is relevant for network administrators and engineers as it provides an indication of the scale and complexity of the core network infrastructure. Monitoring the total number of nodes helps in capacity planning, resource allocation, and identifying potential points of congestion or failure within the core network.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'total-nodes',
    },
  }
}

export default totalNodes
