import React from 'react'

import PageLoading from '@/components/PageLoading'
import useFetchAccessToken from '@/hooks/useFetchAccessToken'

// Load access token once on app load so that subsequent attempts to get it go to cache
const GetAccessToken = ({ children }: any) => {
  const fetchAccessToken = useFetchAccessToken()

  const [accessTokenLoaded, setAccessTokenLoaded] = React.useState(false)

  const fetchAccessTokenWithLoadingState = async () => {
    setAccessTokenLoaded(false)
    await fetchAccessToken()
    setAccessTokenLoaded(true)
  }

  React.useEffect(() => {
    fetchAccessTokenWithLoadingState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (accessTokenLoaded) {
    return children
  }

  return <PageLoading />
}

export default GetAccessToken
