import moment from 'moment'

const getUniqueEventId = (event) => {
  const {
    startTimeEpochUTC,
    RightsStartTimeEpochUTC,
    title,
    proposition,
    territory,
    channelName,
  } = event.meta

  let id = [event.eventUid || event.contentId || event.identifier]

  if (startTimeEpochUTC) {
    const startTimestamp = Number(moment(startTimeEpochUTC).clone().format('x'))
    id.push(startTimestamp)
  }

  if (RightsStartTimeEpochUTC) {
    const rightsStartTimestamp = Number(
      moment(RightsStartTimeEpochUTC).clone().format('x'),
    )
    id.push(rightsStartTimestamp)
  }

  if (channelName) {
    id.push(channelName)
  }

  if (proposition) {
    id.push(proposition)
  }

  if (territory) {
    id.push(territory)
  }

  if (title) {
    id.push(title)
  }

  // Ensure unique IDs for Conviva 2 events
  if (event.propositions && Array.isArray(event.propositions)) {
    id.push(event.propositions.join(','))
  }

  if (event.territories && Array.isArray(event.territories)) {
    id.push(event.territories.join(','))
  }

  return id.join('-')
}

export default getUniqueEventId
