import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

import appSettings from '@/config/appSettings'
import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import { ClientT } from '@/config/types'

const defaultClient = appSettings.defaultClient
const defaultTimezone = CLIENT_DEFAULT_TIMEZONES[defaultClient]

export interface ClientStoreState {
  client: ClientT
  setClient: (client: ClientT) => void
  clientDefaultTimezone: string
}

type Persist = (
  config: StateCreator<ClientStoreState>,
  options: PersistOptions<ClientStoreState>,
) => StateCreator<ClientStoreState>

export const useClientStore = create<ClientStoreState>(
  (persist as Persist)(
    (set) => ({
      client: defaultClient,
      setClient: (client) =>
        set(() => ({
          client,
          clientDefaultTimezone: CLIENT_DEFAULT_TIMEZONES[client],
        })),
      clientDefaultTimezone: defaultTimezone,
    }),
    {
      name: 'client',
    },
  ),
)
