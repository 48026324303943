import React from "react";
import clsx from "clsx";
import moment from "moment";

const Live = ({ className, start, end, active = false }) => {
  const _start = moment(start).clone();
  const _end = moment(end).clone();
  const isLive =
    active || (moment().isAfter(_start) && moment().isBefore(_end));

  if (!isLive) return null;
  return (
    <div className={clsx("bg-red-500  px-1 rounded-sm text-xs", className)}>
      <p className="font-medium text-white">LIVE</p>
    </div>
  );
};

export default Live;
