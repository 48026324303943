import { DurationInputArg1, DurationInputArg2 } from 'moment'

import {
  DEFAULT_DATE,
  FEATURE_FLAGS,
  PROMETHEUS_API_DOMAIN,
} from '@/config/constants'
import { SPLIT_BY } from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import {
  ComparisonOperatorT,
  FormI,
  MetricSettingsFnArgs,
} from '@/config/types'

const commonPrometheusConfig = ({ featureFlags }: MetricSettingsFnArgs) => {
  const isPrometheusConcurrencyEnabled =
    featureFlags[FEATURE_FLAGS.CONVIVA_FALLBACK_ENABLED]

  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [
        SPLIT_BY['device-type'],
        SPLIT_BY['device-platform'],
        SPLIT_BY['cloud-region'],
      ],
    },
    // Rest of options will come from tags endpoint
  ]

  return {
    dataSource: 'Prometheus',
    legacyGroup: 'prometheus',
    group: GROUPS.PROMETHEUS_CONCURRENCY,
    groupAsParam: true,
    apiDomain: PROMETHEUS_API_DOMAIN,
    supportsClient: true,
    hideInMetricsExplorer: !isPrometheusConcurrencyEnabled,
    hideInLegacyMetrics: true,
    timeseriesAsParam: true,
    tags: {
      enabled: true,
      endpoint: '/tags',
      params: { 'metric-group': GROUPS.PROMETHEUS_CONCURRENCY.value },
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: (): [DurationInputArg1, DurationInputArg2] => {
      return [48, 'hours']
    },
    granularityComparison: 'less than or equal' as ComparisonOperatorT,
    defaultConfig: {
      'split-by': 'cloud-region',
      proposition: ['all'],
      ['device-platform']: ['all'],
      ['device-type']: ['all'],
      ['cloud-region']: ['all'],

      ...DEFAULT_DATE('nbcu-dtc'),
    },
  }
}
export default commonPrometheusConfig
