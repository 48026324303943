import queryString from 'query-string'

import { ClientT } from '@/config/types'

export const getAppRoute = ({ client }: { client: ClientT }) => {
  return `/${client}`
}

export const getHomeRoute = ({ client }: { client: ClientT }) => {
  return getAppRoute({ client })
}

export const getMetricsRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/metrics`
}

export const getContentRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/content`
}

export const getContentAllEventsRoute = ({ client }: { client: ClientT }) => {
  return `${getContentRoute({ client })}/all-events`
}

export const getContentHighProfileEventsRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getContentRoute({ client })}/high-profile-events`
}

export const getContentHighProfileEventRoute = ({
  client,
  highProfileEventId,
}: {
  client: ClientT
  highProfileEventId: string
}) => {
  return `${getContentHighProfileEventsRoute({
    client,
  })}/${highProfileEventId}`
}

export const getContentHighProfileEventEditRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getContentHighProfileEventsRoute({
    client,
  })}/edit`
}

export const getContentHighProfileEventAdminRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getContentHighProfileEventsRoute({
    client,
  })}/01189998819991197253`
}

export const getContentAllSeriesRoute = ({ client }: { client: ClientT }) => {
  return `${getContentRoute({ client })}/series`
}

export const getContentSeriesRoute = ({
  client,
  seriesSlug,
  seasonNumber,
}: {
  client: ClientT
  seriesSlug: string
  seasonNumber: string | number
}) => {
  return `${getContentRoute({ client })}/series/${seriesSlug}/${seasonNumber}`
}

export const getContentSeriesEpisodeRoute = ({
  client,
  seriesSlug,
  seasonNumber,
  episodeNumber,
}: {
  client: ClientT
  seriesSlug: string
  seasonNumber: string | number
  episodeNumber: string | number
}) => {
  return `${getContentSeriesRoute({
    client,
    seriesSlug,
    seasonNumber,
  })}/${episodeNumber}`
}

export const getReportsRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/reports`
}

export const getWeeklyViewingReportRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getReportsRoute({ client })}/weekly-viewing`
}

export const getContactRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/contact`
}

export const getFaqsRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/faqs`
}

export const getRoadmapRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/roadmap`
}

export const getNotificationsRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/notifications`
}

export const getRequestPrivilegesRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/request-privileges`
}

export const getManualNotificationsRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getAppRoute({ client })}/manual-notification`
}

export const getManualNotificationsAdminRoute = ({
  client,
}: {
  client: ClientT
}) => {
  return `${getManualNotificationsRoute({ client })}/admin`
}

export const getManualNotificationsAdminEditRoute = ({
  client,
  notificationId,
}: {
  client: ClientT
  notificationId: string
}) => {
  return `${getManualNotificationsAdminRoute({
    client,
  })}/edit/${notificationId}`
}

export const getEventDetailsPageRoute = ({
  client,
  streamType,
  eventId,
  params,
}: {
  client: ClientT
  streamType: string
  eventId: string
  params?: ConfigI
}) => {
  if (!params)
    return `${getContentRoute({
      client,
    })}/event/${streamType}/${eventId}`

  const remove = [
    'dateType',
    'relativeDate',
    'start',
    'end',
    'split-by',
    'stream-type',
    'event-uid',
    'client',
    'identifier',
    'granularity',
    'time-period',
  ]

  Object.keys(params).forEach((paramKey) => {
    const key = paramKey as keyof ConfigI

    if (
      (key && typeof params[key] === 'string') ||
      Array.isArray(params[key])
    ) {
      if (remove.includes(key) || params[key]?.includes('all')) {
        delete params[key]
      }
    }
  })
  return `${getContentRoute({
    client,
  })}/event/${streamType}/${eventId}?${queryString.stringify(params, {
    arrayFormat: 'comma',
  })}`
}

// Peacock

export const getSeverityMatrixRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/severity-matrix`
}

export const getIncidentFormRoute = ({ client }: { client: ClientT }) => {
  return `${getAppRoute({ client })}/incident-form`
}
