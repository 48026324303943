import { addLabelsToData } from '@/config/dataFormatters/utils/conviva'

const getExtremeValues = (data, dataKey, findMax = false) => {
  const dataEntries = data?.data || {}

  const findExtremeValues = (dataPoints) => {
    if (!dataPoints || dataPoints.length === 0) {
      return null
    }

    let extremeValue = dataPoints[0][dataKey] || 0
    let timestamps = [dataPoints[0].timestamp]

    for (let i = 1; i < dataPoints.length; i++) {
      const currentValue = dataPoints[i][dataKey] || 0
      const currentTimestamp = dataPoints[i].timestamp

      if (currentValue === null) {
        continue
      }

      if (currentValue === extremeValue) {
        timestamps.push(currentTimestamp)
      } else if (
        extremeValue !== undefined &&
        (findMax ? currentValue > extremeValue : currentValue < extremeValue)
      ) {
        extremeValue = currentValue
        timestamps = [currentTimestamp]
      }
    }

    return { value: extremeValue, timestamps }
  }

  return Object.keys(dataEntries)
    .map((splitKey) => {
      const extremeValues = findExtremeValues(dataEntries[splitKey])
      if (extremeValues) {
        return {
          id: splitKey || 'Unknown',
          ...extremeValues,
          isTotal: splitKey === 'total',
        }
      }
      return null
    })
    .filter((item) => item !== null)
}

export const timeseriesLegendMin = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const minValuesBySplit = getExtremeValues(data, dataKey)

  const dataWithLabels = addLabelsToData({
    data: minValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}

export const timeseriesLegendMax = ({ settings = {}, config = {}, data }) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  const maxValuesBySplit = getExtremeValues(data, dataKey, true)

  const dataWithLabels = addLabelsToData({
    data: maxValuesBySplit,
    splitBy,
    settings,
  })

  return dataWithLabels || []
}
