import { ClientT, FaqSettingsI } from '@/config/types'

// As clients become more varied, start creating FAQs unique to each client
import commonFaqs from './common'

export const faqs: Record<ClientT, FaqSettingsI> = {
  'nbcu-dtc': commonFaqs,
  now: commonFaqs,
  peacock: commonFaqs,
  showmax: commonFaqs,
  'sky-europe-and-peacock': commonFaqs,
  'sky-europe': commonFaqs,
  skyshowtime: commonFaqs,
  'now-gold': commonFaqs,
}

export default faqs
