import { HiExclamationCircle, HiOutlineExclamationCircle } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonOcellusConfig from './common/ocellus'

const videoStartFailuresTechnicalOcellus: MetricSettingsFn = ({
  variants,
  featureFlags,
}): MetricSettingsI => {
  const ocellusConfig = commonOcellusConfig({ variants, featureFlags })

  return {
    ...ocellusConfig,
    value: 'video-start-failures-technical-ocellus',
    apiParam: 'video-start-failures-technical',
    label: 'Video Start Failures Technical',
    dataKey: 'videoStartFailuresTechnical',
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: true,
    supportsClient: false,
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlineExclamationCircle,
    solidIcon: HiExclamationCircle,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Video Start Technical Failures occur when a client video player fails to play a video and an error code is generated.',
    aggregationType: 'total',
    invertedChangeColor: true,
    defaultConfig: {
      ...ocellusConfig.defaultConfig,
      metric: 'video-start-failures-technical-ocellus',
    },
  }
}

export default videoStartFailuresTechnicalOcellus
