import { HiCheck, HiChevronRight, HiSelector } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router-dom'

import clientSettings from '@/config/clientSettings'
import { FEATURE_FLAGS } from '@/config/constants'
import { ClientSettingsI, ClientT } from '@/config/types'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import useSettings from '@/hooks/useSettings'
import { useSelectedTheme } from '@/hooks/useTheme'
import { useClientStore } from '@/stores/client.store'
import { useUserStore } from '@/stores/user.store'
import { cn } from '@/utils/cn'
import { logEvent } from '@/utils/firebaseAnalytics'
import { Listbox } from '@headlessui/react'

const getLogoUrl = ({
  logo,
  theme,
}: {
  logo: ClientSettingsI['logo']
  theme: ThemeT
}) => {
  const logoUrl = logo[theme].app

  return logoUrl
}

function ClientSelectOptions({
  availableClients,
  listBoxOptionsClassName,
}: {
  availableClients: ClientSettingsI[]
  listBoxOptionsClassName?: string
}) {
  const theme = useSelectedTheme()

  return (
    <Listbox.Options
      unmount={false}
      className={cn(
        'absolute left-0 right-0 z-10 py-2 mx-2 mt-2 overflow-hidden border divide-y rounded-md shadow-lg border-border-main bg-neutral-dimmed-heavy divide-divider-main focus:outline-none',
        listBoxOptionsClassName,
      )}
    >
      <div className='px-4 pb-2'>
        <Listbox.Label className='text-xs font-semibold uppercase text-text-tertiary'>
          Context
        </Listbox.Label>
      </div>
      {availableClients.map(({ name, logo, key }) => {
        const logoUrl = getLogoUrl({ logo, theme })

        return (
          <Listbox.Option
            value={key}
            key={key}
            className={({ active, selected }) =>
              cn(
                'relative flex items-center cursor-default select-none py-3 pl-10 pr-4',
                {
                  'bg-neutral text-text-primary': active || selected,
                  'text-text-secondary': !active && !selected,
                },
              )
            }
          >
            {({ selected }) => (
              <>
                {selected ? (
                  <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-text-primary'>
                    <HiCheck
                      className='w-5 h-5 text-text-primary'
                      aria-hidden='true'
                    />
                  </span>
                ) : null}
                <img
                  src={logoUrl}
                  alt={name}
                  className='w-6 h-6 mr-2.5 rounded-md ring-1 ring-border-main'
                  aria-hidden='true'
                />
                <span className='text-sm font-medium truncate'>{name}</span>
              </>
            )}
          </Listbox.Option>
        )
      })}
    </Listbox.Options>
  )
}

function ClientName({
  client,
  showLabel,
}: {
  client: ClientT
  showLabel?: boolean
}) {
  const theme = useSelectedTheme()

  if (!client) return null

  const { logo, name } = clientSettings[client]
  const logoUrl = getLogoUrl({ logo, theme })

  return (
    <div className='flex items-center justify-between min-w-0 space-x-3'>
      <img
        src={logoUrl}
        alt={name}
        className='object-contain w-10 h-10 rounded-md'
      />
      <div className='flex flex-col items-start justify-center gap-y-0.5'>
        {showLabel && (
          <span className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
            Context
          </span>
        )}
        <p className='flex-1 mb-1 text-base font-semibold leading-tight line-clamp-2 text-text-primary'>
          {name}
        </p>
      </div>
    </div>
  )
}

function ClientButton({
  clientSettings,
  onClick,
}: {
  clientSettings: ClientSettingsI
  onClick: (client: ClientT) => void
}) {
  const typedClient = clientSettings.key as ClientT

  return (
    <div className='w-full pt-1'>
      <button
        onClick={() => onClick(typedClient)}
        className='w-full p-2 rounded-md hover:bg-neutral'
      >
        <div className='flex items-center justify-between space-x-3'>
          <div>
            <ClientName client={typedClient} />
          </div>
          <div>
            <HiChevronRight className='w-5 h-5' />
          </div>
        </div>
      </button>
    </div>
  )
}

interface ClientSelectProps {
  variant?: 'button-list' | 'select'
  disabled?: boolean
  className?: string
  buttonClassName?: string
  listBoxOptionsClassName?: string
}

function ClientSelect({
  variant = 'select',
  className,
  buttonClassName,
  listBoxOptionsClassName,
  disabled,
}: ClientSelectProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { appSettings } = useSettings()
  const client = useClientStore((state) => state.client)
  const authorizedClients = useUserStore((state) => state.authorizedClients)

  const { getFeatureFlag } = useFeatureFlags()

  // NOW Gold
  const nowGoldEnabled = getFeatureFlag(FEATURE_FLAGS.FF_NOW_GOLD_ENABLED)

  const availableClients = appSettings.clients
    .filter((client) => authorizedClients.includes(client))
    .map((client) => clientSettings[client])

  // NOW Gold
  if (nowGoldEnabled) {
    availableClients.push(clientSettings['now-gold'])
  }

  if (!availableClients || availableClients.length === 0) return null

  const handleChangeClient = (selectedClient: ClientT) => {
    logEvent('select_client', { client: selectedClient })
    // Update the 'client' param of the URL with the selected client
    // hocs/ClientAuthorization.tsx will listen for this and synchronise with the client store
    const restOfPathname = location.pathname
      .split('/')
      .slice(2) // Rest of URL starts at index 2 onwards (e.g. ['', 'sky-europe', 'metrics'])
      .filter(Boolean)
      .join('/')

    const search = location.search.replace(
      `client=${client}`,
      `client=${selectedClient}`,
    )

    let newPathname = `/${selectedClient}`

    if (restOfPathname) {
      newPathname += `/${restOfPathname}`
    }

    if (newPathname !== location.pathname) {
      navigate(`${newPathname}${search}`)
    }
  }

  if (variant === 'button-list') {
    return (
      <div
        className={cn(
          'flex flex-col space-y-1 divide-y divide-divider-main',
          className,
        )}
      >
        {availableClients.map((availableClientSettings) => {
          return (
            <ClientButton
              key={availableClientSettings.key}
              clientSettings={availableClientSettings}
              onClick={handleChangeClient}
            />
          )
        })}
      </div>
    )
  }

  if (availableClients.length === 1) {
    return (
      <div className='flex items-center w-full px-5 mb-2'>
        <ClientName client={client} />
      </div>
    )
  }

  return (
    <Listbox
      value={client}
      onChange={handleChangeClient}
      as='div'
      className={cn('relative inline-block px-2 text-left', className)}
      disabled={disabled}
    >
      <Listbox.Button
        className={({ open }) =>
          cn(
            'group w-full bg-neutral-dimmed-heavy -mt-2 xl:bg-neutral rounded-md px-3 py-2 text-sm text-left font-medium text-text-secondary hover:bg-neutral hover:xl:bg-neutral-shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral focus-visible:ring-elements-primary-main',
            {
              'bg-neutral xl:bg-neutral-shadow': open,
              'pointer-events-none': disabled,
            },
            buttonClassName,
          )
        }
      >
        <div className='flex items-center justify-between w-full h-10'>
          <ClientName client={client} showLabel />
          <HiSelector
            className='flex-shrink-0 w-5 h-5 text-text-dimmed group-hover:text-text-tertiary'
            aria-hidden='true'
          />
        </div>
      </Listbox.Button>
      <ClientSelectOptions
        availableClients={availableClients}
        listBoxOptionsClassName={listBoxOptionsClassName}
      />
    </Listbox>
  )
}

export default ClientSelect
