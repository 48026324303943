import clsx from 'clsx'

import { NavigationItemI } from '@/config/types'

import useSettings from '../../../hooks/useSettings'
import NavigationExpandable from './NavigationExpandable'
import NavigationItem from './NavigationItem'

const Navigation = () => {
  const { navigation } = useSettings()

  return (
    <nav className='px-2 mt-0 xl:px-3 xl:mt-4' aria-label='Sidebar'>
      {navigation.map(({ children = [], header, id }: any, index: number) => {
        return (
          <div
            key={id}
            className={clsx({
              'mt-8': index > 0,
            })}
          >
            {header && (
              <h3
                className='px-3 text-xs font-semibold tracking-wider uppercase text-text-tertiary'
                id={`${id}-headline`}
              >
                {header}
              </h3>
            )}
            <div
              className={clsx('space-y-1', {
                'mt-2': header,
              })}
              role='group'
              aria-labelledby={header && `${id}-headline`}
            >
              {children
                .filter((item: NavigationItemI) => !item.hidden)
                .map((item: NavigationItemI) => {
                  if (item?.children?.length && item?.children?.length > 0) {
                    return <NavigationExpandable key={item.id} {...item} />
                  }
                  return <NavigationItem key={item.id} {...item} />
                })}
            </div>
          </div>
        )
      })}
    </nav>
  )
}

export default Navigation
