// Removes properties that are unnecessary for react query (e.g. dateType) to avoid unnecessary network calls

const defaultRemove = ['isRange', 'dateType', 'removeDates']

const cleanseConfig = (config, remove = []) => {
  const _config = { ...config }

  ;[...defaultRemove, ...remove].forEach((toRemove) => {
    delete _config[toRemove]
  })

  return _config
}

export default cleanseConfig
