import {
  PickerProps,
  RangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment'
import { HiOutlineExclamation } from 'react-icons/hi'

import DatePicker from '@/components/DatePicker'
import ListBox from '@/components/ListBox'
import useTheme from '@/hooks/useTheme'
import { cn } from '@/utils/cn'

interface PropsI {
  relative?: boolean
  dates: any[]
  config: ConfigI
  onUpdateLocalConfig: (value: any, delay: number) => void
  datePickerProps?: PickerProps<Moment> | RangePickerProps<Moment>
  showCustomDateRangeDisclaimer?: boolean
  buttonClassName?: string
  listBoxClassName?: string
}

const DatePickerSwitch = ({
  relative,
  dates,
  config,
  onUpdateLocalConfig,
  datePickerProps = {},
  showCustomDateRangeDisclaimer = false,
  buttonClassName,
  listBoxClassName,
}: PropsI) => {
  const theme = useTheme()!
  const removeDates = config?.removeDates || []

  const handleDatePickerChange = (date: any) => {
    onUpdateLocalConfig({ ...date, 'time-period': null }, 300)
  }

  if (relative) {
    return (
      <>
        <ListBox
          listBoxClassName={listBoxClassName}
          buttonClassName={buttonClassName}
          label='Date Picker'
          labelHidden
          value={config?.relativeDate}
          options={dates.filter(
            (date) =>
              removeDates.findIndex(
                (dateToRemove) => dateToRemove === date.id,
              ) === -1,
          )}
          onChange={(relativeDate: any) => {
            const _config: ConfigI = {
              relativeDate,
            }

            if (relativeDate?.id === 'custom') {
              _config.dateType = 'datetime'
              _config['time-period'] = null

              if (config['stream-type']?.includes('vod')) {
                // If the stream type is vod, we won't have a start/end in config to use as our initial date
                // We want to create a sensible start/end using getStartEndParams from conviva2RelativeDates

                if (config?.relativeDate?.getStartEndParams) {
                  const dateParams = config?.relativeDate?.getStartEndParams()

                  if (dateParams && 'start' in dateParams) {
                    _config.start = dateParams?.start.clone().utc().format()
                  }
                  if (dateParams && 'end' in dateParams) {
                    _config.end = dateParams?.end.clone().utc().format()
                  }
                }
              }
            } else {
              const dateParams = relativeDate?.getDateRange() || {}
              const { start, end } = dateParams

              if (start) {
                _config.start = start.clone().utc().format()
              }
              if (end) {
                _config.end = end.clone().utc().format()
              }
              if (dateParams['time-period']) {
                _config['time-period'] = dateParams['time-period']
              }
              _config.dateType = 'relative'
            }
            onUpdateLocalConfig(_config, 0)
          }}
        />
        {config?.dateType === 'datetime' && (
          <div className='mt-2'>
            <DatePicker
              selected={{
                start: config?.start,
                end: config?.end,
                dateType: 'datetime',
                isRange: true,
              }}
              onChange={handleDatePickerChange}
              datePickerProps={datePickerProps}
            />
            {showCustomDateRangeDisclaimer && (
              <div
                className={cn(
                  'flex items-center px-2.5 py-3 mt-2 text-xs font-regular border-l-2 border-amber-500 bg-amber-500/10',
                  {
                    'text-amber-700': theme.type === 'light',
                    'text-amber-400': theme.type === 'dark',
                  },
                )}
              >
                <HiOutlineExclamation className='flex-shrink-0 w-4 h-4 mr-2.5' />
                Custom date ranges can be slow, especially over long windows
              </div>
            )}
          </div>
        )}
      </>
    )
  } else {
    return (
      <DatePicker
        selected={{
          start: config?.start,
          end: config?.end,
          dateType: config?.dateType,
          isRange: config?.isRange,
        }}
        onChange={handleDatePickerChange}
        datePickerProps={datePickerProps}
      />
    )
  }
}

export default DatePickerSwitch
