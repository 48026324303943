import moment, { Moment } from 'moment-timezone'
import {
  HiOutlineCog,
  HiOutlineDeviceMobile,
  HiOutlineGlobeAlt,
  HiOutlineVideoCamera,
} from 'react-icons/hi'

import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import {
  DEVICE_TYPE,
  OPERATING_SYSTEM,
  STREAM_TYPE,
  TERRITORY,
  showmaxTerritoryOptions,
} from '@/config/formOptions'

import { FormI, HomeSettingsI, SelectOptionI } from '../../types'

export const qualityMetrics = [
  'average-bitrate',
  'connection-induced-rebuffering-ratio',
  'video-startup-time',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
  'exits-before-video-start-percentage',
]

export const filterOptions: FormI[] = [
  {
    value: 'territory',
    type: 'button-group',
    multi: true,
    options: [TERRITORY['all'], ...showmaxTerritoryOptions],
  },
  {
    value: 'device-type',
    type: 'button-group',
    multi: true,
    options: [
      DEVICE_TYPE['all'],
      DEVICE_TYPE['stb'],
      DEVICE_TYPE['mobile'],
      DEVICE_TYPE['smart-tv'],
      DEVICE_TYPE['console'],
      DEVICE_TYPE['web'],
      DEVICE_TYPE['streaming-stick'],
    ],
  },
  {
    value: 'stream-type',
    type: 'button-group',
    multi: true,
    options: [STREAM_TYPE['all'], STREAM_TYPE['vod'], STREAM_TYPE['sle']],
  },
  {
    value: 'operating-system',
    type: 'button-group',
    multi: true,
    // Options copied from /tags endpoint response for Showmax client
    options: [
      OPERATING_SYSTEM['android'],
      OPERATING_SYSTEM['android-tv'],
      OPERATING_SYSTEM['chromecast'],
      OPERATING_SYSTEM['explora'],
      OPERATING_SYSTEM['hisense-tv'],
      OPERATING_SYSTEM['huawei'],
      OPERATING_SYSTEM['ios'],
      OPERATING_SYSTEM['lg-tv'],
      OPERATING_SYSTEM['playstation'],
      OPERATING_SYSTEM['samsung-tv'],
      OPERATING_SYSTEM['streama'],
      OPERATING_SYSTEM['tv-os'],
      OPERATING_SYSTEM['web'],
      OPERATING_SYSTEM['xbox'],
    ],
  },
]

export const splitByOptions: SelectOptionI[] = [
  {
    label: 'Split by Territory',
    icon: HiOutlineGlobeAlt,
    value: 'territory',
  },
  {
    label: 'Split by Device Type',
    icon: HiOutlineDeviceMobile,
    value: 'device-type',
  },
  {
    label: 'Split by Stream Type',
    icon: HiOutlineVideoCamera,
    value: 'stream-type',
  },
  {
    label: 'Split by Operating System',
    icon: HiOutlineCog,
    value: 'operating-system',
  },
]

export const defaultConfig = {
  'split-by': 'territory',
  proposition: ['showmax'],
  territory: ['all'],
  'stream-type': ['all'],
  'device-type': ['all'],
  start: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['showmax'])
    .startOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
  end: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['showmax'])
    .endOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
}

const homeSettings: HomeSettingsI = {
  qualityMetrics,
  filterOptions,
  splitByOptions,
  defaultConfig,
  realtime: {
    topEvents: {
      streamTypeOptions: ['sle'],
      defaultStreamType: 'sle',
      label: 'Top Live Events',
    },
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = moment()
        .clone()
        .tz(CLIENT_DEFAULT_TIMEZONES['showmax'])
        .subtract(1, 'minute')
        .startOf('minute')

      const start = now.clone().subtract(30, 'minutes')
      const end = now.clone()

      return { start, end }
    },
  },
  topEvents: {
    streamTypeOptions: ['vod', 'sle'],
    defaultStreamType: 'vod',
  },

  passActivationsAndCancellations: {
    enabled: false,
  },
  minutesAndUniques: {
    enabled: true,
  },
  quality: {
    enabled: true,
  },
  signInsAndSignUps: {
    enabled: true,
  },
  weeklyViewingReport: {
    enabled: false,
  },
}

export default Object.freeze(homeSettings)
