const Lights = ({
  numberOfLightbulbs = 42,
}: {
  numberOfLightbulbs?: number
}) => (
  <div className='absolute top-0 z-30 w-full pointer-events-none h-80'>
    <ul className='lightrope'>
      {new Array(numberOfLightbulbs).fill('x').map((index: number) => (
        <li key={index} className='lightbulb'></li>
      ))}
    </ul>
  </div>
)

export default Lights
