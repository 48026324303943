import clsx from 'clsx'

import EventItemQualityMetrics from '@/components/Event/EventItemQualityMetrics'
import Live from '@/components/Live'
import VideoFormatIcons from '@/components/VideoFormatIcons'
import { EventsSettingsI } from '@/config/types'
import useTheme from '@/hooks/useTheme'
import { useClientStore } from '@/stores/client.store'
import { useEventStore } from '@/stores/event.store'
import { useTimeStore } from '@/stores/time.store'
import { logEvent } from '@/utils/firebaseAnalytics'
import getChannelLogo from '@/utils/getChannelLogo'
import getDateRangeString from '@/utils/getDateRangeString'
import getEventConfigParams from '@/utils/getEventConfigParams'

import ChipGroup from '../../../components/ChipGroup'
import { DiffBar } from './DiffBar'
import { EventItemMeta } from './EventItemMeta'
import { QUALITY_METRICS } from '.'

export const EventItem = ({
  event,
  id,
  index,
  config,
  settings: eventsSettings,
  setDiffBarHovered,
}: {
  event: Conviva2EventI
  index: number
  id: string
  config: ConfigI
  settings: EventsSettingsI
  setDiffBarHovered: (args: {
    enabled: boolean
    start: string
    end: string
  }) => void
}) => {
  const client = useClientStore((state) => state.client)
  const timezone = useTimeStore((state) => state.timezone)
  const eventIsOpen = useEventStore((state) => state.open)
  const selectedEvent = useEventStore((state) => state.event)
  const onOpenEvent = useEventStore((state) => state.onOpenEvent)
  const { type } = useTheme()!

  const streamType = Array.isArray(config['stream-type'])
    ? (config['stream-type'][0] as StreamTypes)
    : (config['stream-type'] as StreamTypes)

  const { metric, ...conviva2EventMetricParams } = getEventConfigParams(
    event,
    config,
  )

  const eventStart = conviva2EventMetricParams.start
  const eventEnd = conviva2EventMetricParams.end

  const { start, end, relative } = getDateRangeString({
    start: eventStart,
    end: eventEnd,
    timezone,
  })

  const channelLogo = getChannelLogo(event?.meta, event?.territories)

  const handleSetDiffBar = (enabled: boolean) => {
    setDiffBarHovered({
      enabled,
      start: eventStart as string,
      end: eventEnd as string,
    })
  }

  const eventIsSelected = selectedEvent?.id === id

  return (
    <li
      tabIndex={0}
      className={clsx(
        'cursor-pointer group rounded-sm border-b border-border-main',
        {
          'ring-2 ring-inset ring-elements-primary-main bg-neutral hover:bg-neutral-shadow':
            eventIsSelected && eventIsOpen,
          'hover:bg-neutral-dimmed bg-neutral-dimmed-heavy': !eventIsSelected,
        },
      )}
      onClick={() => {
        onOpenEvent({
          id,
          streamType,
          config,
          ...event,
        })
        const { meta, ...restOfEvent } = event
        logEvent('select_event', { ...meta, ...restOfEvent })
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
        if (e.key === 'Enter') {
          onOpenEvent({
            id,
            streamType,
            config,
            ...event,
          })
        }
      }}
      onMouseEnter={() => {
        handleSetDiffBar(true)
      }}
      onMouseLeave={() => {
        handleSetDiffBar(false)
      }}
    >
      <div className='grid items-center grid-cols-12 gap-6 px-4 py-4 md:px-6 2xl:gap-8'>
        <div className='col-span-3 2xl:col-span-2'>
          <div
            className='relative flex items-center justify-center h-20 overflow-hidden bg-center bg-no-repeat bg-cover max-w-[200px] rounded-lg sm:h-24 lg:h-28 bg-neutral-shadow'
            style={{
              backgroundImage: event?.meta?.imageUrl
                ? `url(${event?.meta?.imageUrl})`
                : `url(/images/${client}/image-placeholder.png)`,
            }}
          >
            <div className='absolute z-10 flex bottom-2 left-2'>
              <p className='text-xl font-medium leading-none text-white'>
                {index + 1}
              </p>
            </div>
            <div className='absolute z-10 items-end justify-end hidden w-20 h-4 md:w-24 sm:flex bottom-2 right-2'>
              {event?.meta?.channelName && channelLogo && (
                <img
                  src={channelLogo}
                  alt='Channel Logo'
                  className='object-auto max-h-5 xl:max-h-6 max-w-20'
                />
              )}
            </div>
            <div className='absolute top-0 w-full h-full opacity-60 bg-gradient-to-t from-event-backdrop' />
          </div>
        </div>

        <div className='grid h-full grid-cols-12 col-span-9 2xl:col-span-10'>
          <div className='flex items-center justify-between col-span-12'>
            <div className='flex items-center'>
              <Live start={eventStart} end={eventEnd} className='mr-1.5' />
              <p className='overflow-hidden font-medium whitespace-nowrap text:sm md:text-base text-text-secondary line-clamp-1'>
                {event?.meta?.title}
              </p>
            </div>
            <div className='flex items-center justify-self-end'>
              <p className='flex items-center mb-0.5 text-sm font-semibold text-right text-text-secondary tabular-nums'>
                {event?.concurrentPlays &&
                metric?.includes('concurrent-plays') ? (
                  <span className='mr-2 text-sm font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                    Peak Concurrency
                  </span>
                ) : event?.plays && metric?.includes('plays') ? (
                  <span className='mr-2 font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                    Total Plays
                  </span>
                ) : (
                  ' '
                )}

                {event?.concurrentPlays
                  ? new Intl.NumberFormat('en-GB', {}).format(
                      event?.concurrentPlays,
                    )
                  : event?.plays &&
                    new Intl.NumberFormat('en-GB', {}).format(event?.plays)}
              </p>
            </div>
          </div>

          <div className='grid items-start grid-cols-12 col-span-12'>
            <div className='flex flex-col justify-between h-full col-span-8'>
              <div className='flex flex-col'>
                {streamType !== 'vod' && start && end && (
                  <p className='text-xs truncate xl:text-sm mt-0.5 text-text-dimmed'>
                    {start} - {end}{' '}
                    <span className='hidden ml-1 italic opacity-0 2xl:inline-block text-text-tertiary group-hover:opacity-100'>
                      ({relative})
                    </span>
                  </p>
                )}
                <div className='flex items-center space-x-2'>
                  <EventItemMeta event={event} />
                  {['sle', 'vod'].includes(streamType) &&
                    getIcons(event).length > 0 && (
                      <>
                        <span className='text-text-tertiary'>∙</span>

                        <VideoFormatIcons
                          videoFormatIcons={getIcons(event)}
                          className='flex'
                          imageClassName='h-3.5 mt-1 mr-1 opacity-70'
                          type={type}
                        />
                      </>
                    )}
                </div>
              </div>
              <div className='flex flex-wrap items-end gap-1 pb-1'>
                {eventsSettings.showIcons.territory.enabled && (
                  <ChipGroup
                    maxChips={3}
                    formGroup={'territory'}
                    settings={eventsSettings}
                    list={event.territories || []}
                  />
                )}
                {eventsSettings.showIcons.territory.enabled && (
                  <ChipGroup
                    maxChips={3}
                    formGroup={'proposition'}
                    settings={eventsSettings}
                    list={event.propositions || []}
                  />
                )}
              </div>
            </div>

            {streamType !== 'vod' && (
              <div className='flex flex-col items-end justify-start col-span-4'>
                <EventItemQualityMetrics
                  qualityMetrics={QUALITY_METRICS}
                  eventData={event}
                  wrapperClassName='flex flex-col space-y-1 items-end'
                  itemClassName='flex items-center text-xs font-medium text-right text-text-secondary tabular-nums'
                />
              </div>
            )}

            {/* )} */}
          </div>
        </div>
      </div>
      {!!event?.meta?.startTimeEpochUTC && !!event?.meta?.startTimeEpochUTC && (
        <div className='hidden w-full px-0.5 pb-0.5 sm:block'>
          <DiffBar
            start={config.start}
            end={config.end}
            itemStart={eventStart}
            itemEnd={eventEnd}
            setDiffBarHovered={setDiffBarHovered}
            enabled
          />
        </div>
      )}
    </li>
  )
}

function getIcons(event?: Conviva2EventI) {
  return event?.metricsByVideoFormat
    ? Object.keys(event.metricsByVideoFormat).filter(
        // temporarily filtering out 'all' for Ocellus events, will be changed to 'unknown' on the BE
        (key) => key !== 'unknown' && key !== 'all' && !!key,
      )
    : []
}
