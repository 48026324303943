import { BIGVIVA_SDP_API_DOMAIN, DEFAULT_DATE } from '@/config/constants'
import {
  PRODUCT,
  PROPOSITION,
  SPLIT_BY,
  TERRITORY,
  nowTerritoryOptions,
} from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI, VariantsT } from '@/config/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonBigvivaSdpConfig = ({ variants }: { variants?: VariantsT }) => {
  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [
        SPLIT_BY['proposition'],
        SPLIT_BY['territory'],
        SPLIT_BY['product'],
      ],
    },
    {
      value: 'proposition',
      type: 'button-group',
      multi: true,
      options: [
        PROPOSITION['all'],
        PROPOSITION['now-tv'],
        PROPOSITION['sky-x'],
      ],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...nowTerritoryOptions, TERRITORY['at']],
    },
    {
      value: 'product',
      type: 'button-group',
      multi: true,
      options: [
        PRODUCT['all'],
        PRODUCT['sport'],
        PRODUCT['hayu'],
        PRODUCT['cinema'],
        PRODUCT['entertainment'],
        PRODUCT['boost'],
        PRODUCT['other'],
      ],
    },
  ]

  return {
    dataSource: 'SDP BigQuery',
    legacyGroup: 'sdp',
    group: GROUPS.SDP,
    apiDomain: BIGVIVA_SDP_API_DOMAIN,
    apiRoute: 'subscription-events',
    supportsClient: false,
    timeseriesAsParam: true,
    metricAsParam: true,
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonFiveMinuteGranularity,
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    defaultConfig: {
      'split-by': 'proposition',
      'exclude-proposition': ['skyshowtime'],
      proposition: ['all'],
      territory: ['all'],
      product: ['all'],
      ...DEFAULT_DATE('nbcu-dtc'),
    },
  }
}
export default commonBigvivaSdpConfig
