import create from 'zustand'

export type SharedPlotBand = {
  from: number
  to: number
  color: string
  borderColor: string
  borderWidth: number
} | null

interface SharedChartStoreStateI {
  sharedTooltipTimestamp: number | null
  setSharedTooltipTimestamp: (sharedTooltipTimestamp: number | null) => void
  sharedPlotBand: SharedPlotBand
  setSharedPlotBand: (sharedPlotBand: SharedPlotBand) => void
  sharedZoomInfo: { min: number | null; max: number | null }
  setSharedZoomInfo: (min: number | null, max: number | null) => void
}

export const useSharedChartStore = create<SharedChartStoreStateI>((set) => ({
  sharedTooltipTimestamp: null,
  setSharedTooltipTimestamp: (sharedTooltipTimestamp: number | null) =>
    set(() => ({ sharedTooltipTimestamp })),
  sharedPlotBand: null,
  setSharedPlotBand: (sharedPlotBand: SharedPlotBand) =>
    set(() => ({ sharedPlotBand })),
  sharedZoomInfo: { min: null, max: null },
  setSharedZoomInfo: (min: number | null, max: number | null) =>
    set(() => ({ sharedZoomInfo: { min, max } })),
}))
