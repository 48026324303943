import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useClientStore } from '@/stores/client.store'
import {
  logEvent,
  setCurrentScreen,
  setUserProperties,
} from '@/utils/firebaseAnalytics'

import useSettings from '@/hooks/useSettings'

const FirebaseAnalytics = ({ children }) => {
  const client = useClientStore((state) => state.client)
  const { appSettings } = useSettings()

  let location = useLocation()

  useEffect(() => {
    setUserProperties({
      insightsDashboardClient: client,
    })
  }, [client])

  useEffect(() => {
    if (appSettings?.analytics?.enabled) {
      const page_path = location.pathname + location.search
      setCurrentScreen(page_path)
      logEvent('page_view', { page_path })
    }
  }, [location])

  return children
}

export default FirebaseAnalytics
