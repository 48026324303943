import {
  HiOutlineChartSquareBar,
  HiOutlineClipboardList,
  HiOutlineDocumentText,
  HiOutlineFilm,
  HiOutlineHome,
  HiOutlineMail,
  HiOutlinePlay,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi'
import { MdListAlt } from 'react-icons/md'
import { TbOlympics } from 'react-icons/tb'

import { NavigationSettingsI } from '../types'
import { NavigationPropsI } from './index'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const navigation = (props: NavigationPropsI): NavigationSettingsI => {
  const nav = [
    {
      id: 'main-nav',
      children: [
        {
          id: 'home',
          label: 'Home',
          icon: HiOutlineHome,
          url: '/',
        },
        {
          id: 'metrics',
          label: 'Metrics',
          icon: HiOutlineChartSquareBar,
          url: '/metrics',
        },
        {
          id: 'content',
          label: 'Content',
          icon: HiOutlinePlay,
          url: '/content',
          defaultExpanded: true,
          children: [
            {
              id: 'olympics',
              label: 'Olympics',
              url: '/content/olympics',
              icon: TbOlympics,
              hidden: !props.featureFlags['FF_OLYMPICS_EVENT_PAGE'],
            },
            {
              id: 'all-events',
              label: 'All Events',
              icon: HiOutlineFilm,
              url: '/content/all-events',
            },
          ],
        },
        {
          id: 'reports',
          label: 'Reports',
          icon: HiOutlineDocumentText,
          url: '/reports',
          // As there's only one report available atm, we don't want to show empty Reports section, so the whole Reports section is hidden when Certs page FF is disabled
          hidden: !props.featureFlags['FF_CERTIFICATES_PAGE'],
          defaultExpanded: true,
          children: [
            // {
            //   id: 'weekly-viewing',
            //   label: 'Weekly Viewing',
            //   url: '/reports/weekly-viewing',
            //   icon: HiOutlineClipboardList,
            //   badge: 'New',
            //   badgeClassName:
            //     'bg-elements-primary-main/10 text-elements-primary-main',
            // },
            {
              id: 'certificates',
              label: 'Global Certificates',
              url: '/reports/global-certificates',
              icon: HiOutlineClipboardList,
              badge: 'New',
              badgeClassName:
                'bg-elements-primary-main/10 text-elements-primary-main',
              hidden: !props.featureFlags['FF_CERTIFICATES_PAGE'],
            },
          ],
        },
      ],
    },
    // {
    //   id: 'tools-nav',
    //   header: 'Tools',
    //   children: [
    //     {
    //       id: 'severity-matrix',
    //       label: 'Severity Matrix',
    //       icon: HiOutlineTag,
    //       url: '/severity-matrix',
    //     },
    //     {
    //       id: 'incident-form',
    //       label: 'Incident Form',
    //       icon: HiOutlineBell,
    //       url: '/incident-form',
    //     },
    //   ],
    // },
    {
      id: 'support-nav',
      header: 'Support',
      children: [
        {
          id: 'contact',
          label: 'Contact',
          icon: HiOutlineMail,
          url: '/contact',
        },
        {
          id: 'faqs',
          label: 'FAQs',
          icon: HiOutlineQuestionMarkCircle,
          url: '/faqs',
        },
        // {
        //   id: 'roadmap',
        //   label: 'Roadmap',
        //   icon: HiOutlineMap,
        //   url: '/roadmap',
        // },
        {
          id: 'metrics-definitions',
          label: 'Metric Definitions',
          icon: MdListAlt,
          url: '/metric-definitions',
          hidden: !props.featureFlags['FF_METRIC_DEFINITIONS_PAGE_ENABLED'],
        },
      ],
    },
  ]
  return nav
}

export default navigation
