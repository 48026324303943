import { MetricSettingsFn } from '../../types'
import averageBitrate from './conviva/averageBitrate'
import averageVideoStartupTime from './conviva/averageVideoStartupTime'
import concurrentPlays from './conviva/concurrentPlays'
import connectionInducedRebufferingRatio from './conviva/connectionInducedRebufferingRatio'
import exitsBeforeVideoStart from './conviva/exitsBeforeVideoStart'
import exitsBeforeVideoStartPercentage from './conviva/exitsBeforeVideoStartPercentage'
import plays from './conviva/plays'
import rebufferingRatio from './conviva/rebufferingRatio'
import totalAttempts from './conviva/totalAttempts'
import videoPlaybackFailures from './conviva/videoPlaybackFailures'
import videoPlaybackFailuresBusiness from './conviva/videoPlaybackFailuresBusiness'
import videoPlaybackFailuresBusinessPercentage from './conviva/videoPlaybackFailuresBusinessPercentage'
import videoPlaybackFailuresPercentage from './conviva/videoPlaybackFailuresPercentage'
import videoPlaybackFailuresTechnical from './conviva/videoPlaybackFailuresTechnical'
import videoPlaybackFailuresTechnicalPercentage from './conviva/videoPlaybackFailuresTechnicalPercentage'
import videoStartFailures from './conviva/videoStartFailures'
import videoStartFailuresBusiness from './conviva/videoStartFailuresBusiness'
import videoStartFailuresBusinessPercentage from './conviva/videoStartFailuresBusinessPercentage'
import videoStartFailuresPercentage from './conviva/videoStartFailuresPercentage'
import videoStartFailuresTechnical from './conviva/videoStartFailuresTechnical'
import videoStartFailuresTechnicalPercentage from './conviva/videoStartFailuresTechnicalPercentage'
import signIns from './identity/signIns'
import averageBitrateOcellus from './ocellus/averageBitrateOcellus'
import averageVideoStartupTimeOcellus from './ocellus/averageVideoStartupTimeOcellus'
import concurrentPlaysOcellus from './ocellus/concurrentPlaysOcellus'
import connectionInducedRebufferingRatioOcellus from './ocellus/connectionInducedRebufferingRatioOcellus'
import exitsBeforeVideoStartOcellus from './ocellus/exitsBeforeVideoStartOcellus'
import exitsBeforeVideoStartPercentageOcellus from './ocellus/exitsBeforeVideoStartPercentageOcellus'
import playsOcellus from './ocellus/playsOcellus'
import rebufferingRatioOcellus from './ocellus/rebufferingRatioOcellus'
import totalAttemptsOcellus from './ocellus/totalAttemptsOcellus'
import videoPlaybackFailuresBusinessOcellus from './ocellus/videoPlaybackFailuresBusinessOcellus'
import videoPlaybackFailuresBusinessPercentageOcellus from './ocellus/videoPlaybackFailuresBusinessPercentageOcellus'
import videoPlaybackFailuresOcellus from './ocellus/videoPlaybackFailuresOcellus'
import videoPlaybackFailuresPercentageOcellus from './ocellus/videoPlaybackFailuresPercentageOcellus'
import videoPlaybackFailuresTechnicalOcellus from './ocellus/videoPlaybackFailuresTechnicalOcellus'
import videoPlaybackFailuresTechnicalPercentageOcellus from './ocellus/videoPlaybackFailuresTechnicalPercentageOcellus'
import videoStartFailuresBusinessOcellus from './ocellus/videoStartFailuresBusinessOcellus'
import videoStartFailuresBusinessPercentageOcellus from './ocellus/videoStartFailuresBusinessPercentageOcellus'
import videoStartFailuresOcellus from './ocellus/videoStartFailuresOcellus'
import videoStartFailuresPercentageOcellus from './ocellus/videoStartFailuresPercentageOcellus'
import videoStartFailuresTechnicalOcellus from './ocellus/videoStartFailuresTechnicalOcellus'
import videoStartFailuresTechnicalPercentageOcellus from './ocellus/videoStartFailuresTechnicalPercentageOcellus'
import activeSubscriptions from './peacockSdp/activeSubscriptions'
import adFreeTransactions from './peacockSdp/adFreeTransactions'
import prometheusResponseDuration from './prometheusApplication/responseDuration'
import prometheusTotalResponses from './prometheusApplication/totalResponses'
import prometheusTps from './prometheusApplication/tps'
import prometheusPacketDrops from './prometheusCloudEngGateway/packetDrops'
import prometheusThroughputCloudEngGW from './prometheusCloudEngGateway/throughputCloudEngGW'
import prometheusConcurrency from './prometheusConcurrency/concurrency'
import prometheusCPUUsage from './prometheusCore/cpuUsage'
import prometheusMemoryUsage from './prometheusCore/memoryUsage'
import prometheusTotalAvailableNodeCores from './prometheusCore/totalAvailableNodeCores'
import prometheusTotalNodeBufferCores from './prometheusCore/totalNodeBufferCores'
import prometheusTotalNodeRequestedCores from './prometheusCore/totalNodeRequestedCores'
import prometheusTotalNodes from './prometheusCore/totalNodes'
import prometheusTotalReadyNodes from './prometheusCore/totalReadyNodes'
import prometheusUtilisedNodes from './prometheusCore/utilisedNodes'
import prometheusUtilisedPods from './prometheusCore/utilisedPods'
import prometheusRequestRate from './prometheusCoreIngress/requestRate'
import prometheusWaitingConnections from './prometheusCoreIngress/waitingConnections'
import prometheusWritingConnections from './prometheusCoreIngress/writingConnections'
import prometheusMaxConnections from './prometheusCoreLoadBalancer/maxConnections'
import prometheusOpenConnections from './prometheusCoreLoadBalancer/openConnections'
import prometheusTrafficIn from './prometheusCoreNetwork/trafficIn'
import prometheusTrafficOut from './prometheusCoreNetwork/trafficOut'
import prometheusLatency from './prometheusPersistence/latency'
import prometheusThroughputPersistence from './prometheusPersistence/throughputPersistence'
import signUps from './sdp/signUps'

const metrics: { [key: string]: MetricSettingsFn } = {
  // Conviva
  'concurrent-plays': concurrentPlays,
  plays: plays,
  'total-attempts': totalAttempts,
  'average-bitrate': averageBitrate,
  'connection-induced-rebuffering-ratio': connectionInducedRebufferingRatio,
  'rebuffering-ratio': rebufferingRatio,
  'exits-before-video-start': exitsBeforeVideoStart,
  'exits-before-video-start-percentage': exitsBeforeVideoStartPercentage,
  'video-playback-failures': videoPlaybackFailures,
  'video-playback-failures-percentage': videoPlaybackFailuresPercentage,
  'video-playback-failures-business-percentage':
    videoPlaybackFailuresBusinessPercentage,
  'video-playback-failures-technical-percentage':
    videoPlaybackFailuresTechnicalPercentage,
  'video-playback-failures-business': videoPlaybackFailuresBusiness,
  'video-playback-failures-technical': videoPlaybackFailuresTechnical,
  'video-start-failures': videoStartFailures,
  'video-start-failures-percentage': videoStartFailuresPercentage,
  'video-start-failures-business-percentage':
    videoStartFailuresBusinessPercentage,
  'video-start-failures-business': videoStartFailuresBusiness,
  'video-start-failures-technical': videoStartFailuresTechnical,
  'video-start-failures-technical-percentage':
    videoStartFailuresTechnicalPercentage,
  'video-startup-time': averageVideoStartupTime,

  // Ocellus
  'concurrent-plays-ocellus': concurrentPlaysOcellus,
  'plays-ocellus': playsOcellus,
  'total-attempts-ocellus': totalAttemptsOcellus,
  'average-bitrate-ocellus': averageBitrateOcellus,
  'connection-induced-rebuffering-ratio-ocellus':
    connectionInducedRebufferingRatioOcellus,
  'rebuffering-ratio-ocellus': rebufferingRatioOcellus,
  'exits-before-video-start-ocellus': exitsBeforeVideoStartOcellus,
  'exits-before-video-start-percentage-ocellus':
    exitsBeforeVideoStartPercentageOcellus,
  'video-playback-failures-ocellus': videoPlaybackFailuresOcellus,
  'video-playback-failures-percentage-ocellus':
    videoPlaybackFailuresPercentageOcellus,
  'video-playback-failures-business-percentage-ocellus':
    videoPlaybackFailuresBusinessPercentageOcellus,
  'video-playback-failures-technical-percentage-ocellus':
    videoPlaybackFailuresTechnicalPercentageOcellus,
  'video-playback-failures-business-ocellus':
    videoPlaybackFailuresBusinessOcellus,
  'video-playback-failures-technical-ocellus':
    videoPlaybackFailuresTechnicalOcellus,
  'video-start-failures-ocellus': videoStartFailuresOcellus,
  'video-start-failures-percentage-ocellus':
    videoStartFailuresPercentageOcellus,
  'video-start-failures-business-percentage-ocellus':
    videoStartFailuresBusinessPercentageOcellus,
  'video-start-failures-business-ocellus': videoStartFailuresBusinessOcellus,
  'video-start-failures-technical-ocellus': videoStartFailuresTechnicalOcellus,
  'video-start-failures-technical-percentage-ocellus':
    videoStartFailuresTechnicalPercentageOcellus,
  'video-startup-time-ocellus': averageVideoStartupTimeOcellus,

  // Peacock SDP
  'active-subscriptions': activeSubscriptions,
  'ad-free-transactions': adFreeTransactions,

  // Identity
  'sign-ins': signIns,

  // SDP
  'sign-ups': signUps,

  // Prometheus (Application)
  'response-duration': prometheusResponseDuration,
  'total-responses': prometheusTotalResponses,
  tps: prometheusTps,

  // Prometheus Cloud Eng Gateway
  'throughput-cloud-eng-gw': prometheusThroughputCloudEngGW,
  'packet-drops': prometheusPacketDrops,

  // Prometheus (Core)
  'cpu-usage': prometheusCPUUsage,
  'memory-usage': prometheusMemoryUsage,
  'total-nodes': prometheusTotalNodes,
  'total-ready-nodes': prometheusTotalReadyNodes,
  'total-node-buffer-cores': prometheusTotalNodeBufferCores,
  'total-node-requested-cores': prometheusTotalNodeRequestedCores,
  'total-available-node-cores': prometheusTotalAvailableNodeCores,
  'utilised-nodes': prometheusUtilisedNodes,
  'utilised-pods': prometheusUtilisedPods,

  // Prometheus Core Network
  'traffic-in': prometheusTrafficIn,
  'traffic-out': prometheusTrafficOut,

  // Prometheus Core Ingress
  'waiting-connections': prometheusWaitingConnections,
  'writing-connections': prometheusWritingConnections,
  'request-rate': prometheusRequestRate,

  // Prometheus Core Load Balancer
  'open-connections': prometheusOpenConnections,
  'max-connections': prometheusMaxConnections,

  // Prometheus Persistence
  'throughput-persistence': prometheusThroughputPersistence,
  latency: prometheusLatency,

  // Prometheus concurrency - fallback when conviva is down
  concurrency: prometheusConcurrency,
}

export default metrics
