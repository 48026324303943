import RequestContextContent from './components/RequestContextContent'
import { FaqSettingsI } from '../../types'

const faqs: FaqSettingsI = [
  {
    title: 'What is a context?',
    content: `The dashboard is organized into 'contexts', which represent either individual propositions or combinations from NBCU and Sky. You can only view data within contexts you're authorized for. Your Azure AD profile determines your context access.`,
  },
  {
    title: 'How can I get access to additional contexts?',
    content: <RequestContextContent />,
  },
  {
    title: 'What is granularity?',
    content:
      "Granularity is the space between each data point on the chart. For example, a granularity of '1 hour' means that there will be a data point every hour.",
  },
  {
    title: 'What is aggregation?',
    content:
      "Aggregation is the formation of a number of data points into a single cluster. Data in this dashboard is aggregated in different ways depending on the metric. Concurrency for example is aggregated by the 'Peak' value from the range of data points, whilst plays is aggregated by the 'Total' of all of the data points.",
  },
  {
    title:
      'Why do charts with longer date ranges have less granular data points?',
    content:
      'Charts with a short date range typically have data points every 1-15 minutes. However the longer date ranges such as 1M only have a data point to represent each full day. This is because plotting too many total data points to the charts will make the website very slow.',
  },
  {
    title: 'What is Conviva?',
    content:
      'Conviva is a third party video analytics provider that collects and processes our customer facing application data and allows us to monitor and act on the performance of our video streams.',
  },
  {
    title: 'How realtime is the data in this app?',
    content:
      'This varies dependent on the metric, but is typically up to the nearest 1 minute interval for shorter date ranges. The informational tooltip for each metric found in the top right corner of the screen should include this.',
  },
  {
    title:
      'The peak concurrency value for a selected split is different to the value shown for all splits?',
    content:
      'The concurrency values for each data point demonstrate the peak across all selected splits during that data point. The peak concurrency is a MOMENT IN TIME. Scenario: For a data point which is aggregated daily (i.e. one point on the graph per day), it is possible that the Peacock proposition could have achieved a peak of 1.3m in the early hours of the morning when very few Sky users were watching the platform. This peak could then have been surpassed in the evening at 1.4m across all Sky propositions, when only 400k people were watching Peacock. Filtering the graph for Peacock only would show a peak of 1.3m for that day. But for all propositions, the peak would be 1.4m, and the Peacock concurrency in the tooltip would show as 400k.',
  },
]

export default faqs
