import clsx from 'clsx'
import { HiSelector } from 'react-icons/hi'
import Skeleton from 'react-loading-skeleton'

import Avatar from '@/components/Avatar'
import { Menu } from '@headlessui/react'

import UserMenuDropDown from './UserMenuDropDown'

interface UserMenuProps {
  user?: ADUserI
  userLoading: boolean
  logout: () => void
  theme: string
  setTheme: (theme: ThemeT) => void
}

const UserMenu = ({
  user,
  userLoading = true,
  logout,
  theme,
  setTheme,
}: UserMenuProps) => {
  return (
    <Menu as='div' className='relative inline-block px-2 text-left'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={clsx(
                'group w-full bg-neutral border border-border-main rounded-md px-3 py-2 text-sm text-left font-medium text-text-secondary hover:bg-neutral-shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-screen focus-visible:ring-elements-primary-main',
                {
                  'bg-neutral-shadow': open,
                },
              )}
            >
              <span className='flex items-center justify-between w-full h-10'>
                <span className='flex items-center justify-between min-w-0 space-x-3'>
                  {user && (
                    <Avatar
                      size='md'
                      user={{
                        ...user,
                        email: user.userPrincipalName,
                      }}
                    />
                  )}
                  <span className='flex flex-col flex-1 min-w-0'>
                    <p className='text-sm font-medium truncate text-text-primary'>
                      {userLoading ? (
                        <Skeleton width={100} />
                      ) : (
                        `${user?.givenName} ${user?.surname}`
                      )}
                    </p>
                    <p className='text-sm truncate text-text-tertiary'>
                      {userLoading ? <Skeleton width={66} /> : user?.jobTitle}
                    </p>
                  </span>
                </span>
                <HiSelector
                  className='flex-shrink-0 w-5 h-5 text-text-dimmed group-hover:text-text-tertiary'
                  aria-hidden='true'
                />
              </span>
            </Menu.Button>
          </div>
          <UserMenuDropDown
            open={open}
            logout={logout}
            user={user}
            userLoading={userLoading}
            theme={theme}
            setTheme={setTheme}
            className='absolute left-0 right-0 z-10 mx-2 mt-1 origin-top border divide-y rounded-md shadow-lg border-border-main bottom-16 bg-neutral-dimmed-heavy ring-1 ring-black ring-opacity-5 divide-divider-main focus:outline-none'
          />
        </>
      )}
    </Menu>
  )
}

export default UserMenu
