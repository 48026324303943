import ReplaceRoute from './replaceRoute'

// Required for backwards compatible links after we changed the routing structure away from /reports
// and into /content for the HPE/Series event pages

export const reportsRedirects = [
  {
    path: 'events',
    element: (
      <ReplaceRoute
        replace={['/reports/events', '/content/high-profile-events']}
      />
    ),
  },
  {
    path: 'events/:eventId',
    element: (
      <ReplaceRoute
        replace={['/reports/events', '/content/high-profile-events']}
      />
    ),
  },
  {
    path: 'events/01189998819991197253',
    element: (
      <ReplaceRoute
        replace={['/reports/events', '/content/high-profile-events']}
      />
    ),
  },
  {
    path: 'events/edit',
    element: (
      <ReplaceRoute
        replace={['/reports/events', '/content/high-profile-events']}
      />
    ),
  },
  {
    path: 'series/:reportSeriesUuid',
    element: <ReplaceRoute replace={['/reports/series', '/content/series']} />,
  },
  {
    path: 'series/:reportSeriesUuid/:season',
    element: <ReplaceRoute replace={['/reports/series', '/content/series']} />,
  },
  {
    path: 'series/:reportSeriesUuid/:season/:episode',
    element: <ReplaceRoute replace={['/reports/series', '/content/series']} />,
  },
]

export const eventsRedirects = [
  {
    path: 'events',
    element: <ReplaceRoute replace={['/events', '/content/all-events']} />,
  },
]
