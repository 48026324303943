import { HiOutlineServer, HiServer } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonPrometheusCoreConfig from './common/prometheusCore'

const totalNodeRequestedCores: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusCoreConfig = commonPrometheusCoreConfig()

  return {
    ...prometheusCoreConfig,
    value: 'total-node-requested-cores',
    label: 'Total Node Requested Cores',
    shortLabel: 'Tot. Node Req. Cores',
    dataKey: 'totalNodeRequestedCores',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiServer,
    solidIcon: HiOutlineServer,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Total Node Requested Cores is the sum of the requested cores for all the jobs or tasks that have been scheduled to run on a specific node. It represents the total computational capacity allocated to that node based on the submitted job requests.',
    aggregationType: 'total',
    defaultConfig: {
      ...prometheusCoreConfig.defaultConfig,
      metric: 'total-node-requested-cores',
    },
  }
}

export default totalNodeRequestedCores
