import { ClientSettingsI } from '../types'

const clientSettings: ClientSettingsI = {
  name: 'NOW Gold',
  key: 'now-gold',
  themes: {
    light: 'now-gold-light',
    dark: 'now-gold-dark',
  },
  logo: {
    light: {
      app: '/images/now-gold/app-logo.png',
    },
    dark: {
      app: '/images/now-gold/app-logo.png',
    },
  },
  supportedStreamTypes: ['linear', 'vod'],
  metricComparison: { enabled: true }, // TODO: Deprecate me after Metrics Explorer launch
  metricsExplorer: {
    busiestDays: {
      metric: 'concurrent-plays',
      includeTopEvents: true,
    },
  },
}

export default clientSettings
