import { HiExclamationCircle, HiOutlineExclamationCircle } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonConvivaConfig from './common/conviva'

const videoPlaybackFailuresBusinessPercentage: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'video-playback-failures-business-percentage',
    label: 'VPF Business (%)',
    shortLabel: 'VPF-B (%)',
    dataKey: 'videoPlaybackFailuresBusinessPercentage',
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: true,
    supportsClient: false,
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlineExclamationCircle,
    solidIcon: HiExclamationCircle,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    info: 'Video playback business failures occur when video play terminates due to the user not having enough rights to play the content.',
    aggregationType: 'average',
    invertedChangeColor: true,
    ragThresholds: {
      green: 0,
      amber: 1,
      red: 3,
    },
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'video-playback-failures-business-percentage',
    },
  }
}

export default videoPlaybackFailuresBusinessPercentage
