import {
  deviceTypeFormOptions,
  operatingSystemFormOptions,
  propositionFormOptions,
  territoryFormOptions,
} from '@/config/formOptions/default'
import { FormI } from '@/config/types'

const form: FormI[] = [
  {
    value: 'proposition',
    type: 'button-group',
    multi: true,
    options: propositionFormOptions,
  },
  {
    value: 'territory',
    type: 'button-group',
    multi: true,
    options: territoryFormOptions,
  },
  {
    value: 'device-type',
    type: 'button-group',
    multi: true,
    options: deviceTypeFormOptions,
  },
  {
    value: 'operating-system',
    type: 'button-group',
    multi: true,
    options: operatingSystemFormOptions,
  },
]

const defaultConfig: ConfigI = {
  'split-by': 'proposition',
  proposition: ['all'],
  territory: ['all'],
  'device-type': ['all'],
  'operating-system': ['all'],
}

const recordsSettings: any = {
  filterOptions: form,
  defaultConfig,
  form,
  realtime: {
    topEvents: {
      streamTypeOptions: ['sle', 'vod', 'linear'],
      defaultStreamType: 'linear',
    },
  },
  topEvents: {
    streamTypeOptions: ['linear', 'vod', 'sle'],
    defaultStreamType: 'linear',
  },
  passActivationsAndCancellations: {
    enabled: true,
  },
  minutesAndUniques: {
    enabled: true,
    config: {
      'exclude-proposition': ['skyshowtime'],
    },
  },
}

export default Object.freeze(recordsSettings)
