import { IconType } from 'react-icons'
import { usePopperTooltip } from 'react-popper-tooltip'

import { cn } from '@/utils/cn'

const IconButton = ({
  delayShowTooltip = 0,
  hideOnMobile = false,
  label = '',
  Icon,
  onClick = () => null,
  tooltipPlacement = 'auto',
  disabled = false,
  className = 'border-transparent bg-neutral hover:bg-neutral-shadow lg:flex',
  iconClassName = 'text-text-tertiary hover:text-text-secondary',
  tooltipClassName,
}: {
  delayShowTooltip?: number
  hideOnMobile?: boolean
  label?: string
  Icon: IconType
  onClick?: () => void
  tooltipPlacement?: any
  disabled?: boolean
  className?: string
  iconClassName?: string
  tooltipClassName?: string
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: tooltipPlacement,
    delayShow: delayShowTooltip,
  })

  return (
    <>
      <button
        ref={setTriggerRef}
        type='button'
        aria-label={label}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
        className={cn(
          'justify-center p-2 border rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main',
          {
            'cursor-not-allowed opacity-25': disabled,
            'cursor-pointer': !disabled,
          },
          hideOnMobile ? 'hidden' : 'flex',
          iconClassName,
          className,
        )}
      >
        <Icon className='w-5 h-5' />

        <span className='sr-only'>{label}</span>
      </button>
      {visible && !!label && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container-themed',
          })}
        >
          <p
            className={cn(
              'text-sm font-medium text-text-secondary',
              tooltipClassName,
            )}
          >
            {label}
          </p>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  )
}

export default IconButton
