import { RiTimerFill, RiTimerLine } from 'react-icons/ri'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPrometheusApplicationConfig from './common/prometheusApplication'

const responseDuration: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusApplicationConfig = commonPrometheusApplicationConfig()

  return {
    ...prometheusApplicationConfig,
    value: 'response-duration',
    label: 'Response Duration (ms)',
    shortLabel: 'Res. Duration (ms)',
    dataKey: 'responseDuration',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'spline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: RiTimerLine,
    solidIcon: RiTimerFill,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'unit',
        unit: 'millisecond',
        unitDisplay: 'narrow',
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'unit',
        unit: 'millisecond',
        unitDisplay: 'narrow',
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
      }).format(value),
    info: 'Response Duration measures the time duration in milliseconds that it takes for certain operations to complete and helps identify potential performance bottlenecks or issues.',
    aggregationType: 'average',
    invertedChangeColor: true,
    defaultConfig: {
      ...prometheusApplicationConfig.defaultConfig,
      metric: 'response-duration',
    },
  }
}

export default responseDuration
