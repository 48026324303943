import React from 'react'

import getMinutesFromMs from '@/utils/getMinutesFromMs'

export function EventItemMeta({ event }: any) {
  const genre = event?.meta?.genre || null
  const subGenre = event?.meta?.subGenre || null
  const durationMilliseconds = event?.meta?.durationMilliseconds || null

  const genreExists = !!genre && genre !== 'Undefined'
  const subGenreExists = !!subGenre && subGenre !== 'Undefined'

  return (
    <div className='inline-flex items-center space-x-1 mt-0.5 text-sm truncate text-text-tertiary'>
      {genreExists && <p>{genre}</p>}
      {subGenreExists && (
        <>
          {genreExists && <span>∙</span>}
          <p>{subGenre}</p>
        </>
      )}
      {durationMilliseconds && (
        <>
          {(genreExists || subGenreExists) && <span>∙</span>}
          <p>{getMinutesFromMs(durationMilliseconds)}</p>
        </>
      )}
    </div>
  )
}
