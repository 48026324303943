import { MinutesUniquesSettingsI } from '@/config/types'

import { PROPOSITION } from '../formOptions'

import commonBigvivaConfig from './common/bigvivaConfig'

const minutesAndUniquesSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaConfig,
  form: [
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['peacock']],
    },
  ],
}

export default Object.freeze(minutesAndUniquesSettings)
