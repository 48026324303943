import {
  SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN,
  DEFAULT_DATE,
} from '@/config/constants'
import {
  PROPOSITION,
  TERRITORY,
  skyShowTimeTerritoryOptions,
} from '@/config/formOptions'

import { FormI, VariantsT } from '@/config/types'

import { GROUPS } from '@/config/metricsSettings/groups'
import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonBigvivaSdpConfig = ({ variants }: { variants?: VariantsT }) => {
  const form: FormI[] = [
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['skyshowtime']],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...skyShowTimeTerritoryOptions],
    },
  ]

  return {
    dataSource: 'SDP BigQuery',
    legacyGroup: 'sdp',
    group: GROUPS.SDP,
    apiDomain: SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN,
    supportsClient: true,
    apiRoute: 'subscription-events',
    timeseriesAsParam: true,
    metricAsParam: true,
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonFiveMinuteGranularity,
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },

    defaultConfig: {
      'split-by': 'territory',
      proposition: ['skyshowtime'],
      territory: ['all'],
      mysql: true,
      launched: true,
      client: 'skyshowtime',
      ...DEFAULT_DATE('skyshowtime'),
    },
  }
}
export default commonBigvivaSdpConfig
