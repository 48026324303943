import * as React from 'react'

import themes from '../../themes'
import { useThemeStore } from '../stores/theme.store'
import useSettings from './useSettings'

const useTheme = () => {
  const { clientSettings } = useSettings()

  if (clientSettings) {
    const selectedTheme = useThemeStore((state) => state.theme)
    const resolvedTheme = clientSettings.themes[selectedTheme]

    const theme = React.useMemo(
      () => themes?.find(({ value }) => value === resolvedTheme),
      [resolvedTheme, themes],
    )

    return theme
  }
  return null
}

export const useSelectedTheme = () => {
  const selectedTheme = useThemeStore((state) => state.theme)

  return selectedTheme
}

export default useTheme
