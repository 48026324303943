import colors from 'tailwindcss/colors'

const shadeOrder = [
  '500',
  '300',
  '700',
  '100',
  '900',
  '200',
  '600',
  '400',
  '800',
]

const colorBlockList = [
  'inherit',
  'current',
  'transparent',
  'black',
  'white',
  'slate',
  'zinc',
  'neutral',
  'stone',
  'warmGray',
  'trueGray',
  'coolGray',
  'blueGray',
]

// Default Initial colors
const defaultInitialChartColors: string[] = [
  colors.gray['500'],
  colors.orange['500'],
  colors.pink['500'],
  colors.blue['500'],
  colors.purple['500'],
  colors.yellow['500'],
  colors.sky['500'],
  colors.rose['500'],
]



function getChartColors(initialChartColors?: string[]) {
  const chartColors: string[] =
    initialChartColors && initialChartColors.length > 0
      ? [...initialChartColors]
      : [...defaultInitialChartColors]

  for (const shade of shadeOrder) {
    for (const color in colors) {
      if (!colorBlockList.includes(color)) {
        const colorShade = (colors as any)[color][shade]
        if (colorShade && !chartColors.includes(colorShade)) {
          chartColors.push(colorShade)
        }
      }
    }
  }

  return chartColors
}

export default getChartColors
