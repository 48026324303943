import {
  HiOutlineChartSquareBar,
  HiOutlineChatAlt,
  HiOutlineClipboardList,
  HiOutlineDocumentText,
  HiOutlineHome,
  HiOutlineMail,
  HiOutlinePlay,
  HiOutlineQuestionMarkCircle,
  HiOutlineTable,
} from 'react-icons/hi'
import { MdListAlt } from 'react-icons/md'

import { NavigationSettingsI } from '../types'
import { NavigationPropsI } from './index'

const navigation = (props: NavigationPropsI): NavigationSettingsI => {
  const nav = [
    {
      id: 'main-nav',
      children: [
        {
          id: 'home',
          label: 'Home',
          icon: HiOutlineHome,
          url: '/',
        },
        {
          id: 'metrics',
          label: 'Metrics',
          icon: HiOutlineChartSquareBar,
          url: '/metrics',
        },
        {
          id: 'content',
          label: 'Content',
          icon: HiOutlinePlay,
          url: '/content/all-events',
        },
        {
          id: 'reports',
          label: 'Reports',
          icon: HiOutlineDocumentText,
          url: '/reports',
          defaultExpanded: true,
          children: [
            {
              id: 'weekly-viewing',
              label: 'Weekly Viewing',
              url: '/reports/weekly-viewing',
              icon: HiOutlineClipboardList,
            },
          ],
        },
      ],
    },
    {
      id: 'secondary-nav',
      header: 'Support',
      children: [
        {
          id: 'contact',
          label: 'Contact',
          icon: HiOutlineMail,
          url: '/contact',
        },
        {
          id: 'faqs',
          label: 'FAQs',
          icon: HiOutlineQuestionMarkCircle,
          url: '/faqs',
        },
        // {
        //   id: 'roadmap',
        //   label: 'Roadmap',
        //   icon: HiOutlineMap,
        //   url: '/roadmap',
        // },
        {
          id: 'metrics-definitions',
          label: 'Metric Definitions',
          icon: MdListAlt,
          url: '/metric-definitions',
          hidden: !props.featureFlags['FF_METRIC_DEFINITIONS_PAGE_ENABLED'],
        },
      ],
    },
  ]

  if (props.adminMode) {
    return [
      ...nav,
      {
        id: 'admin-nav',
        header: 'Admin',
        children: [
          {
            id: 'manual-notification',
            label: 'Send Notification',
            icon: HiOutlineChatAlt,
            url: '/manual-notification',
          },
          {
            id: 'manual-notifications-admin',
            label: 'Notifications Admin',
            icon: HiOutlineTable,
            url: '/manual-notification/admin',
          },
        ],
      },
    ]
  }
  return nav
}

export default navigation
