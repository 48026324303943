import { RiTimerFlashFill, RiTimerFlashLine } from 'react-icons/ri'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPrometheusApplicationConfig from './common/prometheusApplication'

const tps: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusApplicationConfig = commonPrometheusApplicationConfig()

  return {
    ...prometheusApplicationConfig,
    value: 'tps',
    label: 'TPS',
    shortLabel: 'TPS',
    dataKey: 'TPS',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'spline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: false,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: RiTimerFlashLine,
    solidIcon: RiTimerFlashFill,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 0,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format(
        value,
      ),
    info: `TPS refers to "transactions per second", it is used to measure the rate at which transactions are occurring within the application layer.`,
    calculation: 'Total response requests within a 1 minute period/60',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusApplicationConfig.defaultConfig,
      metric: 'tps',
    },
  }
}

export default tps
