import React from 'react'
import clsx from 'clsx'
import getDateRangeString from '@/utils/getDateRangeString'
import moment from 'moment'
import { motion } from 'framer-motion'
import { usePopperTooltip } from 'react-popper-tooltip'

export const DiffBar = ({
  start,
  end,
  itemStart,
  itemEnd,
  bgClassName = 'bg-neutral',
  enabled = false,
}: any) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'auto' })

  const _start = Number(moment(start).clone().unix()) * 1000
  const _end = Number(moment(end).clone().unix()) * 1000

  const _itemStart = Number(moment(itemStart).clone().unix()) * 1000
  const _itemEnd = Number(moment(itemEnd).clone().unix()) * 1000

  const length = (_itemEnd - _itemStart) / (_end - _start)
  const offset = 1 - (_end - _itemStart) / (_end - _start)

  const dateRangeString = getDateRangeString({
    start: itemStart,
    end: itemEnd,
    includeDay: true,
  })

  return (
    <>
      <div className={clsx('relative h-2 overflow-hidden', bgClassName)}>
        <motion.div
          initial={{ x: '0%', opacity: 0 }}
          animate={{ x: `${offset * 100}%`, opacity: 1 }}
        >
          <div
            ref={setTriggerRef}
            className={clsx(
              'h-2 rounded-full bg-opacity-20 bg-elements-primary-main border-elements-primary-main',
              {
                'opacity-0': !enabled,
              },
            )}
            style={{
              width: `${length * 100}%`,
              borderLeftWidth: '0.375rem',
              borderRightWidth: '0.375rem',
            }}
          />
        </motion.div>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container-themed',
          })}
        >
          <p className='text-sm font-medium text-text-secondary'>
            {dateRangeString.start} - {dateRangeString.end}
          </p>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  )
}
