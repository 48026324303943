/**
 *
 * @param value number, the number to be formatter
 * @param isPercent boolean, true if the figure should be formatted as percentage
 * @param isFriendly  boolean, true if the figure should be formatted as a friendly number (eg:  12K instead of 12,000)
 * @returns string
 */
export const valueFormat = (
  value?: number,
  isPercent?: boolean,
  isFriendly?: boolean,
) => {
  if (value && value !== 0) {
    if (isPercent) {
      return new Intl.NumberFormat('en-GB', {
        unit: 'percent',
        style: 'unit',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(value)
    }

    if (isFriendly) {
      return abbrNum(value)
    }

    return new Intl.NumberFormat('en-GB', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(value)
  }

  return ''
}

export default valueFormat

function abbrNum(number: number) {
  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T']
  let formattedNum = ''

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3)

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      number = (number * 2) / size / 2

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i++
      }
      // Add the letter for the abbreviation
      formattedNum = number.toLocaleString() + abbrev[i]

      // We are done... stop
      break
    }
  }
  if (formattedNum !== '') {
    return formattedNum
  }
  return number.toLocaleString()
}
