import { Moment } from 'moment'

import { SettingsI } from '@/config/types'

const getEventsConfigDateRange = ({
  config,
  settings,
}: {
  config: ConfigI
  settings: SettingsI
}): ConfigI => {
  // If it's not VOD
  if (!config?.['stream-type']?.includes('vod')) {
    const nextConfig = {
      ...config,
    }

    // Check if a VOD date config is already applied, and if yes reset it to start and end
    if (nextConfig['time-period']) {
      const { relativeDate } = settings.defaultConfig
      const dateParams = relativeDate?.getDateRange() as {
        start: Moment
        end: Moment
      }

      nextConfig.dateType = 'relative'
      nextConfig.relativeDate = relativeDate
      nextConfig['time-period'] = null

      if (dateParams?.start) {
        nextConfig.start = dateParams.start.clone().utc().format()
      }
      if (dateParams?.end) {
        nextConfig.end = dateParams.end.clone().utc().format()
      }
    }

    return nextConfig
  }

  // If it's VOD, switch to VOD date range options
  const dates = settings.dates

  let DEFAULT_DATE = {}

  if (config['time-period'] && config['time-period'] !== null) {
    const matchingRelativeDate = dates?.find(
      ({ id }) => id === config['time-period'],
    )

    DEFAULT_DATE = {
      dateType: 'relative' as const,
      relativeDate: matchingRelativeDate,
      'time-period': config['time-period'],
    }
  } else {
    const defaultRelativeDate = dates?.find(({ isDefault }) => isDefault)
    const defaultDateParams = defaultRelativeDate?.getDateRange() as {
      'time-period': string
    }
    const timePeriod = defaultDateParams?.['time-period']

    DEFAULT_DATE = {
      dateType: 'relative' as const,
      relativeDate: defaultRelativeDate,
      'time-period': timePeriod,
    }
  }

  return {
    ...config,
    ...DEFAULT_DATE,
  }
}

export default getEventsConfigDateRange
