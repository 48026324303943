import { RiTimerFlashFill, RiTimerFlashLine } from 'react-icons/ri'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPrometheusPersistenceConfig from './common/prometheusPersistence'

const latency: MetricSettingsFn = (): MetricSettingsI => {
  const prometheusPersistenceConfig = commonPrometheusPersistenceConfig()

  return {
    ...prometheusPersistenceConfig,
    hideInMetricsExplorer: true,
    value: 'latency',
    label: 'Latency',
    shortLabel: 'Latency',
    dataKey: 'latency',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: RiTimerFlashLine,
    solidIcon: RiTimerFlashFill,
    yAxisFormat: (value: number): string => {
      return `${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format(value)}s`
    },
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'unit',
        unit: 'second',
        unitDisplay: 'narrow',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    info: 'Latency is a critical factor in assessing the responsiveness and performance of a network. Latency measures the delay or time it takes for data to travel from source to the destination (in seconds). Lower latency is generally associated with better throughput, especially for applications that require real-time communication.',
    aggregationType: 'average',
    defaultConfig: {
      ...prometheusPersistenceConfig.defaultConfig,
      metric: 'latency',
    },
  }
}

export default latency
