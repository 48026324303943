import ReactSelect, { components } from 'react-windowed-select'

import { FormOptionConfigI } from '@/config/types'
import React from 'react'
import tinycolor from 'tinycolor2'
import useTheme from '../../hooks/useTheme'

const Control = ({ children, ...props }: any) => {
  const value = props?.getValue()[0]
  return (
    <components.Control {...props}>
      {value?.icon && !props?.isMulti ? (
        <value.icon
          className='w-5 h-5 ml-1.5 text-text-dimmed'
          aria-hidden='true'
        />
      ) : null}
      {children}
    </components.Control>
  )
}

const Option = ({ children, ...props }: any) => {
  const { data } = props
  return (
    <components.Option {...props}>
      {data?.icon ? (
        <props.data.icon
          className='flex-shrink-0 w-5 h-5 mr-3 text-inherit'
          aria-hidden='true'
        />
      ) : null}
      {data?.image ? (
        <img
          onError={(e) => {
            const target = e.target as HTMLImageElement
            target.onerror = null
            target.src = '/images/tv-app.png'
          }}
          src={data.image}
          alt={data?.label}
          className='flex-shrink-0 w-5 h-5 mr-3 rounded-md'
          aria-hidden='true'
        />
      ) : null}
      <span className='truncate'>{children}</span>
    </components.Option>
  )
}

const MultiValue = ({ children, ...props }: any) => {
  const { data } = props

  return (
    <components.MultiValue {...props}>
      <div className='flex items-center'>
        {data?.icon ? (
          <props.data.icon
            className='w-4 h-4 mr-2 text-inherit'
            aria-hidden='true'
          />
        ) : null}
        {data?.image ? (
          <img
            src={data.image}
            alt={data?.label}
            className='w-4 h-4 mr-2 rounded-md'
            aria-hidden='true'
          />
        ) : null}
        {children}
      </div>
    </components.MultiValue>
  )
}

interface SelectOptionI extends FormOptionConfigI {
  value: string
}

interface SelectPropsI {
  options: SelectOptionI[]
  value?: string | string[] | null
  onChange: (value: string) => void
  overrideStyles?: { [key: string]: any }
  isSearchable?: boolean
  isMulti?: boolean
  metricComparison?: boolean
  closeMenuOnSelect?: boolean
  [key: string]: any
}

const getSelectedOptions = ({
  options,
  value,
  isMulti,
}: {
  options: SelectOptionI[]
  value?: string | string[] | null
  isMulti?: boolean
}) => {
  if (isMulti) {
    const selectedOptions = options.filter((option) => {
      if (Array.isArray(value)) {
        return value.findIndex((v) => v === option.value) !== -1
      }
      return false
    })
    return selectedOptions
  }
  return options.find((option: any) => option?.value === value)
}
const Select = ({
  options = [],
  value,
  onChange,
  overrideStyles,
  isSearchable = true,
  ...props
}: SelectPropsI) => {
  const [inputValue, setInputValue] = React.useState('')
  const { theme } = useTheme()!

  const [selected, setSelected] = React.useState(() => {
    return getSelectedOptions({ options, value, isMulti: props?.isMulti })
  })

  const handleChange = React.useCallback(
    (_selected: any) => {
      setSelected(_selected)
      if (props?.isMulti) {
        onChange(_selected?.map((option: any) => option?.value))
      } else {
        onChange(_selected?.value)
      }
    },
    [props?.isMulti, onChange],
  )

  // This was causing the Select to reset after every selection
  // React.useEffect(() => {
  //   setSelected(getSelectedOptions({ options, value, isMulti: props?.isMulti }))
  // }, [options])

  const customStyles = (overrideStyles?: { [key: string]: any }) => {
    return {
      input: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-primary'],
        ...overrideStyles?.input,
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        fontSize: '1rem',
        fontFamily: 'inherit',
        borderColor: state.isFocused
          ? theme?.colors['elements-primary-main']
          : theme?.colors['border-main'],
        borderWidth: 1,
        borderRadius: '0.5rem', //rounded-lg
        outline: 'none',
        backgroundColor: theme?.colors['neutral-dimmed-heavy'], // add option for 'tranparent'?
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 6,
        paddingRight: 6,
        overflowX: 'hidden',
        cursor: isSearchable ? 'default' : 'pointer',
        '&:hover': {
          borderColor: state.isFocused
            ? theme?.colors['elements-primary-main']
            : theme?.colors['text-dimmed'],
        },
        '*': {
          boxShadow: 'none !important',
        },
        boxShadow: state.isFocused
          ? `0 0 0 1px ${theme?.colors['elements-primary-main']}`
          : 'none',
        ...overrideStyles?.control,
      }),
      menu: (provided: any) => ({
        ...provided,
        fontFamily: 'inherit',
        fontSize: '1rem',
        backgroundColor: theme?.colors['neutral-dimmed-heavy'],
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', //shadow-lg
        border: `1px solid ${theme?.colors['border-main']}`,
        borderRadius: '0.75rem',
        paddingLeft: 8,
        paddingRight: 8,
        ...overrideStyles?.menu,
        overflowX: 'hidden',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        overflowX: 'hidden',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: 1.2,
        paddingTop: 12,
        paddingBottom: 12,
        marginTop: 4,
        marginBottom: 4,
        borderRadius: '0.5rem',
        backgroundColor: state.isSelected
          ? tinycolor(theme?.colors['elements-primary-main'])
              .setAlpha(0.06)
              .toString()
          : theme?.colors['neutral-dimmed-heavy'],
        color: state.isSelected
          ? theme?.colors['elements-primary-main']
          : theme?.colors['text-secondary'],
        fontWeight: state.isSelected ? 500 : 400,
        border: state.isSelected
          ? `1px solid ${theme?.colors['elements-primary-main']}`
          : '',
        '&:hover': {
          ...provided['&:hover'],
          backgroundColor: tinycolor(theme?.colors['elements-primary-main'])
            .setAlpha(0.11)
            .toString(),
          color: theme?.colors['elements-primary-main'],
        },
      }),
      multiValue: (provided: any) => {
        return {
          ...provided,
          backgroundColor: theme?.colors['neutral'],
          border: `1px solid ${theme?.colors['border-main']}`,
          borderRadius: '0.25rem',

          ...overrideStyles?.multiValue,
        }
      },
      multiValueLabel: (provided: any) => ({
        ...provided,
        backgroundColor: theme?.colors['neutral'],
        color: theme?.colors['text-primary'],
        fontSize: '0.875rem',
        fontWeight: 400,
        paddingLeft: 8,
        paddingRight: 6,
        ...overrideStyles?.multiValueLabel,
      }),
      multiValueRemove: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-dimmed'],
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme?.colors['neutral-shadow'],
          color: theme?.colors['helpers-error-main'],
        },
        ...overrideStyles?.multiValueRemove,
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-tertiary'],
        ...overrideStyles?.dropdownIndicator,
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-primary'],
        fontSize: '1rem',
        lineHeight: 1.2,
        overflow: 'hidden',
        ...overrideStyles?.singleValue,
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: theme?.colors['border-main'],
        ...overrideStyles?.indicatorSeparator,
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-dimmed'],
        '&:hover': {
          ...provided['&:hover'],
          color: theme?.colors['helpers-error-main'],
          ...overrideStyles?.clearIndicator?.['&:hover'],
        },
        ...overrideStyles?.clearIndicator,
      }),
      noOptionsMessage: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-tertiary'],
        ...overrideStyles?.noOptionsMessage,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: theme?.colors['text-dimmed'],
        ...overrideStyles?.placeholder,
      }),
    }
  }

  return (
    <ReactSelect
      windowThreshold={0}
      components={{
        Control,
        Option,
        MultiValue,
      }}
      value={selected}
      onChange={handleChange}
      options={
        Array.isArray(selected)
          ? options.filter(
              (option: any) =>
                !selected?.find(
                  (selectedOption: any) =>
                    selectedOption.value === option.value ||
                    selectedOption === option.value,
                ),
            )
          : options
      }
      className='w-full'
      styles={customStyles(overrideStyles)}
      isSearchable={isSearchable}
      inputValue={inputValue}
      onInputChange={(value: any, action: any) => {
        // Don't clear search after selecting an option in multi value
        if (props.isMulti && props.closeMenuOnSelect === false) {
          if (action.action === 'input-change') setInputValue(value)
        }
      }}
      {...props}
    />
  )
}

export default Select
