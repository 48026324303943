import { HiOutlinePlay, HiPlay } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import { valueFormat } from '@/utils/valueFormat'

import commonConvivaConfig from './common/conviva'

const plays: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'plays',
    label: 'Plays',
    dataKey: 'plays',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlinePlay,
    solidIcon: HiPlay,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Plays shows the number of sessions where at least one frame of a video was displayed over a given interval.',
    aggregationType: 'total',
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'plays',
    },
  }
}

export default plays
