import React from 'react'

import Loading from '../Loading'

const PageLoading = () => {
  return (
    <div className='flex items-center justify-center min-h-screen bg-neutral-dimmed-heavy'>
      <Loading lightTheme={false} />
    </div>
  )
}

export default PageLoading
