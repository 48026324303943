import { LuBookOpen } from 'react-icons/lu'

import { DEFAULT_DATE, PROMETHEUS_API_DOMAIN } from '@/config/constants'
import { SPLIT_BY } from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI } from '@/config/types'

const commonPrometheusCoreNetworkConfig = () => {
  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [SPLIT_BY['proposition'], SPLIT_BY['cloud-region']],
    },
    // Rest of options will come from tags endpoint
  ]

  return {
    dataSource: 'Prometheus',
    legacyGroup: 'prometheus',
    group: GROUPS.PROMETHEUS_CORE_NETWORK,
    apiDomain: PROMETHEUS_API_DOMAIN,
    supportsClient: true,
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: true,
    timeseriesAsParam: true,
    groupAsParam: true,
    tags: {
      enabled: true,
      endpoint: '/tags',
      params: { 'metric-group': GROUPS.PROMETHEUS_CORE_NETWORK.value },
    },
    disclaimer:
      'This is a visualisation of the data received from GST Core teams. Please note that NOW and Sky Europe core metrics come from the same data clusters and cannot be filtered by proposition.',
    externalLinks: [
      {
        icon: LuBookOpen,
        label: 'Prometheus Metrics Data Sources',
        href: 'https://wiki.at.sky/pages/viewpage.action?pageId=509238217',
      },
    ],
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonMinuteGranularity,
    defaultConfig: {
      'split-by': 'proposition',
      proposition: ['all'],
      ['cloud-region']: ['all'],
      namespace: ['all'],
      ...DEFAULT_DATE('now'),
    },
  }
}
export default commonPrometheusCoreNetworkConfig
