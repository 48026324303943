import clsx from 'clsx'
import { startCase } from 'lodash'

import { FormOptionI } from '@/config/types'

interface PropsI {
  name: string
  multi?: boolean
  options: FormOptionI[]
  selected?: string | string[]
  handleUpdateConfig: (value: string | string[], delay?: number) => void
}

const FormButtonGroup = ({
  name,
  multi,
  options = [],
  selected,
  handleUpdateConfig,
}: PropsI) => {
  return (
    <>
      {options.map(([value, config = {}]) => {
        const { label, icon: Icon, image } = config
        const id = name + value

        const checked =
          multi && Array.isArray(selected)
            ? selected?.indexOf(value) !== -1
            : selected === value || selected?.[0] === value

        return (
          <label
            key={value}
            className={clsx(
              'relative py-2 border border-transparent px-4 cursor-pointer focus:outline-none flex items-center mx-1 my-1 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-elements-primary-main focus-within:ring-offset-neutral-dimmed-heavy group',
              {
                'bg-neutral 2xl:bg-neutral-shadow': checked,
                'hover:bg-neutral-dimmed': !checked,
              },
            )}
          >
            <div className='flex items-center h-5'>
              <input
                checked={checked}
                onChange={() => handleUpdateConfig(value, multi ? 600 : 0)}
                id={id}
                name={multi ? id : name}
                type={multi ? 'checkbox' : 'radio'}
                className={clsx(
                  'w-4 h-4 bg-neutral-dimmed-heavy border-border-shadow cursor-pointer text-elements-primary-shadow focus:ring-elements-primary-main focus:ring-offset-neutral-shadow',
                  {
                    rounded: multi,
                  },
                )}
              />
            </div>
            <div
              className={clsx(
                'ml-3 flex items-center font-medium text-sm text-text-secondary group-hover:text-text-primary',
              )}
            >
              {Icon && (
                <Icon
                  className={clsx(
                    'w-5 h-5 mr-2 group-hover:text-text-tertiary',
                    {
                      'text-text-dimmed': !checked,
                      'text-text-tertiary': checked,
                    },
                  )}
                />
              )}
              {image && (
                <img
                  src={image}
                  alt={value}
                  className='max-h-5 w-5 object-contain ml-0.5 mr-2 rounded-sm'
                  onClick={(e) => e.stopPropagation()}
                />
              )}
              <span onClick={(e) => e.stopPropagation()}>
                {label || startCase(value)}
              </span>
            </div>
          </label>
        )
      })}
    </>
  )
}

export default FormButtonGroup
