import { HiOutlineTicket, HiTicket } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import valueFormat from '@/utils/valueFormat'

import commonBigvivaSdpConfig from './common/bigvivaSdp'

const activations: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const bigvivaSdpConfig = commonBigvivaSdpConfig({ variants })

  return {
    ...bigvivaSdpConfig,
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: true,
    value: 'activations',
    label: 'Now Pass Activations',
    dataKey: 'activations',
    icon: HiOutlineTicket,
    solidIcon: HiTicket,
    yAxisFormat: (value: number) => valueFormat(value, false, true),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    aggregationType: 'total',
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: false,
        logic: 'total',
      },
    },
    info: 'Now Pass Activations shows the total number of activated Now or Sky X passes during a given interval.',
    defaultConfig: {
      ...bigvivaSdpConfig.defaultConfig,
      metric: 'activations',
    },
  }
}

export default activations
